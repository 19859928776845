import React from 'react';
import { Amplify } from 'aws-amplify';

import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import cognitoConfiguration from './config/cognito';
import useCachedResources from './hooks/useCachedResources';
import Navigation from './navigation';
import { Provider } from 'mobx-react';
import rootStore from './store/rootStore';
import 'react-native-gesture-handler';
import { components, formFields } from './screens/Auth/LogInStyling'
import 'aws-amplify/auth/enable-oauth-listener';

/** HOTJAR */
import Hotjar from '@hotjar/browser';

const siteId = 3868904;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);
/** END HOTJAR */


Amplify.configure(cognitoConfiguration);


// import  AuthUser from '@aws-amplify/auth';
//import userPoolData from './config/cognito';
// import awsExports from './aws-exports'
export default function App () {
    const isLoadingComplete = useCachedResources();

    //start
    // const [user, setUser] = useState<AuthUser | null>(null);
    // const [error, setError] = useState<unknown>(null);
    // const [customState, setCustomState] = useState<string | null>(null);

    

    if (!isLoadingComplete) {
        return null;
    } else {

        return (
            <Authenticator initialState='signIn' socialProviders={['apple', 'facebook', 'google']} components={components} formFields={formFields} className='authenticator-container'>    
                <SafeAreaProvider>
                    <Provider {...rootStore}>
                        <Navigation />
                        <StatusBar />
                    </Provider>
                </SafeAreaProvider>
            </Authenticator>
        );
    }
}
