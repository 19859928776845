
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getDatabase } from 'firebase/database';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyDnfYvlQF0Vjn_p-f0B2QJMIjJ7FPMWlRM',
    authDomain: 'app-template-169bf.firebaseapp.com',
    projectId: 'app-template-169bf',
    storageBucket: 'app-template-169bf.appspot.com',
    // storageBucket: 'app-template-169bf-properties',
    messagingSenderId: '525384359741',
    appId: '1:525384359741:web:5387aea182ca7330566f54',
    measurementId: 'G-QFX7S8GJ66'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const rtdb = getDatabase(app);

export {
    auth,
    analytics,
    db,
    functions,
    rtdb
};
