import React, { Component } from 'react';
import { View, StyleSheet, TextInput, TouchableOpacity, TextInputProps, Pressable } from 'react-native';
import Colors from '../../constants/colors';
import { FontAwesome5 } from '@expo/vector-icons';

interface IbaseTextInput extends TextInputProps {
  setText ? : (e : string)=>void
  icon ? : string
  iconPosition ? : 'before' | 'after'
  rightComponent ? : Component
  border ? : boolean
  value ? : string
  heightNarrow ? : boolean
  iconOnPress ? : React.Dispatch<string>,
  placeholder ? : string
}

export const HousepoTextInput : React.FC<IbaseTextInput> =(props) => {
    return (
        <View style={[styles.container, !props.border && styles.noBorderContainer, props.heightNarrow && styles.narrowContainer]}>
            { props.iconPosition == 'before' &&
                <Pressable onPress={props.iconOnPress}>
                    <FontAwesome5
                        name={props.icon}
                        size={14}
                        style={[styles.icon, styles.iconBefore, props.heightNarrow && styles.narrowIcon, !props.border && styles.noPaddingLeft]}
                    />
                </Pressable>
            }
            <TextInput
                autoCapitalize='sentences'
                style={[styles.input, props.heightNarrow && styles.narrowInput]}
                placeholder={props.placeholder}
                placeholderTextColor={Colors.grey3}
                onChangeText={props.setText}
                value={props.value}
                onSubmitEditing={props.iconOnPress}
            />
            { props.iconPosition && props.iconPosition !== 'before' &&
                <FontAwesome5
                    name={props.icon}
                    size={14}
                    style={[styles.icon, styles.iconAfter, props.heightNarrow && styles.narrowIcon, !props.border && styles.noPaddingLeft]}
                />
            }
            {props.rightComponent}
        </View>
    );
};

export const HousepoPhoneInput : React.FC<IbaseTextInput> = (props) => {
    return (
        <HousepoTextInput 
            setText={props.setText}  
            icon="phone" 
            iconPosition="after" 
            placeholder="Phone Number" />
    );
};

export const HousepoEmailInput : React.FC<IbaseTextInput> = (props) => (
    <HousepoTextInput 
        setText={props.setText} 
        icon="envelope" 
        iconPosition="after" 
        placeholder="Email" />
);

export const HousepoSearchInput: React.FC<IbaseTextInput> = (props) => {
    return (
        <HousepoTextInput 
            icon="search" 
            setText={props.setText} 
            iconPosition="before" 
            placeholder="Search suburb, home or style" 
            border={false} />
    );
};

export const HousepoPasswordInput : React.FC<IbaseTextInput> = (props) => {
    const [showPassword, setShowPassword] = React.useState(false);
    return (
        <View style={styles.container}>
            <TextInput
                style={styles.input}
                placeholder={'Password'}
                placeholderTextColor={Colors.grey3}
                onChangeText={props.setText}
                secureTextEntry={!showPassword}
                autoCapitalize="none"
                autoCorrect={false}
                  
            />

            <TouchableOpacity onPress={() => setShowPassword(!showPassword)}>
                { showPassword
                    ? <FontAwesome5 
                        name={'eye-slash'}
                        size={14}
                        style={[styles.icon, styles.iconAfter]}
                    />
                    : <FontAwesome5
                        name={'eye'}
                        size={14}
                        style={[styles.icon, styles.iconAfter]}
                    />
                }
            </TouchableOpacity>
        </View>
    );
};

export const HouspoMessageInput : React.FC<IbaseTextInput> = (props) => {
    return (
        <View style={[styles.container]}>
            <TextInput
                autoCapitalize='sentences'
                style={[styles.input]}
                placeholder={props.placeholder}
                onChangeText={props.setText}
                value={props.value}
                numberOfLines={8}
                multiline={true}
            />
        </View>
    );
};

export default HousepoTextInput;

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderWidth: 1,
        borderColor: Colors.grey5,
        marginBottom: 15,
        borderRadius: 4,
        backgroundColor: Colors.white,
        alignContent  : 'center',
        alignItems:'center',
        width: '100%'
    },

    narrowContainer: {
        marginBottom: 0
    },

    noBorderContainer: {
        borderColor: 'transparent',
    },

    input: {
        paddingHorizontal: 16,
        paddingVertical: 19,
        color: Colors.navy,
        fontSize: 14,
        width: '90%',
        flex : 1,
        outlineStyle: 'none' 
    },

    icon: {
        paddingVertical: 20,
        color: Colors.grey3
    },

    narrowInput: {
        paddingVertical: 10,
    },

    narrowIcon: {
        paddingVertical: 10,
    },

    iconBefore: {
        paddingLeft: 16
    },

    iconAfter: {
        paddingRight: 16
    },

    noPaddingLeft: {
        paddingLeft: 0
    }
});
