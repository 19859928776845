import React, {useState} from 'react';
import { StyleSheet, Text, Pressable } from 'react-native';
import Colors from '../../constants/colors';

export type TFilterTab = {
  label : string
  onPress : ()=>void
  key : string
  active : boolean
}

const FilterTab = (props : TFilterTab) => {
    const [active, setActive] = useState< boolean >(props.active);

    const btnOnPress = () => {
        setActive(!active);
        props.onPress();
    };
   
    return (
        <Pressable onPress={()=>btnOnPress()} style={[styles.options, active && styles.optionsActive]}>
            <Text style={[styles.optionsText, active && styles.optionsTextActive]}>{props.label}</Text>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    options: {
        borderColor: Colors.navy,
        borderWidth: 1,
        borderRadius: 8,
        paddingVertical: 5,
        paddingHorizontal: 10,
        marginRight: 10,
        marginBottom: 10
    },
    optionsText: {
        fontSize: 12
    },
    optionsActive: {
        backgroundColor: Colors.navy,
    },
    optionsTextActive: {
        color: Colors.white
    }
});

export default FilterTab;
