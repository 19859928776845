import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { AddEstimateScreen } from '../screens/AddListing/AddEstimateScreen';
import { FeatureScreen } from '../screens/AddListing/FeatureScreen';
import { LocationScreen } from '../screens/AddListing/LocationScreen';
import { NamePropertyScreen } from '../screens/AddListing/NamePropertyScreen';
import { RearrangePhotosScreen } from '../screens/AddListing/RearrangePhotos';
import { ReviewListingScreen } from '../screens/AddListing/ReviewListingScreen';
import { SelectStatusScreen } from '../screens/AddListing/SelectStatusScreen';
import { TypeScreen } from '../screens/AddListing/TypeScreen';
import { StyleScreen } from '../screens/AddListing/StyleScreen';
import { UploadScreen } from '../screens/AddListing/UploadScreen';
import { AddListingStackParamList } from '../types';

const AddListingStack = createNativeStackNavigator<AddListingStackParamList>();
export function AddListingNavigator () {
    return (
        <AddListingStack.Navigator initialRouteName='type' screenOptions={{ headerShown: false }}>
            <AddListingStack.Screen name="type" component={TypeScreen} />
            <AddListingStack.Screen name="style" component={StyleScreen} />
            <AddListingStack.Screen name="location" component={LocationScreen} />
            <AddListingStack.Screen name="features" component={FeatureScreen} />
            <AddListingStack.Screen name="name" component={NamePropertyScreen} />
            <AddListingStack.Screen name="upload" component={UploadScreen} />
            <AddListingStack.Screen name="arrange" component={RearrangePhotosScreen} />
            <AddListingStack.Screen name="estimate" component={AddEstimateScreen} />
            <AddListingStack.Screen name="status" component={SelectStatusScreen} />
            <AddListingStack.Screen name="review" component={ReviewListingScreen} />
        </AddListingStack.Navigator>
    );
}
