import React, { useState } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Animated, TextInput } from 'react-native';
import Colors from '../constants/colors';
import FontProperty from '../constants/FontProperty';
import { Ionicons } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import Layout from '../constants/Layout';
import { setPollProperty } from '../lib/api/Content/posts/posts';
import { ScrollView } from 'react-native-gesture-handler';
import { numberWithCommas } from '../lib/utils/numFormatter';

export type TQuestionModel = {
    open ? : boolean,
    title : string,
    succeedTitle : string,
    succeedText ? : string,
    userId : string,
    propertyId : string,
    lowPrice : number,
    highPrice : number,
    setContactHomeownerModal : React.Dispatch<boolean>
    setHasFinishedPoll : React.Dispatch<boolean>
    setOpenQuestionModal : React.Dispatch<boolean>
    setHasCompletedPoll : React.Dispatch<boolean>
}

const QuestionModel : React.FC< TQuestionModel > = (props) => {
    const [finalAnwsers, setFinalAwnsers] = useState<string[]>([]);
    const questionAnswers = [
        {
            uuid : 'q1',
            question : 'How much do you think this property is worth?',
            questionDesc : '',
            max: 1,
            answers : [
                {
                    label : '$' + (props.highPrice < 2000000 ? ((props.lowPrice - 100000) < 0 ? 0 : numberWithCommas(props.lowPrice - 100000)) : (props.lowPrice - 200000 < 0 ? 0 : numberWithCommas(props.lowPrice - 200000))) + ' - $' + numberWithCommas(props.lowPrice), 
                    uuid : 'q1_2',
                },{
                    label : '$' + numberWithCommas(props.lowPrice) + ' - $' + numberWithCommas(props.highPrice), 
                    uuid : 'q1_3',
                },{
                    label : '$' + numberWithCommas(props.highPrice) + ' - $' + (props.highPrice < 2000000 ? numberWithCommas(props.highPrice + 100000) : numberWithCommas(props.highPrice + 200000)),
                    uuid : 'q1_4',
                }
            ],
        },
        {
            uuid : 'q2',
            question : 'Would you be interested in purchasing this property?',
            questionDesc : '',
            max: 1,
            answers : [
                {
                    label : 'Yes, within the next 12 months', 
                    uuid : 'q2_1',
                },{
                    label : 'Not really', 
                    uuid : 'q2_2',
                },{
                    label : 'Maybe in the future', 
                    uuid : 'q2_3',
                }
            ],
        },
        {
            uuid : 'q3',
            question : 'What property features do you most like?',
            questionDesc : 'Select up to three options',
            max: 3,
            answers : [
                {
                    label : 'Location', 
                    uuid : 'q3_1',
                },{
                    label : 'Living spaces', 
                    uuid : 'q3_2',
                },{
                    label : 'Entertaining areas',
                    uuid : 'q3_3',
                },{
                    label : 'Outdoor Landscaping', 
                    uuid : 'q3_4',
                },{
                    label : 'Kitchen area',
                    uuid : 'q3_5',
                },{
                    label : 'Bathroom/s', 
                    uuid : 'q3_6',
                },{
                    label : 'Bedroom/s', 
                    uuid : 'q3_7',
                }
            ],
        },
        {
            uuid : 'q4',
            question : 'How can the owner best add value to this property?',
            questionDesc : 'Select up to three options',
            max: 3,
            answers : [
                {
                    label : 'Additional living space', 
                    uuid : 'q4_1',
                },{
                    label : 'Update bathroom', 
                    uuid : 'q4_2',
                },{
                    label : 'Update kitchen',
                    uuid : 'q4_3',
                },{
                    label : 'Extra bedroom / home office', 
                    uuid : 'q4_4',
                },{
                    label : 'Extra bathroom',
                    uuid : 'q4_5',
                },{
                    label : 'Outdoor landscaping or pool', 
                    uuid : 'q4_6',
                },{
                    label : 'Improve entertaining areas', 
                    uuid : 'q4_7',
                }
            ],
        },
        {
            uuid : 'q5',
            question : 'Would you like to make an offer or get in touch with the owner?',
            questionDesc : '',
            max: 1,
            answers : [
                {
                    label : 'Yes', 
                    uuid : 'q5_1'
                },{
                    label : 'Maybe Later', 
                    uuid : 'q5_2'
                },
            ],
        },
    ];
    const questionAnswersWithoutLast = [        
        {
            uuid : 'q1',
            question : 'How much do you think this property is worth?',
            questionDesc : '',
            max: 1,
            answers : [
                {
                    label : '$' + (props.highPrice < 2000000 ? ((props.lowPrice - 100000) < 0 ? 0 : numberWithCommas(props.lowPrice - 100000)) : (props.lowPrice - 200000 < 0 ? 0 : numberWithCommas(props.lowPrice - 200000))) + ' - $' + numberWithCommas(props.lowPrice), 
                    uuid : 'q1_1',
                },{
                    label : '$' + numberWithCommas(props.lowPrice) + ' - $' + numberWithCommas(props.highPrice), 
                    uuid : 'q1_2',
                },{
                    label : '$' + numberWithCommas(props.highPrice) + ' - $' + (props.highPrice < 2000000 ? numberWithCommas(props.highPrice + 100000) : numberWithCommas(props.highPrice + 200000)),
                    uuid : 'q1_3',
                }
            ],
        },
        {
            uuid : 'q2',
            question : 'Would you be interested in purchasing this property?',
            questionDesc : '',
            max: 1,
            answers : [
                {
                    label : 'Yes, within the next 12 months', 
                    uuid : 'q2_1',
                },{
                    label : 'Not really', 
                    uuid : 'q2_2',
                },{
                    label : 'Maybe in the future', 
                    uuid : 'q2_3',
                }
            ],
        },
        {
            uuid : 'q3',
            question : 'What property features do you most like?',
            questionDesc : 'Select up to three options',
            max: 3,
            answers : [
                {
                    label : 'Location', 
                    uuid : 'q3_1',
                },{
                    label : 'Living spaces', 
                    uuid : 'q3_2',
                },{
                    label : 'Entertaining areas',
                    uuid : 'q3_3',
                },{
                    label : 'Outdoor Landscaping', 
                    uuid : 'q3_4',
                },{
                    label : 'Kitchen area',
                    uuid : 'q3_5',
                },{
                    label : 'Bathroom/s', 
                    uuid : 'q3_6',
                },{
                    label : 'Bedroom/s', 
                    uuid : 'q3_7',
                }
            ],
        },
        {
            uuid : 'q4',
            question : 'How can the owner best add value to this property?',
            questionDesc : 'Select up to three options',
            max: 3,
            answers : [
                {
                    label : 'Additional living space', 
                    uuid : 'q4_1',
                },{
                    label : 'Update bathroom', 
                    uuid : 'q4_2',
                },{
                    label : 'Update kitchen',
                    uuid : 'q4_3',
                },{
                    label : 'Extra bedroom / home office', 
                    uuid : 'q4_4',
                },{
                    label : 'Extra bathroom',
                    uuid : 'q4_5',
                },{
                    label : 'Outdoor landscaping or pool', 
                    uuid : 'q4_6',
                },{
                    label : 'Improve entertaining areas', 
                    uuid : 'q4_7',
                }
            ],
        },
    ];
    const [questions, setQuestions] = useState(questionAnswers);
    const [numQuestions, setNumQuestions] = useState(questions.length);
    const [targetQuestion, setTargetQuestion] = useState(0);
    const [start, setStart] = useState(false);
    const [end, setEnd] = useState(false);
    const [nextClickable, setNextClickable] = useState(false);
    const [targetAnswer, setTargetAnswer] = useState<string>();
    const [bounceValue, setBounceValue] = useState(new Animated.Value(Layout.window.height + 200));
    const [opacityValue, setOpacityVlaue] = useState(new Animated.Value(0));
    const [checkSize, setCheckSize] = useState(new Animated.Value(120));
    const [isHidden, setIsHidden ] =useState(props.open);
    const [customisedValue, setCustomisedValue] = useState<string | undefined>();


    // Expand model when first press on the header
    const showInitialAnswer = () => {
        if(start == false){
            setTargetQuestion(0);
            setStart(true);
        }
    };

    const resetTargetAnswerCustom = (num:string) => {
        finalAnwsers[targetQuestion] = num;
        setTargetAnswer(num);
        setNextClickable(true);
    };

    // When press each answer, save the answer id, and active the "Next" button
    const resetTargetAnswer = (num:string) => {
        finalAnwsers[targetQuestion] = num;
        setTargetAnswer(num);
        setNextClickable(true);
        setCustomisedValue();
    };

    const addTargetAnswer = (num:string, max:number) => {
        finalAnwsers[targetQuestion] === undefined
            ? finalAnwsers[targetQuestion] = num + '|'
            : finalAnwsers[targetQuestion].includes(num)
                ? finalAnwsers[targetQuestion] = finalAnwsers[targetQuestion].replace(num + '|', '')
                : finalAnwsers[targetQuestion].split('|').length < max+1 && (finalAnwsers[targetQuestion] = finalAnwsers[targetQuestion] + num + '|');
     
        setTargetAnswer(finalAnwsers[targetQuestion]);
        setNextClickable(true);
    };
    
    // Go to the next question
    const nextQuestion = () => {
        setNextClickable(false);
        if(finalAnwsers[targetQuestion] !== undefined && finalAnwsers[targetQuestion] !== ''){
            if(finalAnwsers[1] === 'q2_1' || finalAnwsers[1] === 'q2_3'){
                setQuestions(questionAnswers);
                setNumQuestions(questionAnswers.length);
            }else if(finalAnwsers[1] === 'q2_2'){
                setQuestions(questionAnswersWithoutLast);
                setNumQuestions(questionAnswersWithoutLast.length);
            }
            setTargetQuestion(targetQuestion + 1);
        }
        if(finalAnwsers[targetQuestion+1] !== undefined && finalAnwsers[targetQuestion] !== ''){
            setNextClickable(true);
        }
    };
    
    // Go back to the previous question
    const previousQuestion = () => {
        setTargetQuestion(targetQuestion - 1);
        if(finalAnwsers[targetQuestion-1] !== undefined && finalAnwsers[targetQuestion-1] !== '' ){
            setNextClickable(true);
        }
        setEnd(false);
    };

    // Finish the whole questionaire, and console.log the selections
    const finish = () => {
        if(finalAnwsers[targetQuestion] !== undefined && finalAnwsers[targetQuestion] !== ''){
            setPollProperty(props.propertyId, props.userId, finalAnwsers);
            setEnd(true);
        }
    };

    // Slide show and hide the model
    const toggleSlide = (status : boolean) =>  {
        let slideToValue = Layout.window.height  + 200;
        if(status) {
            slideToValue = 0;
        }
        Animated.spring(
            bounceValue,
            {
                toValue: slideToValue,
                velocity: 10,
                tension: 2,
                friction: 8,
                useNativeDriver: false
            }
        ).start();

        let opacityToValue = 0;
        if(status) {
            opacityToValue = 1;
        }
        Animated.spring(
            opacityValue,
            {
                toValue: opacityToValue,
                velocity: 10,
                tension: 2,
                friction: 8,
                useNativeDriver: false
            }
        ).start();
    };

    // Close model
    const closeModel = () => {
        toggleSlide(false);
        setTimeout(() => {
            setIsHidden(false);
            props.setHasFinishedPoll(true);
            props.setOpenQuestionModal(false);
            setFinalAwnsers([]);
        }, 500);
    };

    // When screen loaded, slide show model
    toggleSlide(true);

    {
        if(end){
            Animated.spring(
                checkSize,
                {
                    toValue: 140,
                    velocity: 8,
                    tension: 2,
                    friction: 1,
                    useNativeDriver: false
                }
            ).start();
        }
    }

    const MessageOwner = () => {
        closeModel();
        setTimeout(() => {
            props.setContactHomeownerModal(true);
            props.setHasCompletedPoll(true);
        }, 500);
    };

    return (
        <Animated.View style={[styles.overlay, {opacity:opacityValue}, !isHidden && {display:'none'}, end && {paddingHorizontal: 24}]}>
            <Animated.View style={[styles.modelContainer, {transform: [{translateY: bounceValue}]}, end && styles.finished]}>
                <Ionicons name="close" size={20} color="#4F4F4F" style={styles.close} onPress={closeModel}/>
                {!end ?
                // Start Quiz
                    <View>
                        <TouchableOpacity onPress={showInitialAnswer}>
                            <Text style={[FontProperty.h2, FontProperty.TextSemiBold, {color:Colors.grey1, marginBottom:18}]}>{targetQuestion == 0 && props.title}</Text>
                            {targetQuestion == 0 && 
                                <Text style={[FontProperty.body_1, FontProperty.TextGrey]}>{questions[0]['question']}</Text>
                            }
                            {targetQuestion == 0 && questions[0]['questionDesc'] !== '' &&
                                <Text style={[FontProperty.smallP, FontProperty.TextGrey]}>{questions[0]['questionDesc']}</Text>
                            }
                        </TouchableOpacity>
                        {targetQuestion == 0 && start == true &&
                            <View style={[{marginTop: 25}]}>
                                {
                                    questions[0]['answers'].map((item, index) => {
                                        return (
                                            <TouchableOpacity
                                                key={item.uuid}
                                                style={[styles.answer, finalAnwsers[targetQuestion] !== undefined && finalAnwsers[targetQuestion].includes(item.uuid) && styles.answerActive]}
                                                onPress={()=>{
                                                    questions[0]['max'] == 1 
                                                        ? resetTargetAnswer(item.uuid)
                                                        : addTargetAnswer(item.uuid, questions[0]['max']);
                                                }}
                                            >
                                                <Text style={[FontProperty.button_2, finalAnwsers[targetQuestion] !== undefined && finalAnwsers[targetQuestion].includes(item.uuid) ? FontProperty.TextTeal : FontProperty.TextLightNavy]}>{item.label}</Text>
                                            </TouchableOpacity>
                                        );
                                    })
                                }
                                <Text style={[{marginTop:24,marginBottom:16,textAlign:'center'}, FontProperty.TextSemiBold]}>Or</Text>
                                <View>
                                    <FontAwesome name='dollar' size={16} color={Colors.grey4} style={{position:'absolute',left:14,top:20}}/>
                                    <TextInput
                                        autoCapitalize='none'
                                        style={styles.input}
                                        placeholder='Type in...'
                                        placeholderTextColor={Colors.grey3}
                                        onChangeText={(text)=>{
                                            setCustomisedValue(text);
                                            resetTargetAnswerCustom(text);
                                        }}
                                        value={(customisedValue !== undefined && !isNaN(parseInt(customisedValue))) ? parseInt(customisedValue) : ''}
                                        keyboardType='numeric'
                                    />
                                    {customisedValue !== undefined && customisedValue !== '' && !isNaN(parseInt(customisedValue)) && <Text style={[FontProperty.TextGrey,FontProperty.miniP, {marginTop:16}]}>AUD${numberWithCommas(parseInt(customisedValue))}</Text>}
                                </View>  
                            </View>
                        }
                        {targetQuestion !== 0 &&
                            <View>
                                <Text style={[FontProperty.body_1, FontProperty.TextGrey]}>{questions[targetQuestion]['question']}</Text>
                                {
                                    questions[targetQuestion]['questionDesc'] !== '' &&
                                    <Text style={[FontProperty.smallP, FontProperty.TextGrey]}>{questions[targetQuestion]['questionDesc']}</Text>
                                }
                                <ScrollView style={[{marginTop: 25,maxHeight: Layout.window.height/2}]}>
                                    {
                                        questions[targetQuestion]['answers'].map((item, index) => {
                                            return (
                                                <TouchableOpacity
                                                    key={item.uuid}
                                                    style={[styles.answer, finalAnwsers[targetQuestion] !== undefined && finalAnwsers[targetQuestion].includes(item.uuid) && styles.answerActive]}
                                                    onPress={()=>{
                                                        questions[targetQuestion]['max'] === 1
                                                            ? resetTargetAnswer(item.uuid)
                                                            : addTargetAnswer(item.uuid, questions[targetQuestion]['max']);
                                                    }}
                                                >
                                                    <Text style={[FontProperty.button_2, finalAnwsers[targetQuestion] !== undefined && finalAnwsers[targetQuestion].includes(item.uuid) ? FontProperty.TextTeal : FontProperty.TextLightNavy]}>{item.label}</Text>
                                                </TouchableOpacity>
                                            );
                                        })
                                    }
                                </ScrollView>
                            </View>
                        }
                        {start == true &&
                            <View style={[Layout.row, Layout.rowBetween, styles.btnGroup]}>
                                {targetQuestion !== 0 && 
                                    <Text style={[styles.prev, FontProperty.button_1, {color: Colors.lightNavy}]} onPress={previousQuestion}>Back</Text>
                                }
                                {targetQuestion == (numQuestions - 1) ? 
                                    <TouchableOpacity
                                        style={[styles.finish, !nextClickable && styles.opacity5]}
                                        onPress={finish}
                                    >
                                        <Text style={[FontProperty.TextWhite, FontProperty.button_1]}>Finish</Text>
                                    </TouchableOpacity>
                                    :
                                    <TouchableOpacity
                                        style={[styles.next, !nextClickable && styles.opacity5]}
                                        onPress={nextQuestion}
                                    >
                                        <Text style={[FontProperty.TextWhite, FontProperty.button_1]}>Next</Text>
                                    </TouchableOpacity>
                                }
                            </View>
                        }
                    </View>
                    :
                    // Quiz Finished
                    <View>
                        <View style={[{paddingHorizontal:20}, Layout.center]}>
                            <View style={{height:140,marginTop: 30,marginBottom: 46,}}>
                                <Animated.View style={[styles.tickContainer, {width:checkSize, height:checkSize}]}>
                                    <FontAwesome name="check" size={40} color={Colors.teal} />
                                </Animated.View>
                            </View>
                            <Text style={[FontProperty.h4, FontProperty.TextSemiBold, {marginBottom:8,color: Colors.grey1}]}>{props.succeedTitle}</Text>
                            <Text style={[FontProperty.p1, {color: Colors.grey1, textAlign:'center'}]}>{props.succeedText}</Text>
                        </View>
                        <View style={[Layout.row, Layout.rowBetween, styles.btnGroup]}>
                            <Text style={[styles.prev, FontProperty.button_1, {color: Colors.lightNavy}]} 
                                onPress={MessageOwner}>Message Owner</Text>
                            <TouchableOpacity
                                style={[styles.finish]}
                                onPress={()=>{closeModel(); props.setHasCompletedPoll(true);}}
                            >
                                <Text style={[FontProperty.TextWhite, FontProperty.button_1]}>Finish</Text>
                            </TouchableOpacity>  
                        </View>
                    </View>
                }
            </Animated.View>
        </Animated.View>
    );
};

const styles = StyleSheet.create({
    overlay: {
        backgroundColor: '#000000cc',
        position: 'absolute',
        width: '100%',
        height: '100%',
        justifyContent: 'flex-end',
        zIndex: 1
    },
    modelContainer: {
        backgroundColor: Colors.white,
        paddingHorizontal: 25,
        paddingVertical: 27,
    },
    close: {
        position: 'absolute',
        right: 25,
        top: 16,
        zIndex: 2
    },
    answer: {
        marginBottom: 10,
        borderWidth: 1,
        borderRadius: 8,
        paddingVertical: 17.5,
        alignItems: 'center',
        justifyContent: 'center',
        height: 55,
        borderColor: Colors.grey4,
    },
    answerActive: {
        borderColor: Colors.teal,
    },
    next: {
        borderRadius: 8,
        paddingVertical: 17.5,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.navy,
        width: '50%',
    },
    btnGroup : {
        justifyContent: 'flex-end',
        marginTop: 26,
    },
    prev: {
        width: '50%',
        textAlign: 'center',
        paddingVertical: 17.5,
    },
    finish: {
        borderRadius: 8,
        paddingVertical: 17.5,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.teal,
        width: '50%',
    },
    opacity5: {
        opacity: 0.5
    },
    tickContainer: {
        borderRadius: 140,
        borderWidth: 3,
        borderColor: Colors.teal,
        justifyContent: 'center',
        textAlign: 'center',
    },
    finished: {
        margin: 'auto',
        width: Layout.window.width > 425 ? 360 : '100%',
        borderRadius: 16,
    },
    input: {
        paddingVertical: 19,
        paddingHorizontal: 30,
        color: Colors.navy,
        fontSize: 14,
        width: '100%',
        flex : 1,
        outlineStyle: 'none',
        borderWidth: 1,
        borderRadius: 8,
        borderColor: Colors.grey4,
    }
});

export default QuestionModel;

