const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';

const Colors = {
    black: '#333333',
    white: '#FFFFFF',
    grey1: '#4F4F4F',
    grey2: '#F4F4F4',
    grey3: '#828282',
    grey4: '#BDBDBD',
    grey5: '#E0E0E0',
    grey6: '#F2F2F2',
    teal: '#00B7A1',
    darkTeal: '#005C51',
    lightTeal: '#80DBD0',
    navy: '#000C4E',
    darkNavy: '#000C4E',
    lightNavy: '#666D95',
    beige: '#D6C5A5',
    darkBeige: '#807663',
    lightBeige: '#E6DCC9',
    red: '#EB5757',
    beigeTint: '#E6DCC9',
    background: '#fff',
    light: {
        text: '#000',
        background: '#fff',
        tint: tintColorLight,
        tabIconDefault: '#ccc',
        tabIconSelected: tintColorLight
    },
    dark: {
        text: '#fff',
        background: '#fff',
        tint: tintColorDark,
        tabIconDefault: '#ccc',
        tabIconSelected: tintColorDark
    }
};

export default Colors;
