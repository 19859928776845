import React from 'react';
import { StyleSheet, Text } from 'react-native';
import FontProperty from '../../constants/FontProperty';



const ButtonLoader : React.FC = (props) => {

    return (
            <Text style={[FontProperty.button_1,FontProperty.TextWhite]}>
                Loading..
            </Text>
    );
}

const styles = StyleSheet.create({

})

export default ButtonLoader

