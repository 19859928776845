import { collection, getDocs, query, orderBy, doc, getDoc, QueryDocumentSnapshot, DocumentData } from 'firebase/firestore';
import { db } from '../../../config/firebase';
import constants from './constants';
import { IAboutContent, IContactContent, IFAQs, IFAQsContent, IHomeContent } from './types';


export const getHomeContent = async (): Promise<IHomeContent[]> => {
    try {
        
        return [{ListAPropertyBanner: "https://firebasestorage.googleapis.com/v0/b/app-template-169bf.appspot.com/o/globalImages%2FScreen%20Shot%202023-02-17%20at%209.45.22%20pm.png?alt=media&token=60e6985b-e316-422e-9420-24f19fc79cb1",
            explorePropertyTitle: "Explore all things property",
            footerContent: "Discover or sell your off market home. Create a profile for your property. Get real time insights and track its value. Share your home renovation journey. Connect directly with buyers and sellers before you are ready to hit the market. Free to list and explore. ",
            footerSiteCompanyLinks: {'About us': 'About', 'Contact us': 'ContactUs', 'FAQs': 'FAQs'},
            footerSiteLinks: {'Explore': 'Explore', 'Newsfeed': 'Newsfeed', 'List a Property': 'Start'},
            footerSiteSocialLinks:{'instagram': 'https://www.instagram.com/abodelyapp/', 'linkedin': 'https://www.linkedin.com/company/abodely-app/'},
            heroBannerBgImage: "https://firebasestorage.googleapis.com/v0/b/app-template-169bf.appspot.com/o/globalImages%2Fherobanner-min-min.jpg?alt=media&token=f3d7a446-aa52-4a60-ade6-fcbb5af92abb",
            heroBannerTitle:"Discover or sell your off market home",
            heroBannerTitleColor:"#333333",
            homeStyleBgImage: "https://firebasestorage.googleapis.com/v0/b/app-template-169bf.appspot.com/o/globalImages%2FScreen%20Shot%202023-02-17%20at%2010.14.10%20pm.png?alt=media&token=812e95eb-37bb-4332-ad5f-cfdef6a66a05",
            homeStyleTitle:"Explore, Connect and be Inspired",
            listPropertyCTATitle: "Discover exclusive properties and renovation projects. Create your free profile today.  ",
            listPropertyTitle:"Seeking feedback on your property or renovation project?",
            signInBanner:"https://firebasestorage.googleapis.com/v0/b/app-template-169bf.appspot.com/o/globalImages%2Fshutterstock_1893707830-min.jpg?alt=media&token=5905d1e3-e54f-462a-aa58-ea8548776fa8"    
        }] as IHomeContent[];
        
         const q = query(collection(db, constants.home));
        const querySnapshot = await getDocs(q);
        const result = querySnapshot?.docs.map(
            doc => {
                return {
                    ...doc.data()
                };
            });
            console.log(result);
        return result as IHomeContent[]; 
    } catch (e) {
        throw Error(e.message);
    }
};

export const getAboutContent = async (): Promise<IAboutContent[]> => {
    try {
        const q = query(collection(db, constants.aboutUsScreen));
        const querySnapshot = await getDocs(q);
        return querySnapshot?.docs.map(
            doc => {
                return {
                    ...doc.data()
                };
            }) as IAboutContent[];
    } catch (e) {
        throw Error(e.message);
    }
};

export const getContactContent = async (): Promise<IContactContent[]> => {
    try {
        const q = query(collection(db, constants.contactUsScreen));
        const querySnapshot = await getDocs(q);
        return querySnapshot?.docs.map(
            doc => {
                return {
                    ...doc.data()
                };
            }) as IContactContent[];
    } catch (e) {
        throw Error(e.message);
    }
};

export const getFAQsContent = async (): Promise<IFAQsContent[]> => {
    try {
        const q = query(collection(db, constants.faqsScreen));
        const querySnapshot = await getDocs(q);
        return querySnapshot?.docs.map(
            doc => {
                return {
                    ...doc.data()
                };
            }) as IFAQsContent[];
    } catch (e) {
        throw Error(e.message);
    }
};


export const getFAQs = async (): Promise<IFAQs[]> => {
    try {
        const q = query(collection(db, constants.faqs), orderBy('weight', 'asc'));
        const querySnapshot = await getDocs(q);
        return querySnapshot?.docs.map(
            doc => {
                return {
                    ...doc.data()
                };
            }) as IFAQs[];
    } catch (e) {
        throw Error(e.message);
    }
};

export const getPP = async () : Promise<QueryDocumentSnapshot<DocumentData>> => {
    try {
        const q = doc(db, 'privacyPolicy', 'content');
        const querySnapshot = await getDoc(q);
        return querySnapshot.data().content;
    } catch (e) {
        throw Error(e.message);
    }
};

export const getTC = async () : Promise<QueryDocumentSnapshot<DocumentData>> => {
    try {
        const q = doc(db, 'termsOfUse', 'content');
        const querySnapshot = await getDoc(q);
        return querySnapshot.data().content;
    } catch (e) {
        throw Error(e.message);
    }
};