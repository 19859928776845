import { TextInputProps, Text, View, StyleSheet } from 'react-native';
import React, { useEffect, useState } from 'react';
import { Formik, FormikErrors } from 'formik';
import * as Yup from 'yup';

import HousepoTextInput from '../HouspoTextInput';
import { RequestNewPasswordFormProps } from './types';
import { TealButton } from '../../components/Button';
import ButtonLoader from '../atom/ButtonLoader';
import FontProperty from '../../constants/FontProperty';
import { getFunctions, httpsCallable } from '@firebase/functions';
import { useNavigation } from '@react-navigation/core';
import { useAuthentication } from '../../hooks/useAuthentication';

const RequestNewPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Email is invalid').required('Email Required')
});

const functions = getFunctions();
const setTempPassword = httpsCallable(functions, 'setTempPassword');

const RequestPasswordChangeForm: React.FC = (props) => {
    const nav = useNavigation();
    const [successMessage,setSuccessMessage] = React.useState('');
    const [userEmail, setUserEmail] = useState<string>('');
    const { user } = useAuthentication();

    const onRequestNewPasswordHandler = async (
        { email }: RequestNewPasswordFormProps,
        setErrors: (errors: FormikErrors<{
          email: string;
      }>) => void
    ) => {
        try {
            await setTempPassword({ email });
            setSuccessMessage('If email exists, a temporary code will be sent to your email');
        } catch (error: any) {
            const errorMessage = error.message as string;
            // Display Correct Error Message
            if (errorMessage.includes('auth/email-already-in-use')) {
                setErrors({ email: 'Email already exists' });
            }
        }
    };

    useEffect(() => {
        user !== undefined && user !== false && user.email !== undefined && setUserEmail(user.email);
    }, [user]);

    if(userEmail === ''){
        return null;
    }

    return (
        <Formik
            initialValues={{ email: userEmail}}
            validationSchema={RequestNewPasswordSchema}
            onSubmit={(values, { setErrors }) => onRequestNewPasswordHandler({ ...values }, setErrors)}
        >
            {({ handleChange, handleBlur, handleSubmit, values, errors, isSubmitting }) => (
                <>
                    {/* Inputs */}
                    <View>
                        <View style={styles.overlay}></View>
                        <HousepoTextInput
                            onChangeText={handleChange('email')}
                            value={values.email}
                            onBlur={handleBlur('email')}
                            error={errors.email || ''}
                            {...emailProps} 
                            editable={false} 
                        />
                    </View>
                    

                    {/* Button */}
                    {successMessage ? 
                        <TealButton
                            isDisabled={isSubmitting}
                            onPress={()=>{nav.navigate('UpdateSetPassword');}} >
                            <Text style={[FontProperty.TextWhite, FontProperty.TextSemiBold, FontProperty.p2]}>
                         Continue
                            </Text>
                        </TealButton>
                        :
                        <TealButton
                            isDisabled={isSubmitting}
                            onPress={handleSubmit} >
                            <Text style={[FontProperty.TextWhite, FontProperty.TextSemiBold, FontProperty.p2]}>
                                {isSubmitting ? <ButtonLoader /> : 'Request New Password'}
                            </Text>
                        </TealButton>
                    }
                    {successMessage && <Text style={[FontProperty.TextTeal]}>{successMessage}</Text>}
                </>
            )}
        </Formik>);
};

export default RequestPasswordChangeForm;

const emailProps = {
    testID: 'email-input',
    placeholder: 'Email',
    autoCapitalize: 'none',
    autoCorrect: false
} as Readonly<TextInputProps>;


const styles = StyleSheet.create({
    overlay:{
        position:'absolute',
        height: 55,
        top: 0,
        width: '100%',
        zIndex: 1
    }
});