import React, {useEffect, useState} from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ListingBaseScreen } from './ListingBaseScreen';
import { getPropertyContent, getPropertyTypes } from '../../lib/api/Content/property';
import Layout from '../../constants/Layout';
import Colors from '../../constants/colors';
import { AddProjectNavProps, AddProjectTypeBasicProps } from '../../types';
import { IprojectType, IpropertyType } from '../../lib/api/Content/types';
import { updatePropertyType } from '../../lib/api/Content/property';
import { View } from '../../components/Themed';
import { getProjectContent, getProjectsTypes, updateProjectType } from '../../lib/api/Content/project';
import FontProperty from '../../constants/FontProperty';

export const ProjectTypeScreen : React.FC = (props) => {
    const nav = useNavigation<AddProjectNavProps>();
    const { projectId } = useRoute<AddProjectTypeBasicProps>().params;
    // const [selectedArray, setSelectedArray] = useState([]);
    const [selected, setSelected] = useState<string | null>(null);
    // const [selectedItems, setSelectedItems] = useState<string | null>('');
    const [options, setOptions] = useState<IprojectType[]>([]);
    const [err, setErr] = useState<string>('');
    const [published, setPublisted] = useState(false);

    useEffect(() => {
        getProjectContent(projectId).then(res=> {
            // let tempType = selectedItems;
            // if(res[0] !== undefined && res[0].type !== undefined) {
            //     setSelectedArray(res[0].type);
            //     res[0].type.forEach(element => {
            //         tempType = tempType + element.uuid + ', ';
            //     });
            //     setSelectedItems(tempType);
            // }
            res[0] && setSelected(res[0].type);
            res[0] !== undefined && res[0].published !== undefined && res[0].published === true && setPublisted(true);
        });
    }, [projectId]);

    // let tempArray = selectedArray;
    // const onSelectHandlar = (id : string, type : string) => {
    //     const temp = {name: type, uuid: id};
        
    //     if(selectedItems?.includes(id)){
    //         tempArray = tempArray.filter(item => item.uuid != id);
    //         setSelectedItems(selectedItems.replace(id + ', ' , ''));
    //         setSelectedArray(tempArray.filter(item => item.uuid != id));
    //     } else {
    //         tempArray?.push(temp);
    //         setSelectedItems(selectedItems + id + ', ');
    //         setSelectedArray(tempArray);
    //     }
    // };
    const onSelectHandlar = (id : string) => {
        setSelected(id);
    };

    const onNextStep = async () => {
        if (selected) {
            await updateProjectType(projectId, selected);
            nav.navigate('name', { projectId });
        } else {
            setErr('Please select at least one option');
        }
    };

    useEffect(() => {
        getProjectsTypes().then(data => {
            setOptions(data);
        }).catch(err => {
            console.log('API : CONTENT : GET_PROPERTY_TYPES : ERROR :', err);
            setErr('Network Error');
        });
    }, []);

    return (
        <ListingBaseScreen
            step={1}
            header="What is your project type?"
            nextScreenHandler={onNextStep}
            error={err}
            setErr={setErr}
            isPublished={published}
            // onEdit={()=>nav.navigate('PropertyProfile', { propertyId: propertyId})}
        >
            <View style={{flexDirection:'row',flexWrap:'wrap',justifyContent:'space-between'}} >
                {
                    options.map((item) => {
                        return (
                            <TouchableOpacity
                                style={[styles.itemContainer, Layout.mbottom25, selected === item.uuid && styles.isActive
                                ]}
                                onPress={() => onSelectHandlar(item.uuid, item.type)}
                                key={item.uuid}
                            >
                                <Text style={[styles.item, selected === item.uuid && styles.isActiveText]}>{item.type}</Text>
                            </TouchableOpacity>
                        );
                    })
                }
            </View>
        </ListingBaseScreen>
    );
};

const styles = StyleSheet.create({
    item: {
        fontSize: 16,
        color: Colors.lightNavy,
        fontFamily: 'inter-medium'
    },
    itemContainer: {
        borderWidth: 1,
        borderColor: Colors.grey4,
        backgroundColor: Colors.white,
        padding: 18,
        marginLeft: 0,
        marginTop: 0,
        alignItems: 'center',
        width: Layout.window.width > 425 ? '48%' : '100%',
        borderRadius: 4,
        flexDirection: 'row',
        justifyContent: 'center'
    },
    isActive: {
        borderColor: Colors.darkNavy
    },
    isActiveText: {
        color: Colors.darkNavy
    },
});
