import React, {useState } from 'react';
import '@aws-amplify/ui-react/styles.css';
//import { Auth } from "aws-amplify";
import { type AuthUser, getCurrentUser, fetchUserAttributes } from "aws-amplify/auth";

  export function useAuthentication () {
    
    const [user, setUser] = useState();

    React.useEffect(() => {
      fetchUser();
    }, []);
    
    const fetchUser = async () => {
      try {
        const user = await fetchUserAttributes();
        setUser(user);
      } catch (error) {
        console.log(error);
      }

    };
    
    if (user) {
      return { 'user' : user }
    } else {
      return { 'user' : false }
    }
  }
