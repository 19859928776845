import React, { useState, useEffect } from 'react';
import { Pressable, ScrollView, StyleSheet, View, Text, Image } from 'react-native';
import ScreenBase from '../../components/ScreenBase';
import { OBQAScreen } from './OnboardQAScreen';
import { OBAccountCreatedScreen } from './OBAccountCreatedScreen';
import { NavyButton } from '../../components/Button';
import Layout from '../../constants/Layout';
import { Ionicons } from '@expo/vector-icons';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import { getOnboardingContent } from '../../lib/api/Content/property';
import { IOnboardingQA } from '../../lib/api/Content/types';
import IMAGE from '../../assets/images';

export const OnboardingScreen = () => {
    const [activeScreen, setSctiveScreen] = useState(1);
    const [onboardAnwsers, setOnboardAnwsers] = useState(['', '', '', '', '', '', '', '']);
    const [questionContent, setQuestionContent] = useState<IOnboardingQA[]>();
    const [loading, setLoading] = useState(true);
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        getOnboardingContent().then(res => {
            setQuestionContent(res);
            setLoading(false);
        }
        );
    }, []);

    if(loading !== false || questionContent == undefined){
        return null;
    }
    
    const nextScreen = () => {
        questionContent[activeScreen - 1]?.compulsory 
            ? checkNotEmpty(activeScreen)
            : moveOn();
    };

    const moveOn = () => {
        setSctiveScreen(activeScreen + 1);
        setShowMessage(false);
    };

    const checkNotEmpty = (activeScreen: number) => {
        onboardAnwsers[activeScreen - 1] !== '' ? moveOn() : setShowMessage(true);
    };

    return (
        <ScreenBase>
            <View style={[{flex:1}]}>
                <View style={[styles.screenHeader, Layout.rowBetween, Layout.globalPadding24]}>
                    { activeScreen < (questionContent.length + 1) &&
                        <>
                            <Pressable onPress={()=>{ setSctiveScreen(activeScreen - 1); }}>{activeScreen !== 1 &&
                                <Ionicons name='arrow-back' size={25} color={Colors.grey1} />
                            }</Pressable>
                            <Text>{activeScreen}/8</Text>
                        </>
                    }
                </View>
                <ScrollView showsVerticalScrollIndicator={false}>
                    {activeScreen == 1 && 
                        <View style={styles.imageContainer}>
                            <Image source={IMAGE.Onboard} style={styles.image} />
                            <Text style={{marginTop:20}}>“Hey there, welcome to Abodely!”</Text>
                        </View>
                    }
                    {activeScreen < (questionContent.length + 1) && <OBQAScreen 
                        onboardAnwsers={onboardAnwsers} 
                        setOnboardAnwsers={setOnboardAnwsers} 
                        onboardingQAData={questionContent[activeScreen-1]} 
                        index={activeScreen - 1} 
                        referenceAns={questionContent[activeScreen-1].answerRef ? questionContent[activeScreen-1].answerRef : 'Static'} 
                    /> }
                    {activeScreen == (questionContent.length + 1) &&  <OBAccountCreatedScreen onboardAnwsers={onboardAnwsers} /> }
                </ScrollView>
                { activeScreen < (questionContent.length + 1) &&
                    <View style={[styles.screenFooter, Layout.globalPadding24]}>
                        {
                            showMessage &&
                            <Text style={{paddingTop:16,color:'#fc5a6a',textAlign:'center'}}><Ionicons name='alert-circle-outline' size={14}/> This field is required, please input or select a value</Text>
                        }
                        <NavyButton btnStyle={[styles.btnStyle]} onPress={()=>nextScreen()} >
                            <Text style={[FontProperty.button_1, FontProperty.TextWhite]}>Next</Text>
                        </NavyButton>
                    </View>
                }
            </View>
        </ScreenBase>
    );
};

const styles = StyleSheet.create({
    screenHeader : {
        paddingTop: 34,
        minHeight: 100
    },
    screenFooter: {
        paddingBottom: 20,
        boxShadow: '0px -6px 10px rgba(0, 0, 0, 0.1)'
    },
    btnStyle: {
        paddingHorizontal: 30,
        marginTop : 16,
        width: '100%'
    },
    imageContainer: {
        alignSelf: 'center',
        alignItems: 'center'
    },
    image: {
        width: 176,
        height: Layout.window.height * 0.15,
        resizeMode : 'contain'
    },
});
