import React, { useEffect, useState } from 'react';
import { StyleSheet, Image, Text, Pressable } from 'react-native';
import { View } from '../../components/Themed';
import Colors from '../../constants/colors';
import { AntDesign } from '@expo/vector-icons';
import IMAGE from '../../assets/images/index';
import { useNavigation } from '@react-navigation/native';
import { TealButton } from '../../components/Button';
import FontProperty from '../../constants/FontProperty';
import ScreenBase from '../../components/ScreenBase';
import Layout from '../../constants/Layout';
import { db } from '../../config/firebase';
import { addDoc, collection } from 'firebase/firestore';
import { useAuthentication } from '../../hooks/useAuthentication';
import ButtonLoader from '../../components/atom/ButtonLoader';
import PopUpModal from '../../components/atom/PopUpModal';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { ScrollView } from 'react-native-gesture-handler';
import ContentText from '../../constants/Content';
import { getHomeContent } from '../../lib/api/Content/content';
import { IHomeContent } from '../../lib/api/Content/types';

const createProperty = async (userId: string) => {
    // Firebase Create Property
    const propertiesRef = collection(db, 'properties');
    try {
        return await addDoc(propertiesRef, {
            userId,
            createdAt: new Date()
        });
    } catch (e) {
        console.log(e);
        throw Error(e);
    }
};

export const StartScreen: React.FC = (props) => {
    const nav = useNavigation();
    const { user } = useAuthentication();
    const [isLoading, setIsLoading] = React.useState(false);
    const [homeContent, setHomeContent] = useState<IHomeContent[]>();
    console.log("START SCREEN")
    const onCreateProperty = async () => {
        // setIsLoading(true);
        try {
            if (user) {
                console.log("WE HAVE USER")
                //const propertyRef = await createProperty(user.uid);
                //nav.navigate('ListProperty', {screen: 'type', params: { propertyId: propertyRef.id }} );
                nav.navigate('ListProperty', {screen: 'type'} );
                return;
            }
            console.log("NO USER FOUND")
            throw Error('User not found');
        } catch (e) {
            // setIsLoading(true);
            console.log("NO USER")
            console.log(e);
        }
    };

    // useEffect(() => {
    //     getHomeContent().then(res => {
    //         setHomeContent(res);
    //     });
    // });

    // if(homeContent === undefined){
    //     return null;
    // }

    
    return (
        <ScreenBase>  
            <ScrollView contentContainerStyle={{flex:1,height:'100%'}} showsVerticalScrollIndicator={false}>
                <View style={[styles.banner]} >
                    <AntDesign
                        style={[{ position: 'absolute', top: 20, right: 20, zIndex: 100 }]}
                        name="close"
                        size={25}
                        onPress={() => { nav.navigate('Platform', { screen: 'Home' }); }}
                    />
                    {/* </TouchableOpacity> */}
                    <Image style={[styles.image]} source={homeContent[0].ListAPropertyBanner} resizeMode="cover" />
                </View>
                <View style={[Layout.globalPadding24,{justifyContent:'space-between',flex:1}]}>
                    <View style={[styles.textContainer]}>
                        {/*<Text style={[FontProperty.h3]}>Create a property listing in 8 easy steps</Text>
                        <Text style={[FontProperty.body_1, FontProperty.TextGrey, { paddingTop: 24 }]}>We want to hear all about your property. The more detailed you are, the better. </Text>*/}
                        <Text style={[FontProperty.h3]}>Coming to the web app soon. In the meantime, please use our mobile app to list properties.</Text>
                    </View>
                    <View style={{marginBottom:40}}>
                        {/*<TealButton isDisabled={isLoading} onPress={onCreateProperty} >
                            {isLoading
                                ? <ButtonLoader />
                                : <Text style={[FontProperty.button_1]}>Let's go</Text>
                            }
                        </TealButton>*/}
                    </View>
                </View>
            </ScrollView>
            {/* {user == false &&
                <PopUpModal show={true}>
                    <Pressable onPress={() => nav.navigate('Platform', { screen: 'Home' })} style={{marginBottom: 20}}>
                        <MaterialCommunityIcons name='arrow-left' size={20} />
                    </Pressable>
                    <View style={{alignItems: 'center'}}>
                        <Text style={[FontProperty.h1, FontProperty.TextBlack, Layout.mbottom8]}>List property is locked <MaterialCommunityIcons name='lock' size={20} /></Text>
                        <Text style={[FontProperty.smallP, Layout.mbottom25, {textAlign: 'center'}]}>{ContentText.encourageSignUpListP}</Text>
                        <Image source={IMAGE.signUpPlaceholder} style={{width:250,height:230,resizeMode:'contain',marginBottom:40}} />
                        <TealButton onPress={() => nav.navigate('Platform', { screen: 'Authentication' })} >
                            <Text style={[FontProperty.button_1, FontProperty.TextWhite]}>Sign In / Register</Text>
                        </TealButton>
                        <Text style={[FontProperty.smallLabel, {textAlign: 'center'}]}>Don't want to sign in? 
                            <Pressable onPress={() => nav.navigate('Platform', { screen: 'Explore' })}>
                                <Text style={FontProperty.TextTeal}>  Explore properties</Text>
                            </Pressable>
                        </Text>
                    </View>
                </PopUpModal>
            }  */}
        </ScreenBase>
    );
};

const styles = StyleSheet.create({
    outContainer: {
        backgroundColor: Colors.white,
        paddingTop: 30
    },
    imageContainer: {
        alignSelf: 'center',
        marginBottom: 41
    },
    banner: {
        width: '100%',
        height: Layout.window.height * 0.4
    },
    image: {
        height: '100%',
        width: '100%'
    },
    textContainer: {
        paddingTop: 46,
        paddingBottom: 64
    }
});
