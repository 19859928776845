import React from 'react';
import { View, Text, StyleSheet, TextInput } from 'react-native';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';

export type TDataTextRow = {
    label : string
    value : string | undefined
    setValue : (arg0: string) => void
}

export const DataLandSize = (props:TDataTextRow) => {
    return(
        <View style={styles.dataTextRow}>
            <View style={styles.dataTextRowData}>
                <View style={styles.container}>
                    { props.label && <Text style={[FontProperty.h4]}>{props.label}</Text>}
                    <TextInput 
                        style={styles.input}
                        keyboardType='numeric'
                        value={props.value}
                        onChangeText={props.setValue} 
                    />
                </View>
            </View>
            <View style={[Layout.row, {height: '80%'}]}>
                <Text style={FontProperty.h4}>㎡</Text>
            </View>
        </View>
    );
};

export default DataLandSize;

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    input: {
        height: 40,
        fontSize: 14,
        borderWidth: 1,
        borderColor: Colors.grey4,
        width: 121, 
        padding: 10,
    },
    dataTextRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    dataTextRowData: {
        width: '90%',
    },


});