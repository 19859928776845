import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';

export type TDataIncrementRow = {
    label : string
    value : number
    setValue : (arg0: number) => void
}
const DataIncrementRow = (props:TDataIncrementRow) => {
    const Increment = () => {
        props.setValue(props.value + 1);
    };

    const Decrement = () => {
        let value = 0;
        if(props.value <= value) {
            value = 0;
        }else{
            value = props.value;
        }

        if(value < 1){
            value = 1;
        }

        props.setValue(value-1);

    };

    return (
        <View style={styles.container}>
            { props.label !== '' && <Text style={[FontProperty.h4, FontProperty.h4, {color:'#2E2B26'}]}>{props.label}</Text> }
            <View style={styles.innerContainer}>
                <Text style={styles.button} onPress={() => Decrement()}> - </Text>
                <Text style={[FontProperty.h4, FontProperty.TextSemiBold, {color:'#2E2B26'}]}>{props.value}</Text>
                <Text style={[styles.button, styles.buttonMinus]} onPress={() => Increment()}> + </Text>
            </View>
        </View>
    );
};

export default DataIncrementRow;

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginBottom: 30
    },
    button: {
        backgroundColor:Colors.white,
        borderWidth: 0,
        padding: 10,
        fontSize: 11,
    },
    innerContainer: {
        flexDirection: 'row',
        width: 153,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    buttonMinus: {
        paddingRight: 0,
    }
});