import React from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import { TealButton } from '../Button';

export type TWarningPopUp = {
    title : string
    content : string
    yesBtnLabel : string
    noBtnLable : string
    yesBtnOnClick : () => void
    noBtnOnClick : () => void
}

const WarningPopUp = (props : TWarningPopUp) => {
    return (
        <View style={styles.popUpContainer}>
            <View style={styles.popUpInnerContainer}>
                <Text style={[FontProperty.h1,{marginBottom:16}]}>{props.title}</Text>
                <Text style={{marginBottom:40}}>{props.content}</Text>
                <View style={Layout.rowBetween}>
                    <View></View>
                    <View></View>
                    <Pressable onPress={()=>props.noBtnOnClick()}><Text>Cancel</Text></Pressable>
                    <TealButton onPress={()=>props.yesBtnOnClick()} isDisabled ={false} ><Text style={[FontProperty.TextWhite, FontProperty.TextSemiBold, FontProperty.p2]}>Delete</Text></TealButton>
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    popUpContainer: {
        position:'absolute',
        zIndex: 3,
        width: '100%',
        height: Layout.window.height,
        backgroundColor: '#000000cc',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 24
    },
    popUpInnerContainer: {
        width: Layout.window.width > 425 ? 360 : '100%',
        backgroundColor: Colors.white,
        borderRadius: 16,
        padding: 32
    },
});

export default WarningPopUp;
