import React, {useState, useEffect} from 'react';
import { StyleSheet, Text, Pressable, Image } from 'react-native';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import { getPropertyData } from '../../lib/api/Content/property';
import { IpropertyData } from '../../lib/api/Content/types';
import { useNavigation } from '@react-navigation/native';

export type THouseTile = {
    propertyId : string
}

const HouseTileID: React.FC<THouseTile> = (props) => {
    const nav = useNavigation();
    const [propertyData, setPropertyData] = useState<IpropertyData>();

    useEffect(() => {
        getPropertyData(props.propertyId).then(res => {
            setPropertyData(res.data());
        });
    },[]);

    if(!propertyData){
        return null;
    }

    return (
        <Pressable key={props.propertyId} style={[styles.outContainer, Layout.mbottom25]}
            onPress={() => {
                nav.navigate('PropertyProfile', { propertyId: props.propertyId });
            }}
        >
            <Image source={propertyData.images[0].viewUrl} style={styles.houseImage}/>
            <Text style={[FontProperty.TextGrey, FontProperty.p1, FontProperty.TextMedium, {marginBottom:4}]}>{propertyData.propertyName}</Text>
            <Text style={[FontProperty.TextGrey, FontProperty.smallP]}>{propertyData.address[1] + ' ' + propertyData.address[2]}</Text>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    outContainer: {
        width: (Layout.window.width - 68) / 2,
    },
    houseImage: {
        width: (Layout.window.width - 68) / 2,
        height: Layout.window.width > 767 ? 244 : 144,
        resizeMode: 'cover',
        marginBottom: 8,
        borderRadius: 4,
    }
});

export default HouseTileID;

