import { StyleSheet, Text, View, Image, Pressable } from 'react-native';
import React from 'react';
import { IMessageContent } from '../../types';
import { timeSince } from '../../lib/utils/dateAdition';
import IMAGE from '../../assets/images';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import { useNavigation } from '@react-navigation/native';
import { AntDesign } from '@expo/vector-icons';

export type TSingleMessage = {
    message : IMessageContent
    senderImage : string
    receiverImage : string
    senderId : string
    receiverId : string
}

const SingleMessage = (props:TSingleMessage) => {
    const nav = useNavigation();
    const pos = props.message.sender === props.senderId ?  'left' : 'right';
    const time = timeSince(props.message.createdByTimestamp);
    const image = props.message.sender === props.senderId ? props.senderImage : props.receiverImage;
    const userId = props.message.sender === props.senderId ? props.senderId : props.receiverId;

    return (
        <View style={[{flexDirection:pos === 'left' ? 'row' : 'row-reverse'}, styles.messageOutContainer]}>
            <View style={[{flexDirection:pos === 'left' ? 'row' : 'row-reverse'}, styles.messageInnerContainer]}>
                <Text style={[FontProperty.miniP, FontProperty.TextGrey, {textAlign:pos === 'left' ? 'left' : 'right',marginTop:15}]}>{time}</Text>
                <Pressable onPress={() => nav.navigate({name: 'UserProfile', params: { userId: userId, postId: '' }})}>
                    <Image source={image ? image : IMAGE.userProfile} style={[styles.headshot]} />
                </Pressable>
                <View style={[styles.messageBox, pos === 'right' && styles.tealMessage]}>
                    { pos === 'left' &&
                    <AntDesign name='caretdown' color={Colors.lightBeige} style={styles.triangle} size={20}/>
                    }
                    <Text style={[FontProperty.TextSemiBold, pos === 'right' && FontProperty.TextWhite]}>{props.message.message}</Text>
                    { pos === 'right' &&
                    <AntDesign name='caretdown' color={Colors.teal} style={styles.triangleRight} size={20}/>
                    }
                </View>
            </View>
        </View>
    );
  
};

export default SingleMessage;

const styles = StyleSheet.create({
    headshot : {
        width: 45,
        height: 45,
        resizeMode: 'cover',
        borderRadius: 45,
        marginHorizontal: 10,
    },
    messageOutContainer: {
        justifyContent:'space-between',
        marginBottom: 20,
        alignItems:'center',
    },
    messageInnerContainer: {
        flex: 1,
        alignItems:'flex-start',
    },
    messageBox: {
        backgroundColor: Colors.lightBeige,
        borderRadius: 8,
        padding: 16,
        maxWidth: '75%'
    },
    tealMessage: {
        backgroundColor: Colors.teal,
    },
    triangle: {
        position: 'absolute',
        left: -10,
        transform: [
            { rotate: '90deg' },
        ],
        top: 13
    },
    triangleRight: {
        position: 'absolute',
        right: -10,
        transform: [
            { rotate: '270deg' },
        ],
        top: 13
    }
});
