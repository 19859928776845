import React, { SetStateAction, useState } from 'react';
import { View, Text, StyleSheet, Switch } from 'react-native';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';

type ThouspoSwitchProps = {
    setSwitchValue : React.Dispatch<SetStateAction<boolean>>
    switchValue : boolean
    label : string
}

export const HouspoSwitch : React.FC<ThouspoSwitchProps> = (props) => {
    const toggleSwitch = () => props.setSwitchValue(previousState => !previousState);

    return (
        <View style={styles.container}>
            { props.label && <Text style={[FontProperty.p1, FontProperty.TextMedium]}>{props.label}</Text>}
            <Switch
                trackColor={{ false: Colors.grey6, true: Colors.teal }}
                thumbColor={Colors.white}
                ios_backgroundColor={Colors.white}
                onValueChange={toggleSwitch}
                value={props.switchValue}
            />
        </View>
    );
};

export default HouspoSwitch;

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
});
