import React, { useState } from 'react';
import { StyleSheet, View, Text, Pressable, TextInput, Image } from 'react-native';
import Layout from '../../constants/Layout';
import FontProperty from '../../constants/FontProperty';
import Colors from '../../constants/colors';
import { createProjectPost, createPropertyPost } from '../../lib/api/Content/posts/posts';
import { useNavigation } from '@react-navigation/native';
import ProfileHeadshotById from './HomeOwnerHeadshotByID';
import { uppercaseFirstLetter } from '../../lib/utils/uppercaseFirstLetter';

export type TPopUpPropertyModal = {
    userId : string
    propertyId ? : string
    projectId ? : string
    cancelBtnOnClick : () => void
    propertyName : string
    propertyDesc : string
    propertyImage : string
}
const PropertyToPost = (props:TPopUpPropertyModal) => {
    const nav = useNavigation();
    const [text, setText] = useState('');

    const weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][new Date().getDay()];
    const formatAMPM = (date:any) => {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0'+minutes : minutes;
        const strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    };
    
    const doneBtnOnClick = async () => {
        props.propertyId !== undefined && await createPropertyPost(props.userId, props.propertyId, text, props.propertyImage, props.propertyName, uppercaseFirstLetter(props.propertyDesc));
        props.propertyId !== undefined && nav.navigate('Platform', { screen: 'PropertyProfile', params: {propertyId: props.propertyId, updated: Date.now()} });
        props.projectId !== undefined && await createProjectPost(props.userId, props.projectId, text, props.propertyImage, props.propertyName, uppercaseFirstLetter(props.propertyDesc));
        props.projectId !== undefined && nav.navigate('Platform', { screen: 'ProjectProfile', params: {projectId: props.projectId, updated: Date.now()} });
        
    };
    
    return (
        <View style={styles.popUpContainer}>
            <View style={styles.popUpInnerContainer}>
                <View style={styles.topButtons}>
                    <Pressable onPress={()=>props.cancelBtnOnClick()}><Text style={[FontProperty.p1,FontProperty.TextGrey,FontProperty.TextSemiBold,{textAlign:'center'}]}>Cancel</Text></Pressable>
                    <Pressable onPress={()=>doneBtnOnClick()}><Text style={[FontProperty.p1,FontProperty.TextTeal,FontProperty.TextSemiBold,{textAlign:'center'}, props.projectId !== undefined && styles.darkThemeBtnTxt]}>Done</Text></Pressable>
                </View>
                <View style={{paddingVertical:16, paddingHorizontal:24}}>
                    <View style={[Layout.row, Layout.rowBetween, Layout.mbottom25]}>
                        <ProfileHeadshotById userId={props.userId} />
                        <Text style={[FontProperty.smallP, FontProperty.TextGrey]}>{weekday + ', ' + formatAMPM(new Date)}</Text>
                    </View>
                    <View >
                        <TextInput
                            autoCapitalize='none'
                            style={[styles.input]}
                            placeholder='Write something...'
                            placeholderTextColor={Colors.grey3}
                            onChangeText={setText}
                            value={text}
                            multiline={true}
                            numberOfLines={2}
                        />
                    </View>
                </View>
                <View style={{paddingHorizontal:24}}>
                    <View style={styles.boxShaw}>
                        <Image source={props.propertyImage} resizeMode='cover' style={{width: Layout.window.width > 425 ? 312 : Layout.window.width - 96, height: 95}} />
                        <View style={{padding:10}}>
                            {props.projectId !== undefined &&
                            <View style={styles.pill}><Text style={[FontProperty.miniP, FontProperty.TextWhite, FontProperty.TextSemiBold]}>Project</Text></View>
                            }
                            <Text style={[FontProperty.smallP,FontProperty.TextBold,{marginBottom:4}]}>{props.propertyName}</Text>
                            <Text style={[FontProperty.smallP,{marginBottom:16}]} numberOfLines={1} ellipsizeMode='tail' >{props.propertyDesc}</Text>
                            <View style={[styles.btn, props.projectId !== undefined && styles.darkThemeBtn]}><Text style={[FontProperty.TextTeal,FontProperty.TextSemiBold, props.projectId !== undefined && styles.darkThemeBtnTxt]}>View updated {props.projectId !== undefined ? 'project' : 'property'}</Text></View>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    popUpContainer: {
        position:'absolute',
        zIndex: 3,
        width: '100%',
        height: Layout.window.height,
        backgroundColor: '#000000cc',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 24,
    },
    popUpInnerContainer: {
        width: Layout.window.width > 425 ? 360 : '100%',
        backgroundColor: Colors.white,
        borderRadius: 16,
        paddingBottom: 40,
    },
    MenuClose_big: {
        width: 21,
        height: 21,
        marginBottom: 16,
        alignSelf: 'flex-end'
    },
    topButtons: {
        justifyContent:'space-between',
        alignItems:'center',
        flexDirection:'row',
        padding: 24,
        borderBottomWidth: 0.5,
        borderColor: '#4f4f4f4d'
    },
    input: {
        color: Colors.navy,
        fontSize: 14,
        width: '100%',
        flex : 1,
        outlineStyle: 'none' 
    },
    btn: {
        width: '100%',
        borderColor: Colors.teal,
        borderWidth: 1,
        textAlign: 'center',
        paddingVertical: 7.5,
        borderRadius: 4
    },
    darkThemeBtn: {
        borderColor: Colors.navy,
    },
    darkThemeBtnTxt: {
        color: Colors.navy,
    },
    boxShaw: {
        boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.08)'
    },
    pill: {
        paddingVertical: 6,
        paddingHorizontal: 16,
        backgroundColor: Colors.navy,
        borderRadius: 16,
        width: 75,
        marginBottom: 12
    },
});

export default PropertyToPost;
