import { create } from 'mobx-persist'

import { observable } from 'mobx'
import AsyncStorage from '@react-native-async-storage/async-storage'

import userStore from './userStore'
import addListingStore from './addListingStore'

const hydrate = create({
  storage: AsyncStorage,
  jsonify: true
})

export const hydrateAll = () => {
  return Promise.all([
    hydrate('userStore', userStore),
    hydrate('addListingStore', addListingStore)
  ])
}

class RootStore {
  @observable hydrated = false
  userStore = userStore
  addListingStore = addListingStore
};

export default new RootStore()
