import { signInWithRedirect, getCurrentUser, AuthUser, fetchAuthSession, signOut } from "aws-amplify/auth";
 
export const fetchAccessToken = async () => {
  try {
    const { accessToken } = (await fetchAuthSession({ forceRefresh: true })).tokens ?? {};
    return accessToken;
  } catch (error) {
    console.log('Error fetching access token:', error);
  }
};

export type TloginResponse = {
  token_type: string
  expires_in : number
  access_token : string
  refresh_token : string
}

export const logout = () => {
    signOut();
};

export type TembeddedData = {
uuid : string
uid : string
}


export const doesUsernameExist = async (username : string) => {
    console.log('lookig for doesUsernameExist');
    return 1; 
    const q = query(collection(db, 'userData'), where('username', '==', username));
    const querySnapshot = await getDocs(q);
    return querySnapshot?.docs.length > 0;
};

export const login = async ( username: string, password: string ) => {
    var authenticationData = {
        Username: 'username',
        Password: 'password',
    };
    /*var authenticationDetails = new CognitoIdentityServiceProvider(
        authenticationData
    );*/
}

export const updateUsername = async (userId : string, username : string) => {
    try {
        const userDataRef = doc(collection(db, 'userData'), userId);
        await setDoc(userDataRef, { username },{ merge: true });
    } catch (err) {
        throw new Error(err);
    }
};

export const updateUserOnboarding = async (userId : string, onboardingData: any) => {
    try {
        const userDataRef = doc(collection(db, 'userData'), userId);
        await setDoc(userDataRef, 
            { onboardQuestions: onboardingData, isOnboarded: true, receiveNotification: true, receiveMessage: true },
            { merge: true }
        );
    } catch (err) {
        throw new Error(err);
    }
};

export const updateUserHeadshot = async (userId : string, headshot : string) => {
    try {
        const userDataRef = doc(collection(db, 'userData'), userId);
        await setDoc(userDataRef, { headshot },{ merge: true });
    } catch (err) {
        throw new Error(err);
    }
};



/*
  const userPool = new CognitoUserPool({
    UserPoolId: userPoolData.UserPoolId,
    ClientId: userPoolData.ClientId,
  })
  
  export function signUp(username, email, password) {
    // Sign up implementation
  }
  
  export function confirmSignUp(username, code) {
    // Confirm sign up implementation
  }
  
  export function signIn(username, password) {
    // Sign in implementation
  }
  
  export function forgotPassword(username) {
    // Forgot password implementation
  }
  
  export function confirmPassword(username, code, newPassword) {
    // Confirm password implementation
  }
  
  export function signOut() {
    // Sign out implementation
  }
  
  export function getCurrentUser() {
    // Get current user implementation
  }
  
  export function getSession() {
    // Get session implementation
  }*/