// PROD
var cognitoConfiguration;

if (window.location.host == 'web.abodely.app') {
    // var userPoolData = {
    //     UserPoolId: 'ap-southeast-2_9P2MAE7Zu', 
    //     ClientId: '7bq1hqph4fqsfi9hd2dot7rjbt', 
    //     Region: 'ap-southeast-2',
    // };


    cognitoConfiguration = {  
        Auth: {
          Cognito: {
            userPoolClientId: '7bq1hqph4fqsfi9hd2dot7rjbt',
            userPoolId: 'ap-southeast-2_9P2MAE7Zu',
            loginWith: { // Optional
              oauth: {
                domain: 'abodely.auth.ap-southeast-2.amazoncognito.com',
                scopes: ['openid email aws.cognito.signin.user.admin'],
                redirectSignIn: ['https://web.abodely.app'],
                redirectSignOut: ['https://web.abodely.app'],
                logoutUrls: ['https://web.abodely.app'],
                responseType: 'code',
              username: 'false',
              email: 'true', // Optional
              phone: 'false', // Optional
              "aws_cognito_username_attributes": [
                "EMAIL"
              ],
              "aws_cognito_social_providers": [
                "GOOGLE",
                "FACEBOOK",
                "APPLE"
              ],
              "aws_cognito_signup_attributes": [
                "EMAIL"
              ],
              "aws_cognito_mfa_configuration": "OFF",
              "aws_cognito_mfa_types": [],
              "aws_cognito_password_protection_settings": {
                "passwordPolicyMinLength": 8,
                "passwordPolicyCharacters": [
                  "REQUIRES_LOWERCASE",
                  "REQUIRES_UPPERCASE",
                  "REQUIRES_NUMBERS",
                  "REQUIRES_SYMBOLS"
                ]
              },
              "aws_cognito_verification_mechanisms": [
                "EMAIL"
              ]
            }
        }
      }}};

} else { // DEV
    // var userPoolData = {
    //     UserPoolId: 'ap-southeast-2_bSj3AgNrV', 
    //     ClientId: '1s9fg4b6lkaj96vhok0ni722f5', 
    //     Region: 'ap-southeast-2',
    // };

    

    cognitoConfiguration = {  
        Auth: {
          Cognito: {
            userPoolClientId: '1s9fg4b6lkaj96vhok0ni722f5',
            userPoolId: 'ap-southeast-2_bSj3AgNrV',
            loginWith: { // Optional
              oauth: {
                domain: 'abodelydev.auth.ap-southeast-2.amazoncognito.com',
                scopes: ['openid email aws.cognito.signin.user.admin'],
                redirectSignIn: ['http://localhost:19006','https://devweb.abodely.app'],
                redirectSignOut: ['http://localhost:19006','https://devweb.abodely.app'],
                logoutUrls: ['http://localhost:19006','https://devweb.abodely.app'],
                responseType: 'code',
              },
              username: 'false',
              email: 'true', // Optional
              phone: 'false', // Optional
              "aws_cognito_username_attributes": [
                "EMAIL"
              ],
              "aws_cognito_social_providers": [
                "GOOGLE",
                "FACEBOOK",
                "APPLE"
              ],
              "aws_cognito_signup_attributes": [
                "EMAIL"
              ],
              "aws_cognito_mfa_configuration": "OFF",
              "aws_cognito_mfa_types": [],
              "aws_cognito_password_protection_settings": {
                "passwordPolicyMinLength": 8,
                "passwordPolicyCharacters": [
                  "REQUIRES_LOWERCASE",
                  "REQUIRES_UPPERCASE",
                  "REQUIRES_NUMBERS",
                  "REQUIRES_SYMBOLS"
                ]
              },
              "aws_cognito_verification_mechanisms": [
                "EMAIL"
              ]
            }
        }
      }};
}


export default cognitoConfiguration;
