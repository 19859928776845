import React from 'react';
import { StyleSheet, ScrollView, Image, Text, Pressable } from 'react-native';
import { View } from '../../components/Themed';
import Colors from '../../constants/colors';
import Verification from '../../components/Verification';
import { auth } from '../../config/firebase';
import IMAGE from '../../assets/images/index';
import { api } from '../../lib/api';
import ScreenBase from '../../components/ScreenBase';
import { useNavigation } from '@react-navigation/native';
import { AntDesign } from '@expo/vector-icons';
import Layout from '../../constants/Layout';

export const CodeVerificationScreen : React.FC = (props) => {
    const nav = useNavigation();
    const onQuit = () => {
        auth.signOut();
        nav.navigate('Home');
    };

    return (
        <ScreenBase>
            <ScrollView style={styles.outContainer} contentInset={{ bottom: 150 }}>
                <View >
                    <AntDesign
                        name="arrowleft"
                        size={25}
                        onPress={onQuit}
                        style={[Layout.globalPadding24, Layout.mbottom15]}
                    />
                </View>
                <View style={styles.imageContainer}>
                    <Image source={IMAGE.Verification} style={styles.image} />
                </View>
                <Verification setUser={props.setUser} />
            </ScrollView>
        </ScreenBase>
    );
};

const styles = StyleSheet.create({
    outContainer: {
        backgroundColor: Colors.white,
        paddingTop: 30
    },
    imageContainer: {
        alignSelf: 'center',
        marginBottom: 41
    },
    image: {
        width: 214,
        height: 193
    },
    bottomTextHyper: {
        color: Colors.teal,
        textAlign: 'center',
    },
});
