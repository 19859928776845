import React, {useEffect, useRef, useState} from 'react';
import { View, Image, StyleSheet, Text, Pressable, ActivityIndicator } from 'react-native';
import { useNavigation, useScrollToTop } from '@react-navigation/native';
import { ScrollView } from 'react-native-gesture-handler';

import Layout from '../../constants/Layout';
import FontProperty from '../../constants/FontProperty';
import { IuserData } from '../../lib/api/user/types';
import { getUserData } from '../../lib/api/user';
import { deletePost, getFollowUserState, getMyFollowers, getMyFollowing, setFollowUser } from '../../lib/api/Content/posts/posts';
import { useAuthentication } from '../../hooks/useAuthentication';
import IMAGE from '../../assets/images';
import Colors from '../../constants/colors.js';

import ScreenBase from '../../components/ScreenBase';
import ScreenHeader from '../../components/atom/ScreenHeader';
import { GreyButton, TealButton } from '../../components/Button';
import AllFFI from '../../components/atom/AllFFI';
import MyPLF from '../../components/molecule/MyPLF';
import ContactHomeownerModel from '../../modals/ContactHomeownerModel';
import ProfileSettings from '../../components/profileScreen/ProfileSettings';
import AllPostsLikeComments from '../../components/atom/AllPostsLikeComments';
import ShareModal from '../../components/atom/ShareModal';
import PopupNotifyUser from '../../components/atom/PopUpNotifyUser';
import PopUpEditPost from '../../components/atom/PopUpEditPost';
import PopUpReportModal from '../../components/atom/PopUpReportModal';
import { sendNotificationHouspo } from '../../lib/api/messages/notifications';
import ContentText from '../../constants/Content';
import { userAllowReceiveNotification } from '../../lib/api/messages/messages';
import NoResult from '../../components/atom/NoResult';
import PopUpViewOnePostModal from '../../components/atom/PopUpViewOnePostModal';

const UserProfileScreen = ({route}:any) => {
    const { user } = useAuthentication();
    const nav = useNavigation();
    const [targetUserData, setUserData] = useState<IuserData>();
    const [targetUser, setTargetUser] = useState<string>();
    const [loading, setLoading] = useState(true);
    const [isMe, setIsMe] = useState(false);
    const [loginStatus, setHaslogin] = useState(false);
    const [visualFollow, setVisualFollow] = useState<boolean>(false);
    const [numFollowers, setNumFollowers] = useState<number>(0);
    const [numFollowing, setNumFollowing] = useState<number>(0);
    const [numInspirations, setNumInspirations] = useState<number>(0);
    const [followers, setFollowers] = useState(['']);
    const [following, setFollowing] = useState(['']);
    const [inspirations, setInspirations] = useState([]);
    const [showffi, setShowffi] = useState(false);
    const [ffiTab, setffiTab] = useState<'Followers' | 'Followings' | 'Inspirations'>('Followers');
    const [trigger, setTrigger] = useState<boolean>(false);
    const [showContactHomeownerModal, setContactHomeownerModal] = useState(false);
    const [showProfileSettings, setShowProfileSettings] = useState<boolean>(false);
    const [showLikes, setShowLikes] = useState(false);
    const [showComments, setShowComments] = useState(false);
    const [targetPost, setTargetPost] = useState('');
    const [showShare, setShowShare] = useState(false);
    const [shareLink, setShareLink] = useState('');
    const [showDeletePopUp, setShowDeletePopUp] = useState(false);
    const [showEditPopUp, setShowEditPopUp] = useState(false);
    const [showReportPopUp, setShowReportPopUp] = useState(false);
    const [receiveNotification, setUserReceiveNotification] = useState<boolean>();
    const [showPostPopUp, setShowPostPopUp] = useState<boolean>(false);
    const [popUpPost, setPopUpPost] = useState<string>('');
    const ref = useRef();


    useEffect(() => {
        if(route.params && route.params.userId) {
            setTargetUser(route.params.userId);
        }else{
            user && setTargetUser(user?.uid);
        }

        if(route.params && route.params.postId){
            setShowPostPopUp(true);
            setPopUpPost(route.params.postId);
            setTargetPost(route.params.postId);
        }

        showLikes === true && setShowLikes(false);
        showComments === true && setShowComments(false);

        ref.current?.scrollTo({
            y: 0,
            animated: true,
        });
    }, [route.params, user, route.params.userId]);

    useScrollToTop(ref);

    useEffect(() => {
        targetUser &&
        getUserData(targetUser).then(userData => {
            setUserData(userData.data());

            if(user){
                user?.uid === targetUser ? setIsMe(true) : setIsMe(false);
                setHaslogin(true);
            }else{
                setHaslogin(false);
            }

            if(userData.data() !== undefined && userData.data().favouriteImages !== undefined){
                setNumInspirations(userData.data().favouriteImages.length);
                setInspirations(userData.data().favouriteImages);
            }else{
                setNumInspirations(0);
                setInspirations([]);
            }
            
            setLoading(false);
        });

        targetUser &&
        userAllowReceiveNotification(targetUser, setUserReceiveNotification);
        
    }, [targetUser, user, trigger, receiveNotification]);

    useEffect(() => {
        !isMe && targetUser && user &&
        getFollowUserState(targetUser, user?.uid).then(ref=>{
            setVisualFollow(ref);
        });

        targetUser &&
        getMyFollowing(targetUser).then(ref=>{
            setNumFollowing(ref.length);
            setFollowing(ref);
        });

        targetUser &&
        getMyFollowers(targetUser).then(ref=>{
            setNumFollowers(ref.length);
            setFollowers(ref);
        });
    },[targetUser, user, trigger, isMe]);

    if(loading || targetUser === undefined || targetUserData === undefined) {
        return (
        // <ScreenBase>
        //     <ScreenHeader noShadow={false}/>
        //     <ScrollView>
        //         <View style={[Layout.globalPadding24, {alignItems:'center'}]}>
        //             <NoResult title="User doesn't exist" removeGapbelow={true} />
        //             <View style={{marginTop:24}}></View>
        //             <View style={{width: Layout.window.width > 425 ? 180 : '100%'}}>
        //                 <TealButton onPress={()=>nav.navigate('Home')}>
        //                     <Text style={[FontProperty.button_1, FontProperty.TextWhite]}>Back to Home</Text>
        //                 </TealButton>
        //             </View>
        //         </View>
                    
            //     </ScrollView>
            // </ScreenBase>
            <ScreenBase>
                <ScreenHeader noShadow={false}/>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <View style={{marginTop:150}}>
                        <ActivityIndicator size={Layout.window.width > 425 ? 107 : 80} color={Colors.teal} />
                    </View>
                </ScrollView>
            </ScreenBase>
        );
    }

    const followThisUser = (uid: string) => {
        if(loginStatus){
            setVisualFollow(true);
            setFollowUser(uid, user?.uid, true);
            receiveNotification && sendNotificationHouspo(user?.uid, targetUser, '', '', false, false, false, true);
        }else{
            nav.navigate('Platform', { screen: 'Authentication', params: { target: 'LOGIN' } });
        }
    };

    const unfollowThisUser = (uid: string) => {
        if(loginStatus){
            setVisualFollow(false);
            setFollowUser(uid, user?.uid, false);
        }else{
            nav.navigate('Platform', { screen: 'Authentication', params: { target: 'LOGIN' } });
        }
    };

    const messageUser = () => {
        loginStatus
            ? setContactHomeownerModal(true)
            : nav.navigate('Platform', { screen: 'Authentication', params: { target: 'LOGIN' } });
    };

    const deleteMyPost = async (postId:string)=> {
        await deletePost(postId);
        setShowDeletePopUp(false);
        setTrigger(!trigger);
    };
    return (
        <>
            { targetUserData !== undefined &&
            <ScreenBase>
                <ScreenHeader/>
                <ScrollView 
                    showsVerticalScrollIndicator={false} 
                    contentContainerStyle={Layout.scrollViewPadding}
                    scrollEventThrottle={0}
                    ref={ref}
                >
                    <View style={styles.sectionOne}>
                        <Image source={targetUserData.headshot ? targetUserData.headshot : IMAGE.userProfile} resizeMode={'cover'} style={styles.imgCard}/>
                        <Text style={[FontProperty.h4, FontProperty.TextSemiBold]}>{targetUserData.username}</Text>
                        <View style={[Layout.rowBetween, {marginTop:24,width:'100%',maxWidth:375,marginBottom:24,opacity:isMe?1:0.5}]}>
                            <Pressable style={[Layout.alignCenter, {flex:1}]} onPress={()=>{isMe && setShowffi(true);isMe && setffiTab('Followers');}}>
                                <Text style={[FontProperty.h4, FontProperty.TextSemiBold]}>{numFollowers}</Text>
                                <Text style={[FontProperty.smallLabel, FontProperty.TextGrey]}>Followers</Text>
                            </Pressable>
                            <Pressable style={[Layout.alignCenter, {flex:1,borderColor:'#4f4f4f66',borderLeftWidth:0.5,borderRightWidth:0.5}]} onPress={()=>{isMe && setShowffi(true);isMe && setffiTab('Followings');}}>
                                <Text style={[FontProperty.h4, FontProperty.TextSemiBold]}>{numFollowing}</Text>
                                <Text style={[FontProperty.smallLabel, FontProperty.TextGrey]}>Following</Text>
                            </Pressable>
                            <Pressable style={[Layout.alignCenter, {flex:1}]} onPress={()=>{isMe && setShowffi(true);isMe && setffiTab('Inspirations');}}>
                                <Text style={[FontProperty.h4, FontProperty.TextSemiBold]}>{numInspirations}</Text>
                                <Text style={[FontProperty.smallLabel, FontProperty.TextGrey]}>Inspirations</Text>
                            </Pressable>
                        </View>
                        {
                            (isMe && loginStatus)
                                ? <GreyButton onPress={()=>setShowProfileSettings(true)}><Text style={[FontProperty.TextSemiBold,FontProperty.p2,FontProperty.TextNavy]}>Profile Settings</Text></GreyButton>
                                : <View style={[Layout.rowBetween, {width:'100%'}]}>
                                    {
                                        !visualFollow
                                            ? <TealButton onPress={()=>followThisUser(targetUser)} btnStyle={{width:(Layout.window.width - 60)/2}}>
                                                <Text style={[FontProperty.TextSemiBold,FontProperty.p2,FontProperty.TextWhite]}>Follow</Text>
                                            </TealButton>
                                            : <GreyButton onPress={()=>unfollowThisUser(targetUser)} btnStyle={{width:(Layout.window.width - 60)/2}}>
                                                <Text style={[FontProperty.TextSemiBold,FontProperty.p2,FontProperty.TextNavy]}>Unfollow</Text>
                                            </GreyButton>
                                    }
                                    <GreyButton onPress={()=>messageUser()} btnStyle={{width:(Layout.window.width - 60)/2}}><Text style={[FontProperty.TextSemiBold,FontProperty.p2,FontProperty.TextNavy]}>Message</Text></GreyButton>
                                </View>
                        }
                    </View>
                    <View style={styles.sectionTwo}>
                        <MyPLF 
                            uid={user?.uid}
                            isMe={isMe}
                            targetUser={targetUser}
                            trigger={trigger}
                            setTrigger={setTrigger}
                            setShowLikes={setShowLikes}
                            setShowComments={setShowComments}
                            setTargetPost={setTargetPost}
                            setShowShare={setShowShare}
                            setShareLink={setShareLink}
                            setShowDeletePopUp={setShowDeletePopUp}
                            setShowEditPopUp={setShowEditPopUp}
                            setShowReportPopUp={setShowReportPopUp}
                        />
                    </View>
                </ScrollView>
                {
                    showPostPopUp &&
                    <PopUpViewOnePostModal 
                        open={showPostPopUp}
                        setShowPostPopUp={setShowPostPopUp} 
                        postId={popUpPost}
                        setPopUpPost={setPopUpPost}
                        userId={user?.uid}
                        setShowLikes={setShowLikes}
                        setShowComments={setShowComments}
                        setTargetPost={setTargetPost}
                        setShowReportPopUp={setShowReportPopUp} 
                        setShowEditPopUp={setShowEditPopUp}
                        setShowDeletePopUp={setShowDeletePopUp}
                        setShowShare={setShowShare}
                        setShareLink={setShareLink}
                    />
                }
                {
                    showffi &&
                    <AllFFI 
                        uid={targetUser}
                        open={showffi} 
                        tab={ffiTab} 
                        setShowffi={setShowffi}
                        followers={followers}
                        followings={following}
                        inspirations={inspirations.reverse()}
                        trigger={trigger}
                        setTrigger={setTrigger}
                    />
                }
                {
                    showContactHomeownerModal &&
                    <ContactHomeownerModel 
                        open={showContactHomeownerModal}
                        setContactHomeownerModal={setContactHomeownerModal}
                        hideHeader={true}
                        placeholder={'Say something to ' + targetUserData.username}
                        succeedBtnLabel={'Return to ' + targetUserData.username + '\'s profile'}
                        succeedText="Message sent!"
                        senderId={user?.uid}
                        receiverId={targetUser}
                        targetUserData={targetUserData}
                    />
                }
                {
                    showProfileSettings &&
                    <ProfileSettings 
                        open={showProfileSettings}
                        setShowProfileSettings={setShowProfileSettings}
                        userId={targetUser}
                        trigger={trigger}
                        setTrigger={setTrigger}
                    />
                }
                {
                    (showLikes || showComments) &&
                        <AllPostsLikeComments 
                            open={showLikes || showComments}
                            showLikes={showLikes} 
                            showComments={showComments} 
                            targetPost={targetPost}  
                            setShowLikes={setShowLikes}
                            setShowComments={setShowComments}
                            setTargetPost={setTargetPost}
                        />
                }
                {
                    showShare &&
                    <ShareModal setShareModal={()=>setShowShare(false)} url={shareLink} mailSubject='Check out this post on abodely.app' title='post'/>
                }
                {
                    showDeletePopUp &&
                    <PopupNotifyUser 
                        title='Delete this post?'
                        subtitle={ContentText.deleteThisPost}
                        yesBtnLabel='Yes'
                        noBtnLable='No'
                        yesBtnOnClick={()=>deleteMyPost(targetPost)}
                        noBtnOnClick={()=>setShowDeletePopUp(false)}
                    />
                }
                { showEditPopUp &&
                    <PopUpEditPost 
                        userId={user?.uid}
                        postId={targetPost}
                        setShowEditPopUp={setShowEditPopUp}
                        trigger={trigger}
                        setTrigger={setTrigger}
                    />
                }
                {
                    showReportPopUp && 
                    <PopUpReportModal 
                        open={showReportPopUp}
                        setShowReportPopUp={setShowReportPopUp} 
                        postId={targetPost} 
                        setTargetPost={setTargetPost}
                    />
                }
                
            </ScreenBase>
            }
        </>
    );
};

export default UserProfileScreen;


const styles = StyleSheet.create({
    sectionOne: {
        paddingTop: 32,
        paddingBottom: 40,
        paddingHorizontal: 24,
        textAlign: 'center',
        alignItems: 'center',
    },
    imgCard: {
        width : 75,
        height : 75,
        marginBottom: 16,
        borderRadius: 75
    },
});