import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import { TealButton } from '../Button';

export type TNewsfeedPromptBlock = {
title : string,
subtitle : string,
buttonLabel : string,
onPress : ()=>{},
}

const NewsfeedPromptBlock : React.FC< TNewsfeedPromptBlock > = (props) => {
    return (
        <View style={[Layout.center, styles.container]}>
            <Text style={[FontProperty.h1,FontProperty.TextBlack]}>{props.title}</Text>
            <Text style={[FontProperty.p1, FontProperty.TextGrey]}>{props.subtitle}</Text>
            <TouchableOpacity onPress={props.onPress}>
                <TealButton btnStyle={[styles.btnStyle]} >
                    <Text style={[FontProperty.button_1, FontProperty.TextWhite]}>{props.buttonLabel}</Text>
                </TealButton>
            </TouchableOpacity>
        </View>
    );
};

const styles = StyleSheet.create({
    btnStyle: {
        width: 'fit-content',
        paddingHorizontal: 30,
        marginTop : 16,
    },
    container : {
        paddingVertical : 40,
        backgroundColor : Colors.beigeTint
    }
});

export default NewsfeedPromptBlock;
