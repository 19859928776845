import React, {useState, useEffect} from 'react';
import { StyleSheet, View, Text, Pressable } from 'react-native';
import Layout from '../../constants/Layout';
import { INotification } from '../../types';
import { timeSince } from '../../lib/utils/dateAdition';
import FontProperty from '../../constants/FontProperty';
import Colors from '../../constants/colors';
import { AntDesign, MaterialCommunityIcons } from '@expo/vector-icons';
import ProfileHeadshotByNotification from './ProfileHeadshotByNotification';
import { deleteNotification, updateReadStatus } from '../../lib/api/messages/notifications';

export type TNotificationItem = {
    data : INotification
    userId : string
}

const NotificationItem = (props:TNotificationItem) => {
    const notification = props.data[0];
    const notificationKey = props.data[1];
    const [showEditDeletePopup, setShowEditDeletePopup] = useState(false);
    const [readUnread, setReadUnread] = useState(notification.read);

    useEffect(() => {
        setReadUnread(notification.read);
    }, [notification]);

    return (
        <View style={[!readUnread && styles.blueBg, styles.container, Layout.row, showEditDeletePopup && styles.topZindex]}>
            <View style={{flex: 1,flexWrap:'wrap'}}>
                <ProfileHeadshotByNotification 
                    dataKey={notificationKey}
                    data={notification} 
                    setRead={setReadUnread}
                    userId={props.userId}
                />
            </View>
            <View style={{alignItems:'flex-end',paddingLeft:10}}>
                <Text style={[FontProperty.miniP, {color:Colors.grey1}]}>{timeSince(notification.createdByTimestamp)}</Text>
                <Pressable onPress={()=>setShowEditDeletePopup(!showEditDeletePopup)}><MaterialCommunityIcons name='dots-horizontal' size={20} color={Colors.grey1} style={{marginTop:6}} /></Pressable>
            </View>
            {
                showEditDeletePopup &&
              <Pressable style={styles.editDeleteReportPopupContainer} onPress={()=>setShowEditDeletePopup(false)}>
                  <View style={styles.editDeleteReportPopup}>
                      <Pressable style={[Layout.row, {marginBottom: 10}]} onPress={()=>{updateReadStatus(props.userId, notificationKey, !readUnread);setShowEditDeletePopup(false);setReadUnread(!readUnread);}}>
                          <AntDesign name='eye' style={{marginRight:8}} />
                          <Text style={FontProperty.TextSemiBold}>Mark as {readUnread ? 'unread' : 'read'}</Text>
                      </Pressable>
                      <Pressable style={Layout.row} onPress={()=>{deleteNotification(props.userId, notificationKey);setShowEditDeletePopup(!showEditDeletePopup);}}>
                          <AntDesign name='delete' style={{marginRight:8}} />
                          <Text style={FontProperty.TextSemiBold}>Remove this notification</Text>
                      </Pressable>
                  </View>
              </Pressable>
            }
        </View>
    );
};


const styles = StyleSheet.create({
    blueBg: {
        backgroundColor: '#EAF3FE'
    },
    container: {
        paddingHorizontal: 16,
        paddingVertical: 12
    },
    editDeleteReportPopupContainer: {
        position: 'absolute',
        top: 4,
        right: 40,
        zIndex: 2,
        width: '90%',
        alignItems: 'flex-end'
    },
    editDeleteReportPopup: {
        backgroundColor: Colors.white,
        boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.08)',
        paddingHorizontal: 16,
        paddingVertical: 8,
        width: 225
    },
    topZindex: {
        zIndex: 4
    }
});

export default NotificationItem;
