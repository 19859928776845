import React, {useEffect, useState} from 'react';
import { Text, StyleSheet, View, Image, Pressable } from 'react-native';
import IMAGE from '../../assets/images';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import { getUserData } from '../../lib/api/user';
import { uppercaseFirstLetter } from '../../lib/utils/uppercaseFirstLetter';

export type TCommentMessage = {
    comment: string
    userID: string
}

const CommentMessage = (props:TCommentMessage) => {
    const [visitorData, setUserHeadshot] = useState<string>(IMAGE.userProfile);
    useEffect(() => {
        getUserData(props.userID).then(visitorData => {
            visitorData.data().headshot !== undefined && setUserHeadshot(visitorData.data()?.headshot);
        });
    }, [props.userID]);
    return (
        <View style={[{marginBottom:8}, Layout.row]}>
            <Image source={visitorData} style={[Layout.circle(55)]} />
            <Text style={[{marginLeft:16,paddingHorizontal:16,paddingVertical:19}, FontProperty.TextGrey]}>{uppercaseFirstLetter(props.comment)}</Text>
        </View>
    );
};

const styles = StyleSheet.create({

});

export default CommentMessage;
