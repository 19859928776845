import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { StyleSheet, Animated, ScrollView, View, FlatList } from 'react-native';
import Colors from '../../constants/colors';
import { Ionicons } from '@expo/vector-icons';
import Layout from '../../constants/Layout';
import { IpropertyData } from '../../lib/api/Content/types';
import { getUsersProperties } from '../../lib/api/Content/property';
import HouseTile from './HouseTile';
import { useNavigation } from '@react-navigation/native';

export type TAllInsightsModel = {
    open ? : boolean,
    userId : string,
    setShowOtherProperties : (arg0: boolean) => void
    propertyId : string
    trigger : boolean
    setTrigger : Dispatch<SetStateAction<boolean>>
}

const AllInsightsModel : React.FC< TAllInsightsModel > = (props) => {
    const [bounceValue, setBounceValue] = useState(new Animated.Value(Layout.window.height + 200));
    const [opacityValue, setOpacityVlaue] = useState(new Animated.Value(0));
    const [isHidden, setIsHidden ] =useState(props.open);
    const [myListing, setMyListing] = useState<IpropertyData>();
    const nav = useNavigation();

    useEffect(() => {
        props.userId !== undefined &&
        getUsersProperties(props.userId).then(res => {
            setMyListing(res);
        });
    },[props.userId]);

    // Slide show and hide the model
    const toggleSlide = (status : boolean) =>  {
        let slideToValue = Layout.window.height  + 200;
        if(status) {
            slideToValue = 0;
        }
        Animated.spring(
            bounceValue,
            {
                toValue: slideToValue,
                velocity: 10,
                tension: 2,
                friction: 8,
                useNativeDriver: false
            }
        ).start();

        let opacityToValue = 0;
        if(status) {
            opacityToValue = 1;
        }
        Animated.spring(
            opacityValue,
            {
                toValue: opacityToValue,
                velocity: 10,
                tension: 2,
                friction: 8,
                useNativeDriver: false
            }
        ).start();
    };

    // Close model
    const closeModel = () => {
        toggleSlide(false);
        props.setShowOtherProperties(false);
        props.setTrigger(props.trigger);

        setTimeout(() => {
            setIsHidden(false);
        }, 500);
    };

    // When screen loaded, slide show model
    toggleSlide(true);

    return (
        <Animated.View style={[styles.overlay, {opacity:opacityValue}, !isHidden && {display:'none'}]}>
            <Animated.View style={[styles.modelContainer, {transform: [{translateY: bounceValue}]}]}>
                <Ionicons name="close" size={20} color="#4F4F4F" style={styles.close} onPress={closeModel}/>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <View style={[Layout.rowBetween, Layout.flexWrap]}>
                        {
                            (myListing && myListing.length > 0 &&
                                <FlatList
                                    data={myListing}
                                    keyExtractor={(item:IpropertyData) => item.uuid}
                                    renderItem={({item}) => (
                                        <HouseTile 
                                            key={item.uuid} 
                                            uuid={item.uuid} 
                                            image={item.images[0].viewUrl} 
                                            name={item.propertyName} 
                                            suburb={item?.address}
                                            selected={props.propertyId === item.uuid}
                                            onPress={() => {
                                                nav.navigate('PropertyInsight', {
                                                    propertyId: item.uuid
                                                });
                                                closeModel();
                                            }} />
                                    )}
                                    numColumns={2}
                                    columnWrapperStyle={{justifyContent:'space-between'}}
                                    onEndReachedThreshold={0.01}
                                />
                            )
                        }
                    </View>
                </ScrollView>
            </Animated.View>
        </Animated.View>
    );
};

const styles = StyleSheet.create({
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.25)',
        position: 'absolute',
        width: '100%',
        height: '100%',
        justifyContent: 'flex-end',
    },
    modelContainer: {
        backgroundColor: Colors.white,
        paddingHorizontal: 25,
        paddingTop: 65,
        paddingBottom: 25,
        height: Layout.window.height * 0.8
    },
    close: {
        position: 'absolute',
        right: 25,
        top: 16,
        zIndex: 2
    },
});

export default AllInsightsModel;

