import { FontAwesome5 } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { StyleSheet, Animated } from 'react-native';

export type TPercentageBar = {
    height : number,
    icon : string
}

const BarChartBar: React.FC<TPercentageBar> = (props) => {
    const [jumpValue, setJumpValue] = React.useState(new Animated.Value(0));

    const toValue = props.height;

    const toggleSlide = () =>  {
        Animated.spring(
            jumpValue,
            {
                toValue: toValue,
                friction: 10,
                useNativeDriver: true
            }
        ).start();
    };

    toggleSlide();

    return (
        <Animated.View style={{width:'100%',height:jumpValue}}>
            <LinearGradient colors={['#d9d9d9b3', '#d9d9d91a']} style={[styles.bar, {width:'100%',height:'100%'}]}>
                <FontAwesome5 name={props.icon} size={21} style={styles.barLabel}/>
            </LinearGradient>
        </Animated.View>
    );
};

const styles = StyleSheet.create({
    bar: {
        flex: 1,
        alignItems: 'center'
    },
    barLabel: {
        position: 'absolute',
        bottom: -40,
        color: '#666666'
    },
});

export default BarChartBar;

