import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Colors from '../../constants/colors';
import Layout from '../../constants/Layout';

export type TStepBarCounter = {
step : number,
totalSteps : number,
}

export const StepBarCounter : React.FC< TStepBarCounter > = (props) => {
    const bars = [];
    for (let i = 1; i <= props.totalSteps; i++) {
        bars.push(i);
    }

    return (
        <View style={[Layout.row]}>
            {
                bars.map((bar, index) => (
                    <View key={bar}
                        style={
                            [
                                styles.bar,
                                bar !== props.totalSteps && { marginRight: 4 },
                                bar <= props.step && styles.active
                            ]}>
                    </View>
                ))
            }
        </View>
    );
};

const styles = StyleSheet.create({
    bar: {
        flex: 1,
        height: 4,
        backgroundColor: Colors.grey6
    },
    active: {
        backgroundColor: Colors.beige
    }
});
