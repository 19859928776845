import React, {Dispatch, SetStateAction, useState, useEffect} from 'react';
import { StyleSheet, View, Text, TextInput, Pressable} from 'react-native';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import { AntDesign } from '@expo/vector-icons';

export type TTagsInput = {
    value : string[]
    setValue : Dispatch<SetStateAction<string[]>>
}

export const TagsInput = (props:TTagsInput) => {
    const [tag, setTag] = useState('');  
    const [tagArry, setTagArry] = useState(props.value); 
    const [numOfTags, setNumOfTags] = useState(props.value.length);

    const addTag = (keycode:string) => {
        if(keycode === 'Enter'){
            tagArry.push(tag);
            props.setValue(tagArry);
            setTagArry(tagArry);
            setTag('');
            setNumOfTags(numOfTags+1);
        }
    };

    const removeTag = (tag:string) => {
        const index = tagArry.indexOf(tag);
        index > -1 && tagArry.splice(index, 1);
        props.setValue(tagArry);
        setTagArry(tagArry);
        setNumOfTags(numOfTags-1);
    };

    useEffect(() => {
        setTagArry(props.value);
    }, [props.value]);

    return (
        <View style={styles.outContainer}>
            <View style={{flexDirection:'row',flexWrap:'wrap'}}>
                {
                    tagArry.length > 0 &&
                    tagArry.map((item:string,index:number)=>{
                        return (
                            <View key={index} style={styles.tag}>
                                <Text style={[FontProperty.TextBeige, FontProperty.smallP, FontProperty.TextSemiBold, {marginRight:8}]}>{item}</Text>
                                <Pressable onPress={()=>removeTag(item)}><AntDesign name='closecircle' size={12} color={Colors.darkBeige}/></Pressable>
                            </View>
                        );
                    })
                }
            </View>
            {/* <TouchableOpacity style={styles.addBtn}><Text style={[FontProperty.TextTeal, FontProperty.smallP, FontProperty.TextSemiBold]}>+ Add tag</Text></TouchableOpacity> */}
            <TextInput
                autoCapitalize='none'
                style={styles.input}
                placeholder='Type in tag in here, click enter to save it'
                placeholderTextColor={Colors.grey3}
                onChangeText={setTag}
                value={tag}
                onKeyPress={(e)=>{addTag(e.key);}}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    outContainer: {
        borderWidth: 1,
        borderColor: Colors.grey5,
        marginBottom: 15,
        borderRadius: 4,
        backgroundColor: Colors.white,
        padding: 11
    },
    addBtn: {
        paddingHorizontal: 12,
        paddingVertical: 8,
        borderColor: Colors.teal,
        borderWidth: 1,
        borderRadius: 4,
        width: 'fit-content'
    },
    input: {
        paddingVertical: 10,
        borderBottomWidth: 1,
        borderBottomColor: Colors.grey5,
        outlineStyle: 'none',
    },
    tag: {
        backgroundColor: Colors.lightBeige,
        paddingHorizontal: 12,
        paddingVertical: 8,
        borderRadius: 4,
        marginRight: 8,
        marginBottom: 8,
        flexDirection: 'row',
        alignItems: 'center'
    }
});

export default TagsInput;

