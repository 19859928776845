import React, {useState} from 'react';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import Layout from '../../constants/Layout';
import FontProperty from '../../constants/FontProperty';
import Colors from '../../constants/colors';
import { Ionicons } from '@expo/vector-icons';
import { TealButton } from '../Button';
import { HouspoMessageInput } from './HouspoTextInput';
import { reportPost } from '../../lib/api/Content/posts/posts';

export type TPopUpReportModal = {
    open: boolean
    postId: string
    setShowReportPopUp : (arg0: boolean) => void
    setTargetPost: (arg0: string) => void
}
const PopUpReportModal = (props:TPopUpReportModal) => {
    const [description, setDescription] = useState<string>('');
  
    const reportThisPost = async ()=> {
        description !== '' &&
        await reportPost(props.postId, description);
        props.setShowReportPopUp(false);
    };
    return (
        <View style={styles.popUpContainer}>
            <View style={styles.popUpInnerContainer}>
                <TouchableOpacity onPress={()=>props.setShowReportPopUp(false)}>
                    <Ionicons name='close' size={20} style={styles.MenuClose_big} color={Colors.black}/>
                </TouchableOpacity>
                <View>
                    <Text style={[FontProperty.h1, {marginBottom:8}]}>Why are you reporting this post?</Text>
                    <Text style={[FontProperty.TextGrey, FontProperty.smallP, {marginBottom:16}]}>Your report is anonymous</Text>
                    <HouspoMessageInput 
                        value={description}
                        placeholder="Please type in the reason..."
                        setText={setDescription}
                    />
                </View>
                <View>
                    <TealButton onPress={()=>reportThisPost()} isDisabled ={false} ><Text style={[FontProperty.TextWhite, FontProperty.TextSemiBold, FontProperty.p2]}>Submit report</Text></TealButton>
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    popUpContainer: {
        position:'absolute',
        zIndex: 3,
        width: '100%',
        height: Layout.window.height,
        backgroundColor: '#000000cc',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 24,
    },
    popUpInnerContainer: {
        width: Layout.window.width > 425 ? 360 : '100%',
        backgroundColor: Colors.white,
        borderRadius: 16,
        padding: 32
    },
    MenuClose_big: {
        width: 21,
        height: 21,
        marginBottom: 16,
        alignSelf: 'flex-end'
    },
});

export default PopUpReportModal;
