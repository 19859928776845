import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View, Image, Pressable } from 'react-native';
import IMAGE from '../../assets/images';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import { getUserData } from '../../lib/api/user';
import { IuserData } from '../../lib/api/user/types';
import { useNavigation } from '@react-navigation/native';

export type THomeOwnerHeadshot = {
    userId : string
    onClickFunction ? : () => void
    subtitle ? : string
    rightText ? : string
}

export const ProfileHeadshotById : React.FC< THomeOwnerHeadshot > = (props) => {
    const [userData, setUserData] = useState<IuserData>();
    const nav = useNavigation();
    useEffect(() => {
        getUserData(props.userId).then(ref=>{
            if(ref.data()) {
                setUserData(ref.data());
            }
        });
    }, [props.userId]);

    if(!userData){
        return null;
    }

    // console.log(userData);

    return (
        <Pressable style={[Layout.row, {alignItems: (props.subtitle && props.subtitle !== '') ? 'flex-start' : 'center' }]} onPress={() => props.onClickFunction ? props.onClickFunction() : console.log()}>
            <View style={styles.headshotContainer}>
                <Image source={userData.headshot ? userData.headshot : IMAGE.userProfile} style={[styles.headshot]} />
            </View>
            <View style={{flex:1}}>
                <View style={[Layout.row, {justifyContent:'space-between',alignItems:'center'}]}>
                    <Text style={[FontProperty.h2, FontProperty.TextBlack]}>{userData.username}</Text>
                    {
                        props.rightText && props.rightText !== '' &&
                        <Text style={[FontProperty.smallLabel, FontProperty.TextGrey]}>{props.rightText}</Text>
                    }
                </View>
                {
                    props.subtitle && props.subtitle !== '' &&
                    <Text style={[FontProperty.smallP, FontProperty.TextGrey, {marginTop:8}]}>{props.subtitle}</Text>
                }
            </View>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    headshotContainer : {
        width: 45,
        height: 45,
        borderRadius: 45,
        overflow: 'hidden',
        marginRight: 13,
    },
    headshot : {
        width: 45,
        height: 45,
        resizeMode: 'cover',
        borderRadius: 45
    }
});

export default ProfileHeadshotById;
