import { Image, Pressable, StyleSheet, Text, View } from 'react-native';
import React, {useEffect, useState} from 'react';
import ScreenHeader from '../../components/atom/ScreenHeader';
import ScreenBase from '../../components/ScreenBase';
import Colors from '../../constants/colors';
import NewsfeedBlock from '../../components/atom/newsfeedBlock/NewsfeedBlock';
import NewsfeedPromptBlock from '../../components/atom/NewsfeedPromptBlock';
import { ScrollView } from 'react-native-gesture-handler';
import { useNavigation } from '@react-navigation/native';
import { getMyFollowing, getFollowerPosts, deletePost } from '../../lib/api/Content/posts/posts';
import { Ipost } from '../../lib/api/Content/posts/types';
import { useAuthentication } from '../../hooks/useAuthentication';
import PopUpModal from '../../components/atom/PopUpModal';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { TealButton } from '../../components/Button';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import IMAGE from '../../assets/images';
import PostAPost from '../../components/atom/PostAPost';
import ShareModal from '../../components/atom/ShareModal';
import AllPostsLikeComments from '../../components/atom/AllPostsLikeComments';
import PopUpReportModal from '../../components/atom/PopUpReportModal';
import SuggestedForYou from '../../components/atom/SuggestedForYou';
import NoResult from '../../components/atom/NoResult';
import ContentText from '../../constants/Content';
import PopupNotifyUser from '../../components/atom/PopUpNotifyUser';
import PopUpEditPost from '../../components/atom/PopUpEditPost';
import Footer from '../../components/molecule/Footer';

const Newsfeed = () => {
    const nav = useNavigation();
    const [newsfeed, setNewsfeed] = useState<Ipost[]>([]);
    const [followingIds, setFollowingIds] = useState<string[]>([]);
    const { user } = useAuthentication();
    const [showLikes, setShowLikes] = useState(false);
    const [showComments, setShowComments] = useState(false);
    const [targetPost, setTargetPost] = useState('');
    const [showShare, setShowShare] = useState(false);
    const [shareLink, setShareLink] = useState('');
    const [showReportPopUp, setShowReportPopUp] = useState(false);
    const [showDeletePopUp, setShowDeletePopUp] = useState(false);
    const [showEditPopUp, setShowEditPopUp] = useState(false);
    const [trigger, setTrigger] = useState<boolean>(false);

    useEffect(() => {
        if (user) {
            getMyFollowing(user.uid).then(ref=>{
                const tempArray = [];
                ref && ref.length > 0 &&
                ref.forEach(element => {
                    tempArray.push(element.uuid.split('_')[0]);
                });
                tempArray.push(user.uid);
                setFollowingIds(tempArray);
            });
        }
    },[user, trigger]);

    useEffect(() => {
        if (user) {
            followingIds.length > 0 &&
            getFollowerPosts(followingIds).then(response => {
                response !== undefined && response.length > 0 && response.sort((a,b) => b.createdAt - a.createdAt);
                setNewsfeed(response);
            });
        }
    },[followingIds]);

    const deleteMyPost = async (postId:string)=> {
        await deletePost(postId);
        setShowDeletePopUp(false);
        setTrigger(!trigger);
    };

    return (
        <ScreenBase>
            <ScreenHeader/>
            <ScrollView showsVerticalScrollIndicator={false}>
                {
                    user !== false && user && 
                    <>
                        <PostAPost uid={user.uid} setTrigger={setTrigger} trigger={trigger} />
                    </>
                }
                <View style={[styles.bar]}></View>  
                { user !== false && newsfeed && 
                    <NewsfeedBlock
                        data={newsfeed[0]}
                        userId={user?.uid}
                        setShowLikes={setShowLikes}
                        setShowComments={setShowComments}
                        setTargetPost={setTargetPost}
                        setShowShare={setShowShare}
                        setShareLink={setShareLink}
                        setShowDeletePopUp={setShowDeletePopUp}
                        setShowEditPopUp={setShowEditPopUp}
                        setShowReportPopUp={setShowReportPopUp}
                    />
                }
                <NewsfeedPromptBlock
                    title='List Your Property'
                    subtitle='You can list your property for free'
                    buttonLabel='Get Started'
                    onPress={() => { nav.navigate('Start'); }}
                />
                {
                    user !== false &&
                    newsfeed.slice(1, 3).map(post => (
                        <View key={post.uuid}>
                            <NewsfeedBlock
                                data={post}
                                userId={user?.uid}
                                setShowLikes={setShowLikes}
                                setShowComments={setShowComments}
                                setTargetPost={setTargetPost}
                                setShowShare={setShowShare}
                                setShareLink={setShareLink}
                                setShowDeletePopUp={setShowDeletePopUp}
                                setShowEditPopUp={setShowEditPopUp}
                                setShowReportPopUp={setShowReportPopUp}
                            />
                            <View style={[styles.bar]}></View>
                        </View>
                    ))
                }
                {
                    user !== false && (newsfeed === undefined || newsfeed.length === 0) &&
                    <>
                        <NoResult title='Follow other users to view their posts' />
                        <View style={[styles.bar]}></View>
                    </>
                    
                }
                {
                    user !== false && user?.uid &&
                    <>
                        <SuggestedForYou userId={user.uid} />
                        <View style={[styles.bar]}></View>
                    </>
                }
                
                {
                    user !== false &&
                    newsfeed.slice(3, newsfeed.length).map(post => (
                        <View key={post.uuid}>
                            <NewsfeedBlock
                                data={post}
                                userId={user?.uid}
                                setShowLikes={setShowLikes}
                                setShowComments={setShowComments}
                                setTargetPost={setTargetPost}
                                setShowShare={setShowShare}
                                setShareLink={setShareLink}
                                setShowDeletePopUp={setShowDeletePopUp}
                                setShowEditPopUp={setShowEditPopUp}
                                setShowReportPopUp={setShowReportPopUp}
                            />
                            <View style={[styles.bar]}></View>
                        </View>
                    ))
                }
                <Footer />
            </ScrollView>
            {
                showShare &&
                <ShareModal setShareModal={()=>setShowShare(false)} url={shareLink} mailSubject='Check out this post on abodely.app' title='post'/>
            }
            {
                showDeletePopUp &&
                    <PopupNotifyUser 
                        title='Delete this post?'
                        subtitle={ContentText.deleteThisPost}
                        yesBtnLabel='Yes'
                        noBtnLable='No'
                        yesBtnOnClick={()=>deleteMyPost(targetPost)}
                        noBtnOnClick={()=>setShowDeletePopUp(false)}
                    />
            }
            { showEditPopUp &&
                    <PopUpEditPost 
                        userId={user?.uid}
                        postId={targetPost}
                        setShowEditPopUp={setShowEditPopUp}
                        trigger={trigger}
                        setTrigger={setTrigger}
                    />
            }
            {
                (showLikes || showComments) &&
                <AllPostsLikeComments 
                    open={showLikes || showComments}
                    showLikes={showLikes} 
                    showComments={showComments} 
                    targetPost={targetPost}  
                    setShowLikes={setShowLikes}
                    setShowComments={setShowComments}
                    setTargetPost={setTargetPost}
                />
            }
            {user == false &&
                <PopUpModal show={true}>
                    <Pressable onPress={() => nav.navigate('Platform', { screen: 'Home' })} style={{marginBottom: 20}}>
                        <MaterialCommunityIcons name='arrow-left' size={20} />
                    </Pressable>
                    <View style={{alignItems: 'center'}}>
                        <Text style={[FontProperty.h1, FontProperty.TextBlack, Layout.mbottom8]}>Newsfeed is locked <MaterialCommunityIcons name='lock' size={20} /></Text>
                        <Text style={[FontProperty.smallP, Layout.mbottom25, {textAlign: 'center'}]}>{ContentText.encourageSignUpFollow}</Text>
                        <Image source={IMAGE.signUpPlaceholder} style={{width:250,height:230,resizeMode:'contain',marginBottom:40}} />
                        <TealButton onPress={() => nav.navigate('Platform', { screen: 'Authentication' })} >
                            <Text style={[FontProperty.button_1, FontProperty.TextWhite]}>Sign In / Register</Text>
                        </TealButton>
                        <Text style={[FontProperty.smallLabel, {textAlign: 'center'}]}>Don't want to sign in? 
                            <Pressable onPress={() => nav.navigate('Platform', { screen: 'Explore' })}>
                                <Text style={FontProperty.TextTeal}> Explore properties</Text>
                            </Pressable>
                        </Text>
                    </View>
                </PopUpModal>
            } 
            {
                showReportPopUp && 
                <PopUpReportModal 
                    open={showReportPopUp}
                    setShowReportPopUp={setShowReportPopUp} 
                    postId={targetPost} 
                    setTargetPost={setTargetPost}
                />
            }
        </ScreenBase>
    );
};

export default Newsfeed;

const styles = StyleSheet.create({
    bar: {
        backgroundColor: Colors.beige,
        height: 8,
        width: '100%'
    }
});
