import React, { useState, useEffect, useRef } from 'react';
import { Text, StyleSheet, ImageBackground, ScrollView, View } from 'react-native';
import ScreenBase from '../../components/ScreenBase';
import ScreenHeader from '../../components/atom/ScreenHeader';
import {getContactContent } from '../../lib/api/Content/content';
import { IContactContent} from '../../lib/api/Content/types';
import FontProperty from '../../constants/FontProperty';
import Footer from '../../components/molecule/Footer';
import { useFocusEffect } from '@react-navigation/native';
import ProfileContact from '../../components/profileScreen/ProfileContact';

export const ContactUsScreen = ({route}:any) => {
    const ref = useRef();
    const [contactContent, setContactContent] = useState<IContactContent[]>();

    useEffect(() => {
        getContactContent().then(res => {
            setContactContent(res);
        });
    }, []);

    useFocusEffect(()=>{
        ref.current?.scrollTo({
            y: 0,
            animated: true,
        });
    });

    if(contactContent === undefined){
        return null;
    }
    

    return (
        <ScreenBase>
            <ScreenHeader/>
            <ScrollView showsVerticalScrollIndicator={false} ref={ref}>
                <ImageBackground source={contactContent[0].bannerBgImage} resizeMode="cover" style={styles.image}>
                    <Text style={[styles.title, FontProperty.TextSemiBold]}>{contactContent[0].pageTitle}</Text>
                </ImageBackground>
                <View style={{paddingVertical: 40}}>
                    <View style={{paddingHorizontal: 24,alignItems: 'center'}}>
                        <Text style={[FontProperty.h1, FontProperty.TextBold, FontProperty.TextBlack, {marginBottom:15}]}>{contactContent[0].formTitle}</Text>
                        <Text style={[FontProperty.smallP, FontProperty.TextBlack, {marginBottom:30}]}>{contactContent[0].formSubtitle}</Text>
                    </View>
                    <ProfileContact />
                </View>
                
                <Footer />
            </ScrollView>
        </ScreenBase>
    );
};

const styles = StyleSheet.create({
    image: {
        paddingVertical: 50,
        paddingHorizontal: 24,
        zIndex: 1,
    },
    title: {
        fontSize: 30,
        lineHeight: 36,
        fontWeight: '600',
    },
});
