import React from 'react';
import { IprojectData, IpropertyData } from '../lib/api/Content/types';
import { getPropertyData, updatePropertyData } from '../lib/api/Content/property';
import { updateProjectData } from '../lib/api/Content/project';

export function useProperty (propertyId : string) {
    const [property, setProperty] = React.useState<IpropertyData>();

    React.useEffect(() => {
        getPropertyData(propertyId)
            .then(propertyDataDoc => {
                if (propertyDataDoc.exists()) {
                    const properteyObj = {
                        uuid: propertyDataDoc.id,
                        ...propertyDataDoc.data() as IpropertyData
                    };
                    setProperty(properteyObj);
                }
            });
    }, []);

    const updateProperty = async (updatedProperty : IpropertyData) => {
        console.log("updateProperty()")
        console.log(updatedProperty);
        // if updated property and property are not the  same?
        // if (JSON.stringify(updatedProperty) !== JSON.stringify(property)) {
        await updatePropertyData(property.uuid, updatedProperty);
        // }
    };

    return {
        property,
        updateProperty
    };
}


export function useProject (projectId : string) {
    const [project, setProject] = React.useState<IprojectData>();

    React.useEffect(() => {
        getPropertyData(projectId)
            .then(projectDataDoc => {
                if (projectDataDoc.exists()) {
                    const projectObj = {
                        uuid: projectDataDoc.id,
                        ...projectDataDoc.data() as IprojectData
                    };
                    setProject(projectObj);
                }
            });
    }, []);

    const updateProject = async (updatedProject : IprojectData) => {
        await updateProjectData(project.uuid, updatedProject);
    };

    return {
        project,
        updateProject
    };
}