import React from 'react';
import { StyleSheet, TouchableOpacity, StyleProp, TextStyle } from 'react-native';
import Colors from '../constants/colors.js';
import Layout from '../constants/Layout.js';

type TbaseButtonParams = {
    isDisabled ? : boolean,
    onPress ? : ()=>void,
    btnStyle ? : StyleProp<TextStyle>[]
}

// const ButtonBase : React.FC<TbaseButtonParams> = () => {
//   const ButtonBase = (props) => {
//   return (
//         <TouchableOpacity>

//         </TouchableOpacity>
//   )
// }

const ButtonBase : React.FC<TbaseButtonParams> = (props) => {
    return (
        <TouchableOpacity
            onPress={props.onPress}
            accessibilityRole="button"
            style={[styles.base, [...props.btnStyle], styles.largeScreen]}
            disabled={props.isDisabled}
        >
            {props.children}
        </TouchableOpacity>
    );
};

const TealButton : React.FC<TbaseButtonParams> = (props) => {
    return (
        <ButtonBase onPress={props.onPress} isDisabled={props.isDisabled} btnStyle={[props.isDisabled ? styles.tealBtndisabled : styles.tealBtn, props.btnStyle]}>
            {props.children}
        </ButtonBase>
    );
};

const NavyButton : React.FC<TbaseButtonParams> = (props) => {
    return (
        <ButtonBase onPress={props.onPress} isDisabled={props.isDisabled} btnStyle={[props.isDisabled ? styles.navyBtndisabled : styles.navyBtn, props.btnStyle]}>
            {props.children}
        </ButtonBase>
    );
};

const NavyOutlineButton : React.FC<TbaseButtonParams> = (props) => {
    return (
        <ButtonBase onPress={props.onPress} btnStyle={[styles.navyBorderBtn, props.btnStyle]}>
            {props.children}
        </ButtonBase>
    );
};

const TealOutlineButton : React.FC<TbaseButtonParams> = (props) => {
    return (
        <ButtonBase onPress={props.onPress} btnStyle={[styles.tealBorderBtn, props.btnStyle]}>
            {props.children}
        </ButtonBase>
    );
};

const RedOutlineButton : React.FC<TbaseButtonParams> = (props) => {
    return (
        <ButtonBase onPress={props.onPress} btnStyle={[styles.redBorderBtn, props.btnStyle]}>
            {props.children}
        </ButtonBase>
    );
};

const GreyButton : React.FC<TbaseButtonParams> = (props) => {
    return (
        <ButtonBase onPress={props.onPress} isDisabled={props.isDisabled} btnStyle={[props.isDisabled ? styles.greyBtndisabled : styles.greyBtn, props.btnStyle]}>
            {props.children}
        </ButtonBase>
    );
};

const TealTextButton : React.FC<TbaseButtonParams> = (props) => {
    return (
        <ButtonBase onPress={props.onPress} btnStyle={styles.tealTextBtn}>
            {props.children}
        </ButtonBase>
    );
};

export {
    ButtonBase,
    TealButton,
    NavyButton,
    GreyButton,
    TealTextButton,
    NavyOutlineButton,
    TealOutlineButton,
    RedOutlineButton
};

const styles = StyleSheet.create({
    base: {
    // width: '100%',
        marginBottom: 10,
        borderWidth: 1,
        borderRadius: 8,
        paddingVertical: 12,
        alignItems: 'center',
        justifyContent: 'center'
    },

    largeScreen: {
        paddingHorizontal: 20
        // marginHorizontal: Layout.window.width > 767 ? 'auto' : 0,

    },

    // Button color
    tealBtn: {
        borderColor: Colors.teal,
        backgroundColor: Colors.teal
    },

    tealBtndisabled: {
        borderColor: Colors.lightTeal,
        backgroundColor: Colors.lightTeal
    },

    tealBorderBtn: {
        borderColor: Colors.teal
    },
    redBorderBtn: {
        borderColor: Colors.red,
        backgroundColor: Colors.white
    },

    navyBtn: {
        borderColor: Colors.navy,
        backgroundColor: Colors.navy
    },

    navyBtndisabled: {
        borderColor: Colors.lightNavy,
        backgroundColor: Colors.lightNavy
    },

    navyBorderBtn: {
        borderColor: Colors.navy,
        borderWidth: 2,
        backgroundColor: '#fff'
    },

    greyBtn: {
        borderColor: Colors.grey6,
        backgroundColor: Colors.grey6,
        width: '100%'
    },

    greyBorderBtn: {
        borderColor: Colors.grey4
    },

    tealTextBtn: {
        justifyContent: 'center',
        alignItems: 'center'
    },

    navyTextBtn: {
        justifyContent: 'center',
        alignItems: 'center'
    },

    greyTextBtn: {
        justifyContent: 'center',
        alignItems: 'center'
    }

});
