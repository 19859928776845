import { collection, doc, DocumentData, DocumentSnapshot, getDoc, getDocs, deleteDoc, limit, orderBy, query, updateDoc, where, setDoc, arrayUnion } from 'firebase/firestore';
import { date } from 'yup';
import { db } from '../../../config/firebase';
import constants from './constants';
import { IpropertyData, IpropertyFeature, IpropertyLabel, IpropertyType, IpropertyStyle } from './types';
import { isEmpty } from 'lodash';
import { apiCall } from '../abodely';

/* CACHES */
const propertyContentCacheMap: Map<string, Promise<Object>> = new Map();
const propertiesListCacheMap: Map<string, Promise<Object>> = new Map();
let propertiesLoading = false;

/* STATIC */
const defaultPropertiesIndex = 'allProperties'

export const getOnboardingContent = async (): Promise<any[]> => {
    try {
        const q = query(collection(db, constants.onboardingQuestions));
        const querySnapshot = await getDocs(q);
        return querySnapshot?.docs.map(
            doc => {
                return {
                    ...doc.data()
                };
            }) as any[];
    } catch (e) {
        throw Error(e.message);
    }
};

export const getPropertyTypes = async (): Promise<IpropertyType[]> => {
    try {
        const q = query(collection(db, constants.propertyTypes), orderBy('weight', 'asc'));
        const querySnapshot = await getDocs(q);
        return querySnapshot?.docs.map(
            doc => {
                return {
                    ...doc.data(),
                    uuid: doc.id
                };
            }) as IpropertyType[];
    } catch (e) {
        throw Error(e.message);
    }
};

export const getPropertyStyles = async (): Promise<IpropertyStyle[]> => {
    try {
        return false; 
        const q = query(collection(db, constants.propertyStyles), orderBy('weight', 'asc'));
        const querySnapshot = await getDocs(q);
        return querySnapshot?.docs.map(
            doc => {
                return {
                    ...doc.data()
                };
            }) as IpropertyStyle[];
    } catch (e) {
        throw Error(e.message);
    }
};

export const getPropertyTypeName = async (styleID: string) => {
    try {
        const q = query(collection(db, constants.propertyTypes), where('uuid', '==', styleID));
        const querySnapshot = await getDocs(q);
        return querySnapshot?.docs.map(
            doc => {
                return {
                    ...doc.data()
                };
            });
    } catch (e) {
        throw Error(e.message);
    }
};

export const getPropertyFavourites = async (): Promise<IpropertyFeature[]> => {
    try {
        const q = query(collection(db, constants.propertyFeatures));
        const querySnapshot = await getDocs(q);
        return querySnapshot?.docs.map(
            doc => {
                return {
                    ...doc.data(),
                    uuid: doc.id
                };
            }) as IpropertyFeature[];
    } catch (e) {
        throw Error(e.message);
    }
};

export const getPropertyStates = async (): Promise<IpropertyLabel[]> => {
    try {
        const q = query(collection(db, constants.propertyStates));
        const querySnapshot = await getDocs(q);
        // Sort by weight
        return querySnapshot?.docs.sort((a, b) => {
            return b.data().weight - a.data().weight;
        }).map(
            doc => {
                return {
                    ...doc.data()
                };
            }) as IpropertyLabel[];
    } catch (e) {
        throw Error(e.message);
    }
};

export const getPropertyData = async (propertyId : string): Promise<DocumentSnapshot<DocumentData>> => {
    const propertyDataRef = doc(db, constants.properties, propertyId);
    return getDoc(propertyDataRef);
};

export const updatePropertyData = async (propertyId : string, property : IpropertyData) => {
    try {
        const propertyDataRef = doc(db, constants.properties, propertyId);
        const propertyDataDoc = await updateDoc(propertyDataRef, property);
        console.log('UPDATE : ', propertyDataDoc);
    } catch (e) {
        throw Error(e.message);
    }
};

export const getUsersProperties = async (userId : string) : Promise<IpropertyData[]> => {
    try {
        // Query for property images that reference the propertyId
        const propertyDataQuesry = query(collection(db, constants.properties), where('userId', '==', userId), where('published', '==', true), orderBy('createdAt', 'desc'));

        // Get propertyImage refs from firebase db
        const propertyData = await getDocs(propertyDataQuesry);
        return propertyData.docs.map((doc) => {
            return {
                ...doc.data(),
                uuid: doc.id
            } as IpropertyData;
        });
    } catch (error) {
        console.log('getPropertyImages', error);
        throw new Error(error);
    }
};

export const getUsersPublishedProperties = async (userId : string) : Promise<IpropertyData[]> => {
    try {
        // Query for property images that reference the propertyId
        const queryContrains = [where('userId', '==', userId), where('published', '==', true)];
        const propertyDataQuery = query(collection(db, constants.properties), ...queryContrains);

        // Get propertyImage refs from firebase db
        const propertyData = await getDocs(propertyDataQuery);
        return propertyData.docs.map((doc) => {
            return {
                ...doc.data(),
                uu_id: doc.id
            } as IpropertyData;
        });
    } catch (error) {
        console.log('getPropertyImages', error);
        throw new Error(error);
    }
};

function findCommonElementsByUUID(arr1:any[], arr2:any[]) {
    return arr1.some(item => arr2.includes(item.uuid));    
}
function findCommonElements(arr1:any[], arr2:any[]) {
    return arr1.every(item => arr2.find(element => element.includes(item.replace(',', ''))));   
}
function stringInArray(arr:any[], string:string) {
    return arr.some(item => item.name === string);    
}

function hasCommonElements(arr1:any[], arr2:any[]) {
    return arr1.some(item => arr2.includes(item))
}

export const propertiesList = async(postcode: string): Promise<Object> => {
    if (postcode=='')
        postcode = defaultPropertiesIndex

    if (!propertiesListCacheMap.has(postcode)) {
        propertiesListCacheMap.set(postcode, getProperties(postcode));
    }
    return propertiesListCacheMap.get(postcode);

};

export async function getProperties(postcode: string):Promise<Object> {
    //we want the postcode to be blank to return properties regardless of postcode
    if (postcode==defaultPropertiesIndex)
        postcode = ''

    const properties = apiCall('home/list', {
        search:postcode,
        search_fields:'post_code'
    });
    return properties;
}

export const filterAllProperties = async (filterObj:any[]) => {
    // 0 Keywords, 1 Type, 2 Style, 3 Status, 4 Price Min, 5 Price Max, 6 Land Size, 7 Address
    try {
        let propertiesSearchPostcode = (filterObj && filterObj[7]) ? filterObj[7].match(/\d+/)[0] : ''
        const finalArray = [];
        await propertiesList(propertiesSearchPostcode).then((result) => {
            result.forEach(element => {
                    
                let showThisProperty = true
               
                const targetProperty = element
    
                // 1. Keyword
                if(showThisProperty && filterObj[0] !== '') {
                    showThisProperty = false;
                    targetProperty.title && targetProperty.title.toLowerCase().includes(filterObj[0].toLowerCase()) && (showThisProperty = true);
    
    
                    if(showThisProperty === false && targetProperty.tags && targetProperty.tags.length > 0){
                        const propertyTags = targetProperty.tags.join(' ').split(' ').map(name => name.toLowerCase());
                        const filterTag = filterObj[1].toLowerCase();
                        (propertyTags.includes(filterTag)) && (showThisProperty = true);
                    } 
                }
                // 2. Type
                showThisProperty 
                    && filterObj[1] !== '' 
                    && targetProperty.type 
                    && targetProperty.type !== '' 
                    && !filterObj[1].includes(targetProperty.type) 
                    && (showThisProperty = false);
                // 3. Style
                if(showThisProperty && filterObj[2] !== '' && targetProperty.style && targetProperty.style !== ''){
                    const propertyStyle = targetProperty.style;
                    const filterStyle = filterObj[2].split('||').slice(0, -1);
                    (!findCommonElementsByUUID(propertyStyle, filterStyle)) && (showThisProperty = false);
                }
                // 4. Status
                showThisProperty 
                    && filterObj[3] !== '' 
                    && targetProperty.status 
                    && targetProperty.status !== '' 
                    && !filterObj[3].includes(targetProperty.status) 
                    && (showThisProperty = false);
    
                // 5. PriceMax
                if(showThisProperty 
                    && filterObj[4] !== '' 
                    && targetProperty.estimateLowPrice 
                    && targetProperty.estimateLowPrice !== ''
                ){
                    (parseInt(targetProperty.estimateLowPrice) < parseInt(filterObj[4]) || targetProperty.showEstimatePrice == false) 
                        && (showThisProperty = false);
                }
                // 6. PriceMax
                if(showThisProperty 
                    && filterObj[5] !== '' 
                    && targetProperty.estimateHighPrice 
                    && targetProperty.estimateHighPrice !== ''
                ){
                    (parseInt(targetProperty.estimateHighPrice) > parseInt(filterObj[5]) || targetProperty.showEstimatePrice == false) 
                    && (showThisProperty = false);
                }
                // 7. LandPrice
                if(showThisProperty && filterObj[6] !== '' && targetProperty.landSize && targetProperty.landSize !== '' ){
                    parseInt(targetProperty.landSize) < parseInt(filterObj[6]) && (showThisProperty = false);
                }
                // 8. Address
                // @TODO - break out the address from Abodely into postcode, city etc
                if(showThisProperty && filterObj[7] !== '' && targetProperty.post_code && targetProperty.post_code !== ''){
                    //const filteredPropertyAddress = filterObj[7].split(' ').map(s => s.toLowerCase().trim()).filter(Boolean);
                    //const targetPropertyAddress = targetProperty.city.split(', ').map(s => s.toLowerCase().trim())
                    //const filteredFilterAddress = [targetProperty.address[1].trim(), targetProperty.address[2].trim(), targetProperty.address[3].trim()];
                    //const filteredFilterAddressRe = filteredFilterAddress.join(' ').split(' ');
            
                    //(!findCommonElements(filteredPropertyAddress, targetProperty.city)) && (showThisProperty = false)
                    
                    //showThisProperty = hasCommonElements(filteredPropertyAddress, targetPropertyAddress)
                    showThisProperty = targetProperty.post_code == propertiesSearchPostcode;
                    /*
                    filteredPropertyAddress.length === 1 
                        ? (!findCommonElements(filteredPropertyAddress, targetPropertyAddress)) && (showThisProperty = false)
                        : (!findCommonElements(targetPropertyAddress, filteredPropertyAddress)) && (showThisProperty = false); */
                        
                }

                //don't show properties without images
                if (targetProperty.cover == null || (targetProperty.cover.media_url && targetProperty.cover.media_url == ''))
                    showThisProperty = false
    
                if(showThisProperty){
                    finalArray.push(targetProperty);
                } 
            });
        })

        return finalArray as IpropertyData[];
    } catch (e) {
        throw Error(e.message);
    }
};

/**
 * This will cache the getViewPropertyContent request and return it
 * @param propertyId 
 * @returns 
 */
export const getPropertyContent = async(propertyId: string) => {
    if (!propertyContentCacheMap.has(propertyId)) {
        let propertyContent = await getViewPropertyContent(propertyId);
        propertyContentCacheMap.set(propertyId, propertyContent);
    } 
    
    return propertyContentCacheMap.get(propertyId);
};

/**
 * Fetch the property content from the API
 * @param propertyID 
 * @returns 
 */
export const getViewPropertyContent = async (propertyID: string) => {
    try {
        let url = 'home/view/'+propertyID;
        let property = await apiCall(url,{},'get');
        return property as IpropertyData[];
    } catch (e) {
        throw Error(e.message);
    }
};

export const getSimiliarProperty = async (propertyType:string, uuid:string) => {
    try {
        const q = query(collection(db, constants.properties), 
            where('published', '==', true), 
            where('uuid', '!=', uuid),
            where('type', '==', propertyType),
            orderBy('uuid', 'desc'),
            orderBy('createdAt', 'desc'),
            limit(5)
        );
        const querySnapshot = await getDocs(q);
        return querySnapshot?.docs.map(
            doc => {
                return {
                    ...doc.data()
                };
            });
    } catch (e) {
        throw Error(e.message);
    }
};

export const getPropertyStatus = async (propertyStatus:string) => {
    try {
        const q = query(collection(db, constants.propertyStates), 
            where('uuid', '==', propertyStatus)
        );
        const querySnapshot = await getDocs(q);
        return querySnapshot?.docs.map(
            doc => {
                return {
                    ...doc.data()
                };
            });
    } catch (e) {
        throw Error(e.message);
    }
};

export const deleteProperty = async (propertyID: string) => {
    try {
        let url = 'home/delete/'+propertyID;
        let property = await apiCall(url,{},'post');

        const q = query(collection(db, 'followProperties'), 
            where('terms', 'array-contains',  propertyID + '_')
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.docs.forEach(element => {
            deleteDoc(doc(db, 'followProperties', element.id));
        });
        await deleteDoc(doc(db, constants.properties, propertyID));
    } catch (e) {
        throw Error(e.message);
    }
};

export const updatePropertyType= async (uuid : string, type: string) => {
    try {
        const userDataRef = doc(collection(db, 'properties'), uuid);
        await setDoc(userDataRef, 
            { type: type, uuid: uuid },
            { merge: true }
        );
    } catch (err) {
        throw new Error(err);
    }
};

export const updatePropertyState= async (uuid : string, status: string) => {
    try {
        const userDataRef = doc(collection(db, 'properties'), uuid);
        await setDoc(userDataRef, 
            { status: status },
            { merge: true }
        );
    } catch (err) {
        throw new Error(err);
    }
};

export const updatePropertyStyle= async (uuid : string, style: []) => {
    try {
        const userDataRef = doc(collection(db, 'properties'), uuid);
        await setDoc(userDataRef, 
            { style: style },
            { merge: true }
        );
    } catch (err) {
        throw new Error(err);
    }
};

export const updatePropertyAddress= async (uuid : string, address: string[], projects: string[]) => {
    try {
        const userDataRef = doc(collection(db, 'properties'), uuid);
        await setDoc(userDataRef, 
            { address: address },
            { merge: true }
        );

        if(projects !== undefined && projects.length > 0){
            const q = query(collection(db, 'projects'), 
                where('uuid', 'in',  projects)
            );
            const querySnapshot = await getDocs(q);
            querySnapshot.docs.forEach(element => {
                const projectsDoc = doc(collection(db, 'projects'), element.id);
                setDoc(projectsDoc, 
                    { address: address},
                    { merge: true }
                );
            });
        }
    } catch (err) {
        throw new Error(err);
    }
};

export const updatePropertyInfo= async (uuid : string, bedroom: number, bathroom: number, carpark: number, landSize: number) => {
    try {
        const userDataRef = doc(collection(db, 'properties'), uuid);
        await setDoc(userDataRef, 
            { bedroom: bedroom, bathroom: bathroom, carpark: carpark, landSize: landSize },
            { merge: true }
        );
    } catch (err) {
        throw new Error(err);
    }
};

export const updatePropertyDetails= async (uuid : string, name: string, desc: string, neighbourhoodDesc: string, tags: string[]) => {
    try {
        const userDataRef = doc(collection(db, 'properties'), uuid);
        await setDoc(userDataRef, 
            { propertyName: name, description: desc, neighbourhoodDescription: neighbourhoodDesc, tags: tags },
            { merge: true }
        );
    } catch (err) {
        throw new Error(err);
    }
};

export const updatePropertyPrice= async (uuid : string, low: number, high: number, showEstimatePrice: boolean) => {
    try {
        const userDataRef = doc(collection(db, 'properties'), uuid);
        await setDoc(userDataRef, 
            { estimateLowPrice: low, estimateHighPrice : high, showEstimatePrice: showEstimatePrice },
            { merge: true }
        );
    } catch (err) {
        throw new Error(err);
    }
};

export const updatePropertyPublish= async (uuid : string, publishStatus: boolean) => {
    try {
        const userDataRef = doc(collection(db, 'properties'), uuid);
        await setDoc(userDataRef, 
            { published: publishStatus },
            { merge: true }
        );
    } catch (err) {
        throw new Error(err);
    }
};

export const updatePropertyImagesTemp= async (uuid : string, images: string[]) => {
    try {
        const userDataRef = doc(collection(db, 'properties'), uuid);
        await setDoc(userDataRef, 
            { images: images },
            { merge: true }
        );
    } catch (err) {
        throw new Error(err);
    }
};

export const updatePropertyVisits= async (uid : string, propertyId: string) => {
    try {
        const visitDataRef = doc(collection(db, 'visitors'), `${propertyId}`);
        await setDoc(visitDataRef, 
            { visitors: arrayUnion({uid, createdAt: new Date(), createdAtTimestamp: Date.now()})},
            { merge: true }
        );
    } catch (err) {
        throw new Error(err);
    }
};
