import { addDoc, collection, doc, DocumentData, DocumentReference, getDoc, getDocs, limit, orderBy, query, QueryDocumentSnapshot, setDoc, where, deleteDoc, documentId, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from '../../../../config/firebase';
import { IContactContent } from '../types';
import { constants } from './constants';
import { createCommentsParams, IPropertyPost, Icomment, Ipost, Icontact } from './types';

export const createPost = async (userId:string, description:string, images:string[]) : Promise<DocumentReference<Ipost>> => {
    const post = {
        userId,
        description,
        images,
        type: 'post',
        createdAt: new Date(),
        likesCount: 0,
    } as Ipost;
    try {
        const postsRef = collection(db, constants.posts);
        return await addDoc(postsRef, post) as DocumentReference<Ipost>;
    } catch (e) {
        throw Error(e);
    }
};

export const createPropertyPost = async (userId:string, propertyId:string, description:string, propertyFImage:string, propertyTitle:string, propertyDesc:string) : Promise<DocumentReference<IPropertyPost>> => {
    const post = {
        userId,
        propertyId,
        description,
        type: 'propertyPost',
        propertyFImage,
        propertyTitle,
        propertyDesc,
        createdAt: new Date(),
        likesCount: 0,
    } as IPropertyPost;

    try {
        const postsRef = collection(db, constants.posts);
        return await addDoc(postsRef, post) as DocumentReference<IPropertyPost>;
    } catch (e) {
        throw Error(e);
    }
};

export const createProjectPost = async (userId:string, projectId:string, description:string, propertyFImage:string, propertyTitle:string, propertyDesc:string) : Promise<DocumentReference<IPropertyPost>> => {
    const post = {
        userId,
        projectId,
        description,
        type: 'projectPost',
        propertyFImage,
        propertyTitle,
        propertyDesc,
        createdAt: new Date(),
        likesCount: 0,
    } as IPropertyPost;

    try {
        const postsRef = collection(db, constants.posts);
        return await addDoc(postsRef, post) as DocumentReference<IPropertyPost>;
    } catch (e) {
        throw Error(e);
    }
};

export const deletePost = async (postId: string) => {
    try {
        await deleteDoc(doc(db, 'posts', postId));
    } catch (e) {
        throw Error(e.message);
    }
};

export const getPosts = async (userId :string) : Promise<Ipost[]> => {
    try {
        const getPostsQuery = query(collection(db, constants.posts), where('userId', '==', userId), orderBy('createdAt', 'desc'));
        const postsData = await getDocs(getPostsQuery);
        return postsData.docs.map(post => ({
            uuid: post.id,
            ...post.data() as Ipost
        }) as Ipost);
    } catch (error) {
        console.log('getPostsError', error);
        throw new Error(error);
    }
};

export const getThreeFavouritePosts = async () : Promise<Ipost[]> => {
    try {
        const getPostsQuery = query(collection(db, constants.posts), orderBy('likesCount', 'desc'), orderBy('createdAt', 'desc'), limit(3));
        const postsData = await getDocs(getPostsQuery);
        return postsData.docs.map(post => ({
            uuid: post.id,
            ...post.data() as Ipost
        }) as Ipost);
    } catch (error) {
        console.log('getThreeFavouritePostsError', error);
        throw new Error(error);
    }
};


export const getPostContent = async (postId :string) : Promise<any> => {
    try {
        const postDataRef = doc(collection(db, 'posts'), postId);
        const postData = await getDoc(postDataRef);
        return postData.data();
    } catch (error) {
        console.log('getPostsError', error);
        throw new Error(error);
    }
};

export const getPostContentWithId = async (postId :string) : Promise<any> => {
    try {
        const postDataRef = doc(collection(db, 'posts'), postId);
        const postData = await getDoc(postDataRef);
        return {uuid: postId, ...postData.data()};
    } catch (error) {
        console.log('getPostsError', error);
        throw new Error(error);
    }
};

export const updatePostContent = async (postId : string, description : string ) : Promise<void> => {
    try {
        const followRef = doc(db, constants.posts, postId);
        await setDoc(followRef,
            { description: description },
            { merge: true }
        );
    } catch (error) {
        console.log('updatePostDesc', error);
        throw new Error(error);
    }
};

export const updatePostImages = async (postId : string, images : string[] ) : Promise<void> => {
    try {
        const followRef = doc(db, constants.posts, postId);
        await setDoc(followRef,
            { images: images },
            { merge: true }
        );
    } catch (error) {
        console.log('updatePostImages', error);
        throw new Error(error);
    }
};

export const getPostLikes = async (postId : string) : Promise<QueryDocumentSnapshot<DocumentData>> => {
    try {
        const postDataRef = doc(collection(db, 'posts'), postId);
        const postData = await getDoc(postDataRef);
        return postData.data().likes;
    } catch (err) {
        throw new Error(err);
    }
};

export const getPostComments = async (postId : string) : Promise<any[]> => {
    try {
        const postCommentsRef = query(collection(doc(collection(db, 'posts'), postId), 'comments'), orderBy('createdAt', 'desc'));
        const postComments = await getDocs(postCommentsRef);
        return postComments.docs.map(comments => ({
            ...comments.data()
        }));
    } catch (err) {
        throw new Error(err);
    }
};

export const getFollowerPosts = async (userIds :string[]) : Promise<any[]> => {
    try {
        const getPostsQuery = query(collection(db, constants.posts), where('createdAt', '!=', null));
        const postsData = await getDocs(getPostsQuery);

        const finalList = [];

        postsData.docs.map(post=>
        {
            if(userIds.includes(post.data().userId)){
                finalList.push({uuid: post.id, ...post.data()});
            } 
        }
        );

        return finalList;
    } catch (error) {
        console.log('getPostsError', error);
        throw new Error(error);
    }
};

export const getLikeState = async (propertyId: string, userId: string) : Promise<boolean> => {
    try {
        const likeRef = doc(db, constants.likes, `${propertyId}_${userId}`);
        const likeData = await getDoc(likeRef);
        return likeData.exists() && likeData.data().state;
    } catch (error) {
        console.log('getLikeStateError', error);
        throw new Error(error);
    }
};

export const getFollowState = async (propertyId: string, userId: string) : Promise<boolean> => {
    try {
        const followRef = doc(db, constants.followProperties, `${propertyId}_${userId}`);
        const followData = await getDoc(followRef);
        return followData.exists() && followData.data().state;
    } catch (error) {
        console.log('getFollowStateError', error);
        throw new Error(error);
    }
};

export const setFollowProperty = async (propertyId : string, userId : string, followState : boolean) : Promise<void> => {
    try {
        const followRef = doc(db, constants.followProperties, `${propertyId}_${userId}`);
        if(followState){
            await setDoc(followRef,
                { state: followState, terms: [propertyId + '_' , '_' + userId], createdAt: new Date(), createdAtTimestamp: Date.now() },
                { merge: true }
            );
        }else{
            await deleteDoc(followRef);
        }
    } catch (error) {
        console.log('createFollow', error);
        throw new Error(error);
    }
};

export const createComment = async (params : createCommentsParams) : Promise<DocumentReference<Icomment>> => {
    const { user, comment, propertyId } = params;
    const commentData = {
        user,
        comment,
        propertyId,
        createdAt: new Date(),
        createdAtTimestamp: Date.now()
    } as Icomment;

    try {
        const commentsRef = collection(db, 'comments');
        return await addDoc(commentsRef, commentData) as DocumentReference<Icomment>;
    } catch (e) {
        console.log(e);
        throw Error(e);
    }
};

export const createProjectComment = async (params : createCommentsParams) : Promise<DocumentReference<Icomment>> => {
    const { user, comment, propertyId } = params;
    const commentData = {
        user,
        comment,
        propertyId,
        isProject : true,
        createdAt: new Date(),
        createdAtTimestamp: Date.now()
    } as Icomment;

    try {
        const commentsRef = collection(db, 'comments');
        return await addDoc(commentsRef, commentData) as DocumentReference<Icomment>;
    } catch (e) {
        console.log(e);
        throw Error(e);
    }
};

export const getComments = async (postId : string) : Promise<Icomment[]> => {
    try {
        const getCommentsQuery = query(collection(db, constants.comments), where('postId', '==', postId), orderBy('createdAt', 'desc'));
        const commentsData = await getDocs(getCommentsQuery);

        return commentsData.docs.map(comment => ({
            uuid: comment.id,
            ...comment.data() as Icomment
        }) as Icomment);
    } catch (error) {
        console.log('getCommentsError', error);
        throw new Error(error);
    }
};

export const getCommentsProperty = async (propertyId : string) : Promise<Icomment[]> => {
    try {
        const getCommentsQuery = query(collection(db, constants.comments), where('propertyId', '==', propertyId), orderBy('createdAt', 'desc'));
        const commentsData = await getDocs(getCommentsQuery);

        return commentsData.docs.map(comment => ({
            uuid: comment.id,
            ...comment.data() as Icomment
        }) as Icomment);
    } catch (error) {
        console.log('getCommentsError', error);
        throw new Error(error);
    }
};

export const setPollProperty = async (pid : string, userId : string, answers : string[]) : Promise<void> => {
    try {
        const pollRef = doc(db, constants.poll, `${pid}_${userId}`);
        await setDoc(
            pollRef,
            { answers: answers, finished: true, createdAt: new Date(), terms: [pid + '_' , '_' + userId], createdAtTimestamp: Date.now() },
            { merge: true }
        );
    } catch (error) {
        console.log('createPoll', error);
        throw new Error(error);
    }
};

export const getPollState = async (pid : string, userId : string) => {
    try {
        const pollRef = doc(db, constants.poll, `${pid}_${userId}`);
        const pollData = await getDoc(pollRef);
        return pollData.exists() && pollData.data().finished;
    } catch (error) {
        console.log('getPollStateError', error);
        throw new Error(error);
    }
};

export const getFollowUserState = async (followUserId: string, userId: string) : Promise<boolean> => {
    try {
        const followRef = doc(db, constants.followUsers, `${followUserId}_${userId}`);
        const followData = await getDoc(followRef);
        return followData.exists() && followData.data().state;
    } catch (error) {
        console.log('getFollowUserStateError', error);
        throw new Error(error);
    }
};

export const setFollowUser = async (followUserId : string, userId : string, followState : boolean) : Promise<void> => {
    try {
        const followRef = doc(db, constants.followUsers, `${followUserId}_${userId}`);
        if(followState){
            await setDoc(followRef,
                { state: followState, terms: [followUserId + '_' , '_' + userId], createdAt: new Date() },
                { merge: true }
            );
        }else{
            await deleteDoc(followRef);
        }
    } catch (error) {
        console.log('createFollow', error);
        throw new Error(error);
    }
};

export const getMyFollowing = async (userId: string) => {
    try {
        const followRef = query(collection(db, constants.followUsers), where('terms', 'array-contains',  '_' + userId), orderBy('createdAt', 'desc'));
        const followData = await getDocs(followRef);
        return followData.docs.map(followdt => ({
            uuid: followdt.id,
            ...followdt.data()
        }));
    } catch (error) {
        console.log('getMyFollowingError', error);
        throw new Error(error);
    }
};

export const getMyFollowers = async (userId: string) => {
    try {
        const followRef = query(collection(db, constants.followUsers), where('terms', 'array-contains',  userId + '_'), orderBy('createdAt', 'desc'));
        const followData = await getDocs(followRef);
        return followData.docs.map(followdt => ({
            uuid: followdt.id,
            ...followdt.data()
        }));
    } catch (error) {
        console.log('getMyFollowersError', error);
        throw new Error(error);
    }
};

export const getMyFollowedListing = async (userId: string) => {
    try {
        const followRef = query(collection(db, constants.followProperties), where('terms', 'array-contains', '_' +  userId), orderBy('createdAt', 'desc'));
        const followData = await getDocs(followRef);
        return followData.docs.map(followdt => ({
            uuid: followdt.id,
            ...followdt.data()
        }));
    } catch (error) {
        console.log('getMyFollowedListingError', error);
        throw new Error(error);
    }
};

export const setContact = async (userId : string, message : string, userName : string, email : string, role : string) : Promise<DocumentReference<Icontact>> => {
    const contact = {
        userId,
        message,
        userName,
        email,
        role,
        createdAt: new Date(),
    } as Icontact;
    try {
        const contactRef = collection(db, constants.contactUs);
        return await addDoc(contactRef, contact) as DocumentReference<Icontact>;
    } catch (e) {
        throw Error(e);
    }
};

export const addPostLike = async (postId: string, userId : string) => {

    try {
        const likeDataRef = doc(collection(db, 'posts'), postId);
        const likeData = await getDoc(likeDataRef);
        let likesCount = 0;
        likeData !== undefined && likeData.data() !== undefined && (likesCount = likeData.data().likesCount);
        
        await setDoc(likeDataRef, 
            { likes: arrayUnion(userId), likesCount: likesCount+1 },
            { merge: true }
        );
    } catch (err) {
        throw new Error(err);
    }
};

export const removePostLike = async (postId: string, userId : string) => {
    try {
        const likeDataRef = doc(collection(db, 'posts'), postId);
        const likeData = await getDoc(likeDataRef);
        let likesCount = 0;
        likeData !== undefined && likeData.data() !== undefined && (likesCount = likeData.data().likesCount);

        await setDoc(likeDataRef, 
            { likes: arrayRemove(userId), likesCount: likesCount-1 },
            { merge: true }
        );
    } catch (err) {
        throw new Error(err);
    }
};

export const addPostComment = async (postId: string, userId : string, comment: string) => {
    try {
        const postDataRef = doc(collection(doc(collection(db, 'posts'), postId), 'comments'));
        await setDoc(postDataRef, 
            {userId, comment, createdAt: new Date(), createdAtTimestamp: Date.now()},
            { merge: true }
        );
    } catch (err) {
        throw new Error(err);
    }
};

export const reportPost = async (postId: string, reason: string) => {
    const commentItem = {reason, createdAt: new Date()};
    try {
        const postDataRef = doc(collection(db, 'reports'), postId);
        await setDoc(postDataRef, 
            { reasons: arrayUnion(commentItem) },
            { merge: true }
        );
    } catch (err) {
        throw new Error(err);
    }
};

export const getFollowPropertiesByTime = async (propertyId: string, startDate: number, endDate: number) : Promise<any> => {
    try {
        const followRef = query(collection(db, constants.followProperties), where('terms', 'array-contains',  propertyId + '_'), orderBy('createdAt', 'desc'));
        const followData = await getDocs(followRef);
        const matchingFollow = [];
        await followData.docs.map((followdt) => {
            followdt.data().createdAtTimestamp > startDate && followdt.data().createdAtTimestamp < endDate && matchingFollow.push(followdt.id);
        });
        return matchingFollow.length;
    } catch (error) {
        console.log('getFollowPropertiesByTimeError', error);
        throw new Error(error);
    }
};

export const getVisitorByTime = async (propertyId: string, startDate: number, endDate: number) : Promise<any> => {
    try {
        const visitorRef = doc(db, constants.visitors, propertyId);
        const visitorData = await getDoc(visitorRef);
        const matchingVisitor = [];
        visitorData.data() !== undefined && visitorData.data().visitors !== undefined && 
        await visitorData.data().visitors.forEach((visitordt) => {
            visitordt.createdAtTimestamp > startDate && visitordt.createdAtTimestamp < endDate && matchingVisitor.push(visitordt);
        });
        return matchingVisitor.length;
    } catch (error) {
        console.log('getVisitorByTimeError', error);
        throw new Error(error);
    }
};

export const getPollByTime = async (propertyId: string, startDate: number, endDate: number) : Promise<any> => {
    try {
        const pollRef = query(collection(db, constants.poll), where('terms', 'array-contains',  propertyId + '_'), orderBy('createdAt', 'desc'));
        const pollData = await getDocs(pollRef);
        const matchingPoll = [];
        await pollData.docs.map((polldt) => {
            polldt.data().createdAtTimestamp > startDate && polldt.data().createdAtTimestamp < endDate && matchingPoll.push(polldt.id);
        });
        return matchingPoll.length;
    } catch (error) {
        console.log('getFollowPropertiesByTimeError', error);
        throw new Error(error);
    }
};

export const getPollData = async (propertyId: string) : Promise<any> => {
    try {
        const pollRef = query(collection(db, constants.poll), where('terms', 'array-contains',  propertyId + '_'), orderBy('createdAt', 'desc'));
        const pollData = await getDocs(pollRef);
        return pollData.docs.map(polldt => ({
            uuid: polldt.id,
            ...polldt.data()
        }));
    } catch (error) {
        console.log('getFollowPropertiesByTimeError', error);
        throw new Error(error);
    }
};