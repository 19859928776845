import React, {useEffect, useState} from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ListingBaseScreen } from './ListingBaseScreen';
import HousepoTextInput from '../../components/HouspoTextInput';
import { HouspoMessageInput } from '../../components/atom/HouspoTextInput';
import FontProperty from '../../constants/FontProperty';
import { useProject } from '../../hooks/useProperty';
import { AddProjectTypeBasicProps } from '../../types';
import { ScrollView } from 'react-native-gesture-handler';
import TagsInput from '../../components/atom/TagsInput';
import ContentText from '../../constants/Content';
import IconText from '../../components/atom/IconText';
import { getProjectContent, updateProjectDetails } from '../../lib/api/Content/project';

export const NameProjectScreen: React.FC = (props) => {
    const nav = useNavigation();
    const { projectId } = useRoute<AddProjectTypeBasicProps>().params;
    const [name, setName] = useState('');
    const [description, setDescription] = useState<string>('');
    const [tags, setTags] = useState<string[]>([]);
    const { project, updateProject } = useProject(projectId);
    const [err, setErr] = useState<string>('');
    const [published, setPublisted] = useState(false);

    useEffect(() => {
        getProjectContent(projectId).then(res=> {
            res[0].projectName !== undefined && setName(res[0].projectName);
            res[0].description !== undefined && setDescription(res[0].description);
            res[0].tags !== undefined && setTags(res[0].tags);
            res[0] !== undefined && res[0].published !== undefined && res[0].published === true && setPublisted(true);
        });
    }, [projectId]);

    const onNextStep = async () => {
        if (name !== '' && description !== '') {
            await updateProjectDetails(projectId, name, description, tags);
            nav.navigate('upload', { projectId });
        }else if(name == ''){
            setErr('Please give your project a name');
        }else if(name.split(' ').length > 5){
            setErr('Name is too long, maximum 5 words');
        }else if(description === ''){
            setErr('Please give your project a short description');
        }
    };

    return (
        <ListingBaseScreen
            step={2}
            nextScreenHandler={onNextStep}
            error={err}
            setErr={setErr}
            isPublished={published}
        >
            <ScrollView style={{paddingBottom:40}} showsVerticalScrollIndicator={false}>
                <Text style={[FontProperty.h1, {marginBottom:12}]}>Give your project a name</Text>
                <Text style={[FontProperty.body_1, FontProperty.TextGrey, styles.blurbContainer]}>
                    {ContentText.listProjectName}
                </Text>
                <HousepoTextInput value={name} setText={setName} placeholder='Name...' />
                <Text style={[FontProperty.h1, {marginBottom:24,marginTop:18}]}>Now let’s describe your project</Text>
                <HouspoMessageInput 
                    value={description}
                    placeholder="Tell us more about this project..."
                    setText={setDescription}
                />
                <View style={{marginTop: 30}}></View>
                <IconText 
                    icon='bulb1'
                    title='Tips to improve your project'
                    subtitle='Add project tags to your project to improve search results.'
                />
                <Text style={[FontProperty.h1, {marginBottom:12,marginTop:18}]}>Add some tags</Text>
                <TagsInput value={tags} setValue={setTags} />
            </ScrollView>
        </ListingBaseScreen>
    );
};

const styles = StyleSheet.create({
    blurbContainer: {
        paddingBottom: 24
    }
});
