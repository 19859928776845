import React from 'react';
import Colors from '../../../constants/colors';
import Button from './Button';
import { IdefinedButtonProps } from './types';
// TEAL BUTTON
const TealButtonComponent: React.FC<IdefinedButtonProps> = (props) => {
    return (
        <Button {...props} backgroundColor={Colors.teal} textColor={Colors.white} />
    );
};

export const TealButton = React.memo(TealButtonComponent);
// NAVY BUTTON

const NavyButtonComponent: React.FC<IdefinedButtonProps> = (props) => {
    return (
        <Button {...props} backgroundColor={Colors.navy} textColor={Colors.white} />
    );
};

export const NavyButton = React.memo(NavyButtonComponent);

// NAVY OUTLINE BUTTON
const NavyOutlineButtonComponent: React.FC<IdefinedButtonProps> = (props) => {
    return (
        <Button {...props} backgroundColor={Colors.white} borderColor={Colors.navy} textColor={Colors.navy} />
    );
};

export const NavyOutlineButton = React.memo(NavyOutlineButtonComponent);

// TEAL OUTLINE BUTTON

const TealOutlineButtonComponent: React.FC<IdefinedButtonProps> = (props) => {
    return (
        <Button {...props} backgroundColor={Colors.white} borderColor={Colors.teal} textColor={Colors.teal} />
    );
};

export const TealOutlineButton = React.memo(TealOutlineButtonComponent);

// GREY BUTTON BUTTON
const GreyButtonComponent: React.FC<IdefinedButtonProps> = (props) => {
    return (
        <Button {...props} backgroundColor={Colors.white} borderColor={Colors.teal} textColor={Colors.teal} />
    );
};

export const GreyButton = React.memo(GreyButtonComponent);
