import React, {useEffect, useRef, useState} from 'react';
import { StyleSheet, View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ListingBaseScreen } from './ListingBaseScreen';
import Colors from '../../constants/colors';
import InfoBlurb from '../../components/atom/InfoBlurb';
import { AddListingNavProps, AddListingTypeBasicProps } from '../../types';
import { useProperty } from '../../hooks/useProperty';
// import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import { getPropertyContent, updatePropertyAddress } from '../../lib/api/Content/property';
import AddressForm from '../../components/atom/AddressForm';
import ContentText from '../../constants/Content';

export const LocationScreen : React.FC = (props) => {
    const ref = useRef();
    const nav = useNavigation<AddListingNavProps>();
    const { propertyId } = useRoute<AddListingTypeBasicProps>().params;
    const { property, updateProperty } = useProperty(propertyId);
    const [err, setErr] = useState<string>('');
    const [street, setStreet] = useState('');
    const [suburb, setSuburb] = useState('');
    const [state, setState] = useState('');
    const [postCode, setPostcode] = useState('');
    const [country, setCountry] = useState('Australia');
    const [projects, setProjects] = useState<string[]>([]);
    const [published, setPublisted] = useState(false);


    useEffect(() => {
        getPropertyContent(propertyId).then(res=> {
            if(res[0].address !== undefined){
                setStreet(res[0].address[0]);
                setSuburb(res[0].address[1]);
                setState(res[0].address[2]); 
                setPostcode(res[0].address[3]);
                setCountry(res[0].address[4]);
            }
            if(res[0].projects !== undefined){
                setProjects(res[0].projects);
            }

            res[0] !== undefined && res[0].published !== undefined && res[0].published === true && setPublisted(true);
        });
    }, [propertyId]);

    const onNextStep = async () => {
        if (street === '') {
            setErr('Please type in street');
        } else if (suburb === '') {
            setErr('Please type in suburb');
        } else if (state === '') {
            setErr('Please select a state');
        } else if (postCode === '') {
            setErr('Please type in postCode');
        } else {
            const address = [street,suburb,state,postCode,country];
            await updatePropertyAddress(propertyId, address, projects);
            nav.navigate('features', { propertyId });
        }
        
    };

    return (
        <ListingBaseScreen
            step={3}
            header="Where is your place located?"
            nextScreenHandler={onNextStep}
            error={err}
            setErr={setErr}
            isPublished={published}
        >
            <View>
                <View style={{marginTop:40}}></View>
                <AddressForm 
                    setStreet={setStreet} street={street}
                    setSuburb={setSuburb} suburb={suburb} 
                    setState={setState} state={state} 
                    setPostcode={setPostcode} postCode={postCode} 
                    setCountry={setCountry} country={country} 
                />
                <View style={{marginTop:24}}></View>
                <InfoBlurb>
                    {ContentText.listPropertyLocated}
                </InfoBlurb>
            </View>

        </ListingBaseScreen>
    );
};

const styles = StyleSheet.create({

    contentContainer: {
        flexDirection: 'row',
        justifyContent: 'center'
    },
    item: {
        fontSize: 16,
        color: Colors.lightNavy,
        fontFamily: 'inter-medium'
    },
    itemContainer: {
        borderWidth: 1,
        borderColor: Colors.grey4,
        backgroundColor: Colors.white,
        padding: 18,
        marginLeft: 0,
        marginTop: 0,
        alignItems: 'center',
        width: '100%',
        borderRadius: 4,
        flexDirection: 'row',
        justifyContent: 'center'
    },
    info: {
        marginLeft: 10
    },
    isActive: {
        borderColor: Colors.teal
    },
    isActiveText: {
        color: Colors.teal
    },
    skip: {
        textAlign: 'center',
        fontFamily: 'inter-semibold',
        color: Colors.grey4,
        marginTop: 6
    }
});
