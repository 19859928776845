import React from 'react';
import { StyleSheet, Text, View, Animated } from 'react-native';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';

export type TPercentageBar = {
    title : string,
    number : number,
}

const PercentageBar: React.FC<TPercentageBar> = (props) => {
    const toValue = props.number / 100 * ( Layout.window.width - 48 ) * 0.7;
    return (
        <View style={[Layout.rowBetween, Layout.mbottom15]}>
            <View style={{width:'80%'}}>
                <Text style={[FontProperty.miniP,FontProperty.TextSemiBold, {color:Colors.grey3, marginBottom:8} ]}>{props.title}</Text>
                <View style={[
                    styles.bar, 
                    {width: props.number === 0 ? 1 : toValue},
                    props.number > 50 && {backgroundColor: Colors.teal}
                ]}></View>
            </View>
            <View style={[{width:'20%'}]}>
                <Text style={[FontProperty.p1, FontProperty.TextMedium, {textAlign:'right'}, props.number > 50 && FontProperty.TextTeal]}>{props.number}%</Text>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    bar : {
        height: 8,
        borderRadius: 10,
        backgroundColor: Colors.lightTeal,
    }
});

export default PercentageBar;

