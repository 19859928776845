const ContentText = {
    encourageSignUpListP: 'Sign up to list your own property and experience more features',
    encourageSignUpInsight: 'Sign up to view your engagement insights and experience more features',
    encourageSignUpFollow: 'Sign up to follow others and experience more features',
    improvePropertyTitle: 'Tips to improve your listing',
    improvePropertSubtTitle: 'Update your property listing based on the real time poll results  from the Abodely property community. Connect with Abodely users directly after they have completed your property survey',
    accountCreated: 'Welcome to Abodely! Start explore your local market, reach out directly to homeowners or enjoy browsing for inspiration.',
    deleteThisProperty: 'Deleting the property will clear out this property\'s insights data from your dashboard as well.',
    deleteThisProject: 'Deleting the project will clear out this project\'s likes and comments data as well.',
    deleteThisPost: 'Deleting the post will clear out this post\'s likes and comments data as well.',
    listPropertyLocated: 'We respect your privacy. Only the suburb and street will be shown on your public profile.',
    listPropertyName: 'How will you describe your property in 3 to 5 words?',
    listProjectName: 'How will you describe your project in 3 to 5 words?',
    listPropertyTags: 'Keywords will help improve search results for your property',
    listPropertyImage: 'Don\'t forget to upload photos of your living spaces, bedrooms, bathrooms, landscaping and entertaining areas for better listing performance',
    cantReceiveMessage: 'Receive message is truned off. ',
    cantReceiveNotification: 'Receive notification is turned off.',
    allowReceiveNotification: 'Notification on.',
    allowReceiveMessage: 'Receive message on.',
};

export default ContentText;

export const staticUrls = {
    downloadMobileApp: 'https://abodely.app.link/AWZLFDRymEb'
}
