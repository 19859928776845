import { StyleSheet, Text } from 'react-native';
import React from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Dimensions } from 'react-native';
import Layout from '../constants/Layout';

const ScreenBase : React.FC = ({ children }) => {
    const screenWidth = Dimensions.get('window').width;
    return (
        <SafeAreaView style={[styles.container, screenWidth > 376 && styles.maxWidth]}>
            {children}
        </SafeAreaView>
    );
};

export default ScreenBase;

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#fff',
        flex : 1,
        overflow: 'scroll'
    },
    maxWidth: {
        width: Layout.window.width,
        marginHorizontal: 'auto',
    },
});
