import React, {Dispatch, SetStateAction, useState, useEffect} from 'react';
import { View, Text, TouchableOpacity, Pressable, Image } from 'react-native';
import { StyleSheet } from 'react-native';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import { IOnboardingQA, IOnboardingQAA, IpropertyStyle, IpropertyType } from '../../lib/api/Content/types';
import { AntDesign } from '@expo/vector-icons';
import Colors from '../../constants/colors';
import InfoPopup from '../../components/atom/InfoPopup';
import { getPropertyTypes, getPropertyStyles } from '../../lib/api/Content/property';
import HouspoSuburbs from '../../components/atom/HouspoSuburbs';


export type TOnboardingStep = {
    onboardAnwsers : string[],
    setOnboardAnwsers : Dispatch<SetStateAction<string[]>>,
    onboardingQAData : IOnboardingQA,
    index : number,
    referenceAns ? : 'propertyTypes' | 'propertyStyles' | 'Static' | string
}

export const OBQAScreen = (props:TOnboardingStep) => {
    const [selected, setSelected] = useState(props.onboardAnwsers[props.index]);
    const [help, setHelp] = useState<null | {title : string, body : string}>(null);
    const [propertyTypes, setPropertyTypes] = useState(null);
    const [propertyStyles, setPropertyStyles] = useState(null);
    const multiSelect = props.onboardingQAData.multiSelection;
    const [loadingTypes, setLoadingTypes] = useState(true);
    const [loadingStyles, setLoadingStyles] = useState(true);
    const [openSuburb, setSuburbOpen] = useState(false);
    const [locationSuburbValue, setLocationSuburbValue] = useState('');

    const reorderArr = (array:any) => {
        array.sort(function (a:any, b:any) {
            return a.weight - b.weight;
        });
    };

    useEffect(() => {
        props.referenceAns == 'propertyTypes' && (
            getPropertyTypes().then(res => {
                reorderArr(res);
                setPropertyTypes(res);
                setLoadingTypes(false);
            }
            )
        );
        
        props.referenceAns == 'propertyStyles' && (
            getPropertyStyles().then(res => {
                reorderArr(res);
                setPropertyStyles(res);
                setLoadingStyles(false);
            }
            )
        );

        props.referenceAns == 'Static' &&  setPropertyTypes(null);
        props.referenceAns == 'Static' &&  setPropertyStyles(null);
        
       
    }, [props.index]);

    if(props.referenceAns === 'propertyTypes' && loadingTypes !== false){
        return null;
    }
    if(props.referenceAns === 'propertyStyles' && loadingStyles !== false){
        return null;
    }

    const onSelectHandlar = (value : string) => {
        const targetAnswer = props.onboardAnwsers;
        if(multiSelect){
            if(targetAnswer[props.index].includes(value)){
                setSelected(targetAnswer[props.index].replace(value + ',', ''));
                targetAnswer[props.index] = targetAnswer[props.index].replace(value + ',', '');
                props.setOnboardAnwsers(targetAnswer);
            } else {
                setSelected(targetAnswer[props.index] + value + ',');
                targetAnswer[props.index] = targetAnswer[props.index] + value + ',';
                props.setOnboardAnwsers(targetAnswer);
            }
        } else {
            setSelected(value);
            targetAnswer[props.index] = value;
            props.setOnboardAnwsers(targetAnswer);
        }
    };

    return (
        <View style={[Layout.globalPadding24, {alignItems:'center'}]}>
            <View style={[styles.contentContainer]}>
                {props.onboardingQAData.question && <Text style={[FontProperty.TextSemiBold, FontProperty.h4, Layout.mbottom40, FontProperty.TextBlack]}>{props.onboardingQAData.question}</Text> }
                {props.onboardingQAData.answers && 
                    props.onboardingQAData.answers.map((item:IOnboardingQAA) => {
                        return (
                            <Pressable
                                style={[
                                    styles.itemContainer, 
                                    Layout.mbottom25, 
                                    props.onboardAnwsers[props.index].includes(item.value) && styles.isActive
                                ]}
                                onPress={() => onSelectHandlar(item.value)}
                                key={item.value}
                            >
                                <Text style={[
                                    styles.item, 
                                    props.onboardAnwsers[props.index].includes(item.value) && styles.isActiveText
                                ]}>{item.title}</Text>
                                {
                                    item.description &&
                                    <Pressable onPress={() => { setHelp({ title: item.title, body: item.description }); }}>
                                        <AntDesign
                                            name='infocirlceo'
                                            size={14}
                                            style={styles.icon}
                                        />
                                    </Pressable>
                                }
                            </Pressable>
                        );
                    })
                }
                {props.referenceAns == 'suburbs' &&
                    <View style={{width:'100%'}}>
                        <HouspoSuburbs 
                            value={locationSuburbValue}
                            setOpen={setSuburbOpen}
                            // setValue={setLocationSuburbValue}
                            setValue={(locationSuburbValue)=>{
                                setLocationSuburbValue(locationSuburbValue);
                                onSelectHandlar(locationSuburbValue);
                            }}
                            targetState=''
                            noResultMessage="Postcode or suburb doesn't exist"
                            multiple={true}
                        />
                    </View>
                }
                <View style={styles.halfSizeItemsContainer}>
                    {props.referenceAns == 'propertyTypes' && propertyTypes && propertyTypes !== null &&
                        propertyTypes.map((item:IpropertyType, index:number) => {
                            return (
                                <View key={item.uuid} style={{width:(Layout.window.width - 68)/2}}>
                                    <Pressable
                                        style={[
                                            styles.itemContainer,
                                            styles.itemIconContainer, 
                                            Layout.mbottom25, 
                                            props.onboardAnwsers[props.index].includes(item.uuid) && styles.isActive
                                        ]}
                                        onPress={() => onSelectHandlar(item.uuid)}
                                    >
                                        <Image source={item.icon} style={[styles.ppimages, props.onboardAnwsers[props.index].includes(item.uuid) && styles.hide]}/>
                                        <Image source={item.iconActive} style={[styles.ppimages, !props.onboardAnwsers[props.index].includes(item.uuid) && styles.hide]}/>
                                        <Text style={[styles.item, styles.itemIcon, props.onboardAnwsers[props.index].includes(item.uuid) && styles.isActiveIconText, {marginRight:0}]}>{item.type}</Text>
                                    </Pressable>
                                </View>
                            );
                        })
                    }
                    {props.referenceAns == 'propertyStyles' && propertyStyles && propertyStyles !== null &&
                        propertyStyles.map((item:IpropertyStyle, index:number) => {
                            return (
                                <View key={item.uuid} style={{width:(Layout.window.width - 68)/2}}>
                                    <Pressable
                                        style={[
                                            styles.itemContainer,
                                            styles.itemIconContainer, 
                                            Layout.mbottom25, 
                                            props.onboardAnwsers[props.index].includes(item.uuid) && styles.isActive
                                        ]}
                                        onPress={() => onSelectHandlar(item.uuid)}
                                    >
                                        <Image source={props.onboardAnwsers[props.index].includes(item.uuid) ? item.iconActive : item.icon} style={styles.ppimages}/>
                                        <Text style={[styles.item, styles.itemIcon, props.onboardAnwsers[props.index].includes(item.uuid) && styles.isActiveIconText, {marginRight:0}]}>{item.style}</Text>
                                    </Pressable>
                                </View>
                            );
                        })
                    }
                </View>
            </View>
            {
                help && (
                    <View style={styles.info}>
                        <InfoPopup onClose={() => { setHelp(null); }} title={help.title} body={help.body} />
                    </View>
                )
            }
        </View>
    );
};

const styles = StyleSheet.create({
    contentContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 17,
        paddingBottom: 15,
        width: '100%',
    },
    itemContainer: {
        borderWidth: 1,
        borderColor: Colors.grey4,
        backgroundColor: Colors.white,
        padding: 18,
        marginLeft: 0,
        marginTop: 0,
        alignItems: 'flex-end',
        width: '100%',
        borderRadius: 4,
        flexDirection: 'row',
        justifyContent: 'center'
    },
    item: {
        fontSize: 16,
        color: Colors.lightNavy,
        fontFamily: 'inter-medium',
        marginRight: 10,
        textAlign: 'center'
    },
    icon: {
        color: Colors.grey4
    },
    isActive: {
        borderColor: Colors.teal
    },
    isActiveText: {
        color: Colors.teal
    },
    isActiveIconText: {
        color: Colors.navy
    },
    info: {
        backgroundColor: Colors.white,
        boxShadow: '0px 0px 10px 2px #d1d1d1',
        maxWidth: 320,
        top: 0,
        position: 'absolute',
    },
    itemIconContainer: {
        flexDirection: 'column',
        alignItems: 'center'
    },

    halfSizeItemsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    halfSizeItemContainer: {
        width: '48%'
    },
    ppimages : {
        width: 50,
        height: 50,
        marginBottom: 13,
        resizeMode : 'contain'
    },
    itemIcon: {
        color: Colors.grey4,
    },
    hide: {
        display: 'none'
    }
});
