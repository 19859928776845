import * as ImageManipulator from 'expo-image-manipulator';

export const compressImage = async (images: string[])=> {
    
    const compressedImages = [];
    for await (const image of images) {
        await ImageManipulator.manipulateAsync(image.uri, [{resize: {width: 768}}], { compress: 0.8 }, ).then((ref)=>{
            compressedImages.push(ref);
        });
    }
    return compressedImages;
};

export const compressImageSingle = async (image: string)=> {
    return await ImageManipulator.manipulateAsync(image.uri, [{resize: {width: 100}}], { compress: 0.8 }, );
};


