import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity, Platform } from 'react-native';
import { GreyButton, NavyButton } from '../../components/Button';
import FontProperty from '../../constants/FontProperty';
import Colors from '../../constants/colors';
import Layout from '../../constants/Layout';
import IMAGE from '../../assets/images';
import ScreenBase from '../../components/ScreenBase';
// import RegisterForm from '../../components/forms/RegisterForm';
// import LoginForm from '../../components/forms/LoginForm';
import ScreenHeader from '../../components/atom/ScreenHeader';
import { getHomeContent } from '../../lib/api/Content/content';
import { IHomeContent } from '../../lib/api/Content/types';
// import { Amplify, Auth } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
// import userPoolData from '../../config/cognito';
// import awsmobile from '../../aws-exports';

export const AuthenticationScreen = ({route}:any) => {
    const [authType, setAuthType] = useState<'LOGIN' | 'REGISTER'>('LOGIN');
    const [homeContent, setHomeContent] = useState<IHomeContent[]>();

    
    // Amplify.configure({
    //     Auth: {
    //         region: awsmobile.REGION,
    //         userPoolId: awsExports.USER_POOL_ID,
    //         userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID
    //     }
    // });

    useEffect(() => {
        if(route.params){
            if(route.params?.target){
                setAuthType(route.params?.target);
            }
        }
    }, [route.params?.target]);
    
    useEffect(() => {
        getHomeContent().then(res => {
            setHomeContent(res);
        });
    });

    if(homeContent === undefined){
        return null;
    }

    return (
        <ScreenBase>
            <ScreenHeader/>
            <Image source={homeContent[0].signInBanner} style={[Layout.banner]} />
            <View style={{ backgroundColor: '#fff', paddingBottom: 20, paddingTop: 24, flex: 1 }}>
                <View style={[Layout.screenContainer, {flex: 1}]}>
                { /* <View style={[styles.signInBtns, Layout.row]}>
                        { /* authType === 'LOGIN' && (
                            <>
                                <NavyButton btnStyle={[{ flex: 1 }]}>
                                    <Text style={[FontProperty.TextWhite, FontProperty.TextSemiBold, FontProperty.p2]}>Sign In</Text>
                                </NavyButton>
                                <GreyButton
                                    onPress={() => { setAuthType('REGISTER'); }}
                                    isDisabled={false}
                                    btnStyle={[{ flex: 1 }]}
                                >
                                    <Text style={[FontProperty.TextGrey, FontProperty.TextSemiBold, FontProperty.p2]}>Sign Up</Text>
                                </GreyButton>
                            </>
                        )}

                        {authType === 'REGISTER' && (
                            <>
                                <GreyButton
                                    btnStyle={[{ flex: 1 }]}

                                    onPress={() => { setAuthType('LOGIN'); }}>
                                    <Text style={[FontProperty.TextGrey, FontProperty.TextSemiBold, FontProperty.p2, {}]}>Sign In</Text>
                                </GreyButton>
                                <NavyButton
                                    btnStyle={[{ flex: 1 }]}

                                    isDisabled={false}
                                >
                                    <Text style={[FontProperty.TextWhite, FontProperty.TextSemiBold, FontProperty.p2]}>Sign Up</Text>
                                </NavyButton>
                            </>
                        ) // should end here }
                        </View> */ }
                    <View style={{flex: 1}}>
                        <Authenticator initialState='signIn' socialProviders={['amazon', 'apple', 'facebook', 'google']}>
                            {({ signOut, user }) => (
                                <main>
                                <h1>Hello {user.username}</h1>
                                <button onClick={signOut}>Sign out</button>
                                </main>
                            )}
                        </Authenticator>
                    </View>
                    <View>
                        <TouchableOpacity onPress={() => { authType == 'LOGIN' ? setAuthType('REGISTER') : setAuthType('LOGIN'); }} >
                            {authType === 'LOGIN' && <Text style={styles.bottomText}>Don't have an account? <Text style={styles.bottomTextHyper} >Join now</Text></Text>}
                            {authType === 'REGISTER' && <Text style={styles.bottomText}>Already have an account? <Text style={styles.bottomTextHyper} >Sign in</Text></Text>}
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </ScreenBase>
    );
};

const webStyles = StyleSheet.create({
    container: {
        backgroundColor: Colors.white,
    },

    checkboxContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 17
    },

    checkbox: {
        alignSelf: 'center'
    },

    forgotPw: {
        color: Colors.grey3,
        marginTop: 3,
        fontSize: 12,
        fontFamily: 'inter-semibold'
    },

    bottomText: {
        textAlign: 'center',
        marginTop: 16,
        color: Colors.grey4
    },

    bottomTextHyper: {
        color: Colors.teal
    },

    signInBtns: {
        paddingVertical: 20,
        width: '100%'
    },
    buttonContainer: {
        paddingBottom: 10
    }
});

const deviceStyles = StyleSheet.create({
    container: {
        backgroundColor: Colors.white
    },

    checkboxContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },

    checkbox: {
        alignSelf: 'center'
    },

    forgotPw: {
        color: Colors.grey3,
        marginTop: 3,
        fontSize: 12,
        fontFamily: 'inter-semibold'
    },

    bottomText: {
        textAlign: 'center',
        // marginTop: 25,
        color: Colors.grey4
    // paddingBottom
    },

    bottomTextHyper: {
        color: Colors.teal
    },

    signInBtns: {
        paddingTop: 44,
        width: '100%'
    }
});

const styles = Platform.OS === 'web' ? webStyles : deviceStyles;
