import React from 'react';
import { StyleSheet, View, Text} from 'react-native';
import FontProperty from '../../constants/FontProperty';
import { IpropertyStatus } from '../../lib/api/Content/types';


export type TpropertyStatus = {
    data : IpropertyStatus[]
}

const StatusPill : React.FC< TpropertyStatus  > = (props) => {
    return (
        <View style={styles.pill}>
            <Text style={[{color:props.data[0].color}, FontProperty.TextSemiBold]}><View style={[styles.dot, {backgroundColor:props.data[0].color}]}></View>{props.data[0].name}</Text>
        </View>
    );
};

const styles = StyleSheet.create({
    pill: {
        paddingVertical:6,
        paddingHorizontal:16,
        backgroundColor:'white',
        borderRadius:50,width:'fit-content',
        position: 'absolute',
        zIndex: 1,
        left: 15,
        top: 15
    },
    dot: {
        width: 8,
        height: 8,
        borderRadius: 8,
        marginRight: 10
    }
});

export default StatusPill;
