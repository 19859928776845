import { ImageInfo } from 'expo-image-picker';
import { addDoc, collection, doc, setDoc, getDoc, getDocs, query, updateDoc, where, arrayUnion } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadString } from 'firebase/storage';
import { db } from '../../../config/firebase';
import { useAuthentication } from '../../../hooks/useAuthentication';
import { TdrupalValue } from '../../../types';
import { getFileType } from '../../utils/getFileType';
import constants from './constants';
import { IpropertyImage } from './types';

export type uploadImagesParams = ImageInfo[]

export type TcontentType = 'property_image'
export type TfieldName = 'field_image'
export type TuploadImageResponse = {
  fid : TdrupalValue<number>
}
export const uploadImage = async (image : ImageInfo, userId : string, propertyId : string) : Promise<string> => {
    // Add Image ref to firebase db
    const propertyImagesCollection = collection(db, constants.propertyImages);
    const propertyDataRef = doc(collection(db, 'properties'), propertyId);
    try {
        if (!image.base64) {
            throw new Error('No image');
        }
        const propertyImageRef = await addDoc(propertyImagesCollection, {
            user: userId,
            property: propertyId
        });
        // Upload Image to Google Cloud storage
        const fileType = getFileType(image);
        const storage = getStorage();
        const storageRef = ref(storage, `propertyImages/${propertyImageRef.id}.${fileType}`);

        const imageStorageRef = await uploadString(storageRef, image.base64.split('base64,')[1], 'base64');
        // Add Image URL to firebase db
        const viewUrl = await getImageUrl(imageStorageRef.metadata.fullPath);
        await updateDoc(propertyImageRef, {
            imageRef: imageStorageRef.metadata.fullPath,
            viewUrl
        });

        await updateDoc(propertyDataRef, 
            { images: arrayUnion({viewUrl: viewUrl}) }
        );
        // If Upload fails delete image ref from firebase db
        // return array of image refs

        return imageStorageRef.metadata.fullPath;
        // update ImageRef with image url
    } catch (e) {
        console.log('IMAGE UPLOAD : ', e);
    }
};

export const uploadMultipleImages = (images : uploadImagesParams, userId : string, propertyId : string) : Promise<string[]> => {
    // for each image, create a promise that upload the image to the server

    const promises = images.map(async (image) => {
        return uploadImage(image, userId, propertyId);
    });

    return Promise.all(promises)
        .then((images) => {
            // Return array of image urls
            return images;
        });
};

export const getImageUrl = async (fileName : string) : Promise<string> => {
    // Get Image Ref
    const storage = getStorage();
    const imageRef = ref(storage, fileName);
    try {
        const url = await getDownloadURL(imageRef);
        return url;
    } catch {
    // Return Error Image
        return '';
    }
};

export const getPropertyImages = async (propertyId : string) : Promise<IpropertyImage[]> => {
    try {
        // Query for property images that reference the propertyId
        const propertyImagesQuery = query(collection(db, constants.propertyImages), where('property', '==', propertyId));

        // Get propertyImage refs from firebase db
        const propertyImages = await getDocs(propertyImagesQuery);
        return propertyImages.docs.map((doc) => {
            return {
                ...doc.data(),
                uuid: doc.id
            } as IpropertyImage;
        });
    } catch (error) {
        console.log('getPropertyImages', error);
        throw new Error(error);
    }
};

export const updatePropertyImageData = async (propertyImages : IpropertyImage[]) => {
    const propertyImagesCollection = collection(db, constants.propertyImages);
    try {
        await Promise.all(propertyImages.map(async (propertyImage) => {
            const propertyImageRef = doc(propertyImagesCollection, propertyImage.uuid);
            await updateDoc(propertyImageRef, {
                ...propertyImage
            });
        }));
    } catch (error) {
        console.log('updatePropertyImageData', error);
        throw new Error(error);
    }
};

export const uploadHeadshot = async (image : ImageInfo, userId : string) : Promise<string> => {
    // Add Image ref to firebase db
    const headshotsCollection = collection(db, constants.headshots);
    const userDataRef = doc(collection(db, 'userData'), userId);
    try {
        if (!image.base64) {
            throw new Error('No image');
        }
        const heashotRef = await addDoc(headshotsCollection, {
            user: userId,
        });
        // Upload Image to Google Cloud storage
        const fileType = getFileType(image);
        const storage = getStorage();
        const storageRef = ref(storage, `userHeadshots/${heashotRef.id}.${fileType}`);
        const imageStorageRef = await uploadString(storageRef, image.base64.split('base64,')[1], 'base64');
        // Add Image URL to firebase db
        const viewUrl = await getImageUrl(imageStorageRef.metadata.fullPath);
        await updateDoc(heashotRef, {
            imageRef: imageStorageRef.metadata.fullPath,
            viewUrl
        });

        await setDoc(userDataRef, { headshot: viewUrl },{ merge: true });
        // If Upload fails delete image ref from firebase db
        // return array of image refs

        return imageStorageRef.metadata.fullPath;
        // update ImageRef with image url
    } catch (e) {
        console.log('IMAGE UPLOAD : ', e);
    }
};


export const uploadMultiplePostImages = (images : uploadImagesParams, userId : string) : Promise<string[]> => {
    // for each image, create a promise that upload the image to the server
    const promises = images.map(async (image) => {
        return uploadPostImage(image, userId);
    });

    return Promise.all(promises)
        .then((images) => {
            return images;
        });
};

export const uploadPostImage = async (image : ImageInfo, userId : string) : Promise<string> => {
    // Add Image ref to firebase db
    const postImagesCollection = collection(db, constants.postImages);
    try {
        if (!image.base64) {
            throw new Error('No image');
        }
        const postImageRef = await addDoc(postImagesCollection, {
            user: userId,
        });
        // Upload Image to Google Cloud storage
        const fileType = getFileType(image);
        const storage = getStorage();
        const storageRef = ref(storage, `postImages/${postImageRef.id}.${fileType}`);

        const imageStorageRef = await uploadString(storageRef, image.base64.split('base64,')[1], 'base64');
        // Add Image URL to firebase db
        const viewUrl = await getImageUrl(imageStorageRef.metadata.fullPath);
        await updateDoc(postImageRef, {
            imageRef: imageStorageRef.metadata.fullPath,
            viewUrl
        });

        return viewUrl;
        // update ImageRef with image url
    } catch (e) {
        console.log('IMAGE UPLOAD : ', e);
    }
};


export const uploadMultipleProjectImages = (images : uploadImagesParams, userId : string, projectId : string) : Promise<string[]> => {
    // for each image, create a promise that upload the image to the server

    const promises = images.map(async (image) => {
        return uploadProjectImage(image, userId, projectId);
    });

    return Promise.all(promises)
        .then((images) => {
            // Return array of image urls
            return images;
        });
};

export const uploadProjectImage = async (image : ImageInfo, userId : string, projectId : string) : Promise<string> => {
    // Add Image ref to firebase db
    const projectImagesCollection = collection(db, constants.projectImages);
    const projectDataRef = doc(collection(db, 'projects'), projectId);
    try {
        if (!image.base64) {
            throw new Error('No image');
        }
        const projectImageRef = await addDoc(projectImagesCollection, {
            user: userId,
            project: projectId
        });

        // Upload Image to Google Cloud storage
        const fileType = getFileType(image);
        const storage = getStorage();
        const storageRef = ref(storage, `projectImages/${projectImageRef.id}.${fileType}`);

        const imageStorageRef = await uploadString(storageRef, image.base64.split('base64,')[1], 'base64');
        // Add Image URL to firebase db
        const viewUrl = await getImageUrl(imageStorageRef.metadata.fullPath);
        await updateDoc(projectImageRef, {
            imageRef: imageStorageRef.metadata.fullPath,
            viewUrl
        });

        await updateDoc(projectDataRef, 
            { images: arrayUnion({viewUrl: viewUrl}) }
        );
        // If Upload fails delete image ref from firebase db
        // return array of image refs

        return imageStorageRef.metadata.fullPath;
        // update ImageRef with image url
    } catch (e) {
        console.log('IMAGE UPLOAD : ', e);
    }
};