import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

const ScreenWrapper : React.FC = (props) => {
  return (
        <View style={[styles.container, props.noFlex && {flex : 0}]}>
            {props.children}
        </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingHorizontal: 24,
    alignSelf: 'center',
    flex : 1,
  }
})

export default ScreenWrapper
