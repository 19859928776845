import React, {useState } from 'react';
import { StyleSheet, View, Text, Pressable, ScrollView } from 'react-native';
import Layout from '../../constants/Layout';
import { IpropertyData } from '../../lib/api/Content/types';
import FontProperty from '../../constants/FontProperty';
import PropertyCard from './PropertyCard';
import Colors from '../../constants/colors';
import { AntDesign } from '@expo/vector-icons';

export type TSimilarProperty = {
    data : IpropertyData[]
}

export const SimilarProperty : React.FC< TSimilarProperty > = (props) => {
    const [ref, setRef] = useState(0);
    const [directionStep, setDirectionStep] = useState(0);

    const scrollScale = Math.ceil(176 * props.data.length / (Layout.window.width - 24) - 1);
    const scrollHandler = (plus:boolean) => {
        plus ? setDirectionStep((directionStep === scrollScale + 1) ? directionStep : directionStep + 1) : setDirectionStep(directionStep === 0 ? 0 : directionStep - 1);
        ref.scrollTo({
            x: 176 * 2 * (plus ? directionStep + 1 : directionStep - 1),
            y: 0,
            animated: true,
        });
    };

    return (
        <View>
            <Text style={[FontProperty.h1, styles.headerContainer, Layout.globalPadding24]}>You may also like...</Text>
            <View>
                <ScrollView
                    horizontal={true}
                    scrollEnabled={false}
                    showsVerticalScrollIndicator={false}
                    style={{paddingLeft:24,paddingRight:8}}
                    ref={(ref) => {
                        setRef(ref);
                    }}
                >
                    {
                        props.data.map((item, index:number)=>{
                            return (
                                <PropertyCard data={item} from={item.uuid} key={index} />
                            );
                        })
                    }

                </ScrollView>
                <View style={[{flexDirection: 'row',justifyContent:'center'}]}>
                    {scrollScale > 0 && <Pressable onPress={()=>scrollHandler(false)} style={[styles.arrow, {top: '50%'}]}><AntDesign name='left' size={14} color={Colors.white}/></Pressable> }
                    {scrollScale > 0 && <Pressable onPress={()=>scrollHandler(true)} style={[styles.arrow, styles.rightArr, {top: '50%'}]}><AntDesign name='right' size={14} color={Colors.white}/></Pressable> }
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    headerContainer: {
        paddingTop: 24,
        marginBottom: 12
    },
    arrow: {
        backgroundColor: Colors.teal,
        borderRadius: 100,
        width: 30,
        height: 30,
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.8)',
        marginHorizontal: 10
    }
});

export default SimilarProperty;
