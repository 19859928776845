import React, {useEffect, useState} from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ListingBaseScreen } from './ListingBaseScreen';
import { getPropertyContent, getPropertyStates } from '../../lib/api/Content/property';
import Layout from '../../constants/Layout';
import Colors from '../../constants/colors';
import { AddListingNavProps, AddListingTypeBasicProps } from '../../types';
import { IpropertyType } from '../../lib/api/Content/types';
import { useProperty } from '../../hooks/useProperty';
import { updatePropertyState } from '../../lib/api/Content/property';
import { View } from '../../components/Themed';
import IconText from '../../components/atom/IconText';

export const SelectStatusScreen : React.FC = (props) => {
    const nav = useNavigation<AddListingNavProps>();
    const { propertyId } = useRoute<AddListingTypeBasicProps>().params;
    const [selected, setSelected] = useState<string | null>(null);
    const [options, setOptions] = useState<IpropertyType[]>([]);
    const { property, updateProperty } = useProperty(propertyId);
    const [err, setErr] = useState<string>('');
    const [published, setPublisted] = useState(false);

    useEffect(() => {
        getPropertyContent(propertyId).then(res=> {
            res[0].status !== undefined && setSelected(res[0].status);
            res[0] !== undefined && res[0].published !== undefined && res[0].published === true && setPublisted(true);
        });
    }, [propertyId]);

    useEffect(() => {
        getPropertyStates().then(data => {
            setOptions(data);
        }).catch(err => {
            console.log('API : CONTENT : GET_PROPERTY_TYPES : ERROR :', err);
            setErr('Network Error');
        });
    }, []);

    const onSelectHandlar = (id : string) => {
        setSelected(id);
    };

    const onNextStep = async () => {
        if (selected) {
            await updatePropertyState(propertyId, selected);
            nav.navigate('review', { propertyId });
        } else {
            setErr('Please select an option');
        }
    };

    

    return (
        <ListingBaseScreen
            step={8}
            header="Is your property..."
            nextScreenHandler={onNextStep}
            isSecondLastStep={true}
            error={err}
            setErr={setErr}
            isPublished={published}
        >
            <View style={{flexDirection:'row',flexWrap:'wrap',justifyContent:'space-between'}} >
                {
                    options.map((item) => {
                        return (
                            <TouchableOpacity
                                style={[styles.itemContainer, Layout.mbottom25, selected === item.uuid && styles.isActive]}
                                onPress={() => onSelectHandlar(item.uuid)}
                                key={item.uuid}
                            >
                                <Text style={[styles.item, selected === item.uuid && styles.isActiveText]}>{item.name}</Text>
                            </TouchableOpacity>
                        );
                    })
                }
            </View>
            <View style={{marginTop: 30}}></View>
            <IconText 
                icon='bulb1'
                title='Tips to improve your listing'
                subtitle='If you are listing your property as "For Sale", please ensure you have a completed contract for sale
                that complies with all applicable laws and regulations'
            />
        </ListingBaseScreen>
    );
};

const styles = StyleSheet.create({
    item: {
        fontSize: 16,
        color: Colors.lightNavy,
        fontFamily: 'inter-medium'
    },
    itemContainer: {
        borderWidth: 1,
        borderColor: Colors.grey4,
        backgroundColor: Colors.white,
        padding: 18,
        marginLeft: 0,
        marginTop: 0,
        alignItems: 'center',
        width: Layout.window.width > 425 ? '48%' : '100%',
        borderRadius: 4,
        flexDirection: 'row',
        justifyContent: 'center'
    },
    isActive: {
        borderColor: Colors.teal
    },
    isActiveText: {
        color: Colors.teal
    },
});

