
import { ref,  push, onValue, update, get, child, remove } from 'firebase/database';
import { rtdb } from '../../../config/firebase';

export const sendNotificationHouspo = async (
    userId: string, senderId: string, propertyId: string, projectId:string, followProperty: boolean, followProject: boolean, commentProperty: boolean, followUser: boolean
) => {
    try {
        await push(ref(rtdb, `notifications/${senderId}`), {
            userId,
            propertyId,
            projectId, 
            followProperty,
            followProject,
            commentProperty,
            followUser,
            read: false,
            createdByTimestamp: Date.now()
        });
    } catch (error) {
        console.log('sendNotificationHouspo error: ', error);
    }
};

export const getUserNotificationHouspo = async (currentUserId: string, setState: React.Dispatch<React.SetStateAction<any[]>>) => {
    try {
        await onValue(ref(rtdb, `notifications/${currentUserId}`), (snapshot) => {
            const data = [];
            snapshot.forEach((child) => {
                data.push([child.val(), child.key]);
            });
            data.reverse();
            setState(data);
        }
        );
    } catch (error) {
        console.log('ERROR : ', error);
    }
};

export const updateReadStatus = async (userId:string, key:string, status:boolean) => {
    const notificationsRef = ref(rtdb);
    const notificationnData = await get(child(notificationsRef, `notifications/${userId}/${key}`));
    try {
        await update(ref(rtdb, `notifications/${userId}/${key}`), 
            {
                userId : notificationnData.val().userId,
                propertyId: notificationnData.val().propertyId ? notificationnData.val().propertyId : '',
                projectId : notificationnData.val().projectId ? notificationnData.val().projectId : '',
                followProperty : notificationnData.val().followProperty ? notificationnData.val().followProperty : false,
                followProject : notificationnData.val().followProject ? notificationnData.val().followProject : false,
                commentProperty : notificationnData.val().commentProperty ? notificationnData.val().commentProperty : false,
                followUser : notificationnData.val().followUser ? notificationnData.val().followUser : false,
                read: status,
                createdByTimestamp: notificationnData.val().createdByTimestamp,
            },);
    } catch (error) {
        console.log('ERROR : ', error);
    }
};

export const deleteNotification = async (userId:string, key:string) => {
    try {
        await remove(ref(rtdb, `notifications/${userId}/${key}`));
      
    } catch (error) {
        console.log('ERROR : ', error);
    }
};

export const haveUnreadNotification = async (userId:string, setState: React.Dispatch<React.SetStateAction<boolean>>) => {
    try {
        await onValue(ref(rtdb, `notifications/${userId}`), (snapshot) => {
            let haveUnread = false;
            snapshot.forEach((child) => {
                child.val().read === false && (haveUnread = true);
            });
            setState(haveUnread);
        }
        );
    } catch (error) {
        console.log('ERROR : ', error);
    }
};