import { AntDesign } from '@expo/vector-icons';
import React, {useEffect, useState} from 'react';
import {ScrollView, StyleSheet, Text, View, Pressable } from 'react-native';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import { getSuggestedProperties, getUserData } from '../../lib/api/user';
import PropertyTileByIdVertial from './PropertyTileByIdVertial';

export type TSuggestedForYou = {
  userId : string,
}

const SuggestedForYou = (props:TSuggestedForYou) => {
    const [userOnboardingData, setUserOnboardingData] = useState<string[] | undefined>([]);
    const [suggestedProperty, setSuggestedProperties] = useState();
    const [ref, setRef] = useState(0);
    const [directionStep, setDirectionStep] = useState(0);
  
    useEffect(() => {
        getUserData(props.userId).then(ref=>{
            ref.data() && ref.data()?.onboardQuestions &&
            setUserOnboardingData(ref.data()?.onboardQuestions);
        });
    }, [props.userId]);

    useEffect(() => {
        if(userOnboardingData?.length > 0) {
            const userPreferenceType = userOnboardingData[3].split(',');
            const userPreferenceStyle = userOnboardingData[4].split(',');
            userPreferenceType.pop();
            userPreferenceStyle.pop();

            getSuggestedProperties(props.userId, userPreferenceType,userPreferenceStyle).then(ref=>{
                setSuggestedProperties(ref);
            });
        }
    }, [userOnboardingData]);

    if(userOnboardingData?.length === 0 || suggestedProperty === undefined) {
        return null;
    }

    

    const scrollScale = Math.ceil((140 * suggestedProperty.length) / (Layout.window.width - 24)-1);
    const scrollHandler = (plus:boolean) => {
        plus ? setDirectionStep((directionStep === scrollScale + 1) ? directionStep : directionStep + 1) : setDirectionStep(directionStep === 0 ? 0 : directionStep - 1);
        ref.scrollTo({
            x: 120 * 2 * (plus ? directionStep + 1 : directionStep - 1),
            y: 0,
            animated: true,
        });
    };

    return (
        suggestedProperty !== undefined && suggestedProperty.length > 0 &&
        <View style={[Layout.row, {paddingTop:21, paddingLeft:24}]}>
            <View>
                <Text style={[FontProperty.TextSemiBold,FontProperty.h4,FontProperty.TextBlack,{marginBottom:20}]}>Suggested property for you:</Text>
                
                <ScrollView 
                    horizontal 
                    style={{width: Layout.window.width,paddingBottom:21,paddingRight:32}}
                    scrollEnabled={false}
                    showsVerticalScrollIndicator={false}
                    ref={(ref) => {
                        setRef(ref);
                    }}
                >
                    {
                        suggestedProperty.map((item:any, index:number)=>{
                            return (
                                <PropertyTileByIdVertial propertyData={item.data()} userId={props.userId} key={item.data().uuid}/>
                            );
                        })
                    }
                </ScrollView>
                <View style={[{flexDirection: 'row',justifyContent:'center',paddingRight:24}]}>
                    {scrollScale > 0 && <Pressable onPress={()=>scrollHandler(false)} style={[styles.arrow, {top: '50%'}]}><AntDesign name='left' size={14} color={Colors.white}/></Pressable> }
                    {scrollScale > 0 && <Pressable onPress={()=>scrollHandler(true)} style={[styles.arrow, styles.rightArr, {top: '50%'}]}><AntDesign name='right' size={14} color={Colors.white}/></Pressable> }
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    arrow: {
        backgroundColor: Colors.teal,
        borderRadius: 100,
        width: 30,
        height: 30,
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.8)',
        marginBottom: 24,
        marginHorizontal: 10
    },
});


export default SuggestedForYou;