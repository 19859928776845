import React, {useEffect, useState} from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ListingBaseScreen } from './ListingBaseScreen';
import HousepoTextInput from '../../components/HouspoTextInput';
import { HouspoMessageInput } from '../../components/atom/HouspoTextInput';
import FontProperty from '../../constants/FontProperty';
import { useProperty } from '../../hooks/useProperty';
import { AddListingTypeBasicProps } from '../../types';
import { getPropertyContent, updatePropertyDetails } from '../../lib/api/Content/property';
import { ScrollView } from 'react-native-gesture-handler';
import TagsInput from '../../components/atom/TagsInput';
import ContentText from '../../constants/Content';
import IconText from '../../components/atom/IconText';

export const NamePropertyScreen: React.FC = (props) => {
    const nav = useNavigation();
    const { propertyId } = useRoute<AddListingTypeBasicProps>().params;
    const [name, setName] = useState('');
    const [description, setDescription] = useState<string>('');
    const [neighbourhoodDescription, setNeighbourhoodDescription] = useState<string>('');
    const [tags, setTags] = useState<string[]>([]);
    const { property, updateProperty } = useProperty(propertyId);
    const [err, setErr] = useState<string>('');
    const [published, setPublisted] = useState(false);

    useEffect(() => {
        getPropertyContent(propertyId).then(res=> {
            res[0].propertyName !== undefined && setName(res[0].propertyName);
            res[0].description !== undefined && setDescription(res[0].description);
            res[0].tags !== undefined && setTags(res[0].tags);
            res[0].neighbourhoodDescription !== undefined && setNeighbourhoodDescription(res[0].neighbourhoodDescription);
            res[0] !== undefined && res[0].published !== undefined && res[0].published === true && setPublisted(true);
        });
    }, [propertyId]);

    const onNextStep = async () => {
        if (name !== '' && description !== '' && neighbourhoodDescription !== '') {
            await updatePropertyDetails(propertyId, name, description, neighbourhoodDescription, tags);
            nav.navigate('estimate', { propertyId });
        }else if(name == ''){
            setErr('Please give your place a name');
        }else if(name.split(' ').length > 5){
            setErr('Name too long, maximum 5 words');
        }else if(description === ''){
            setErr('Please give your place a short description');
        }else if(neighbourhoodDescription === ''){
            setErr('Please give your neighbourhood a short description');
        }
        // else if(tags.length === 0){
        //     setErr('Please add at least one tag to your property');
        // }
    };

    return (
        <ListingBaseScreen
            step={6}
            nextScreenHandler={onNextStep}
            error={err}
            setErr={setErr}
            isPublished={published}
        >
            <ScrollView style={{paddingBottom:40}} showsVerticalScrollIndicator={false}>
                <Text style={[FontProperty.h1, {marginBottom:12}]}>Give your place a name</Text>
                <Text style={[FontProperty.body_1, FontProperty.TextGrey, styles.blurbContainer]}>
                    {ContentText.listPropertyName}
                </Text>
                <HousepoTextInput value={name} setText={setName} placeholder='Name...' />
                <Text style={[FontProperty.h1, {marginBottom:24,marginTop:18}]}>Now let’s describe your place</Text>
                <HouspoMessageInput 
                    value={description}
                    placeholder="Tell us more about this place..."
                    setText={setDescription}
                />
                <Text style={[FontProperty.h1, {marginBottom:24,marginTop:18}]}>About your neighbourhood</Text>
                <HouspoMessageInput 
                    value={neighbourhoodDescription}
                    placeholder="Tell us more about your neighbourhood..."
                    setText={setNeighbourhoodDescription}
                />
                <View style={{marginTop: 30}}></View>
                <IconText 
                    icon='bulb1'
                    title='Tips to improve your listing'
                    subtitle='Add property tags to your listing to improve search results. Tag ideas include Pool, View, Aspect,
                    Garden, Balcony, Private, Shed, Cellar etc.'
                />
                <Text style={[FontProperty.h1, {marginBottom:12,marginTop:18}]}>Add some tags</Text>
                <TagsInput value={tags} setValue={setTags} />
            </ScrollView>
        </ListingBaseScreen>
    );
};

const styles = StyleSheet.create({
    blurbContainer: {
        paddingBottom: 24
    }
});
