import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View, Image, Pressable } from 'react-native';
import IMAGE from '../../assets/images';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import { updateReadStatus } from '../../lib/api/messages/notifications';
import { getUserData } from '../../lib/api/user';
import { IuserData } from '../../lib/api/user/types';
import { INotification } from '../../types';

export type TProfileHeadshotByNotification = {
    data : INotification
    dataKey : string
    userId : string
    onClickFunction ? : () => void
    setRead : (arg0: boolean) => void
}

export const ProfileHeadshotByNotification : React.FC< TProfileHeadshotByNotification > = (props) => {
    const notificationData = props.data;
    const nav = useNavigation();
    const [userData, setUserData] = useState<IuserData>();
    const [text, setText] = useState('');


    useEffect(() => {
        getUserData(notificationData.userId).then(ref=>{
            if(ref.data()) {
                setUserData(ref.data());
            }
        });

        notificationData.followUser && setText(' just followed you. Check it out and expand your connection');
        notificationData.followProperty && setText(' just followed your property. View your property now');
        notificationData.commentProperty && notificationData.projectId !== '' && setText(' commented on your project. View your project now');
        notificationData.commentProperty && notificationData.propertyId !== ''  && setText(' commented on your property. View your property now');
        notificationData.followProject && setText(' just followed your project. View your project now');

    }, [notificationData.userId]);

    if(!userData){
        return null;
    }

    const notificationOnClick = () => {
        updateReadStatus(props.userId, props.dataKey, true);
        props.setRead(true);
        notificationData.followUser && nav.navigate({name: 'UserProfile', params: { userId: notificationData.userId, postId: '' }});
        (notificationData.followProject || (notificationData.commentProperty && notificationData.projectId !== '' ))  && nav.navigate({name: 'ProjectProfile', params: { projectId: notificationData.projectId}});
        (notificationData.followProperty || (notificationData.commentProperty && notificationData.propertyId !== '' )) && nav.navigate({name: 'PropertyProfile', params: { propertyId: notificationData.propertyId }});
    };

    return (
        <View style={[Layout.row, {alignItems: 'center' }]}>
            <Pressable style={styles.headshotContainer} onPress={() => {nav.navigate({name: 'UserProfile', params: { userId: notificationData.userId, postId: '' }});}}>
                <Image source={userData.headshot ? userData.headshot : IMAGE.userProfile} style={[styles.headshot]} />
            </Pressable>
            <Pressable style={{flex:1}} onPress={()=>notificationOnClick()}>
                <View style={[Layout.row, {justifyContent:'space-between',alignItems:'center'}]}>
                    <Text style={[{fontWeight:'400', color:Colors.grey1}]}><Text style={[FontProperty.h2, FontProperty.TextBlack, FontProperty.TextSemiBold]}>{userData.username}</Text>{text}</Text>
                </View>
            </Pressable>
        </View>
    );
};

const styles = StyleSheet.create({
    headshotContainer : {
        width: 45,
        height: 45,
        borderRadius: 45,
        overflow: 'hidden',
        marginRight: 13,
    },
    headshot : {
        width: 45,
        height: 45,
        resizeMode: 'cover',
        borderRadius: 45
    }
});

export default ProfileHeadshotByNotification;
