import React from 'react';
import { StyleSheet, Text, View, Image } from 'react-native';
import IMAGE from '../../assets/images';
import FontProperty from '../../constants/FontProperty';

export type TNoResult = {
    title : string,
    subtitle ? : string,
    removeGapbelow ? : boolean
}

const NoResult: React.FC<TNoResult> = (props) => {
    return (
        <View style={{alignItems:'center',justifyContent:'center',flex:1,marginTop:80, marginBottom:props.removeGapbelow ? 0 : 80}}>
            <Image source={IMAGE.noResult} style={{width:280,height:200,resizeMode:'contain'}}></Image>
            <Text style={[{marginTop:40,marginBottom:10,textAlign:'center'}, FontProperty.p1, FontProperty.TextBold]}>{props.title}</Text>
            {props.subtitle && <Text style={[FontProperty.TextGrey, FontProperty.smallP, {textAlign:'center'}]}>{props.subtitle}</Text>}
        </View>
    );
};

const styles = StyleSheet.create({
});

export default NoResult;

