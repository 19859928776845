import React, {useEffect, useState} from 'react';
import { StyleSheet, View, Text } from 'react-native';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import { getUserNotification, getUserReceiveMessage, updateUserNotification, updateUserReceiveMessage } from '../../lib/api/user/contants';
import WarningBar from '../atom/Warningbar';
import { TealButton } from '../common/button';
import HouspoSwitch from '../atom/HouspoSwitch';
import { userBlockNotification, userBlockReceiveMessage } from '../../lib/api/messages/messages';

const ProfileGeneral = (props: { userId: string; }) => {
    const [postMessageSucceed, setPostMessageSucceed] = useState(false);
    const [receiveMessage, setReceiveMessage] = useState(false);
    const [receiveNotification, setReceiveNotification] = useState(false);

    const updateNotification = async() =>{
        userBlockNotification(props.userId, !receiveNotification);
        userBlockReceiveMessage(props.userId, !receiveMessage);
        
        setPostMessageSucceed(true);
        setTimeout(() => {setPostMessageSucceed(false);}, 3000);
        await updateUserNotification(props.userId, receiveNotification);
        await updateUserReceiveMessage(props.userId, receiveMessage); 
    };

    useEffect(() => {
        getUserNotification(props.userId).then(res => {
            setReceiveNotification(res);
        });
        getUserReceiveMessage(props.userId).then(res => {
            setReceiveMessage(res);
        });
    },[props.userId]);


    return (
        <>
            <View style={{padding:24}}>
                <HouspoSwitch label='Receive message' switchValue={receiveMessage} setSwitchValue={setReceiveMessage} />
                <Text style={[FontProperty.TextGrey, FontProperty.smallP, {marginTop:16}]}>After closing, you won&apos;t receive any message from the other user</Text>
            </View>
            <View style={{padding:24}}>
                <HouspoSwitch label='Receive notifications' switchValue={receiveNotification} setSwitchValue={setReceiveNotification} />
                <Text style={[FontProperty.TextGrey, FontProperty.smallP, {marginTop:16}]}>After closing, you won&apos;t receive any engagement notification from the other user</Text>
            </View>
            <View style={[Layout.flexBottom,{paddingHorizontal:24}]}>
                <TealButton onPress={()=>updateNotification()} btnStyle={{width:'100%'}}>
                    <Text style={[FontProperty.TextSemiBold,FontProperty.p2,FontProperty.TextWhite]}>Update</Text>
                </TealButton>
            </View>
            {
                postMessageSucceed &&
                <WarningBar text='Updated!' icon='check-circle' backgroundcolor={Colors.teal} bottom={105}/>
            }
        </>
    );
};

const styles = StyleSheet.create({

});

export default ProfileGeneral;

