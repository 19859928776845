import React, {useEffect, useRef} from 'react';
import { Text, StyleSheet, ScrollView  } from 'react-native';
import Colors from '../../constants/colors';

import ScreenBase from '../../components/ScreenBase';
import ScreenHeader from '../../components/atom/ScreenHeader';

import Layout from '../../constants/Layout';
import Footer from '../../components/molecule/Footer';
import ProfileFaqs from '../../components/profileScreen/ProfileFaqs';
import FontProperty from '../../constants/FontProperty';
import { useFocusEffect } from '@react-navigation/native';

const FaqsScreen = () => {
    const ref = useRef();

    useFocusEffect(()=>{
        ref.current?.scrollTo({
            y: 0,
            animated: true,
        });
    });
    return (
        <ScreenBase>
            <ScreenHeader/>
            <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={Layout.scrollViewPadding} ref={ref}>
                <Text style={[styles.title, FontProperty.TextSemiBold, {paddingHorizontal: 24, paddingVertical: 40}]}>Frequently Asked Questions</Text>
                <ProfileFaqs />
                <Footer />
            </ScrollView>
        </ScreenBase>
    );
};

export default FaqsScreen;

const styles = StyleSheet.create({
    title: {
        fontSize: 30,
        lineHeight: 36,
        fontWeight: '600',
    },
});