import React, {useState} from 'react';
import { StyleSheet, Image, Pressable } from 'react-native';
import Layout from '../../constants/Layout';
import { useNavigation } from '@react-navigation/native';
import { FontAwesome } from '@expo/vector-icons';
import Colors from '../../constants/colors';
import { addUserFavourateImages, removeUserFavourateImages } from '../../lib/api/user/contants';

export type TFavouriteImageList = {
  imageUrl : string
  uid : string
}
const FavouriteImageList = (props:TFavouriteImageList) => {
    const [likes, setLikes] = useState(true);
    const [showGallery, setShowGallery] = useState(false);

    const progressData = (uid:string, imageUrl:string) => {
        if(likes){
            setLikes(false);
            removeUserFavourateImages(uid, imageUrl);
        } else {
            setLikes(true);
            addUserFavourateImages(uid, imageUrl);
        }
    };

    return (
        <Pressable onPress={()=>setShowGallery(!showGallery)}>
            <Image source={props.imageUrl} style={[styles.houseImage, 
                {
                    width: showGallery ? (Layout.window.width - 48) : (Layout.window.width - 78) / 2,
                    height: showGallery ? (Layout.window.width / 1.5) : Layout.window.width > 767 ? 244 : 144
                }]}/>
            <Pressable style={styles.like} onPress={()=>{progressData(props.uid, props.imageUrl);}}>
                {
                    likes 
                        ? <FontAwesome name='heart' size={15} color={Colors.teal} />
                        : <FontAwesome name='heart-o' size={15} color={Colors.black} />
                }
            </Pressable>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    houseImage: {
        resizeMode: 'cover',
        marginBottom: 16,
        borderRadius: 4,
    },
    like: {
        padding: 7,
        backgroundColor:'white',
        borderRadius:30,
        position: 'absolute',
        zIndex: 2,
        right: 8,
        top: 8
    }
});

export default FavouriteImageList;
