import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Pressable, Image} from 'react-native';
import Layout from '../../constants/Layout';
import { IpropertyImage } from '../../lib/api/Content/types';
import Carousel from 'react-native-reanimated-carousel';
import Colors from '../../constants/colors';
import { ICarouselInstance } from 'react-native-reanimated-carousel';
import { FontAwesome } from '@expo/vector-icons';
import { addUserFavourateImages, removeUserFavourateImages } from '../../lib/api/user/contants';
import { getUserData } from '../../lib/api/user';

export type TPopUpGalleryModal = {
  setPopUpGallery : () => void
  images : IpropertyImage[]
  index : number
  allowFavourate : boolean
  userId : string | false | undefined
  autoPlay ? : boolean
}

const PopUpGalleryModal = (props:TPopUpGalleryModal) => {
    const images = props.images;
    const width = Layout.window.width;
    const numOfImages = images.length;
    const dots = Array(numOfImages).fill('');
    const [active, setActive] = useState(props.index);
    const [userFavourateImages, setUserFavourateImages] = useState<string[]>([]);
    const ref = React.useRef<ICarouselInstance>(null);
    const [trigger, setTrigger] = useState(false);
    const [tempFavPic, setTemFavPic] = useState<string[]>(['']);

    useEffect(() => {
        props.userId &&
        getUserData(props.userId).then(mineData => {
            if(mineData.data().favouriteImages !== undefined){
                setUserFavourateImages(mineData.data()?.favouriteImages);
                setTemFavPic(mineData.data()?.favouriteImages);
            }
        });
    }, [props.userId, trigger]);

    const likethisPic = async (userId:string, picUrl: string)=> {
        if(userFavourateImages.includes(picUrl)){
            const index = tempFavPic.indexOf(picUrl);
            index > -1 && tempFavPic.splice(index, 1);
            setTrigger(!trigger);
            await removeUserFavourateImages(userId, picUrl);
        }else{
            tempFavPic.push(picUrl, '');
            setTrigger(!trigger);
            await addUserFavourateImages(userId, picUrl);
        }
    };
    

    return (
        <Pressable style={styles.popUpContainer} onPress={()=>props.setPopUpGallery()}>
            <View>
                <Carousel
                    loop
                    width={width}
                    height={Layout.window.width > 425 ? 520 : 320}
                    autoPlay={props.autoPlay !== undefined ? props.autoPlay : true}
                    autoPlayInterval={3000}
                    pagingEnabled={true}
                    ref={ref}
                    data={images}
                    scrollAnimationDuration={1000}
                    onSnapToItem={(index) => {setActive(index);}}
                    defaultIndex={active}
                    renderItem={({item, index }) => (
                        <Pressable style={{width:'100%', height:Layout.window.width > 425 ? 520 : 320}}>
                            <Image source={item.media_url} resizeMode='contain' style={{width:'100%',height:'100%'}}></Image>
                            {
                                props.allowFavourate &&
                                <Pressable style={styles.like} onPress={()=>{likethisPic(props.userId, item.media_url);}}>
                                    {
                                        tempFavPic.length > 0 && tempFavPic.includes(item.media_url) 
                                            ? <FontAwesome name='heart' size={15} color={Colors.teal} />
                                            : <FontAwesome name='heart-o' size={15} color={Colors.black} />
                                    }
                                    
                                </Pressable>
                            }
                        </Pressable>
                        
                    )}
                />
                <View style={[Layout.row, {position:'absolute', bottom:10,width:'100%',justifyContent:'center',paddingHorizontal: 10}]}>
                    { dots.map((dot, dotIndex:number )=>{
                        return (
                            <Pressable key={dotIndex} style={[styles.dot, active===dotIndex && styles.activeDot]} onPress={()=>{ref.current?.scrollTo({ count: dotIndex - active, animated: true });}}></Pressable>
                        );
                    }) }
                </View>
            </View>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    popUpContainer: {
        position:'absolute',
        zIndex: 3,
        width: '100%',
        height: Layout.window.height,
        backgroundColor: '#000000cc',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 24
    },
    dot: {
        width: 15,
        height: 15,
        borderRadius: 30,
        backgroundColor: 'white',
        marginHorizontal: 2.5,
        boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.8)',
    },
    activeDot: {
        backgroundColor: Colors.teal
    },
    like: {
        padding: 8,
        backgroundColor:'white',
        borderRadius:30,
        position: 'absolute',
        zIndex: 2,
        right: 15,
        top: 15
    }
});

export default PopUpGalleryModal;
