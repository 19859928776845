import React from 'react';
import { StyleSheet, Text, Pressable, Image } from 'react-native';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';

export type THouseTile = {
    cover : string,
    city : string
    title : string,
    onPress ? : ()=>void,
    uu_id : string,
    selected ? : boolean,
    property_status_colour ? : string,
    property_status_string ? : string
}

const HouseTile: React.FC<THouseTile> = (props) => {
    //const suburb = props.city;
    return (
        <Pressable key={props.uu_id} onPress={props.onPress} style={[styles.outContainer, Layout.mbottom25]}>
            <Image source={props.cover} style={[styles.houseImage, props.selected === true && styles.selectedImage]}  />
            {props.property_status_string != '' && <Text style={[styles.floatingPropertyStatusBox,{backgroundColor:props.property_status_colour}]}>{props.property_status_string}</Text>}
            <Text style={[FontProperty.TextGrey, FontProperty.p1, FontProperty.TextMedium, {marginBottom:4}, props.selected === true && FontProperty.TextTeal]}>{props.title}</Text>
            <Text style={[FontProperty.TextGrey, FontProperty.smallP, props.selected === true && FontProperty.TextTeal]}>{props.city}</Text>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    outContainer: {
        // width: (Layout.window.width - 68) / 2,
        flex: 1,
        paddingHorizontal: 10
    },
    houseImage: {
        // width: (Layout.window.width - 68) / 2,
        height: Layout.window.width > 767 ? 244 : 144,
        resizeMode: 'cover',
        marginBottom: 8,
        borderRadius: 4,
    },
    selectedImage: {
        borderWidth: 4,
        borderColor: '#00B7A1',
    },
    floatingPropertyStatusBox: {
        left: 15,
        top: 5,
        padding: 4,
        color: '#FFF',
        position: 'absolute'
    }
});

export default HouseTile;
