import React from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import IMAGE from '../../assets/images';
import ScreenBase from '../../components/ScreenBase';
import Colors from '../../constants/colors';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useNavigation } from '@react-navigation/core';
import SetNewPasswordForm from '../../components/forms/SetNewPasswordForm';
import { Ionicons } from '@expo/vector-icons';

export const SetNewPasswordScreen = () => {
    const nav = useNavigation();

    return (
        <ScreenBase>
            <Image source={IMAGE.AuthBanner} style={[Layout.banner]} />
            <View style={[Layout.screenContainer]}>
                <View style={{ backgroundColor: '#fff', paddingTop: 40 }}>
                    <TouchableOpacity style={[Layout.row, Layout.mbottom25]} onPress={() => nav.goBack()} >
                        <Ionicons name='chevron-back' color={Colors.grey3} size={20} />
                        <Text style={[FontProperty.p1, FontProperty.TextGrey]} >Go Back</Text>
                    </TouchableOpacity>
                    <View style={[styles.textContainer]}>
                        <Text style={[FontProperty.TextMedium, FontProperty.h4, Layout.mbottom8]}>Set New Password</Text>
                        <Text style={[FontProperty.p1, FontProperty.TextGrey, FontProperty.TextRegular, Layout.mbottom32]}>Input the verificaiton code that you receive in your password reset email.</Text>
                    </View>
                    <SetNewPasswordForm />

                </View>
            </View>
            <View style={[styles.buttonContainer]}>

                <TouchableOpacity onPress={() => { nav.navigate('RequestPasswordChange'); }} >
                    <Text style={styles.bottomText}>Haven't made a request? <Text style={styles.bottomTextHyper} >Password Change Request</Text></Text>
                </TouchableOpacity>


            </View>
        </ScreenBase>
    );
};

const styles = StyleSheet.create({
    textContainer: {
        paddingBottom: 10
    },
    bottomText: {
        textAlign: 'center',
        marginTop: 45,
        color: Colors.grey4
    },

    bottomTextHyper: {
        color: Colors.teal
    },
    buttonContainer: {
        paddingBottom: 20
    }
}
);
