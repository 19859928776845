import React from 'react';
import { StyleSheet, Text, View, Image, Pressable} from 'react-native';
import FontProperty from '../../constants/FontProperty';
import { IpropertyData } from '../../lib/api/Content/types';
import Colors from '../../constants/colors';
import IMAGE from '../../assets/images';
import { useNavigation } from '@react-navigation/native';


export type TpropertyData = {
    data : IpropertyData,
    from : string
}

const PropertyCard : React.FC< TpropertyData  > = (props) => {
    const nav = useNavigation();
    return (
        <Pressable onPress={()=>{
            nav.navigate('PropertyProfile', {
                propertyId: props.data.uuid
            });}}>
            <View style={{paddingVertical:5,paddingRight:6,width:176,flex:1}}>
                <View style={styles.card}>
                    {/* <Image source={props.data.images ? props.data.images[0].viewUrl : IMAGE.mock} resizeMode='cover' width={170} height={91} /> */}
                    <Image source={props.data.images[0].viewUrl} resizeMode='cover' style={{width:170,height:91}} />
                    <View style={{padding:10,justifyContent:'space-between',flex:1}}>
                        <Text style={[FontProperty.smallP, FontProperty.TextBold, {marginBottom:4}]}>{props.data.propertyName}</Text>
                        <Text style={[FontProperty.smallP, {marginBottom:16,flex:1}]} numberOfLines={2} ellipsizeMode='tail'>{props.data.description}</Text>
                        <Text style={[styles.btn, FontProperty.TextTeal, FontProperty.TextSemiBold]}>View</Text>
                    </View>
                </View>
            </View>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    card: {
        boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.08)',
        height: '100%',
        borderRadius: 4,
        flex:1,
        overflow: 'hidden'
    },
    btn: {
        borderColor: Colors.teal,
        borderWidth: 1,
        borderRadius: 4,
        textAlign: 'center',
        paddingVertical: 4.5
    }
});

export default PropertyCard;
