import { AntDesign } from '@expo/vector-icons';
import React from 'react';
import { StyleSheet, View, Text} from 'react-native';
import IMAGE from '../../assets/images';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';

export type TIconImageGrid = {
    icon : string,
    title : string,
    subtitle : string
}

const IconText: React.FC<TIconImageGrid> = (props) => {
    return (
        <View style={[Layout.row,{padding:24,backgroundColor:Colors.lightBeige,alignItems:'flex-start'}]}>
            <View style={styles.icon}>
                <AntDesign name={props.icon} size={18} />
            </View>
            <View style={{flexDirection:'column',flex:1}}>
                <Text style={[FontProperty.smallP,FontProperty.TextSemiBold,{marginBottom:6}]}>{props.title}</Text>
                <Text style={[FontProperty.smallP]}>{props.subtitle}</Text>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    icon: {
        width:38,
        height:38,
        borderRadius: 40,
        backgroundColor: Colors.white,
        marginRight: 24,
        justifyContent:'center',
        alignItems:'center'
    },
});

export default IconText;

