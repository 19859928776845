/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { NavigationContainer, DefaultTheme} from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';

import { RootStackParamList } from '../types';
import LinkingConfiguration from './LinkingConfiguration';
import userStore from '../store/userStore';

import { observer } from 'mobx-react';
import { hydrateAll } from '../store/rootStore';

import { useAuthentication } from '../hooks/useAuthentication';
import LoadingScreen from '../screens/Global/LoadingScreen.web';
import { getUserData } from '../lib/api/user';
import { AuthenticatedDrawerNavigation } from './AuthenticatedDrawer';


export default function Navigation () {
    return (
        <NavigationContainer
            linking={LinkingConfiguration}
            theme={DefaultTheme} >
            <RootNavigation />
        </NavigationContainer>
    );
}

const RootStack = createNativeStackNavigator<RootStackParamList>();
const RootNavigation = observer(() => {
    const { user } = useAuthentication();
    const [hydrated, setHydrated] = React.useState(false);
    const [initial, setInitial] = React.useState('');

    React.useEffect(() => {
        hydrateAll().then(() => {
            setHydrated(true);
        });

        const landingLocation = window.location.href;
        if(!landingLocation.includes('Loading')){
            const originRoot = landingLocation.split('/');
            originRoot[originRoot.length-1] === '' ? setInitial(originRoot[originRoot.length-2]) :  setInitial(originRoot[originRoot.length-1]);
        }
    }, []);

    React.useEffect(() => {
        if (user) {
            getUserData(user.uid).then(userData => {
                userStore.setField('name', userData.data()?.username);
            });
        }
    }, [user]);


    return (
        <RootStack.Navigator
            screenOptions={{ headerShown: false }}
        >
            <RootStack.Screen name="Platform" component={() => { return (<AuthenticatedDrawerNavigation initialRoute={initial}/>); }} />
            {/* {
                user === undefined || !hydrated
                    ? <RootStack.Screen name="Loading" component={LoadingScreen} />
                    : <RootStack.Screen name="Platform" component={() => { return (<AuthenticatedDrawerNavigation initialRoute={initial}/>); }} />
            } */}
        </RootStack.Navigator>
    );
});
