import React from 'react';
import { child, get, onValue, push, ref, update, remove, query} from 'firebase/database';
import { rtdb } from '../../../config/firebase';
import { includes } from 'lodash';

export const getMessages = async (conversationId: string, setState: React.Dispatch<React.SetStateAction<any[]>>) => {
    try {
        await onValue(ref(rtdb, `conversationMessages/${conversationId}`), (snapshot) => {
            const data = [];
            snapshot.forEach((child) => {
                data.push(child.val());
            });
            setState(data);
        }
        );
    } catch (error) {
        console.log('ERROR : ', error);
    }
};

export const sendMessageHouspo = async (senderId: string, receiverId: string, message: string) => {
    // Sender -> Receiver
    const chatUID = `${senderId}_${receiverId}`;
    const chatUIDReverse = `${receiverId}_${senderId}`;
    
    try {
        await update(ref(rtdb, `conversations/${chatUID}`), {
            lastMessage: {
                sender: senderId,
                receiver: receiverId,
                message,
                createdByTimestamp: Date.now(),
                read: true
            }
        });

        await push(ref(rtdb, `conversationMessages/${chatUID}`), {
            sender: senderId,
            receiver: receiverId,
            message,
            createdByTimestamp: Date.now()
        });

        await update(ref(rtdb, `userConversations/${senderId}`), {
            [receiverId]: {uid:receiverId, createdAt: Date.now(), read: true}
        });

        await onValue(ref(rtdb, `userBlockReceiveMessage/${receiverId}`), (snapshot) => {
            snapshot.val() === null &&
            update(ref(rtdb, `conversations/${chatUIDReverse}`), {
                lastMessage: {
                    sender: senderId,
                    receiver: receiverId,
                    message,
                    createdByTimestamp: Date.now(),
                    read: false
                }
            });
            snapshot.val() === null &&
            push(ref(rtdb, `conversationMessages/${chatUIDReverse}`), {
                sender: senderId,
                receiver: receiverId,
                message,
                createdByTimestamp: Date.now()
            });
            snapshot.val() === null &&
            update(ref(rtdb, `userConversations/${receiverId}`), {
                [senderId]:  {uid:senderId, createdAt: Date.now(), read: false}
            });
        });
        
    } catch (error) {
        console.log('ERROR : ', error);
    }
};

export const getUserConversationsHouspo = async (currentUserId: string, setState: React.Dispatch<React.SetStateAction<any[]>>) => {
    try {
        const userConversationsRef = await ref(rtdb, `userConversations/${currentUserId}`);
        onValue(userConversationsRef, (snapshot) => {
            const unreadMessages = [];
            const readMessages = [];
            snapshot.forEach((child) => {
                child.val().read && readMessages.push(child.val());
                !child.val().read && unreadMessages.push(child.val());
            });
            const readIds = readMessages.sort((a,b) => b.createdAt - a.createdAt);
            const unReadIds = unreadMessages.sort((a,b) => b.createdAt - a.createdAt);
            setState([...unReadIds, ...readIds]);
        });
    } catch (error) {
        console.log('ERROR : ', error);
    }
};

export const getConversationsHouspo = async (conversationsIds: string[], myId:string, setState: React.Dispatch<React.SetStateAction<any[]>>) => {
    try {
        for (const conversationid of conversationsIds) {
            const targetId = myId + '_' + conversationid.uid;
            const conversationsRef = await ref(rtdb, `conversations/${targetId}`);
            onValue(conversationsRef, (snapshot) => {
                setState(prevState => {
                    const converstationState = prevState.filter(conversation => (conversation.id !== myId + '_' + conversationid.uid));
                    return [
                        ...converstationState,
                        snapshot.val() !== null ?
                            {
                                id: myId + '_' + conversationid.uid,
                                ...snapshot.val()
                            } : {
                            
                            }
                    ];
                });
            });
        }

    } catch (error) {
        console.log('ERROR : ', error);
    }
};

export const getUserNotificationHouspo = async (currentUserId: string, setState: React.Dispatch<React.SetStateAction<any[]>>) => {
    try {
        await onValue(ref(rtdb, `notifications/${currentUserId}`), (snapshot) => {
            const data = [];
            snapshot.forEach((child) => {
                data.push([child.val(), child.key]);
            });
            data.reverse();
            setState(data);
        }
        );
    } catch (error) {
        console.log('ERROR : ', error);
    }
};

export const updateReadStatus = async (chatUID:string, status:boolean) => {
    const conversationsRef = ref(rtdb);
    const conversationData = await get(child(conversationsRef, `conversations/${chatUID}`));
    try {
        await update(ref(rtdb, `conversations/${chatUID}`), 
            {
                lastMessage: {
                    sender: conversationData.val().lastMessage.sender,
                    receiver: conversationData.val().lastMessage.receiver,
                    message: conversationData.val().lastMessage.message,
                    createdByTimestamp: conversationData.val().lastMessage.createdByTimestamp,
                    read: status
                }
            },);

        await update(ref(rtdb, `userConversations/${chatUID.split('_')[0]}`), {
            [chatUID.split('_')[1]]: {uid:chatUID.split('_')[1], createdAt: conversationData.val().lastMessage.createdByTimestamp, read: status}
        });
    } catch (error) {
        console.log('ERROR : ', error);
    }
};

export const deleteMessage = async (senderId: string, receiverId: string) => {
    const chatUID = `${senderId}_${receiverId}`;
    try {
        await remove(ref(rtdb, `conversations/${chatUID}`));
        await remove(ref(rtdb, `conversationMessages/${chatUID}`));
        await remove(ref(rtdb, `userConversations/${senderId}/${receiverId}`));
        
    } catch (error) {
        console.log('ERROR : ', error);
    }
};

export const haveUnreadMessages = async (userId:string, setState: React.Dispatch<React.SetStateAction<boolean>>) => {
    try {
        await onValue(ref(rtdb, `userConversations/${userId}`), (snapshot) => {
            let haveUnread = false;
            snapshot.forEach((child) => {
                !child.val().read && (haveUnread = true);
            });
            setState(haveUnread);
        }
        );

    } catch (error) {
        console.log('ERROR : ', error);
    }
};

export const userBlockNotification = async (userId:string, block: boolean) => {
    try {
        block
            ? await update(ref(rtdb, `userBlockNotification/${userId}`),{block:block})
            : await remove(ref(rtdb, `userBlockNotification/${userId}`));
    } catch (error) {
        console.log('ERROR : ', error);
    }
};

export const userBlockReceiveMessage = async (userId:string, block: boolean) => {
    try {
        block
            ? await update(ref(rtdb, `userBlockReceiveMessage/${userId}`),{block:block})
            : await remove(ref(rtdb, `userBlockReceiveMessage/${userId}`));
    } catch (error) {
        console.log('ERROR : ', error);
    }
};

export const userAllowReceiveMessage = async (userId:string, setState: React.Dispatch<React.SetStateAction<boolean | undefined>>) => {
    try {
        await onValue(ref(rtdb, `userBlockReceiveMessage/${userId}`), (snapshot) => {
            setState( snapshot.val() === null ? true : false);
        }
        );
    } catch (error) {
        console.log('ERROR : ', error);
    }
};

export const userAllowReceiveNotification = async (userId:string, setState: React.Dispatch<React.SetStateAction<boolean | undefined>>) => {
    try {
        await onValue(ref(rtdb, `userBlockNotification/${userId}`), (snapshot) => {
            setState( snapshot.val() === null ? true : false);
        }
        );
    } catch (error) {
        console.log('ERROR : ', error);
    }
};