import { collection, doc, DocumentData, DocumentSnapshot, getDoc, getDocs, deleteDoc, limit, orderBy, query, updateDoc, where, setDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { date } from 'yup';
import { db } from '../../../config/firebase';
import constants from './constants';
import { IpropertyData, IpropertyFeature, IpropertyLabel, IpropertyType, IpropertyStyle, IprojectData } from './types';


export const getProjectsTypes = async (): Promise<IpropertyType[]> => {
    try {
        const q = query(collection(db, constants.projectTypes), orderBy('weight', 'asc'));
        const querySnapshot = await getDocs(q);
        return querySnapshot?.docs.map(
            doc => {
                return {
                    ...doc.data(),
                    uuid: doc.id
                };
            }) as IpropertyType[];
    } catch (e) {
        throw Error(e.message);
    }
};

export const getProjectContent = async (propertyID: string) => {
    try {
        const q = query(collection(db, constants.projects), where('uuid', '==', propertyID));
        const querySnapshot = await getDocs(q);
        return querySnapshot?.docs.map(
            doc => {
                return {
                    ...doc.data()
                };
            }) as IpropertyData[];
    } catch (e) {
        throw Error(e.message);
    }
};

export const updateProjectType= async (uuid : string, type: string) => {
    try {
        const userDataRef = doc(collection(db, constants.projects), uuid);
        await setDoc(userDataRef, 
            { type: type, uuid: uuid },
            { merge: true }
        );
    } catch (err) {
        throw new Error(err);
    }
};

export const updateProjectData = async (projectId : string, project : IprojectData) => {
    try {
        const projectDataRef = doc(db, constants.properties, projectId);
        const projectDataDoc = await updateDoc(projectDataRef, project);
        console.log('UPDATE : ', projectDataDoc);
    } catch (e) {
        throw Error(e.message);
    }
};

export const updateProjectDetails= async (uuid : string, name: string, desc: string, tags: string[]) => {
    try {
        const userDataRef = doc(collection(db, 'projects'), uuid);
        await setDoc(userDataRef, 
            { projectName: name, description: desc, tags: tags },
            { merge: true }
        );
    } catch (err) {
        throw new Error(err);
    }
};

export const updateProjectPublish= async (uuid : string, publishStatus: boolean) => {
    try {
        const userDataRef = doc(collection(db, 'projects'), uuid);
        await setDoc(userDataRef, 
            { published: publishStatus },
            { merge: true }
        );
    } catch (err) {
        throw new Error(err);
    }
};

export const updateProjectImagesTemp= async (uuid : string, images: string[]) => {
    try {
        const userDataRef = doc(collection(db, 'projects'), uuid);
        await setDoc(userDataRef, 
            { images: images },
            { merge: true }
        );
    } catch (err) {
        throw new Error(err);
    }
};

export const addProjectIntoProperty= async (propertyId : string, projectId: string) => {
    try {
        const userDataRef = doc(collection(db, 'properties'), propertyId);
        await setDoc(userDataRef, 
            { projects: arrayUnion(projectId) },
            { merge: true }
        );
    } catch (err) {
        throw new Error(err);
    }
};

export const getProjectTypeName = async (typeID: string) => {
    try {
        const q = query(collection(db, constants.projectTypes), where('uuid', '==', typeID));
        const querySnapshot = await getDocs(q);
        return querySnapshot?.docs.map(
            doc => {
                return {
                    ...doc.data()
                };
            });
    } catch (e) {
        throw Error(e.message);
    }
};

export const setFollowProject = async (projectId : string, userId : string, followState : boolean) : Promise<void> => {
    try {
        const followRef = doc(db, 'followProjects', `${projectId}_${userId}`);
        if(followState){
            await setDoc(followRef,
                { state: followState, terms: [projectId + '_' , '_' + userId], createdAt: new Date(), createdAtTimestamp: Date.now() },
                { merge: true }
            );
        }else{
            await deleteDoc(followRef);
        }
    } catch (error) {
        console.log('createFollow', error);
        throw new Error(error);
    }
};

export const deleteProject = async (projectyID: string) => {
    try {
        const q = query(collection(db, 'followProjects'), 
            where('terms', 'array-contains',  projectyID + '_')
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.docs.forEach(element => {
            deleteDoc(doc(db, 'followProjects', element.id));
        });
        await deleteDoc(doc(db, constants.projects, projectyID));
    } catch (e) {
        throw Error(e.message);
    }
};


export const deleteProjectFromProperty = async (projectyID: string, propertyId: string) => {
    try {
        const propertyRef = doc(collection(db, 'properties'), propertyId);
        await setDoc(propertyRef, 
            { projects: arrayRemove(projectyID) },
            { merge: true }
        );
    } catch (err) {
        throw new Error(err);
    }
};

function findCommonElementsByUUID(arr1:any[], arr2:any[]) {
    return arr1.some(item => arr2.includes(item.uuid));    
}
function findCommonElements(arr1:any[], arr2:any[]) {
    return arr1.every(item => arr2.find(element => element.includes(item)));   
}
export const filterAllProjects = async (filterObj:any[]) => {
    try {
        const q = query(collection(db, 'projects'), 
            where('published', '==', true), 
            orderBy('createdAt', 'desc')
        );
        const querySnapshot = await getDocs(q);
        const finalArray = [];
        querySnapshot.docs.forEach(element => {
            let showThisProperty = true;
            const targetProject = element.data();
            
            // 1. Type
            // if(showThisProperty && filterObj[0] !== '' && targetProject.type && targetProject.type !== ''){
            //     const projectType = targetProject.type;
            //     const filterType = filterObj[0].split('||').slice(0, -1);
            //     // (!findCommonElementsByUUID(projectType, filterType)) && (showThisProperty = false);
            // }
            showThisProperty 
                && filterObj[0] !== '' 
                && targetProject.type 
                && targetProject.type !== '' 
                && !filterObj[0].includes(targetProject.type) 
                && (showThisProperty = false);

            // 2. Keywords
            if(showThisProperty && filterObj[1] !== '') {
                showThisProperty = false;
                targetProject.projectName && targetProject.projectName.toLowerCase().includes(filterObj[1].toLowerCase()) && (showThisProperty = true);

                if(showThisProperty === false && targetProject.tags && targetProject.tags.length > 0){
                    const projectTags = targetProject.tags.join(' ').split(' ').map(name => name.toLowerCase());
                    const filterTag = filterObj[1].toLowerCase();
                    (projectTags.includes(filterTag)) && (showThisProperty = true);
                }
            }

            // 3. Address
            if(showThisProperty && showThisProperty && filterObj[2] !== '' && targetProject.address && targetProject.address['0'] !== ''){
                const filteredPropertyAddress = filterObj[2].split(' ').filter(Boolean);
                const filteredFilterAddress = [targetProject.address[1].trim(), targetProject.address[2].trim(), targetProject.address[3].trim()];
                const filteredFilterAddressRe = filteredFilterAddress.join(' ').split(' ');

                filteredPropertyAddress.length === 1 
                    ? (!findCommonElements(filteredPropertyAddress, filteredFilterAddressRe)) && (showThisProperty = false)
                    : (!findCommonElements(filteredFilterAddressRe, filteredPropertyAddress)) && (showThisProperty = false);
            }

            if(showThisProperty){
                finalArray.push(targetProject);
            }
        });
        return finalArray as IprojectData[];
    } catch (e) {
        throw Error(e.message);
    }
};

export const getMyFollowedProjects = async (userId: string) => {
    try {
        const followRef = query(collection(db, 'followProjects'), where('terms', 'array-contains', '_' +  userId), orderBy('createdAt', 'desc'));
        const followData = await getDocs(followRef);
        return followData.docs.map(followdt => ({
            uuid: followdt.id,
            ...followdt.data()
        }));
    } catch (error) {
        console.log('getMyFollowedProjectsError', error);
        throw new Error(error);
    }
};


export const getMostPopularProjectSevenDays = async () => {
    return false;
    const sevenDay = Date.now() - 604800000;
    const projectMap = new Map();

    try {
        const q = query(collection(db, 'followProjects'), where('createdAtTimestamp', '>=', sevenDay));
        const querySnapshot = await getDocs(q);
        querySnapshot.docs.forEach(element => {
            const projectId = element.id.split('_')[0];
            projectMap.has(projectId)
                ? projectMap.set(projectId, projectMap.get(projectId) + 1)
                : projectMap.set(projectId, 1);
        });

        return projectMap;
    } catch (e) {
        throw Error(e.message);
    }
};

// export const getMostPopularProjectOneMonth = async () => {
//     const oneMonth = Date.now() - 86400000 * 30;
//     const projectMap = new Map();
//     try {
//         const q = query(collection(db, 'followProjects'), where('createdAtTimestamp', '>=', oneMonth));
//         const querySnapshot = await getDocs(q);
//         querySnapshot.docs.forEach(element => {
//             const projectId = element.id.split('_')[0];
//             projectMap.has(projectId)
//                 ? projectMap.set(projectId, projectMap.get(projectId) + 1)
//                 : projectMap.set(projectId, 1);
//         });

//         return projectMap;
//     } catch (e) {
//         throw Error(e.message);
//     }
// };


export const getFollowProjectsState = async (propertyId: string, userId: string) : Promise<boolean> => {
    try {
        const followRef = doc(db, 'followProjects', `${propertyId}_${userId}`);
        const followData = await getDoc(followRef);
        return followData.exists() && followData.data().state;
    } catch (error) {
        console.log('getFollowProjectStateError', error);
        throw new Error(error);
    }
};