import React, {useEffect, useState} from 'react';
import { StyleSheet, ScrollView, Linking } from 'react-native';
import Layout from '../../constants/Layout';
import { getTC } from '../../lib/api/Content/content';
import RenderHtml, { MixedStyleDeclaration } from 'react-native-render-html';
import Colors from '../../constants/colors';

const ProfileTC = () => {
    const [ppContent, setPPContent] = useState<string>();

    useEffect(() => {
        getTC().then(res => {
            setPPContent(res);
        });
    },[]);

    if(!ppContent){
        return null;
    }
    const renderersProps = {
        a: {
            onPress(event, url, htmlAttribs, target) {
                Linking.openURL(url);
            }
        }
    };
    
    return (
        <ScrollView style={[Layout.globalPadding24, {paddingBottom:100}]} showsVerticalScrollIndicator={false}>
            <RenderHtml
                contentWidth={Layout.window.width-48}
                source={{ html: ppContent }}
                tagsStyles={tagsStyles}
                renderersProps={renderersProps}
                defaultTextProps={{selectable:true}}
            />
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    
});

const tagsStyles: Record<string, MixedStyleDeclaration> = {
    body: {
        fontFamily: 'inter-regular',
        fontSize: 14,
        lineHeight: 20,
        margin: 0
    },
    p: {
        margin: 0,
        marginBottom: 16
    },
    ul: {
        margin: 0,
        marginBottom: 16,
        paddingHorizontal: 20
    },
    li: {
        margin: 0,
        marginBottom: 16,
        paddingHorizontal: 20
    },
    h2: {
        fontFamily: 'inter-semibold',
        marginBottom: 16,
        marginTop: 16,
        fontSize: 14,
        lineHeight: 20,
    },
    a: {
        color: Colors.teal,
    },
    span: {
        //display:'flex',
        flex: 1,
        paddingLeft: 20,
        width: '100%'
    }

};


export default ProfileTC;

