import React from 'react';
import { StyleSheet } from 'react-native';
import { INotification } from '../../types';
import NotificationItem from '../atom/NotificationItem';

export type TAllNotifications = {
    data : INotification[]
    userId : string
}

const AllNotifications = (props:TAllNotifications) => {
    const allMessages = props.data;

    return (
        allMessages.map((notification:INotification, index:number)=>{
            return (notification !== undefined && 
            <NotificationItem 
                data={notification}
                key={index}
                userId={props.userId}
            />);
        })
    );
};

const styles = StyleSheet.create({
});

export default AllNotifications;
