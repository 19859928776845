import React, { SetStateAction, Dispatch, useState, useEffect } from 'react';
import {  StyleSheet, View, TextInput, Pressable, Text, ScrollView } from 'react-native';
// import * as statePostcode from '../../lib/utils/ausPostcodeState.json';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import { AntDesign, FontAwesome, Ionicons } from '@expo/vector-icons';

export type THouspoSuburbs = {
  open : boolean
  value : string
  setOpen : Dispatch<SetStateAction<boolean>>
  setValue : Dispatch<SetStateAction<string>>
  targetState : string
  noResultMessage : string
  multiple : boolean
  finalArr : string[]
  setFinalArr : Dispatch<SetStateAction<string[]>>
  noborder ? : boolean
}

const HouspoSuburbsMultiSearch : React.FC< THouspoSuburbs > = (props) => {
    const statePostcode = require('../../lib/utils/ausPostcodeState.json');
    const [text, onChangeText] = useState(props.multiple ? '' : props.value);
    const [matchingItems, setMatchingItems] = useState([]);
    // const [multiItems, setMultiItems] = useState<string[]>((props.multiple && props.value !== '') ? props.value.split('||') : []);
    const [trigger, setTrigger] = useState(false);
    const suburbItems = [
        {label: 'All Suburb', value: ''},
    ];
    
    statePostcode && 
    statePostcode.forEach(element => {
        (element.state_code == props.targetState || props.targetState === '') && suburbItems.push({label: element.place_name + ' ' +  element.state_code + ' ' + element.postcode , value: element.place_name + ' ' +  element.postcode});
    });

    const finalArray = props.finalArr;
    const tempMatchItems = [];
    useEffect(() => {
        text !== '' && isNaN(parseInt(text)) &&
        suburbItems.forEach(element => {
            element.label.toLowerCase().startsWith(text.toLowerCase()) && 
            tempMatchItems.push({label: element.label , value: element.value});
            
            setMatchingItems(tempMatchItems);
        });

        text !== '' && !isNaN(parseInt(text)) &&
        suburbItems.forEach(element => {
            element.label.toLowerCase().includes(text.toLowerCase()) && 
            tempMatchItems.push({label: element.label , value: element.value});
            
            setMatchingItems(tempMatchItems);
        });
    }, [text]);

    const clickMatched = (label:string, value:string) => {
        props.multiple ? appendToList(label) : resetList(label);
        props.setOpen(false);
    };

    const appendToList = (label:string) => {
        onChangeText('');
        if(label !== ''){
            finalArray.push(label);
            props.setValue(finalArray.join('|'));
            props.setFinalArr(finalArray);
        }
    };

    const resetList = (label:string) => {
        onChangeText(label);
        props.setValue(label);
    };

    const removeItem = (label:string) => {
        const index = finalArray.indexOf(label);
        index > -1 && finalArray.splice(index, 1);
        props.setFinalArr && finalArray && props.setFinalArr(finalArray);
        props.setValue(finalArray.join('|'));
        setTrigger(!trigger);
    };
    
    return (
        <View>
            <View style={{zIndex: 2}}>
                <View style={[styles.container]}>
                    <TextInput
                        autoCapitalize='none'
                        style={[styles.input]}
                        placeholder='Search postcode or suburb...'
                        placeholderTextColor={Colors.grey3}
                        onChangeText={(text)=>{
                            onChangeText(text);
                            props.setOpen(true);
                            !props.multiple && props.setValue(text);
                        }}
                        value={text}
                    /> 
                    {
                        text !== '' &&
                        <Ionicons name="close-circle" size={13} color="#BDBDBD" style={[styles.closeSearch]} 
                            onPress={()=>{clickMatched('', ''); }}/>
                    }
                </View>
                {
                    text !== '' && text.length > 2 && props.open && 
                    <View style={styles.popupContainer}>
                        <ScrollView style={{maxHeight:226,paddingBottom:16}}>
                            {
                                matchingItems.length > 0 &&
                                matchingItems.map((item, index) => (
                                    <Pressable key={index} onPress={()=>clickMatched(item.label, item.value)} style={{paddingTop:16,flexDirection:'row',alignItems:'center'}}>
                                        <FontAwesome name="map-marker" color={Colors.grey3}/>
                                        <Text style={[FontProperty.smallP, FontProperty.TextGrey, {marginLeft:10}]}>{item.label}</Text>
                                    </Pressable>
                                ))
                            }
                            {
                                matchingItems.length === 0 &&
                                <Text style={[FontProperty.smallP, FontProperty.TextGrey, {paddingTop:16}]}>{props.noResultMessage}</Text>
                            }
                        </ScrollView>
                    </View>
                }
            </View>
            {
                finalArray && props.multiple && finalArray.length > 0 && 
                <View style={{flexDirection:'row',flexWrap:'wrap'}}>
                    {
                        finalArray.length > 0 &&
                        finalArray.map((item:string,index:number)=>{
                            return (
                                <View key={index} style={styles.tag}>
                                    <Text style={[FontProperty.TextBeige, FontProperty.miniP, FontProperty.TextSemiBold, {marginRight:8}]}>{item}</Text>
                                    <Pressable onPress={()=>removeItem(item)}><AntDesign name='closecircle' size={12} color={Colors.darkBeige}/></Pressable>
                                </View>
                            );
                        })
                    }
                </View>
            }
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderWidth: 1,
        borderColor:  Colors.white,
        borderRadius: 4,
        backgroundColor: Colors.white,
        alignContent: 'center',
        alignItems:'center',
        paddingVertical: 14.5
    },
    input: {
        padding: 0,
        color: Colors.grey1,
        fontSize: 14,
        flex : 1,
        outlineStyle: 'none' 
    },
    popupContainer: {
        position: 'absolute',
        top: 47,
        width: '100%',
        backgroundColor: Colors.white,
        borderWidth: 1,
        borderTopWidth: 0,
        borderColor: Colors.white,
        paddingHorizontal: 16,
        boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.2)',
    },
    closeSearch: {
        position: 'absolute',
        right: 6
    },
    tag: {
        backgroundColor: Colors.lightBeige,
        paddingHorizontal: 12,
        paddingVertical: 2,
        borderRadius: 4,
        marginRight: 8,
        marginBottom: 8,
        flexDirection: 'row',
        alignItems: 'center'
    }
});

export default HouspoSuburbsMultiSearch;
