export const addSecondsToDate = (date: Date, seconds: number): string => {
    const newDate = new Date(date.getTime() + seconds * 1000).toISOString();
    return newDate;
};

export const nowOlderThanDate = (ISOdate: string) : boolean => {
    const now = new Date();
    const date = new Date(ISOdate);
    return now > date;
};

export const firebaseCreatedAtToDate = (createdDate: Date) => {
    const weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][new Date(createdDate).getDay()];
    const formatAMPM = (date:any) => {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0'+minutes : minutes;
        const strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    };

    const formatDate = (date:any) => {
        const day = date.getDate();
        const month = date.getMonth()+1;
        const year = date.getFullYear();
        const strTime = day + '-' + month + '-' + year;
        return strTime;
    };

    const isDateInThisWeek = (date: Date) => {
        const todayObj = new Date();
        const dayIndex = todayObj.getDay();
        const firstDayOfWeek = todayObj.setHours(0, 0, 0, 0) - 86400000 * (dayIndex);
        const lastDayOfWeek = todayObj.setHours(0, 0, 0, 0) + 86400000 * (7 - dayIndex);
        const createdDate = (new Date(date)).getTime();
        return createdDate >= firstDayOfWeek && createdDate <= lastDayOfWeek;
    };

  
    const isInWeek = isDateInThisWeek(createdDate);
    let createdDateFormatted = formatDate(createdDate);
    isInWeek && (createdDateFormatted = weekday + ', ' + formatAMPM(createdDate));
    return createdDateFormatted;
};

export const timeSince = (date: number) => {
    const seconds = Math.floor((Date.now() - date) / 1000);
    let interval = seconds / 31536000;
  
    if (interval > 1) {
        return Math.floor(interval) + 'y';
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + 'm';
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + 'd';
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + 'h';
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + 'm';
    }
    return Math.floor(seconds) + 's';
};