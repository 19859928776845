import { IpropertyType } from '../../lib/api/Content/types';

//@todo - add icons
export const getHomeTypeName = (propertyType:number) => {
    let name;
    switch(propertyType) {
        case 1:
            name = "House"
            break;
        case 2:
            name =  "Townhouse"
            break;
        case 3:
            name =  "Apartment"
            break;
        case 4:
            name =  "Other"
            break;
        default:
            name =  "N/A"
    }     

    return {
        type: name, 
        id: propertyType,
        icon:'', 
        iconActive: ''
    } as IpropertyType;
}