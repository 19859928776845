import React, {useEffect, useState} from 'react';
import { StyleSheet, TextInput, View, Text} from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ListingBaseScreen } from './ListingBaseScreen';
import Layout from '../../constants/Layout';
import HouspoSwitch from '../../components/atom/HouspoSwitch';
import { AddListingTypeBasicProps } from '../../types';
import { useProperty } from '../../hooks/useProperty';
import Colors from '../../constants/colors';
import { Foundation } from '@expo/vector-icons';
import { updatePropertyPrice } from '../../lib/api/Content/property';
import { getPropertyContent } from '../../lib/api/Content/property';
import { numberWithCommas } from '../../lib/utils/numFormatter';
import FontProperty from '../../constants/FontProperty';


export const AddEstimateScreen: React.FC = (props) => {
    const nav = useNavigation<AddListingTypeBasicProps>();
    const { propertyId } = useRoute<AddListingTypeBasicProps>().params;
    const { property, updateProperty } = useProperty(propertyId);
    const [doDisplay, setDoDisplay] = useState(false);
    const [low, setLow] = useState<string>('');
    const [high, setHigh] = useState<string>('');
    const [err, setErr] = useState<string>('');
    const [published, setPublisted] = useState(false);

    useEffect(() => {
        getPropertyContent(propertyId).then(res=> {
            res[0].estimateLowPrice !== undefined && setLow(res[0].estimateLowPrice.toString());
            res[0].estimateHighPrice !== undefined && setHigh(res[0].estimateHighPrice.toString());
            res[0].showEstimatePrice !== undefined && setDoDisplay(res[0].showEstimatePrice);
            res[0] !== undefined && res[0].published !== undefined && res[0].published === true && setPublisted(true);
        });
    }, [propertyId]);

    const onNextStep = async () => {
        if(low === ''){
            setErr('Please input a estimated lowest price');
        } else if (high === ''){
            setErr('Please input a estimated highest price');
        } else if(isNaN(parseInt(low)) || isNaN(parseInt(high))){
            setErr('Please type in number only');
        } else if(low === '0' || high === '0'){
            setErr('Price can\'t be 0');
        }else if (parseInt(high) < parseInt(low)){
            setErr('High price is lower than the low price');
        } else {
            await updatePropertyPrice(propertyId, parseInt(low), parseInt(high), doDisplay);
            nav.navigate('status', { propertyId });
        }
    };

    return (
        <ListingBaseScreen
            step={7}
            header="What is your estimated price?"
            nextScreenHandler={onNextStep}
            error={err}
            setErr={setErr}
            isPublished={published}
        >
            <View style={[Layout.rowBetween, {alignItems:'flex-start'}]}>
                <View style={{width: '48%'}}>
                    <View style={[styles.InputConatiner]}>
                        <Foundation name='dollar' size={22} style={{paddingLeft:16}} color={Colors.grey3} />
                        <TextInput
                            autoCapitalize='none'
                            style={styles.input}
                            placeholder='0'
                            placeholderTextColor={Colors.grey3}
                            onChangeText={setLow}
                            value={low}
                            keyboardType='numeric'
                        />
                    </View>
                    {low !== undefined && low !== '' && !isNaN(parseInt(low)) && <Text style={[FontProperty.TextGrey,FontProperty.miniP]}>From AUD${numberWithCommas(parseInt(low))}</Text>}
                </View>
                <View style={{width: '48%'}}>
                    <View style={[styles.InputConatiner]}>
                        <Foundation name='dollar' size={22} style={{paddingLeft:16}} color={Colors.grey3} />
                        <TextInput
                            autoCapitalize='none'
                            style={styles.input}
                            placeholder='0'
                            placeholderTextColor={Colors.grey3}
                            onChangeText={setHigh}
                            value={high}
                            keyboardType='numeric'
                        />  
                    </View>
                    {high !== undefined && high !== '' && !isNaN(parseInt(high)) && <Text style={[FontProperty.TextGrey,FontProperty.miniP]}>To AUD${numberWithCommas(parseInt(high))}</Text>}
                </View>
            </View>
            <View style={[styles.switchContainer]}>
                <HouspoSwitch setSwitchValue={setDoDisplay} switchValue={doDisplay} label="Display on profile?" />
            </View>

        </ListingBaseScreen>
    );
};

const styles = StyleSheet.create({
    InputConatiner: {
        flexDirection: 'row',
        borderWidth: 1,
        borderColor: Colors.grey5,
        marginBottom: 15,
        borderRadius: 4,
        backgroundColor: Colors.white,
        alignContent  : 'center',
        alignItems:'center',
    },
    switchContainer: {
        paddingTop: 70
    },
    input: {
        paddingVertical: 19,
        paddingHorizontal: 16,
        color: Colors.navy,
        fontSize: 14,
        width: '90%',
        flex : 1,
        outlineStyle: 'none' 
    }
});
