import React, { useState } from 'react';
import { StyleSheet, View, Text, Image, Pressable } from 'react-native';
import FontProperty from '../../constants/FontProperty';
import Colors from '../../constants/colors.js';
import Layout from '../../constants/Layout';
import RenderHtml, { MixedStyleDeclaration } from 'react-native-render-html';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useNavigation } from '@react-navigation/native';

export type TSwitchTab = {
  title: string,
  content: string,
  firstTabTitle: string,
  firstTabContent: string,
  secondTabTitle: string,
  secondTabContent: string,
  userLoggedIn : boolean
}

const SwitchTab = (props:TSwitchTab) => {
    const [tab, setTab] = useState('First');
    const nav = useNavigation();

    return (
        <View style={[Layout.globalPadding24, {backgroundColor: Colors.lightBeige, paddingVertical: 40}]}>
            <Text style={[FontProperty.h1, FontProperty.TextBold, FontProperty.TextBlack, {marginBottom:20}]}>{props.title}</Text>
            <Text style={[FontProperty.smallP, FontProperty.TextBlack, {marginBottom:20}]}>{props.content}</Text>
            <View style={styles.switchTabGroup}>
                <Pressable onPress={()=>setTab('First')} style={[styles.switchTab, tab === 'First' && styles.switchTabActive]}>
                    <Text style={[styles.switchTabText, tab === 'First' && styles.switchTabTextActiveNavy]}>{props.firstTabTitle}</Text>
                </Pressable>
                <Pressable onPress={()=>setTab('Second')} style={[styles.switchTab, tab === 'Second' && styles.switchTabActive]}>
                    <Text style={[styles.switchTabText, tab === 'Second' && styles.switchTabTextActiveNavy]}>{props.secondTabTitle}</Text>
                </Pressable>
            </View>
            <View style={{backgroundColor: Colors.white, paddingHorizontal: 24, paddingVertical: 30, borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopRightRadius: 4}}>
                {tab === 'First' && <RenderHtml source={{ html: props.firstTabContent }} tagsStyles={tagsStyles} defaultTextProps={{selectable:true}}/> }
                {tab === 'Second' && <RenderHtml source={{ html: props.secondTabContent }} tagsStyles={tagsStyles} defaultTextProps={{selectable:true}}/> }
                {props.userLoggedIn === false &&
                    <TouchableOpacity
                        style={[styles.btn]}
                        onPress={()=>{nav.navigate('Authentication');}}
                    >
                        <Text style={[FontProperty.smallP, FontProperty.TextWhite, FontProperty.TextMedium]}>Sign In</Text>
                    </TouchableOpacity>
                }
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    switchTabGroup: {
        flexDirection: 'row',
        marginBottom: -5,

    },
    switchTab: {
        paddingHorizontal: Layout.window.width > 767 ? 18 : 10,
        paddingTop: 10,
        paddingBottom: 15,
        backgroundColor: '#F2F2F2',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        width: Layout.window.width > 767 ? 'auto' : (Layout.window.width - 48)/2,
        textAlign: 'center'
    },
    switchTabActive: {
        zIndex: 1,
        boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.08)',
        backgroundColor: Colors.white
    },
    switchTabText: {
        color: '#828282',
        fontSize: 12,
        fontFamily: 'inter-regular'
    },
    switchTabTextActive: {
        color: Colors.teal,
        fontFamily: 'inter-bold'
    },
    switchTabTextActiveNavy: {
        color: Colors.navy,
        fontFamily: 'inter-bold'
    },
    btn: {
        borderRadius: 8,
        paddingVertical: 11.5,
        alignItems: 'center',
        justifyContent: 'center',
        width: 140,
        backgroundColor: Colors.navy
    }
});

const tagsStyles: Record<string, MixedStyleDeclaration> = {
    p: {
        margin: 0,
        marginBottom: 16,
        color: Colors.black,
        fontFamily: 'inter-regular'
    },
    ul: {
        margin: 0,
        marginBottom: 16,
        paddingLeft: 10,
        color: Colors.black,
    },
    li: {
        margin: 0,
        marginBottom: 16,
        paddingLeft: 10,
        color: Colors.black,
    },
    strong: {
        fontWeight: '600',
        marginBottom: 16,
    },
    a: {
        color: Colors.teal,
    }
};


export default SwitchTab;
