import Colors from './colors.js';

const FontProperty = {
    // Font family
    TextBold: {
        fontFamily: 'inter-bold'
    },
    TextError: {
        color: Colors.red
    },

    TextSemiBold: {
        fontFamily: 'inter-semibold'
    },

    TextMedium: {
        fontFamily: 'inter-medium'
    },

    TextRegular: {
        fontFamily: 'inter-regular'
    },

    TextLight: {
        fontFamily: 'inter-light'
    },

    // Font size
    h0: {
        fontSize: 24,
        fontWeight: '600' as const,
        lineHeight: 34
    },
    h1: {
        fontSize: 18,
        fontWeight: '600' as const,
        lineHeight: 21.78
    },
    h2: {
        fontSize: 16,
        fontWeight: '500' as const,
        lineHeight: 19.36
    },
    h3: {
        fontSize: 21,
        fontWeight: '600' as const,
        lineHeight: 25.41
    },
    h4: {
        fontSize: 18,
        lineHeight: 21.78
    },
    p1: {
        fontSize: 16,
        lineHeight: 24
    },
    body_1: {
        fontSize: 16,
        lineHeight: 24,
        fontWeight: '400' as const
    },
    p2: {
        fontSize: 16,
        lineHeight: 19.36
    },
    smallP: {
        fontSize: 14,
        lineHeight: 16.94
    },
    smallLabel: {
        fontSize: 12
    },
    miniP: {
        fontSize: 12,
        lineHeight: 14.52
    },
    subtitle_1: {
        color: '#bdbdbd',
        weight: '400' as const,
        fontSize: 14,
        lineHeight: 17
    },
    subtitle_2: {
        color: '#333',
        fontWeight: '600' as const,
        fontSize: 14,
        lineHeight: 21
    },
    title_1: {
        color: '#333333',
        fontWeight: '600' as const,
        fontSize: 24,
        lineHeight: 36
    },

    // Font Color
    TextTeal: {
        color: Colors.teal
    },

    TextDarkNavy: {
        color: Colors.darkNavy
    },

    TextNavy: {
        color: Colors.navy
    },

    TextLightNavy: {
        color: Colors.lightNavy
    },

    TextBlack: {
        color: Colors.black
    },

    TextGrey: {
        color: Colors.grey3
    },

    TextWhite: {
        color: Colors.white
    },

    TextBeige: {
        color: Colors.darkBeige
    },
    button_1: {
        fontWeight: '600' as const,
        fontSize: 16,
        lineHeight: 12,
        color: '#FFFFFF'
    },
    button_2: {
        fontWeight: '500' as const,
        fontSize: 16,
        lineHeight: 12,
        color: '#FFFFFF'
    },
    center: {
        textAlign: 'center'
    }
};

export default FontProperty;
