import React, {useEffect, useState, useRef} from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Animated, TextInput, Pressable } from 'react-native';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import { AntDesign, Ionicons } from '@expo/vector-icons';
import Layout from '../../constants/Layout';
import { ScrollView } from 'react-native-gesture-handler';
import { getMessages, sendMessageHouspo, updateReadStatus, userAllowReceiveNotification, userBlockReceiveMessage } from '../../lib/api/messages/messages';
import { getUserData } from '../../lib/api/user';
import { IuserData } from '../../lib/api/user/types';
import SingleMessage from './SingleMessage';
import { IMessageContent } from '../../types';
import { updateUserReceiveMessage } from '../../lib/api/user/contants';
import ContentText from '../../constants/Content';

export type TMessageViewScreen = {
    targetCov : string,
    setTrigger: (arg0:boolean) => void
    trigger: boolean
    setTargetConversation: (arg0:string) => void
    receiveMessage: boolean
}
const MessageViewScreen = (props: TMessageViewScreen) => {
    const [bounceValue, setBounceValue] = useState(new Animated.Value(Layout.window.width + 200));
    const [isHidden, setIsHidden ] = useState(props.targetCov !== '');
    const [senderData, setSenderData] = useState<IuserData>();
    const [receiverData, setReceiverData] = useState<IuserData>();
    const [messages, setMessages ] = useState<any[]>();
    const [myMessage, setMyMessage] = useState<string>('');
    const scrollViewRef = useRef();

    const closeWholeModel = () => {
        toggleSlide(false);
        updateReadStatus(props.targetCov, true);
        props.setTargetConversation('');
        props.setTrigger(!props.trigger);
        setTimeout(() => {
            setIsHidden(false);
        }, 500);
    };

    const toggleSlide = (status : boolean) =>  {
        let slideToValue = Layout.window.width + 200;
        if(status) {
            slideToValue = 0;
        }
        Animated.spring(
            bounceValue,
            {
                toValue: slideToValue,
                velocity: 10,
                tension: 2,
                friction: 8,
                useNativeDriver: false
            }
        ).start();
    };

    toggleSlide(isHidden);
    useEffect(() => {
        const receiver = props.targetCov.split('_')[0];
        const sender = props.targetCov.split('_')[1];
        getUserData(sender).then((ref)=>{
            ref && ref.data() && ref.data() && setSenderData(ref.data());
        });
        getUserData(receiver).then((ref)=>{
            ref && ref.data() && ref.data() && setReceiverData(ref.data());
        });
        getMessages(props.targetCov, setMessages);

    }, [props.targetCov]);

    if(senderData === undefined || receiverData === undefined || messages === undefined ){
        return null;
    }

    const sendMessage = () => {
        if(myMessage !== '') {
            sendMessageHouspo(props.targetCov.split('_')[0], props.targetCov.split('_')[1], myMessage);
            setMyMessage('');
        }
    };

    const goReceiveMessage = () => {
        userBlockReceiveMessage(props.targetCov.split('_')[0], false);
        updateUserReceiveMessage(props.targetCov.split('_')[0], true);
    };
    
    return (
        <Animated.View style={[styles.outContainer, {transform: [{translateX: bounceValue}]}, !isHidden && {display:'none'}]}>
            <TouchableOpacity onPress={closeWholeModel} style={[Layout.row, {padding:24}]}>
                <Ionicons name="arrow-back" size={23} color={Colors.grey1} style={{marginRight:15}}/>
                <Text style={[FontProperty.h1, FontProperty.TextBlack]}>{senderData.username}</Text>
            </TouchableOpacity>
            <ScrollView 
                ref={scrollViewRef}
                onContentSizeChange={() => scrollViewRef.current.scrollToEnd({ animated: true })}      
                style={{paddingHorizontal:12, paddingBottom:40}}>
                {messages && messages.length > 0 && senderData && receiverData && 
                    messages.map((message:IMessageContent, index:number)=>{
                        return (
                            <SingleMessage
                                key={index} 
                                senderId={props.targetCov.split('_')[1]}
                                receiverId={props.targetCov.split('_')[0]}
                                senderImage={senderData.headshot} 
                                receiverImage={receiverData.headshot}
                                message={message}
                            />
                        );
                    })
                }
            </ScrollView>
            {
                !props.receiveMessage &&
                <View style={{paddingHorizontal:24,paddingVertical:16,backgroundColor:Colors.lightBeige}}>
                    <Text style={[FontProperty.TextGrey, FontProperty.miniP]}><AntDesign name='exclamationcircleo' size={12} color={Colors.red} /> Receive message is turned off, you won&apos;t reveive any message from other users. Click <Pressable onPress={()=>goReceiveMessage()}><Text style={FontProperty.TextTeal}>Turn on receive message</Text></Pressable> to start chat.</Text>
                </View>
            }
            <View style={styles.sendMessage}>
                <TextInput
                    autoCapitalize='none'
                    style={[styles.input]}
                    placeholder='Write a message...'
                    placeholderTextColor={Colors.grey3}
                    onChangeText={setMyMessage}
                    value={myMessage}
                />
                <Pressable 
                    style={styles.postBtn} 
                    onPress={()=>sendMessage()}>
                    <Text style={[FontProperty.TextWhite, FontProperty.TextSemiBold]}>
                        Send
                    </Text></Pressable>
            </View>
        </Animated.View>
    );
};

const styles = StyleSheet.create({
    outContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: Colors.white,
        justifyContent: 'space-between',
        zIndex: 2
    },
    input: {
        color: Colors.navy,
        fontSize: 14,
        width: '100%',
        flex : 1,
        outlineStyle: 'none' 
    },
    postBtn: {
        backgroundColor: Colors.teal,
        borderRadius: 4,
        paddingHorizontal: 15,
        paddingVertical: 4.5,
        marginLeft: 16
    },
    sendMessage: {
        flexDirection:'row',
        alignItems:'center',
        padding: 24,
        boxShadow: '0px -6px 10px rgba(0, 0, 0, 0.1)'
    }
});

export default MessageViewScreen;

