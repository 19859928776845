import React from 'react';
import { StyleSheet } from 'react-native';
import { IMessage } from '../../types';
import MessageItem from '../atom/MessageItem';

export type TAllMessages = {
    data : IMessage[]
    setTrigger: (arg0:boolean) => void
    trigger: boolean
    setTargetConversation: (arg0:string) => void
}

const AllMessages = (props:TAllMessages) => {
    const allMessages = props.data;

    return (
        allMessages.map((message:IMessage)=>{
            return (message.lastMessage !== undefined && 
            <MessageItem 
                data={message} 
                setTrigger={props.setTrigger} 
                trigger={props.trigger} 
                key={message.id} 
                setTargetConversation={props.setTargetConversation}
            />);
        })
    );
};

const styles = StyleSheet.create({
});

export default AllMessages;
