import React, {useEffect, useState} from 'react';
import { StyleSheet, View, Text, ScrollView, Pressable, Image } from 'react-native';
import IMAGE from '../../assets/images';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import { getUserData } from '../../lib/api/user';
import { IuserData } from '../../lib/api/user/types';
import { TealButton } from '../Button';
import UpdateProfileForm from '../forms/UpdateProfileForm';
import WarningBar from '../atom/Warningbar';
import Colors from '../../constants/colors';
import { doesUsernameExist, updateUsername } from '../../lib/api/user/authentication';
import ChangeAvatar from '../atom/ChangeAvatar';
import { getAuth, updateEmail } from 'firebase/auth';

const ProfileDetails = (props: { userId: string; user:any }) => {
    const [userData, setUserData] = useState<IuserData>();
    const [editable, setEditable] = useState<boolean>(false);
    const [error, setError] = useState('');
    const [succeed, setSucceed] = useState(false);
    const auth = getAuth();
    
    const [username, setUsername] = useState<string | undefined>('');
    const [originUsername, setOriginUsername] = useState<string | undefined>('');
    const [email, setUserEmail] = useState(props.user ? props.user.email : '');
    const [originEmail, setUserOriginEmail] = useState(props.user ? props.user.email : '');
    const [uploadImageModal, setUploadImageModal] = useState(false);
    const [trigger, setTrigger] = useState(false);



    useEffect(() => {
        getUserData(props.userId).then(ref=>{
            setUserData(ref.data());
            setUsername(ref.data()?.username);
            setOriginUsername(ref.data()?.username);
        });
    }, [props.userId,trigger]);

    if(!userData && props.user !== false){
        return null;
    }
    
    const uploadImage = () => {
        setUploadImageModal(true);
    };

    const updateUsesrProfile = async() => {
        if(username !== undefined && username !== ''){
            if(originUsername !== username){
                const usernameExists = await doesUsernameExist(username);
                if (usernameExists) {
                    setError('Username is taken');
                    setTimeout(() => {setError('');}, 3000);
                }else{
                    await updateUsername(props.userId, username);
                    setSucceed(true);
                    setTimeout(() => {setSucceed(false);}, 3000);
                    setEditable(false);
                }
            }

            if(email !== undefined && email !== '' && auth.currentUser){
                if(originEmail !== email){
                    await updateEmail(auth.currentUser, email).then(() => {
                        console.log('Updated!');
                    }).catch((error) => {
                        console.log(error);
                    });
                }
            } else {
                setError('Email address is required');
                setTimeout(() => {setError('');}, 3000);
            }
        } else {
            setError('Username is required');
            setTimeout(() => {setError('');}, 3000);
        }

        
    };

    
    return (
        <View style={[{flex:1,paddingTop:55}]}>
            <View style={[Layout.alignCenter, Layout.globalPadding24]}>
                <Pressable style={styles.userProfile} onPress={()=>uploadImage()}>
                    <Image source={userData.headshot ? userData.headshot : IMAGE.userProfile} resizeMode={'cover'} style={styles.imgCard}></Image>
                    <View style={styles.edit}><Text style={[FontProperty.TextSemiBold, FontProperty.TextWhite]}>Edit</Text></View>
                </Pressable>
                <ScrollView style={{width:'100%'}} showsVerticalScrollIndicator={false}>
                    <UpdateProfileForm uid={props.userId} username={username} setUsername={setUsername} email={email} setUserEmail={setUserEmail} editable={editable} />
                </ScrollView>
            </View>
            <View style={[Layout.flexBottom, Layout.globalPadding24]}>
                {
                    editable
                        ? <TealButton onPress={()=>{updateUsesrProfile();}}>
                            <Text style={[FontProperty.TextSemiBold,FontProperty.p2,FontProperty.TextWhite]}>Done</Text>
                        </TealButton>
                        : <TealButton onPress={()=>setEditable(true)}>
                            <Text style={[FontProperty.TextSemiBold,FontProperty.p2,FontProperty.TextWhite]}>Edit details</Text>
                        </TealButton>

                }
            </View>
            {
                error !== '' &&
                <WarningBar text={error} icon='exclamation-triangle' backgroundcolor={Colors.red} bottom={105}/>
            }
            {
                succeed &&
                <WarningBar text='Your profile is updated!' icon='check-circle' backgroundcolor={Colors.teal} bottom={105}/>
            }
            {   
                uploadImageModal &&
                <ChangeAvatar 
                    uploadImageModal={uploadImageModal} 
                    setUploadImageModal={setUploadImageModal} 
                    image={userData.headshot ? userData.headshot : IMAGE.userProfile}
                    uid={props.userId} 
                    trigger={trigger}
                    setTrigger={setTrigger}
                />
            }
        </View>
    );
};

const styles = StyleSheet.create({
    userProfile: {
        borderRadius: 50,
        display: 'flex',
        alignItems: 'center',
        width: 100,
        overflow: 'hidden',
        marginBottom: 40
    },
    imgCard: {
        width : 100,
        height : 100,
    },
    edit: {
        backgroundColor: '#000000CC',
        textAlign: 'center',
        width: '100%',
        position: 'absolute',
        bottom: 0,
        paddingVertical: 5
    }
});

export default ProfileDetails;

