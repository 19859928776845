import React, {useEffect, useState} from 'react';
import { StyleSheet, View, Text, ScrollView } from 'react-native';
import { getFAQs } from '../../lib/api/Content/content';
import { IFAQs } from '../../lib/api/Content/types';
import AccordionListItem from '../atom/AccordionListItem';
import RenderHtml, { MixedStyleDeclaration } from 'react-native-render-html';
import Layout from '../../constants/Layout';

const ProfileFaqs = () => {
    const [faqContent, setFaqContent] = useState<IFAQs[]>();

    useEffect(() => {
        getFAQs().then(res => {
            setFaqContent(res);
        });
    },[]);

    if(!faqContent){
        return null;
    }

    return (
        <ScrollView style={{paddingBottom:40}} showsVerticalScrollIndicator={false}>
            {
                faqContent.map((item:IFAQs, index:number)=>{
                    return (
                        <AccordionListItem title={item.question} key={index}>
                            <RenderHtml
                                contentWidth={Layout.window.width}
                                source={{ html: item.anwser }}
                                tagsStyles={tagsStyles}
                                defaultTextProps={{selectable:true}}
                            />
                        </AccordionListItem>
                    );
                })
            }
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    
});

const tagsStyles: Record<string, MixedStyleDeclaration> = {

    p: {
        margin: 0,
        marginBottom: 16
    },
    ul: {
        margin: 0,
        marginBottom: 16,
        paddingHorizontal: 20
    },
    li: {
        margin: 0,
        marginBottom: 16,
        paddingHorizontal: 25
    },
    strong: {
        fontWeight: '600',
        marginBottom: 16,
    }
};

export default ProfileFaqs;

