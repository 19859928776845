import { action, observable, makeObservable } from 'mobx';
import { persist } from 'mobx-persist';
import { TembeddedData } from '../lib/api/user/authentication';

type TuserStoreField = 'refreshToken' | 'interestTypesObj' |'propertyTypesObj'| 'userTypeObj' |'features' | 'accessToken' | 'expiresIn' | 'uid' | 'hasVerified' | 'hasOnboarded' | 'name'
class User {
    constructor () {
        makeObservable(this);
    }

  @persist @observable uid = '' as string;
  @persist @observable name = '' as string;
  @persist @observable userImgUrl = '' as string;
  @persist @observable csrfToken = '' as string;
  @persist @observable logoutToken = '' as string;

  @persist @observable hasOnboarded = false as boolean;
  @persist @observable hasVerified = false as boolean;
  @observable features = [];
  @observable userTypeObj = {} as TembeddedData;
  @observable propertyTypesObj = [] as TembeddedData[];
  @observable interestTypesObj = [] as TembeddedData[];
  @persist @observable userType = '' as string;
  @persist @observable propertyType = '' as string;
  @observable sellerType = '' as string;
  @observable buyerType = '' as string;
  @persist @observable refreshToken = '' as string;
  @persist @observable accessToken = '' as string;
  @persist @observable expiriesOn = '' as string;

  @action
  setField (field : TuserStoreField, value : any) {
      this[field] = value;
  }

  @action
  setUserType (type : string) {
      this.userType = type;
  }

  @action
  setOnboarded (flag : boolean) {
      this.hasOnboarded = flag;
  }

  @action
  setPropertyType (type : string) {
      this.propertyType = type;
  }

  @action
  setSellerType (type : string) {
      this.sellerType = type;
  }

  @action
  setBuyerType (type : string) {
      this.buyerType = type;
  }

  @action
  setUser (uid : string, name : string, csrfToken : string, logoutToken : string) {
      this.uid = uid;
      this.name = name;
      this.csrfToken = csrfToken;
      this.logoutToken = logoutToken;
  }

  @action
  setIsVerified (isVerified: boolean) {
      this.hasVerified = isVerified;
  }

  @action
  logout () {
      this.uid = '';
      this.name = '';
      this.csrfToken = '';
      this.logoutToken = '';
      this.hasOnboarded = false;
      this.hasVerified = false;
  }
}

const userStore = new User();

export default userStore;
