import { Ionicons } from '@expo/vector-icons';
import React, {useState} from 'react';
import { StyleSheet, View, Text, Animated, Image, TouchableOpacity, Pressable } from 'react-native';
import IMAGE from '../../assets/images';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import { setContact } from '../../lib/api/Content/posts/posts';
import { HouspoMessageInput } from '../atom/HouspoTextInput';
import { TealButton } from '../common/button';
import { useAuthentication } from '../../hooks/useAuthentication';
import HousepoTextInput from '../HouspoTextInput';
import HouspoDropdown from '../atom/HouspoDropdown';

const ProfileContact = (props: { userId ? : string; setShowProfileSettings ? : (arg0 : boolean) => void }) => {
    const { user } = useAuthentication();
    const [postMessageSucceed, setPostMessageSucceed] = useState(false);
    const [userName, setUsername] = useState('');
    const [email, setEmail] = useState('');
    
    const [message, setMessage] = useState('');

    const [opacityValue, setOpacityVlaue] = React.useState(new Animated.Value(0));
    const [jumpValue, setJumpValue] = React.useState(new Animated.Value(80));
    const [isHiddenSucceed, setIsHiddenSucceed ] = React.useState(true);
   

    const [dropdownItems, setDropdownItems] = useState([
        {label: 'Homeowner', value: 'Homeowner'},
        {label: 'Interested buyer', value: 'Interested buyer'},
        {label: 'Property renovator', value: 'Property renovator'},
        {label: 'Property professional', value: 'Property professional'},
        {label: 'Other', value: 'Other'},
    ]);
    const [role, setRole] = useState('');
    const [openDropdownState, setDropdownStateOpen] = useState(false);

    const sendMessage = async () => {
        if(message !== '' && userName !== '' && email !== '') {
            await setContact(props.userId ? props.userId : '', message, userName, email, role);
            setPostMessageSucceed(true);
            toggleSlideSucceed(true);
        }
    };

    const toggleSlideSucceed = (status : boolean) =>  {
        let opacityToValue = 0;
        if(status) {
            opacityToValue = 1;
        }
        Animated.spring(
            opacityValue,
            {
                toValue: opacityToValue,
                velocity: 10,
                tension: 2,
                friction: 8,
                useNativeDriver: false
            }
        ).start();

        Animated.spring(
            jumpValue,
            {
                toValue: 53,
                velocity: 10,
                tension: 2,
                friction: 2,
                useNativeDriver: false
            }
        ).start();
    };

    const finishSend = () => {
        setUsername('');
        setEmail('');
        setMessage('');
        setRole('');
        setPostMessageSucceed(false);
        props.setShowProfileSettings && props.setShowProfileSettings(false);
    };

    return (
        <>
            <View style={{paddingHorizontal:24}}>
                <View style={Layout.window.width > 767 && Layout.rowBetween}>
                    <View style={{flex:1, paddingRight: Layout.window.width > 767 ? 20 : 0}}>
                        <HousepoTextInput value={userName} setText={setUsername} placeholder='Name*' />
                    </View>
                    <View style={{flex:1}}>
                        <HousepoTextInput value={email} setText={setEmail} placeholder='Email*' />
                    </View>
                </View>
                {/* {user === false && */}
                <HouspoDropdown 
                    open={openDropdownState}
                    value={role}
                    items={dropdownItems}
                    setOpen={setDropdownStateOpen}
                    setValue={(filterValue)=>{
                        setRole(filterValue);
                    }}
                    setItems={setDropdownItems}
                    placeholder="You are..."
                />
                {/* } */}
                <HouspoMessageInput placeholder='What can we help with?*' setText={setMessage} value={message}/>
            </View>
            <View style={[Layout.flexBottom,{paddingHorizontal:24}, (message === '' || userName === '' || email === '') && styles.opacity]}>
                <TealButton onPress={()=>sendMessage()} btnStyle={{width:'100%'}}>
                    <Text style={[FontProperty.TextSemiBold,FontProperty.p2,FontProperty.TextWhite]}>Send</Text>
                </TealButton>
            </View>
            {
                postMessageSucceed &&
                <Animated.View style={[styles.overlay, {opacity:opacityValue}, !isHiddenSucceed && {display:'none'}]}>
                    <View style={styles.succeedModel}>
                        <Ionicons name="close" size={20} color="#4F4F4F" style={styles.close} onPress={()=>finishSend()}/>
                        <Image source={IMAGE.MessageSent} style={[styles.image]} />
                        <Animated.Image source={IMAGE.MessageSentJump} style={[styles.imageJump, {top:jumpValue}]} />
                        <Text style={[FontProperty.h1, FontProperty.TextBlack, {textAlign:'center'}]}>Thanks for contacting us, our team will get back to you soon!</Text>
                        <TouchableOpacity
                            style={[styles.send]}
                            onPress={()=>finishSend()}
                        >
                            <Text style={[FontProperty.TextWhite, FontProperty.button_1]}>Ok</Text>
                        </TouchableOpacity>
                    </View>
                </Animated.View>
            }
        </>
    );
};

const styles = StyleSheet.create({
    opacity: {
        opacity: 0.5
    },
    send: {
        borderRadius: 8,
        paddingVertical: 17.5,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.teal,
        height: 56,
        marginTop: 40,
        width: '100%',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        paddingHorizontal: 24,
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        zIndex: 100
    },
    succeedModel: {
        backgroundColor: Colors.white,
        paddingHorizontal: 24,
        paddingTop: 62,
        paddingBottom: 37,
        alignItems: 'center',
        width: Layout.window.width > 425 ? 360 : '100%',
        borderRadius: 16,
        boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.08)',
        marginTop: -78
    },
    image: {
        width: 142,
        height: 150,
        marginBottom: 31,
    },
    imageJump: {
        width: 108,
        height: 79,
        position: 'absolute'
    },
    close: {
        position: 'absolute',
        right: 24,
        top: 22,
        zIndex: 2
    },
    opacity5: {
        opacity: 0.5
    },
});

export default ProfileContact;

