import React, {useState, useEffect} from 'react';
import { StyleSheet, View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ListingBaseScreen } from './ListingBaseScreen';
import Colors from '../../constants/colors';
import { ScrollView } from 'react-native-gesture-handler';
import { useProperty } from '../../hooks/useProperty';
import { AddListingTypeBasicProps } from '../../types';
import DataIncrementRow from '../../components/atom/DataIncrementRow';
import { getPropertyContent, updatePropertyInfo } from '../../lib/api/Content/property';
import DataLandSize from '../../components/atom/DataLandSize';

export const FeatureScreen : React.FC = (props) => {
    const nav = useNavigation();
    const { propertyId } = useRoute<AddListingTypeBasicProps>().params;
    const { property, updateProperty } = useProperty(propertyId);
    const[numBed, setNumBed] = useState(0);
    const[numBath, setNumBath] = useState(0);
    const[numCar, setNumCar] = useState(0);
    const[landSize, setLandSize] = useState('');
    const [err, setErr] = useState<string>('');
    const [published, setPublisted] = useState(false);

    useEffect(() => {
        getPropertyContent(propertyId).then(res=> {
            res[0].bedroom !== undefined && setNumBed(res[0].bedroom);
            res[0].bathroom !== undefined && setNumBath(res[0].bathroom);
            res[0].carpark !== undefined && setNumCar(res[0].carpark);
            res[0].landSize !== undefined && setLandSize(res[0].landSize.toString());
            res[0] !== undefined && res[0].published !== undefined && res[0].published === true && setPublisted(true);
        });
    }, [propertyId]);


    const onNextStep = async () => {
        if(landSize !== undefined && landSize.trim() !== '' && !isNaN(landSize)) {
            await updatePropertyInfo(propertyId, numBed, numBath, numCar, parseInt(landSize));
            nav.navigate('upload', { propertyId });
        } else {
            setErr('Please type in your land size and with number only');
        }
    };

    return (
        <ListingBaseScreen
            step={4}
            header="Tell us about your property features"
            nextScreenHandler={onNextStep}
            error={err}
            setErr={setErr}
            isPublished={published}
        >
            <ScrollView showsVerticalScrollIndicator={false}>
                <View style={[styles.itemWrapper]}>
                    <DataIncrementRow label="Bedrooms" value={numBed} setValue={setNumBed} />
                    <DataIncrementRow label="Bathrooms" value={numBath} setValue={setNumBath} />
                    <DataIncrementRow label="Carparks" value={numCar} setValue={setNumCar} />
                    <DataLandSize label='Land size' value={landSize} setValue={setLandSize} />
                </View>
            </ScrollView>

        </ListingBaseScreen>
    );
};

const styles = StyleSheet.create({

    contentContainer: {
        flexDirection: 'row',
        justifyContent: 'center'
    },
    item: {
        fontSize: 16,
        color: Colors.lightNavy,
        fontFamily: 'inter-medium'
    },
    itemWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginTop: 40
    },
    itemContainer: {
        borderWidth: 1,
        borderColor: Colors.grey4,
        backgroundColor: Colors.white,
        padding: 18,
        marginLeft: 0,
        marginTop: 0,
        alignItems: 'center',
        width: '48%',
        borderRadius: 4,
        flexDirection: 'row',
        justifyContent: 'center'
    },
    info: {
        marginLeft: 10
    },
    isActive: {
        borderColor: Colors.teal
    },
    isActiveText: {
        color: Colors.teal
    },
    skip: {
        textAlign: 'center',
        fontFamily: 'inter-semibold',
        color: Colors.grey4,
        marginTop: 6
    }
});
