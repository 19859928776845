import { collection, doc, setDoc, arrayUnion, arrayRemove, getDoc } from '@firebase/firestore';
import { auth, db } from '../../../config/firebase';

export const addUserFavourateImages = async (userId : string, image: string) => {
    try {
        const userDataRef = doc(collection(db, 'userData'), userId);
        await setDoc(userDataRef, 
            { favouriteImages: arrayUnion(image) },
            { merge: true }
        );
    } catch (err) {
        throw new Error(err);
    }
};

export const removeUserFavourateImages = async (userId : string, image: string) => {
    try {
        const userDataRef = doc(collection(db, 'userData'), userId);
        await setDoc(userDataRef, 
            { favouriteImages: arrayRemove(image) },
            { merge: true }
        );
    } catch (err) {
        throw new Error(err);
    }
};

export const updateUserNotification = async (userId : string, status: boolean) => {
    try {
        const userDataRef = doc(collection(db, 'userData'), userId);
        await setDoc(userDataRef, 
            { receiveNotification: status },
            { merge: true }
        );
    } catch (err) {
        throw new Error(err);
    }
};

export const getUserNotification = async (userId : string) => {
    try {
        const userDataRef = doc(collection(db, 'userData'), userId);
        const userData = await getDoc(userDataRef);
        return userData.exists() && userData.data().receiveNotification;
    } catch (err) {
        throw new Error(err);
    }
};

export const updateUserReceiveMessage = async (userId : string, status: boolean) => {
    try {
        const userDataRef = doc(collection(db, 'userData'), userId);
        await setDoc(userDataRef, 
            { receiveMessage: status },
            { merge: true }
        );
    } catch (err) {
        throw new Error(err);
    }
};

export const getUserReceiveMessage = async (userId : string) => {
    try {
        const userDataRef = doc(collection(db, 'userData'), userId);
        const userData = await getDoc(userDataRef);
        return userData.exists() && userData.data().receiveMessage;
    } catch (err) {
        throw new Error(err);
    }
};
