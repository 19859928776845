import React, {useState, useEffect} from 'react';
import { StyleSheet, View, Image, Text, Pressable } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { AddListingTypeBasicProps } from '../../types';
import { ListingBaseScreen } from './ListingBaseScreen';
import { getPropertyContent, updatePropertyImagesTemp } from '../../lib/api/Content/property';
import { useProperty } from '../../hooks/useProperty';
import { DragSortableView } from 'react-native-drag-sort';
import Layout from '../../constants/Layout';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import { AntDesign } from '@expo/vector-icons';
import ContentText from '../../constants/Content';

const numOfItemsRow = Layout.window.width > 425 ? 3 : 2;

export const RearrangePhotosScreen: React.FC = () => {
    const nav = useNavigation<AddListingTypeBasicProps>();
    const { propertyId } = useRoute<AddListingTypeBasicProps>().params;
    const [err, setErr] = useState<string>('');
    const [images, setImages] = useState<string[]>([]);
    const { property, updateProperty } = useProperty(propertyId);
    const [trigger, setTrigger] = useState(false);
    const [removePicArr, setRemovePicArr] = useState<string[]>([]);
    const [published, setPublisted] = useState(false);

    useEffect(() => {
        getPropertyContent(propertyId).then(res=> {
            if(res[0].images !== undefined) {
                setImages(res[0].images);
            }
            res[0] !== undefined && res[0].published !== undefined && res[0].published === true && setPublisted(true);
        });
    }, [propertyId]);

    const removePic = (index:number) => {
        setImages((current) => current.filter((image, i)=>i != index));
        setTrigger(!trigger);
    };

    const rearrangePic = (from:number, to:number) => {
        images.splice(to, 0, images.splice(from, 1)[0]);
        setImages(images);
        setTrigger(!trigger);
    };

    const onNextStep = async () => {
        if (images.length > 0 && removePicArr.length < images.length) {
            let tempImages = images;
            tempImages = tempImages.filter( ( el ) => !removePicArr.includes( el.viewUrl ) );
            await updatePropertyImagesTemp(propertyId, tempImages);
            nav.navigate('name', { propertyId });
        } else {
            setErr('Please click back and upload at least one image');
        }
    };

    return (
        <ListingBaseScreen
            step={5}
            header="Rearrange your Photos"
            nextScreenHandler={onNextStep}
            error={err}
            setErr={setErr}
            isPublished={published}
        >
            <Text style={[FontProperty.body_1, FontProperty.TextGrey, {marginBottom: 16}]}>{Layout.window.width > 576 ? 'Long press to drag and rearrange the photo' : 'Tap and drag to rearrange photo'}</Text>
            <Text style={[FontProperty.body_1, FontProperty.TextBlack, FontProperty.TextSemiBold, {marginBottom: 8}]}>Tip to improve your listing</Text>
            <Text style={[FontProperty.smallP, FontProperty.TextGrey, {marginBottom: 24, fontStyle: 'italic'}]}>{ContentText.listPropertyImage}</Text>
            <View>
                {
                    images.length > 0 &&
                    <DragSortableView
                        dataSource={images}
                        parentWidth={Layout.window.width - 48}
                        childrenWidth= {(Layout.window.width - 48) / numOfItemsRow}
                        childrenHeight= {(Layout.window.width -48) / numOfItemsRow}
                        keyExtractor={(item,index)=> index}
                        marginChildrenBottom={8}
                        delayLongPress={50}
                        maxScale={1.05}
                        scaleDuration={50}
                        slideDuration={200}
                        isDragFreely={false}
                        renderItem={(item,index)=>{
                            return (
                                <View key={index}>
                                    <View pointerEvents='none'>
                                        <Image style={[styles.itemContainer]} resizeMode={'cover'} source={item.viewUrl}/>
                                    </View>
                                    
                                            
                                    {index !== 0 &&
                                        <Pressable onPress={()=>removePic(index)} style={[styles.removeIcon, {backgroundColor: Colors.red}]}>
                                            <AntDesign name='close' size={14} color={Colors.white}/>
                                        </Pressable>
                                    }
                                </View>
                                
                            );
                        }}
                        onDragEnd={(from,to)=>rearrangePic(from, to)}
                        
                    />
                }
                <View style={styles.pill}><Text style={[FontProperty.TextWhite, FontProperty.TextSemiBold]}>Featured Photo</Text></View>
            </View>
        </ListingBaseScreen>
    );
};

const styles = StyleSheet.create({
    itemContainer: {
        alignItems: 'center',
        width: (Layout.window.width - 48) / numOfItemsRow - 8,
        height: (Layout.window.width - 48) / numOfItemsRow,
        borderRadius: 4,
    },
    pill: {
        paddingVertical: 6,
        paddingHorizontal: 16,
        backgroundColor: Colors.teal,
        borderRadius: 16,
        position: 'absolute',
        left: (Layout.window.width - 48) / numOfItemsRow - 155,
        top: (Layout.window.width - 48) / numOfItemsRow - 39
    },
    removeIcon: {
        position: 'absolute',
        right: -8,
        top: -8,
        borderRadius: 50,
        paddingVertical: 2,
        paddingHorizontal: 3
    },
    overlay: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: 1,
    },
});
