import React from 'react';
import { StyleSheet, View, Text, Image } from 'react-native';
import Layout from '../../constants/Layout';
import Colors from '../../constants/colors.js';
import RenderHtml, { MixedStyleDeclaration } from 'react-native-render-html';
import FontProperty from '../../constants/FontProperty';

export type TImageContent = {
    image : string,
    title : string,
    content : string,
    layout : 'Image Left' | 'Image Right'
  }

const ImageContent = (props:TImageContent) => {      
    return (
        <View style={[styles.outContainer, {flexDirection: Layout.window.width > 767 ? (props.layout === 'Image Left' ? 'row-reverse' : 'row') : 'column'}]}>
            <View style={styles.contentContainer}>
                <Text style={[FontProperty.h1, FontProperty.TextBold, FontProperty.TextBlack, {marginBottom:20}]}>{props.title}</Text>
                <RenderHtml source={{ html: props.content }} tagsStyles={tagsStyles} defaultTextProps={{selectable:true}}/>
            </View>
            {
                props.image !== undefined && props.image !== '' &&
                <View style={styles.contentContainer}>
                    <Image source={props.image} style={styles.imageContainer} />
                </View>
            }
        </View>
    );
};

const styles = StyleSheet.create({
    outContainer: {
        paddingVertical: Layout.window.width > 767 ? 60 : 40
    },
    contentContainer: {
        width: Layout.window.width > 767 ? 383.5 : Layout.window.width,
        paddingHorizontal: 24,
        marginBottom: 20,
    },
    imageContainer: {
        height: 300,
        resizeMode: 'cover',
        borderRadius: 16
    }
});

const tagsStyles: Record<string, MixedStyleDeclaration> = {
    p: {
        margin: 0,
        marginBottom: 16,
        color: Colors.black,
        fontFamily: 'inter-regular'
    },
    ul: {
        margin: 0,
        marginBottom: 16,
        paddingLeft: 10,
        color: Colors.black,
    },
    li: {
        margin: 0,
        marginBottom: 16,
        paddingLeft: 10,
        color: Colors.black,
    },
    strong: {
        fontWeight: '600',
        marginBottom: 16,
    },
    a: {
        color: Colors.teal,
    }
};

export default ImageContent;
