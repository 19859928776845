import React, {useEffect, useState, Dispatch, SetStateAction} from 'react';
import { StyleSheet, Text, View, Pressable, FlatList, Image } from 'react-native';
import Layout from '../../constants/Layout';
import { useNavigation } from '@react-navigation/native';
import { getUsersProperties } from '../../lib/api/Content/property';
import { IprojectData, IpropertyData } from '../../lib/api/Content/types';
import HouseTile from '../atom/HouseTile';
import { getMyFollowedListing, getPosts } from '../../lib/api/Content/posts/posts';
import HouseTileID from '../atom/HouseTileID';
import IMAGE from '../../assets/images';
import FontProperty from '../../constants/FontProperty';
import NewsfeedBlock from '../atom/newsfeedBlock/NewsfeedBlock';
import Colors from '../../constants/colors';
import { IPropertyPost } from '../../lib/api/Content/posts/types';
import NoResult from '../atom/NoResult';
import { getMyFollowedProjects } from '../../lib/api/Content/project';
import ProjectTileID from '../atom/ProjectTileID';

export type TMyPLF = {
    uid : string
    isMe : boolean
    trigger : boolean
    targetUser : string
    setTrigger : (arg0:boolean) => void
    setShowLikes: Dispatch<SetStateAction<boolean>>
    setShowComments : Dispatch<SetStateAction<boolean>>
    setTargetPost : Dispatch<SetStateAction<string>>
    setShowShare : Dispatch<SetStateAction<boolean>>
    setShareLink : Dispatch<SetStateAction<string>>
    setShowDeletePopUp : Dispatch<SetStateAction<boolean>>
    setShowEditPopUp : Dispatch<SetStateAction<boolean>>
    setShowReportPopUp : Dispatch<SetStateAction<boolean>>
}

const MyPLF : React.FC< TMyPLF > = (props) => {
    const nav = useNavigation();
    const [PLFTabModal, setPLFTabModal] = useState<'My Post' | 'My Listing' | 'Followed Listing' | 'Followed Projects'>('My Post');
    const [myListing, setMyListing] = useState<IpropertyData>();
    const [myFollowedListing, setMyFollowedListing] = useState();
    const [myFollowedProjects, setMyFollowedProjects] = useState();
    const [myposts, setMyPosts] = useState();

    useEffect(() => {
        getUsersProperties(props.targetUser).then(res => {
            setMyListing(res);
        });
        getMyFollowedListing(props.targetUser).then(res => {
            setMyFollowedListing(res);
        });
        getMyFollowedProjects(props.targetUser).then(res => {
            setMyFollowedProjects(res);
        });
        getPosts(props.targetUser).then(res => {
            setMyPosts(res);
        });
    },[props.targetUser]);

    useEffect(() => {
        getPosts(props.targetUser).then(res => {
            setMyPosts(res);
        });
    },[props.targetUser, props.trigger]);

    if(!myListing){
        return null;
    }

    if(!props.isMe && (PLFTabModal === 'Followed Listing' || PLFTabModal === 'Followed Projects')){
        setPLFTabModal('My Post');
    }

    return (
        <View style={[styles.outContainer]}>
            <View style={{backgroundColor: Colors.white}}>
                <View style={[Layout.rowBetween, {width:'100%',alignItems:'flex-end'}]}>
                    <Pressable onPress={()=>{setPLFTabModal('My Post');props.setTrigger(!props.trigger);}} style={[styles.tabs, PLFTabModal==='My Post' && styles.activeTab]}><Text>{props.isMe && 'My '}Posts</Text></Pressable>
                    <Pressable onPress={()=>{setPLFTabModal('My Listing');props.setTrigger(!props.trigger);}} style={[styles.tabs, PLFTabModal==='My Listing' && styles.activeTab]}><Text>{props.isMe && 'My '}Properties</Text></Pressable>
                    {props.isMe && <Pressable onPress={()=>{setPLFTabModal('Followed Listing');props.setTrigger(!props.trigger);}} style={[styles.tabs, PLFTabModal==='Followed Listing' && styles.activeTab]}><Text >Followed Properties</Text></Pressable>}
                    {props.isMe && <Pressable onPress={()=>{setPLFTabModal('Followed Projects');props.setTrigger(!props.trigger);}} style={[styles.tabs, PLFTabModal==='Followed Projects' && styles.activeTab]}><Text >Followed Projects</Text></Pressable>}
                </View>
                <View style={{paddingHorizontal: 24,paddingTop:24}}>
                    {
                        PLFTabModal ==='My Post' &&
                        ((myposts !== undefined && myposts.length > 0) ?
                            myposts.map((post:IPropertyPost, index:number) => (
                                <View key={post.uuid} style={{marginHorizontal:-24}}>
                                    <NewsfeedBlock
                                        data={post}
                                        userId={props?.uid}
                                        setShowLikes={props.setShowLikes}
                                        setShowComments={props.setShowComments}
                                        setTargetPost={props.setTargetPost}
                                        setShowShare={props.setShowShare}
                                        setShareLink={props.setShareLink}
                                        setShowDeletePopUp={props.setShowDeletePopUp}
                                        setShowEditPopUp={props.setShowEditPopUp}
                                        setShowReportPopUp={props.setShowReportPopUp}
                                    />
                                    <View style={[styles.bar]}></View>
                                </View>
                            ))
                            :  <NoResult title='No post' />
                        )
                    }
                    <View style={[Layout.rowBetween, Layout.flexWrap]}>
                        {
                            PLFTabModal ==='My Listing' &&
                            (myListing.length > 0 ?
                                <FlatList
                                    data={myListing}
                                    keyExtractor={(item:IpropertyData) => item.uuid}
                                    renderItem={({item}) => (
                                        <HouseTile key={item.uuid} uuid={item.uuid} image={item.images[0].viewUrl} name={item.propertyName} suburb={item?.address}
                                            onPress={() => {
                                                nav.navigate('PropertyProfile', {
                                                    propertyId: item.uuid,
                                                });
                                            }} />
                                    )}
                                    numColumns={2}
                                    columnWrapperStyle={{justifyContent:'space-between'}}
                                    onEndReachedThreshold={0.01}
                                />
                                : <NoResult title='No property listed' />
                            )
                        }
                    </View>
                    <View style={[Layout.rowBetween, Layout.flexWrap]}>
                        {
                            PLFTabModal ==='Followed Listing' && props.isMe &&
                            (myFollowedListing.length > 0 ?
                                <FlatList
                                    data={myFollowedListing}
                                    keyExtractor={(item:IpropertyData) => item.uuid}
                                    renderItem={({item}) => (
                                        <HouseTileID propertyId={item.uuid.split('_')[0]} />
                                    )}
                                    numColumns={2}
                                    columnWrapperStyle={{justifyContent:'space-between'}}
                                    onEndReachedThreshold={0.01}
                                />
                                : <NoResult title='Not following any property' />
                            )
                        }
                    </View>
                    <View style={[Layout.rowBetween, Layout.flexWrap]}>
                        {
                            PLFTabModal ==='Followed Projects' && props.isMe &&
                            (myFollowedProjects.length > 0 ?
                                <FlatList
                                    data={myFollowedProjects}
                                    keyExtractor={(item:IprojectData) => item.uuid}
                                    renderItem={({item}) => (
                                        <ProjectTileID projectId={item.uuid.split('_')[0]} />
                                    )}
                                    numColumns={2}
                                    columnWrapperStyle={{justifyContent:'space-between'}}
                                    onEndReachedThreshold={0.01}
                                />
                                : <NoResult title='Not following any projects' />
                            )
                        }
                    </View>
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    outContainer: {
    },
    tabs: {
        flex: 1,
        paddingBottom: 18,
        textAlign: 'center',
        borderBottomWidth: 5,
        borderBottomColor: '#E2E2E2',
    },
    activeTab: {
        borderBottomColor: '#000000',
    },
    bar: {
        backgroundColor: Colors.beige,
        height: 8,
        width: '100%'
    }
});

export default MyPLF;

