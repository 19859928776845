import React from 'react';
import { View, StyleSheet, TextInput, TouchableOpacity, TextInputProps, Text, FlatList } from 'react-native';
import Colors from '../constants/colors';
import { FontAwesome5 } from '@expo/vector-icons';
import Layout from '../constants/Layout';
import FontProperty from '../constants/FontProperty';

interface IbaseTextInput extends TextInputProps {
  setText ? : React.Dispatch<string>
  icon ? : string
  iconPosition ? : 'before' | 'after'
  rightComponent ? : Component
  error ? : string
  editable ? : boolean
  keyPressFunc ? : ()=>void
}

export const HousepoTextInput : React.FC<IbaseTextInput> = (props) => {
    return (
        <View style={styles.outContainer}>
            <View style={[styles.container, props.editable !== undefined && props.editable === false && styles.disabledInput]}>
                { props.iconPosition == 'before' &&
                <FontAwesome5
                    name={props.icon}
                    size={14}
                    style={[styles.icon, styles.iconBefore]}
                />
                }
                <TextInput
                    autoCapitalize='none'
                    style={[styles.input, props.editable !== undefined && props.editable === false && styles.disabledInputText]}
                    placeholder={props.placeholder}
                    placeholderTextColor={Colors.grey3}
                    onChangeText={props.setText || props.onChangeText}

                    value={props.value}
                />
                { props.iconPosition && props.iconPosition !== 'before' &&
                <FontAwesome5
                    name={props.icon}
                    size={14}
                    style={[styles.icon, styles.iconAfter]}
                />
                }
                {props.rightComponent}
            </View>
            <View style={[Layout.row, Layout.mbottom5]}>
                <Text style={[FontProperty.TextError]}>{props.error}</Text>
            </View>
        </View>
    );
};

interface IhouspoAutoSuggest {
  elements : {
    uuid : string,
    name : string,
  }[]
  setSuggestion : (suggestionId : string) => void
}

export const HouspoAutoSuggest : React.FC<IhouspoAutoSuggest> = (props) => {
    return (

        <View style={s.dropdownContainer}>
            {/* Dropdown list */}
            <FlatList
                data={props.elements}
                renderItem={({ item,index }) => (
                    <TouchableOpacity 
                        style={[index % 2 && s.dropItemOdd ]} 
                        onPress={()=>{props.setSuggestion(item.uuid);}}>
                        <Text style={[s.dropdownText]}>{item.name}</Text>
                    </TouchableOpacity>
                )}
                keyExtractor={item => item.uuid}
            />
        </View>
    );
};

const s = StyleSheet.create({
    dropdownContainer: {
        position: 'absolute',
        bottom: -190,
        width: '100%',
        height: 200,
        backgroundColor: '#fff',
        borderColor: Colors.grey3,
        borderWidth: 1,
        borderTopWidth: 0,
        zIndex: 200,
    },
    dropdownText: {
        padding: 15,

    },
    dropItemOdd: {
        backgroundColor: Colors.grey2
    }
 
});

export const HousepoPhoneInput : React.FC<IbaseTextInput> = (props) => {
    return (
        <HousepoTextInput
            setText={props.setText}
            icon="phone"
            iconPosition="after"
            placeholder="Phone Number" />
    );
};

export const HousepoEmailInput : React.FC<IbaseTextInput> = (props) => (
    <HousepoTextInput
        setText={props.setText}
        icon="envelope"
        iconPosition="after"
        placeholder="Email" />
);

export const HousepoSearchInput: React.FC<IbaseTextInput> = (props) => {
    return (
        <HousepoTextInput
            icon="search"
            setText={props.setText}
            iconPosition="before"
            placeholder="Search suburb, home or style" />
    );
};

export const HousepoPasswordInput : React.FC<IbaseTextInput> = (props) => {
    const [showPassword, setShowPassword] = React.useState(false);
    return (
        <View style={styles.outContainer}>
            <View style={styles.container}>
                <TextInput
                    style={styles.input}
                    placeholder={'Password'}
                    placeholderTextColor={Colors.grey3}
                    onChangeText={props.setText || props.onChangeText}
                    secureTextEntry={!showPassword}
                    autoCapitalize="none"
                    autoCorrect={false}
                    onKeyPress={(e)=>
                        (props.keyPressFunc !== undefined && e.nativeEvent.key === 'Enter') && props.keyPressFunc()
                    }
                />

                <TouchableOpacity onPress={() => setShowPassword(!showPassword)}>
                    { showPassword
                        ? <FontAwesome5
                            name={'eye-slash'}
                            size={14}
                            style={[styles.icon, styles.iconAfter]}
                        />
                        : <FontAwesome5
                            name={'eye'}
                            size={14}
                            style={[styles.icon, styles.iconAfter]}
                        />
                    }
                </TouchableOpacity>
            </View>
        </View>
    );
};

export default HousepoTextInput;

const styles = StyleSheet.create({
    outContainer: {
        marginBottom: 17,
    },

    container: {
        flexDirection: 'row',
        borderWidth: 1,
        borderColor: Colors.grey5,
        borderRadius: 4,
        backgroundColor: Colors.white,
        alignContent: 'center',
    },

    input: {
        paddingHorizontal: 16,
        paddingVertical: 19,
        color: Colors.navy,
        fontSize: 14,
        width: '90%',
        flex: 1,
        outlineStyle: 'none',
    },

    icon: {
        paddingVertical: 20,
        color: Colors.grey3
    },

    iconBefore: {
        paddingLeft: 16
    },
    iconAfter: {
        paddingRight: 16
    },
    disabledInput: {
        backgroundColor: '#EEEEEE'
    },
    disabledInputText: {
        color: '#6B6B6B'
    }
});
