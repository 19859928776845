const IMAGE = {
    AuthBanner: require('./signup-banner-image-2.png'),
    Verification: require('./forgot-password.png'),
    OnboardSucceed: require('./onboard-succeed.png'),
    GallryPlaceholder: require('./gallery.png'),
    AddListing : {
        save : require('./addListing/saveImage.png'),
        saveDark : require('./addListing/saveImageDark.png'),
    },
    Onboard: require('./onboard.png'),
    abodely : {
        icon : {
            teal : require('./abodely/abodely-logo-flat.svg'),
            white : require('./abodely/abodely-logo-tall-white.png'),
        }
    },
    mock: {
        property: require('./mock/property.png'),
        property_2: require('./mock/property_mock.png'),
        property_4: require('./mock/property_4.jpg'),
        banner: require('./mock/banner.png'),
        properties : [
            require('./mock/property.png'),
            require('./mock/property_2.jpg'),
            require('./mock/property_3.jpg'),
            require('./mock/property_4.jpg'),
            require('./mock/property_5.jpg'),
            require('./mock/property_6.jpg'),
            require('./mock/property_7.jpg'),
            require('./mock/property_8.jpg'),
            require('./mock/property_9.jpg'),
            require('./mock/property_10.jpg'),

        ]
    },
    project : {
        deck : require('./mock/project_1.jpg'),
        bathroom : require('./mock/project_2.jpg'),
        demo : require('./mock/project_3.png'),
    },
    icon: require('./icons/icon.png'),
    MessageSent: require('./message-sent.svg'),
    MessageSentJump: require('./message-sent-jump.svg'),
    explore : {
        beInspired : require('./icons/be-inspired.svg'),
        exploreProjects : require('./icons/explore-projects.svg'),
        listProperty : require('./icons/list-property.svg'),
        getInsights : require('./icons/get-insights.svg'),
    },
    heroBanner: require('./mock/hero-banner.png'),
    userProfile: require('./userProfile.png'),
    signUpPlaceholder: require('./signUpPlaceholder.png'),
    filterIcon: require('./icons/filterIcon.svg'),
    noResult: require('./noResults.png'),
    landSize: require('./icons/landSize.svg'),
};

export default IMAGE;
