import React, { useState } from 'react';
import { View, Text, StyleSheet, SafeAreaView } from 'react-native';
import { TealButton } from '../components/Button';
import FontProperty from '../constants/FontProperty';
import Colors from '../constants/colors';
import Layout from '../constants/Layout';

import {
    CodeField,
    Cursor,
    useBlurOnFulfill,
    useClearByFocusCell
} from 'react-native-confirmation-code-field';
import { useNavigation } from '@react-navigation/native';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useAuthentication } from '../hooks/useAuthentication';

const CELL_COUNT = 4;
const functions = getFunctions();
const verifyUser = httpsCallable(functions, 'verifyUser');

const Verification = ({ setUser }) => {
    const [value, setValue] = useState('');
    const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT });
    const [props, getCellOnLayoutHandler] = useClearByFocusCell({
        value,
        setValue
    });
    const [err, setErr] = React.useState('');
    const [message, setMessage] = React.useState('');
    const nav = useNavigation();
    const [btnText, setBtnText] = React.useState('Verify');

    const onVerify = async () => {
        setBtnText('Verifying...');
        try {
            const response = await verifyUser({ verificationCode: parseInt(value) });
            if (response.data.success) {
                setUser(prevState => (
                    {
                        ...prevState,
                        emailVerified: true
                    }
                ));
                nav.navigate('Home');
            } else {
                setBtnText('Verification Failed');
                setErr(response.data.message);
            }
        } catch (e) {
            console.log(e);
            setBtnText('Verification Failed');
            setErr('Wrong verification code');
        }
    };

    const onNewCode = async () => {
        try {
            // const newCodeResponse = await api.auth.newVerificationCode();
            // if (newCodeResponse.data.sucess) {
            //     setMessage('A new code has been sent to your email');
            //     setErr('');
            // } else {
            //     setErr('Network Error');
            // }
            
        } catch {
            setErr('Network Error');
        }
    };

    return (
        <View style={Layout.globalPadding24}>
            <Text style={[FontProperty.TextMedium, FontProperty.h4, Layout.mbottom8]}>Email Verification</Text>
            <Text style={[FontProperty.p1, FontProperty.TextGrey, FontProperty.TextRegular, Layout.mbottom40]}>We&apos;ve sent a verification code to your email. Please enter it below.</Text>
            <SafeAreaView style={Layout.mbottom32}>
                <CodeField
                    ref={ref}
                    {...props}
                    value={value}
                    onChangeText={setValue}
                    cellCount={CELL_COUNT}
                    rootStyle={styles.codeFiledRoot}
                    keyboardType="number-pad"
                    textContentType="oneTimeCode"
                    renderCell={({ index, symbol, isFocused }) => (
                        <Text
                            key={index}
                            style={[styles.cell, isFocused && styles.focusCell]}
                            onLayout={getCellOnLayoutHandler(index)}>
                            {symbol || (isFocused ? <Cursor /> : null)}
                        </Text>
                    )}
                />
            </SafeAreaView>
            <TealButton onPress={onVerify} isDisabled ={false} ><Text style={[FontProperty.TextWhite, FontProperty.TextSemiBold, FontProperty.p2]}>{btnText}</Text></TealButton>
            {/* <TouchableOpacity onPress={onNewCode}>
                <Text style={styles.bottomText}>Didn't get it? <Text style={styles.bottomTextHyper}>Resend</Text></Text>
            </TouchableOpacity> */}
            <Text style={ [FontProperty.center, styles.errorText, FontProperty.TextError]}>{err}</Text>
            <Text style={ [FontProperty.center, styles.errorText, FontProperty.TextTeal]}>{message}</Text>
        </View>
    );
};

export default Verification;

const styles = StyleSheet.create({
    bottomText: {
        textAlign: 'center',
        marginTop: 25,
        color: Colors.grey4
    },

    bottomTextHyper: {
        color: Colors.teal
    },

    cell: {
        width: '20%',
        height: 68,
        borderWidth: 1,
        borderColor: Colors.grey4,
        textAlign: 'center',
        paddingTop: 18,
        fontSize: 24
    },

    focusCell: {
        borderColor: Colors.teal
    },
    errorText: {
        alignSelf: 'center',
        paddingTop: 15
    }
});
