import React, {useEffect, useState} from 'react';
import { StyleSheet, Text, TouchableOpacity, View, Image } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ListingBaseScreen } from './ListingBaseScreen';
import { getPropertyStyles, updatePropertyStyle } from '../../lib/api/Content/property';
import Layout from '../../constants/Layout';
import Colors from '../../constants/colors';
import { AddListingNavProps, AddListingStyleBasicProps } from '../../types';
import { IpropertyStyle } from '../../lib/api/Content/types';
import { getPropertyContent } from '../../lib/api/Content/property';
import FontProperty from '../../constants/FontProperty';

export const StyleScreen : React.FC = (props) => {
    const nav = useNavigation<AddListingNavProps>();
    const { propertyId } = useRoute<AddListingStyleBasicProps>().params;
    const [selectedArray, setSelectedArray] = useState([]);
    const [selectedItems, setSelectedItems] = useState<string | null>('');
    const [options, setOptions] = useState<IpropertyStyle[]>([]);
    const [err, setErr] = useState<string>('');
    const [published, setPublisted] = useState(false);

    useEffect(() => {
        getPropertyContent(propertyId).then(res=> {
            let tempStyle = selectedItems;
            if(res[0].style !== undefined) {
                setSelectedArray(res[0].style);
                res[0].style.forEach(element => {
                    tempStyle = tempStyle + element.uuid + ', ';
                });
                setSelectedItems(tempStyle);
            }
            res[0] !== undefined && res[0].published !== undefined && res[0].published === true && setPublisted(true);
        });
    }, [propertyId]);

    let tempArray = selectedArray;
    const onSelectHandlar = (id : string, style : string) => {
        const temp = {name: style, uuid: id};
        
        if(selectedItems?.includes(id)){
            tempArray = tempArray.filter(item => item.uuid != id);
            setSelectedItems(selectedItems.replace(id + ', ' , ''));
            setSelectedArray(tempArray.filter(item => item.uuid != id));
        } else {
            tempArray?.push(temp);
            setSelectedItems(selectedItems + id + ', ');
            setSelectedArray(tempArray);
        }
    };

    const onNextStep = async () => {
        if (selectedItems !== '') {
            await updatePropertyStyle(propertyId, selectedArray);
            nav.navigate('location', { propertyId });
        } else {
            setErr('Please select at least one option');
        }
    };

    useEffect(() => {
        getPropertyStyles().then(data => {
            setOptions(data);
        }).catch(err => {
            console.log('API : CONTENT : GET_PROPERTY_STYLES : ERROR :', err);
            setErr('Network Error');
        });
    }, []);

    return (
        <ListingBaseScreen
            step={2}
            header="What is your property style?"
            nextScreenHandler={onNextStep}
            error={err}
            setErr={setErr}
            isPublished={published}
        >
            <Text style={[FontProperty.body_1, FontProperty.TextGrey, {marginBottom: 24}]}>Select as many styles as you would like</Text>
            <View style={{flexDirection:'row',flexWrap:'wrap',justifyContent:'space-between'}} >
                {
                    options.map((item) => {
                        return (
                            <TouchableOpacity
                                style={[styles.itemContainer, Layout.mbottom25, selectedItems?.includes(item.uuid) && styles.isActive
                                ]}
                                onPress={() => onSelectHandlar(item.uuid, item.style)}
                                key={item.uuid}
                            >
                                <Image source={item.icon} style={[styles.ppimages, selectedItems?.includes(item.uuid)&& styles.hide]}/>
                                <Image source={item.iconActive} style={[styles.ppimages, !selectedItems?.includes(item.uuid) && styles.hide]}/>
                                <Text style={[styles.item, selectedItems?.includes(item.uuid) && styles.isActiveText]}>{item.style}</Text>
                            </TouchableOpacity>
                        );
                    })
                }
            </View>
        </ListingBaseScreen>
    );
};

const styles = StyleSheet.create({
    item: {
        fontSize: 16,
        color: Colors.lightNavy,
        fontFamily: 'inter-medium'
    },
    itemContainer: {
        borderWidth: 1,
        borderColor: Colors.grey4,
        backgroundColor: Colors.white,
        padding: 18,
        marginLeft: 0,
        marginTop: 0,
        alignItems: 'center',
        width: Layout.window.width > 425 ? '48%' : '100%',
        borderRadius: 4,
        flexDirection: 'column',
        justifyContent: 'center'
    },
    info: {
        marginLeft: 10
    },
    isActive: {
        borderColor: Colors.teal
    },
    isActiveText: {
        color: Colors.teal
    },
    ppimages : {
        width: 50,
        height: 50,
        marginBottom: 13,
        resizeMode : 'contain'
    },
    hide: {
        display: 'none'
    }
});
