import React from 'react';
import { View, StyleSheet, Text, ActivityIndicator } from 'react-native';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import { AntDesign, } from '@expo/vector-icons';
import { TealButton, NavyButton } from '../Button';
import Layout from '../../constants/Layout';

interface IuploadBox {
  onUpload : () => void
  theme ? : string
}

export const Uploadbox : React.FC<IuploadBox> = ({ onUpload, theme }) => {
    const [loading, setLoading] = React.useState(false);
    const [isComplete, setIsComplete] = React.useState(false);

    const uploadHandler = async () => {
        setIsComplete(false);
        setLoading(true);
        await onUpload();
        setLoading(false);
        setIsComplete(true);
    };

    return (<>
        <View style={styles.container}>
            <View style={[styles.iconContainer, loading && { backgroundColor: '#fff' }]}>
                {
                    loading
                        ?  (theme !== undefined && theme === 'blue') ? <ActivityIndicator size={Layout.window.width > 425 ? 107 : 80} color={Colors.navy} /> : <ActivityIndicator size={Layout.window.width > 425 ? 107 : 80} color={Colors.teal} /> 
                        : <AntDesign
                            name='arrowup'
                            size={55}
                            style={styles.icon}
                        />
                }
            </View>
            <View style={styles.btnContainer} >
                {
                    (theme !== undefined && theme === 'blue') ?
                        <NavyButton
                            onPress={uploadHandler}
                        ><Text style={[FontProperty.TextWhite, FontProperty.TextSemiBold, FontProperty.p2]}>{loading ? 'Uploading...' : 'Upload' } </Text></NavyButton>
                        :<TealButton
                            onPress={uploadHandler}
                        ><Text style={[FontProperty.TextWhite, FontProperty.TextSemiBold, FontProperty.p2]}>{loading ? 'Uploading...' : 'Upload' } </Text></TealButton>

                }
            </View>
        </View>
        <View style={{ paddingTop: 30 }}>
            {isComplete && <Text style={[FontProperty.center, FontProperty.h1, FontProperty.TextNavy]}>Images have been uploaded</Text>}
        </View>
    </>
    );
};

export default Uploadbox;

const styles = StyleSheet.create({
    container: {
        borderWidth: 1,
        borderColor: Colors.grey4,
        borderRadius: 8,
        paddingTop: Layout.window.width > 425 ? 77 : 20,
        paddingBottom: Layout.window.width > 425 ? 22 : 20,
        alignItems: 'center'
    },
    iconContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.beige,
        borderRadius: 53.5,
        width: Layout.window.width > 425 ? 107 : 80,
        height: Layout.window.width > 425 ? 107 : 80,
        marginBottom: Layout.window.width > 425 ? 63 : 20,
    },
    icon: {
        color: Colors.darkBeige
    },
    btnContainer: {
        width: 155
    },
    closeIcon: {
        position: 'absolute',
        right: 10,
        zIndex: 1
    },
    imageBlock: {
        height: 150, width: Layout.window.width * 0.3, marginHorizontal: 10, borderRadius: 8
    }
});
