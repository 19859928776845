import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { NameProjectScreen } from '../screens/AddProject/NameProjectScreen';
import { RearrangePhotosScreen } from '../screens/AddProject/RearrangePhotos';
import { ReviewListingScreen } from '../screens/AddProject/ReviewListingScreen';
import { ProjectTypeScreen } from '../screens/AddProject/TypeScreen';
import { UploadScreen } from '../screens/AddProject/UploadScreen';
import { AddListingStackParamList } from '../types';

const AddProjectStack = createNativeStackNavigator<AddListingStackParamList>();
export function AddProjectNavigator () {
    return (
        <AddProjectStack.Navigator initialRouteName='type' screenOptions={{ headerShown: false }}>
            <AddProjectStack.Screen name="type" component={ProjectTypeScreen} />
            <AddProjectStack.Screen name="name" component={NameProjectScreen} />
            <AddProjectStack.Screen name="upload" component={UploadScreen} />
            <AddProjectStack.Screen name="arrange" component={RearrangePhotosScreen} />
            <AddProjectStack.Screen name="review" component={ReviewListingScreen} />
        </AddProjectStack.Navigator>
    );
}
