let url
if (process.env.NODE_ENV && process.env.NODE_ENV == 'development') {
    url='http://127.0.0.1:8000'
} else if (window.location.host == 'devweb.abodely.app') {
    url='https://devapi.abodely.app'
} else if (window.location.host == 'web.abodely.app') {
    url='https://api.abodely.app'
}

var apiConfig = {
    url: url
};

export default apiConfig;