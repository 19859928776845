import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { StyleSheet, View, Text, TextInput } from 'react-native';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import DropDownPicker from 'react-native-dropdown-picker';
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';

export type TAddressForm = {
    setStreet : Dispatch<SetStateAction<string>>
    setSuburb : Dispatch<SetStateAction<string>>
    setState : Dispatch<SetStateAction<string>>
    setPostcode : Dispatch<SetStateAction<string>>
    setCountry : Dispatch<SetStateAction<string>>
    street : string
    suburb : string
    state : string
    postCode : string
    country : string
}

const AddressForm = (props:TAddressForm) => {
    const [open, setOpen] = useState(false);
    const [items, setItems] = useState([
        {label: 'Select a state', value: ''},
        {label: 'Victoria', value: 'VIC'},
        {label: 'Queensland', value: 'QLD'},
        {label: 'New South Wales', value: 'NSW'},
        {label: 'Australian Capital Territory', value: 'ACT'},
        {label: 'South Australia', value: 'SA'},
        {label: 'Western Australia', value: 'WA'},
        {label: 'Northern Territory', value: 'NT'},
        {label: 'Tasmania', value: 'TAS'},
    ]);
    const [address, setAddress] = useState<object[]>([]);

    useEffect(() => {
        address.length > 0 &&
        address.forEach(element => {
            if(element.types[0] === 'postal_code'){
                props.setPostcode(element.long_name);
            }
            if(element.types[0] === 'administrative_area_level_1'){
                props.setState(element.short_name);
            }
        });
    }, [address]);

    return (
        <>
            <View style={styles.fullWidth}>
                <Text style={[styles.text, FontProperty.smallP, FontProperty.TextSemiBold]}>Address</Text>
                <GooglePlacesAutocomplete
                    placeholder='Type in your address'
                    fetchDetails={true}
                    onPress={(data, details = null) => {
                        details !== null && details.address_components && setAddress(details.address_components);
                        if(details !== null && details.address_components){
                            props.setStreet(details.name);
                            props.setSuburb(details.vicinity);
                        }
                    }}
                    onFail={error => console.error(error)}
                    requestUrl={{
                        useOnPlatform: 'all',
                        url:
                            'https://automation.orangedigital.au/api',
                    }}
                    styles={{
                        textInput: {
                            paddingHorizontal: 8,
                            paddingVertical: 9,
                            color: '#4F4F4F',
                            fontSize: 14,
                            width: '90%',
                            flex : 1,
                            outlineStyle: 'none',
                            marginBottom: 0,
                            height: 'auto',
                            backgroundColor: 'transparent',
                            borderWidth: 1,
                            borderColor: Colors.grey5,
                            borderRadius: 4,
                        },
                    }}
                    query={{
                        key: 'AIzaSyAxbAZ68K4oIz5590Pp5di_TMSmStLmcZY',
                        language: 'en',
                        components: 'country:aus'
                    }}
                />
            </View>
            
            <View style={[Layout.rowBetween, {flexWrap:'wrap',zIndex:1,paddingTop:24}]}>
                <View style={styles.fullWidth}>
                    <Text style={[styles.text, FontProperty.smallP, FontProperty.TextSemiBold]}>Street</Text>
                    <TextInput 
                        value={props.street}
                        onChangeText={props.setStreet}
                        style={[styles.input]}
                    />
                </View>
                <View style={styles.halfWidth}>
                    <Text style={[styles.text, FontProperty.smallP, FontProperty.TextSemiBold]}>Suburb</Text>
                    <TextInput 
                        value={props.suburb}
                        onChangeText={props.setSuburb}
                        style={[styles.input]}
                    />
                </View>
                <View style={[styles.halfWidth,{zIndex:1}]}>
                    <Text style={[styles.text, FontProperty.smallP, FontProperty.TextSemiBold]}>State</Text>
                    <DropDownPicker
                        open={open}
                        value={props.state}
                        items={items}
                        setOpen={setOpen}
                        setValue={props.setState}
                        setItems={setItems}
                        placeholder=''
                        containerStyle={{
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            borderWidth: 1,
                            borderColor: Colors.grey5,
                            borderRadius: 4,
                            padding: 10,
                            marginBottom: 16,
                        }}
                        listItemContainerStyle={{
                            backgroundColor: Colors.white,
                            padding: 10,
                            flexDirection: 'row',
                        }}
                        selectedItemLabelStyle={{
                            color: Colors.teal
                        }}
                        dropDownContainerStyle={{
                            borderWidth: 1,
                            borderColor: Colors.grey5,
                            top: 37,
                            justifyContent: 'space-between',
                            left: 0,
                            maxHeight: 171
                        }}
                        textStyle={{
                            color: Colors.grey1,
                            fontSize: 14,
                        }}
                        tickIconStyle={{
                            borderColor: '#4F4F4F',
                            width: 12,
                            height: 12,
                        }}
                        arrowIconStyle={{
                            width: 12,
                            height: 12,
                            marginLeft: 8
                        }}
                        theme="LIGHT"
                        style={styles.dropdown}
                    />
                </View>
                <View style={styles.halfWidth}>
                    <Text style={[styles.text, FontProperty.smallP, FontProperty.TextSemiBold]}>Postcode</Text>
                    <TextInput 
                        value={props.postCode}
                        onChangeText={props.setPostcode}
                        style={[styles.input]}
                    />
                </View>
                <View style={styles.halfWidth}>
                    <Text style={[styles.text, FontProperty.smallP, FontProperty.TextSemiBold]}>Country</Text>
                    <TextInput 
                        value={props.country}
                        style={[styles.input]}
                    />
                </View>
            </View>
            
        </>
    );
};

const styles = StyleSheet.create({
    input : {
        fontSize: 14,
        borderWidth: 1,
        borderColor: Colors.grey5,
        width: '100%', 
        padding: 10,
        borderRadius: 4,
        backgroundColor: Colors.white,
        marginBottom: 20,
        outlineStyle: 'none',
    },
    fullWidth: {
        width: '100%'
    },
    halfWidth: {
        width: '48%'
    },
    text: {
        marginBottom: 10
    },
    dropdown : {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1
    },
});

export default AddressForm;
