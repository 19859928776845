export default {
    propertyTypes: 'propertyTypes',
    propertyFeatures: 'propertyFeatures',
    propertyImages: 'propertyImages',
    properties: 'properties',
    propertyStates: 'propertyStates',
    propertyStyles: 'propertyStyles',
    home: 'home',
    onboardingQuestions: 'onboardingQuestions',
    faqs: 'faqs',
    headshots: 'headshots',
    postImages: 'postImages',
    projectTypes: 'projectTypes',
    projects: 'projects',
    projectImages: 'projectImages',
    aboutUsScreen: 'aboutUsScreen',
    contactUsScreen: 'contactUsScreen',
    faqsScreen: 'faqsScreen'
};
