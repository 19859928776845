import React from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Animated, Image, TextInput } from 'react-native';
import Colors from '../constants/colors';
import FontProperty from '../constants/FontProperty';
import { Ionicons } from '@expo/vector-icons';
import Layout from '../constants/Layout';
import IMAGE from '../assets/images';
import { ProfileHeadshot } from '../components/atom/HomeOwnerHeadshot';
import { HouspoMessageInput } from '../components/atom/HouspoTextInput';
import { sendMessageHouspo } from '../lib/api/messages/messages';
import { IuserData } from '../lib/api/user/types';

export type TContactHomeownerModel = {
    open : boolean,
    succeedBtnLabel : string
    succeedText : string
    setContactHomeownerModal : React.Dispatch<boolean>
    hideHeader : boolean
    placeholder : string
    senderId : string
    receiverId : string
    targetUserData : IuserData
}

const ContactHomeownerModel : React.FC< TContactHomeownerModel > = (props) => {
    const [message, setMessage] = React.useState();
    const [messageStatus, setMessageStatus] = React.useState(false);
    const [bounceValue, setBounceValue] = React.useState(new Animated.Value(Layout.window.width + 200));
    const [opacityValue, setOpacityVlaue] = React.useState(new Animated.Value(0));
    const [jumpValue, setJumpValue] = React.useState(new Animated.Value(80));
    const [isHidden, setIsHidden ] = React.useState(props.open);
    const [isHiddenSucceed, setIsHiddenSucceed ] = React.useState(true);

    const closeWholeModel = () => {
        toggleSlide(false);
        setTimeout(() => {
            setIsHidden(false);
            props.setContactHomeownerModal(false);
        }, 500);
    };

    const sendMessage = async() => {
        if(message !== undefined && message !== '') {
            await sendMessageHouspo(props.senderId, props.receiverId, message).then(() => {
                setMessageStatus(true);
                toggleSlideSucceed(true);
                setIsHiddenSucceed(true);
            }).catch((error) => {
                console.log('SendMessageError: ' + error);
            });
        }
    };

    // Slide show and hide the model
    const toggleSlide = (status : boolean) =>  {
        let slideToValue = Layout.window.width + 200;
        if(status) {
            slideToValue = 0;
        }
        Animated.spring(
            bounceValue,
            {
                toValue: slideToValue,
                velocity: 10,
                tension: 2,
                friction: 8,
                useNativeDriver: false
            }
        ).start();
    };
    const toggleSlideSucceed = (status : boolean) =>  {
        let opacityToValue = 0;
        if(status) {
            opacityToValue = 1;
        }
        Animated.spring(
            opacityValue,
            {
                toValue: opacityToValue,
                velocity: 10,
                tension: 2,
                friction: 8,
                useNativeDriver: false
            }
        ).start();

        Animated.spring(
            jumpValue,
            {
                toValue: 53,
                velocity: 10,
                tension: 2,
                friction: 2,
                useNativeDriver: false
            }
        ).start();
    };

    toggleSlide(isHidden);
    
    return (
        <Animated.View style={[styles.outContainer, {transform: [{translateX: bounceValue}]}, !isHidden && {display:'none'}]}>
            <View>
                <TouchableOpacity onPress={closeWholeModel} style={Layout.row}>
                    <Ionicons name="arrow-back" size={23} color={Colors.grey1} style={{marginRight:15}}/>
                    {!props.hideHeader &&
                        <Text style={[FontProperty.h1, FontProperty.TextBlack]}>Contact homeowner</Text>
                    }
                </TouchableOpacity>
                <View style={{marginTop:33, marginBottom:36}}>
                    <ProfileHeadshot name={props.targetUserData.username} imgUrl={props.targetUserData.headshot ? props.targetUserData.headshot : IMAGE.userProfile} />
                </View>
                <Text style={[FontProperty.h1, FontProperty.TextBlack, Layout.mbottom40]}>Message</Text>
                <View>
                    <HouspoMessageInput placeholder={props.placeholder} setText={setMessage}/>
                </View>
            </View>
            <View>
                <TouchableOpacity
                    style={[styles.send, Layout.mbottom40, (message == undefined || message == '') && styles.opacity5]}
                    onPress={()=>(message == undefined || message == '') ? console.log('Empty') : sendMessage()}
                >
                    <Text style={[FontProperty.TextWhite, FontProperty.button_1]}>Send</Text>
                </TouchableOpacity>
            </View>
            {
                messageStatus &&
                <Animated.View style={[styles.overlay, {opacity:opacityValue}, !isHiddenSucceed && {display:'none'}]}>
                    <View style={styles.succeedModel}>
                        <Ionicons name="close" size={20} color="#4F4F4F" style={styles.close} onPress={closeWholeModel}/>
                        <Image source={IMAGE.MessageSent} style={[styles.image]} />
                        <Animated.Image source={IMAGE.MessageSentJump} style={[styles.imageJump, {top:jumpValue}]} />
                        <Text style={[FontProperty.h1, FontProperty.TextBlack]}>{props.succeedText}</Text>
                        <TouchableOpacity
                            style={[styles.send]}
                            onPress={closeWholeModel}
                        >
                            <Text style={[FontProperty.TextWhite, FontProperty.button_1]}>{props.succeedBtnLabel}</Text>
                        </TouchableOpacity>
                    </View>
                </Animated.View>
            }
        </Animated.View>
    );
};

const styles = StyleSheet.create({
    outContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: Colors.white,
        paddingHorizontal: 24,
        paddingTop: 26,
        justifyContent: 'space-between',
        zIndex: 2
    },
    message: {
        marginBottom: 10,
        borderWidth: 1,
        borderRadius: 8,
        paddingVertical: 17.5,
        alignItems: 'center',
        justifyContent: 'center',
        height: 55,
        borderColor: Colors.grey4,
    },
    activeMessage: {
        borderColor: Colors.teal,
    },
    send: {
        borderRadius: 8,
        paddingVertical: 17.5,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.teal,
        height: 56,
        marginTop: 40,
        width: '100%',
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.8)',
        position: 'absolute',
        top: 0,
        left: 0,
        paddingHorizontal: 24,
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        alignItems: 'center'
    },
    succeedModel: {
        backgroundColor: Colors.white,
        paddingHorizontal: 24,
        paddingTop: 62,
        paddingBottom: 37,
        alignItems: 'center',
        width: Layout.window.width > 425 ? 360 : '100%',
        borderRadius: 16
    },
    image: {
        width: 142,
        height: 150,
        marginBottom: 31,
    },
    imageJump: {
        width: 108,
        height: 79,
        position: 'absolute'
    },
    close: {
        position: 'absolute',
        right: 24,
        top: 22,
        zIndex: 2
    },
    opacity5: {
        opacity: 0.5
    },
});

export default ContactHomeownerModel;

