import React, { useEffect } from 'react';
import { StyleSheet, ScrollView, Image } from 'react-native';
import { Text, View } from '../../components/Themed';
import Colors from '../../constants/colors';
import Layout from '../../constants/Layout';
import FontProperty from '../../constants/FontProperty';
import { TealButton } from '../../components/Button';
import IMAGE from '../../assets/images/index';
import { useNavigation } from '@react-navigation/native';
import { updateUserOnboarding } from '../../lib/api/user/authentication';
import { useAuthentication } from '../../hooks/useAuthentication';
import ContentText from '../../constants/Content';

export type TOnboardingStep = {
    onboardAnwsers : string[],
}

export const OBAccountCreatedScreen = (props:TOnboardingStep)=> {
    const nav = useNavigation();
    const { user } = useAuthentication();

    const goToScreen = () => {
        props.onboardAnwsers[7] == 'q8_1_Property' ? nav.navigate('Start') : nav.navigate('Explore');
    };
    
    useEffect(() => {
        if (user) {
            updateUserOnboarding(user.uid, props.onboardAnwsers);
        }
    }, [user]);

    return (
        <ScrollView style={[Layout.globalPadding24, styles.outContainer]} contentInset={{bottom: 150}} showsVerticalScrollIndicator={false}>
            <View style={styles.topContainer}>
            </View>
            <View style={[styles.contentContainer]}>
                <Text style={[FontProperty.TextSemiBold, FontProperty.h4, Layout.mbottom8, FontProperty.TextBlack]}>Account Created!</Text>
                <Text style={[FontProperty.TextRegular, FontProperty.p1, Layout.mbottom52,FontProperty.TextBlack]}>{ContentText.accountCreated}</Text>
            </View>
            <View style={[styles.imageContainer, Layout.mbottom52, Layout.globalPadding24]}>
                <Image source={IMAGE.OnboardSucceed} style={styles.image} />
            </View>
            <TealButton isDisabled ={false} onPress={()=> {goToScreen();}} ><Text style={[FontProperty.TextWhite, FontProperty.TextSemiBold, FontProperty.p2]}>Get Started!</Text></TealButton>    
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    outContainer: {
        backgroundColor: Colors.white,
    },
    topContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 50,
    },
    contentContainer: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    imageContainer: {
        alignSelf: 'center',
    },
    image: {
        width: 340,
        height: 340,
    },
});
