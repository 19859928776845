import React from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import IMAGE from '../../assets/images';
import ScreenBase from '../../components/ScreenBase';
import RequestPasswordChangeForm from '../../components/forms/UpdateRequestPasswordForm';
import Colors from '../../constants/colors';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useNavigation } from '@react-navigation/core';
import { Ionicons } from '@expo/vector-icons';
import { TealButton } from '../../components/Button';
import SetNewPasswordForm from '../../components/forms/SetNewPasswordForm';
import SetNewPasswordChangeForm from '../../components/forms/UpdateSetNewPasswordForm';
import ForgotPasswordChangeForm from '../../components/forms/ForgotPassword';

export const RequestPasswordChangeScreen = () => {
    const nav = useNavigation();

    return (
        <ScreenBase>
            <Image source={IMAGE.AuthBanner} style={[Layout.banner]} />
            <View style={[Layout.screenContainer]}>
                <View style={{ backgroundColor: '#fff', paddingTop: 40 }}>
                    <TouchableOpacity style={[Layout.row, Layout.mbottom25]} onPress={() => { nav.goBack(); }} >
                        <Ionicons name='chevron-back' color={Colors.grey3} size={20} />
                        <Text style={[FontProperty.p1, FontProperty.TextGrey]} >Go Back</Text>
                    </TouchableOpacity>
                    <View style={[styles.textContainer]}>
                        <Text style={[FontProperty.TextMedium, FontProperty.h4, Layout.mbottom8]}>Forgot password?</Text>
                        <Text style={[FontProperty.p1, FontProperty.TextGrey, FontProperty.TextRegular, Layout.mbottom32]}>Step 1. Submit your email address and a verification code will be sent to your email address</Text>
                    </View>
                    <ForgotPasswordChangeForm />
                </View>
                <View style={{ backgroundColor: '#fff', paddingTop: 80 }}>
                    <View style={[styles.textContainer]}>
                        <Text style={[FontProperty.p1, FontProperty.TextGrey, FontProperty.TextRegular, Layout.mbottom32]}>Step 2. Received the code in your email? Click the button below to change your password </Text>
                    </View>
                    <View style={[Layout.mbottom32]}>
                        <TealButton onPress={()=>{ nav.navigate('SetNewPassword'); }}><Text style={[FontProperty.TextWhite, FontProperty.button_1]}>Change Password</Text></TealButton>
                    </View>
                </View>
            </View>
        </ScreenBase>
    );
};

const styles = StyleSheet.create({
    textContainer: {
        paddingBottom: 10
    },
    bottomText: {
        textAlign: 'center',
        marginTop: 20,
        color: Colors.grey4
    },
    bottomTextHyper: {
        color: Colors.teal
    },
}
);
