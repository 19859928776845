import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View, Image, Pressable, TextInput} from 'react-native';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import { getUserData } from '../../lib/api/user';
import { IuserData } from '../../lib/api/user/types';
import { useNavigation } from '@react-navigation/native';
import { AntDesign } from '@expo/vector-icons';
import * as ImagePicker from 'expo-image-picker';
import { uploadMultiplePostImages } from '../../lib/api/Content/images';
import { createPost } from '../../lib/api/Content/posts/posts';
import ProfileHeadshotById from './HomeOwnerHeadshotByID';
import { compressImage } from '../../lib/utils/compressImage';

export type TpostAPost = {
    uid : string
    setTrigger: (arg0:boolean)=>void
    trigger : boolean
}

const PostAPost = (props:TpostAPost) => {
    const nav = useNavigation();
    const [userData, setUserData] = useState<IuserData>();
    const [postImage, setPostImage] = useState(false); 
    const [text, setText] = useState('');
    const [err, setErr] = useState<string>('');
    // const [uploadedImages, setUploadedImages] = React.useState<string[]>([]);
    const [postImages, setPostImages] = React.useState<string[]>([]);
    const [uploading, setUploading] = useState(false); 
    const [trigger, setTrigger] = useState(false); 
    const [succeed, setSucceed] = useState(false); 

    useEffect(() => {
        getUserData(props.uid).then(userData => {
            setUserData(userData.data());
        });
    }, [props.uid]);

    if(userData === undefined){
        return null;
    }
    const onUpload = async () => {
        try {
            setUploading(true);
            const images = await pickImages();
            if (images.length > 0) {
                const postImagesCallback = await uploadMultiplePostImages(images, props.uid);
                if (postImagesCallback.length > 0) {
                    const allImages = [...postImages,  ...postImagesCallback];
                    setPostImages(allImages);
                    setUploading(false);
                    return;
                }
            }
        } catch (error) {
            setErr('Something went wrong');
            console.log(error);
        }
    };
    const pickImages = async () => {
        try {
            const result = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsEditing: true,
                aspect: [4, 3],
                quality: 1,
                allowsMultipleSelection: true,
                base64: true,
            });
            if (!result.cancelled) {
                if(result.selected !== undefined && result.selected.length > 0){
                    return await compressImage(result.selected);
                }
            } else {
                setPostImage(false);
                return [];
            }
        } catch (error) {
            throw new Error(error);
        }
    };
    
    const removePic = (index:number) => {
        postImages.splice(index, 1);
        setPostImages(postImages);
        setTrigger(!trigger);
    };

    const createMyPost = async () => {
        if(text !== '' || postImages.length > 0){
            await createPost(props.uid, text, postImages);
            setSucceed(true);
            setPostImage(false);
            setPostImages([]);
            setText('');
            props.setTrigger(!props.trigger);
            setTimeout(() => {
                setSucceed(false);
            }, 2000);
            
        }
    };

    return (
        <View>
            <View style={{paddingVertical:16, paddingHorizontal:24}}>
                <View style={[Layout.row, Layout.rowBetween, Layout.mbottom25]}>
                    <ProfileHeadshotById userId={props.uid} onClickFunction={()=>nav.navigate({name: 'UserProfile', params: { userId: props.uid, postId: '' }})} />
                    <Pressable style={styles.btn} onPress={()=>createMyPost()}><Text style={[FontProperty.TextWhite, FontProperty.TextSemiBold]}>
                        {
                            succeed
                                ? <Text>Posted!</Text>
                                : <Text>Post</Text>
                        }
                    </Text></Pressable>
                </View>
                <View >
                    <Pressable onPress={()=>setPostImage(true)}>
                        <TextInput
                            autoCapitalize='none'
                            style={[styles.input]}
                            placeholder='What do you want to talk about?'
                            placeholderTextColor={Colors.grey3}
                            onChangeText={setText}
                            value={text}
                            multiline={true}
                            numberOfLines={postImage ? 3 : 1}
                        />
                    </Pressable>
                    {
                        postImage &&
                        <View style={[Layout.row]}>
                            {postImages.length > 0 && postImages.map((uploadImage, index) => {
                                return <View key={index} style={{marginRight:10,marginBottom:10}}>
                                    <Image style={[styles.itemContainer]} resizeMode={'cover'} source={{ uri: uploadImage }}/>
                                    <Pressable onPress={()=>removePic(index)} style={[styles.removeIcon, {backgroundColor: Colors.red}]}>
                                        <AntDesign name='close' size={14} color={Colors.white}/>
                                    </Pressable>
                                </View>;
                            }
                            )}
                            {
                                uploading
                                    ? <Pressable onPress={()=>{onUpload();}}>
                                        <Text style={[FontProperty.TextGrey, FontProperty.smallP]}>Uploading...</Text>
                                    </Pressable>
                                    : <Pressable style={styles.postImage} onPress={()=>{onUpload();}}>
                                        <AntDesign name='plus' color={Colors.grey3}/>
                                    </Pressable>
                            }
                        </View>
                    }
                </View>
            </View>
        </View>
       
    );
};

const styles = StyleSheet.create({
    btn: {
        backgroundColor: Colors.teal,
        borderRadius: 4,
        paddingHorizontal: 15,
        paddingVertical: 4.5
    },
    input: {
        color: Colors.navy,
        fontSize: 14,
        width: '100%',
        flex : 1,
        outlineStyle: 'none' 
    },
    postImage: {
        borderWidth: 1,
        borderColor: Colors.grey4,
        alignItems: 'center',
        justifyContent: 'center',
        width: 48,
        height: 48,
        borderRadius: 4,
        marginBottom:10,
        borderStyle: 'dashed',
    },
    itemContainer: {
        alignItems: 'center',
        width: 48,
        height: 48,
        borderRadius: 4
    },
    removeIcon: {
        position: 'absolute',
        right: -5,
        top: -5,
        borderRadius: 50,
        padding: 2,
        width: 18,
        height: 18,
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default PostAPost;
