import React, {useEffect, useState} from 'react';
import { StyleSheet, View, Text, Pressable } from 'react-native';
import Layout from '../../constants/Layout';
import { getFollowPropertiesByTime, getPollByTime, getVisitorByTime } from '../../lib/api/Content/posts/posts';
import HouspoDropdown from './HouspoDropdown';
import { LinearGradient } from 'expo-linear-gradient';
import Colors from '../../constants/colors';
import { AntDesign, FontAwesome5 } from '@expo/vector-icons';
import FontProperty from '../../constants/FontProperty';
import BarChartBar from './BarChartBar';

export type THouspoBarChart = {
    propertyId : string
}

const HouspoBarChart = (props:THouspoBarChart) => {
    const today = Date.now();
    const [openFilterState, setFilterStateOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [numberOfFollows, setNumberOfFollows] = useState(0);
    const [numberOfVisitors, setNumberOfVisitors] = useState(0);
    const [numberOfPoll, setNumberOfPoll] = useState(0);
    const [filterItems, setFilterItems] = useState([
        {label: 'Last 7 days', value: (today-604800000)},
        {label: 'Last 30 days', value: (today-2592000000)},
        {label: 'Last 3 months', value: (today-7776000000)},
        {label: 'Last 6 months', value: (today-15552000000)},
        {label: 'Last 1 year', value: (today-31536000000)},
    ]);
    const [filterValue, setFilterValue] = useState(filterItems[0].value);
    const [selectedBar, setSelectedBar] = useState('');

    useEffect(() => {
        const getBarData = async () =>{
            await getVisitorByTime(props.propertyId, filterValue, today).then(res => {
                (res !== undefined && res > 0) ? setNumberOfVisitors(res) : setNumberOfVisitors(0);
            });
            
            await getFollowPropertiesByTime(props.propertyId, filterValue, today).then(res => {
                (res !== undefined && res > 0) ? setNumberOfFollows(res) : setNumberOfFollows(0);
            });
           
            await getPollByTime(props.propertyId, filterValue, today).then(res => {
                (res !== undefined && res > 0) ? setNumberOfPoll(res) : setNumberOfPoll(0);
            });

            setLoading(false);
        }; 

        props.propertyId !== undefined && getBarData();
        setSelectedBar('');
        
    }, [filterValue, props.propertyId]);

    useEffect(() => {
        setFilterValue(filterItems[0].value);
        setFilterStateOpen(false);
    }, [props.propertyId]);

    if(loading || props.propertyId === undefined){
        return null;
    }

    const dataSet = [numberOfVisitors, numberOfFollows, numberOfPoll];
    // const dataSet = [2131, 1012, 8763];
    const maxData = Math.max(...dataSet);
    const percentageIndex = maxData < 10 ? 1 : maxData.toString().length;
    const yAxisIndex = Math.pow(10, percentageIndex);
    const yAxis = [...Array(6).keys()];
    yAxis.reverse();

    const barHeight = Layout.window.width > 425 ? 300 : 220;
    
    return (
        <View style={[{paddingTop:12,paddingBottom: 86,zIndex:5}]}>
            <View style={[Layout.globalPadding24, {alignItems:'flex-end',zIndex:5,paddingBottom:53}]}>
                <View style={[{width: 200}]}>
                    <HouspoDropdown 
                        open={openFilterState}
                        value={filterValue}
                        items={filterItems}
                        setOpen={setFilterStateOpen}
                        setValue={(filterValue)=>{
                            setFilterValue(filterValue);
                        }}
                        setItems={setFilterItems}
                        placeholder="Select a state"
                        narrowPadding={true}
                    />
                </View>
            </View>
            <Pressable style={[styles.barOutContainer, {height: barHeight}]} onPress={()=>setSelectedBar('')}>
                <View style={styles.yAxis}>
                    {
                        yAxis.map((item, index)=>{
                            return (
                                <View key={index}>
                                    <Text style={styles.textBlack}>{item*yAxisIndex/5}</Text>
                                    <View style={styles.dash}></View>
                                </View>
                            );
                        })
                    }
                </View>
                <View style={styles.xAxis}>
                    <View style={styles.bar}></View>
                    <Pressable 
                        style={[styles.bar, dataSet[0] > 0 && styles.topline, selectedBar === 'view' && styles.zIndexTop]}
                        onPress={()=> selectedBar === 'view' ? setSelectedBar('') : setSelectedBar('view')}
                    >   
                        <BarChartBar height={(dataSet[0]/(yAxis[0]*yAxisIndex/5)) * barHeight} icon='eye'/>
                        <View style={styles.triangle}></View>
                        {
                            selectedBar === 'view' &&
                            <>
                                <AntDesign name='caretdown' color={Colors.beige} style={styles.triangle} size={20}/>
                                <View style={styles.popup}>
                                    <Text style={[FontProperty.smallP, FontProperty.TextBlack]}>{dataSet[0]} Property View{dataSet[0] > 1 && 's'}</Text>
                                </View>
                            </>
                        }
                    </Pressable>
                    <View style={styles.bar}></View>
                    <Pressable 
                        style={[styles.bar, dataSet[1] > 0 && styles.topline, selectedBar === 'follow' && styles.zIndexTop]}
                        onPress={()=>selectedBar === 'follow' ? setSelectedBar('') : setSelectedBar('follow')}
                    >
                        <BarChartBar height={(dataSet[1]/(yAxis[0]*yAxisIndex/5)) * barHeight} icon='user-friends'/>
                        <View style={styles.triangle}></View>
                        {
                            selectedBar === 'follow' &&
                            <>
                                <AntDesign name='caretdown' color={Colors.beige} style={styles.triangle} size={20}/>
                                <View style={styles.popup}>
                                    <Text style={[FontProperty.smallP, FontProperty.TextBlack]}>{dataSet[1]} Follower{dataSet[1] > 1 && 's'}</Text>
                                </View>
                            </>
                        }
                    </Pressable>
                    <View style={styles.bar}></View>
                    <Pressable 
                        style={[styles.bar, dataSet[2] > 0 &&styles.topline, selectedBar === 'poll' && styles.zIndexTop]}
                        onPress={()=>selectedBar === 'poll' ? setSelectedBar('') : setSelectedBar('poll')}
                    >
                        <BarChartBar height={(dataSet[2]/(yAxis[0]*yAxisIndex/5)) * barHeight} icon='list-ul'/>
                        <View style={styles.triangle}></View>
                        {
                            selectedBar === 'poll' &&
                            <>
                                <AntDesign name='caretdown' color={Colors.beige} style={styles.triangle} size={20}/>
                                <View style={[styles.popup, {right: -30}]}>
                                    <Text style={[FontProperty.smallP, FontProperty.TextBlack]}>{dataSet[2]} Survey Complete{dataSet[2] > 1 && 's'}</Text>
                                </View>
                            </>
                        }
                    </Pressable>
                    <View style={styles.bar}></View>
                </View>
            </Pressable>
        </View>
    );
};

const styles = StyleSheet.create({
    barOutContainer: {
        width: Layout.window.width, 
        flexDirection: 'row',
        paddingHorizontal: 12
    },
    yAxis: {
        justifyContent: 'space-between',
        paddingRight: 16
    },
    xAxis: {
        flex:1,
        flexDirection: 'row',
        alignItems: 'flex-end',
        paddingBottom: 7
    },
    textBlack: {
        color: '#666666',
    },
    topline: {
        borderTopWidth: 3,
        borderColor: '#B5B5B5'
    },
    dash: {
        borderTopWidth: 1,
        borderColor: '#B5B5B5',
        borderStyle: 'dashed',
        position: 'absolute',
        width: Layout.window.width - 48,
        bottom: 7,
        left: '110%'
    },
    popup: {
        backgroundColor: Colors.beige,
        borderRadius: 8,
        padding: 16,
        width: 'max-content',
        position: 'absolute',
        top: -70
    },
    zIndexTop: {
        zIndex: 1
    },
    triangle: {
        position: 'absolute',
        top: -30
    },
    bar: {
        flex: 1,
        alignItems: 'center'
    },
});

export default HouspoBarChart;

