import React from 'react';
import { StyleSheet, View, Text, Pressable, TouchableOpacity, Image } from 'react-native';
import Layout from '../../constants/Layout';
import FontProperty from '../../constants/FontProperty';
import { TealButton, NavyButton } from '../Button';
import Colors from '../../constants/colors';
import { Ionicons } from '@expo/vector-icons';
import IMAGE from '../../assets/images';

export type TPopUpPropertyModal = {
    title : string
    yesBtnLabel : string
    noBtnLable ? : string
    yesBtnOnClick : () => void
    noBtnOnClick ? : () => void
    darktheme ? : boolean
}
const PopUpPropertyModal = (props:TPopUpPropertyModal) => {
    return (
        <View style={styles.popUpContainer}>
            <View style={styles.popUpInnerContainer}>
                <TouchableOpacity onPress={()=>props.yesBtnOnClick()}>
                    <Ionicons name='close' size={20} style={styles.MenuClose_big} color={Colors.black}/>
                </TouchableOpacity>
                <View style={{alignItems:'center'}}>
                    <Image source={(props.darktheme !== undefined && props.darktheme) ? IMAGE.AddListing.saveDark : IMAGE.AddListing.save} style={{resizeMode:'contain',width:215,height:143}} />
                    <Text style={[FontProperty.h1,{marginVertical:40}]}>{props.title}</Text>
                </View>
                <View>
                    {
                        (props.darktheme !== undefined && props.darktheme)
                            ? <NavyButton onPress={()=>props.yesBtnOnClick()} isDisabled ={false} ><Text style={[FontProperty.TextWhite, FontProperty.TextSemiBold, FontProperty.p2]}>{props.yesBtnLabel}</Text></NavyButton>
                            : <TealButton onPress={()=>props.yesBtnOnClick()} isDisabled ={false} ><Text style={[FontProperty.TextWhite, FontProperty.TextSemiBold, FontProperty.p2]}>{props.yesBtnLabel}</Text></TealButton>
                    
                    }
                    {props.noBtnLable && <Pressable onPress={()=>props.noBtnOnClick()}><Text style={[FontProperty.TextTeal, {textAlign:'center',paddingTop:10}]}>{props.noBtnLable}</Text></Pressable>}
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    popUpContainer: {
        position:'absolute',
        zIndex: 3,
        width: '100%',
        height: Layout.window.height,
        backgroundColor: '#000000cc',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 24,
    },
    popUpInnerContainer: {
        width: Layout.window.width > 425 ? 360 : '100%',
        backgroundColor: Colors.white,
        borderRadius: 16,
        padding: 32
    },
    MenuClose_big: {
        width: 21,
        height: 21,
        marginBottom: 16,
        alignSelf: 'flex-end'
    },
});

export default PopUpPropertyModal;
