import { Ionicons } from '@expo/vector-icons';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';

const InfoBlurb : React.FC = (props) => {
    return (
        <View style={Layout.row}>
            <View style={styles.iconContainer}>
                <Ionicons name="information-circle-outline" size={24} color={Colors.teal} />
            </View>
            <View style={[styles.textContainer]} >
                <Text style={[FontProperty.body_1, FontProperty.TextGrey]}>
                    {props.children}
                </Text>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    iconContainer: {
        paddingRight: 8,
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        height: '100%'
    },
    textContainer: {
        width: 0,
        flexGrow: 1,
        flex: 1,
        paddingTop: 3
    }
});

export default InfoBlurb;
