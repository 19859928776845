import React from 'react';
import { Platform, StyleSheet, Text, TextInput, View, Image, TouchableOpacity } from 'react-native';
import IMAGE from '../../assets/images';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';

export type TSearchBar = {
    placeholder: string
    onPost: (text: string) => Promise<void>
    userImage : string
    darkTheme ? : boolean
}

const CommentBar: React.FC<TSearchBar> = (props) => {
    const [searchText, setSearchText] = React.useState('');
    const onPost = async () => {
        {
            searchText !== '' && await props.onPost(searchText);
        }
        
        setSearchText('');
    };
    return (
        <View style={[styles.container, Layout.row]}>

            <View style={[styles.imageContainer]}>
                <Image source={props.userImage ? props.userImage : IMAGE.userProfile} style={[Layout.circle(55)]} />
            </View>

            <View style={[Layout.row, styles.textInputContainer]}>
                <TextInput placeholder={props.placeholder} style={[styles.textInput, Platform.OS === 'web' && Layout.noOutline]} clearButtonMode={'always'} value={searchText} onChange={(e) => { setSearchText(e.target.value); }} />
            </View>
            <TouchableOpacity onPress={onPost}>
                <Text style={[styles.post, props.darkTheme && FontProperty.TextNavy ]}>
                    Post
                </Text>
            </TouchableOpacity>

        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        alignSelf: 'center',
        width: '100%',
        paddingBottom: 25
    },
    textInputContainer: {
        backgroundColor: Colors.grey6,
        borderRadius: 8,
        padding: 11,
        flex: 1
    },
    searchIcon: {
        paddingRight: 16
    },
    textInput: {
        flex: 1
    },
    imageContainer: {
        paddingRight: 10
    },
    post: {
        color: Colors.teal,
        fontWeight: '400',
        fontSize: 14,
        paddingLeft: 16
    },
    searchResult: {
        flex: 1,
        backgroundColor: '#fff',
        height: Layout.window.height,
        width: Layout.window.width
    }
});

export default CommentBar;
