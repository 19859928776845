import { AntDesign } from '@expo/vector-icons';
import React, {useState, useEffect, Dispatch, SetStateAction} from 'react';
import { Pressable, StyleSheet, Text, TextInput, View, Image } from 'react-native';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import { uploadMultiplePostImages } from '../../lib/api/Content/images';
import { getPostContent, updatePostContent, updatePostImages } from '../../lib/api/Content/posts/posts';
import { IPropertyPost } from '../../lib/api/Content/posts/types';
import ProfileHeadshotById from './HomeOwnerHeadshotByID';
import * as ImagePicker from 'expo-image-picker';
import { compressImage } from '../../lib/utils/compressImage';
import { uppercaseFirstLetter } from '../../lib/utils/uppercaseFirstLetter';

export type TPopUpEditPost = {
  userId : string
  postId : string
  setShowEditPopUp : Dispatch<SetStateAction<boolean>>
  trigger : boolean
  setTrigger : (arg0:boolean) => void
}
const PopUpEditPost = (props:TPopUpEditPost) => {
    const [text, setText] = useState('');
    const [originText, setOriginText] = useState('');
    const [postimages, setPostImages] = useState<string[]>([]);
    const [originImages, setOriginImages] = useState<string[]>([]);
    const [post, setPost] = useState<IPropertyPost>();
    const [uploading, setUploading] = useState(false); 
    const [trigger, setTrigger] = useState(false); 

    useEffect(() => {
        props.postId &&
        getPostContent(props.postId).then(postData => {
            if(postData){
                setPost(postData);
                setText(postData.description);
                setOriginText(postData.description);
                postData.images && postData.images.length > 0 && setPostImages(postData.images);
                postData.images && postData.images.length > 0 && setOriginImages(postData.images);
            }
        });
    },[props.postId]);

    if(post == undefined){
        return null;
    }

    const onUpload = async () => {
        try {
            setUploading(true);
            const images = await pickImages();

            if (images.length > 0) {
                const postImagesCallback = await uploadMultiplePostImages(images, props.userId);
                if (postImagesCallback.length > 0) {
                    const allImages = [...postimages,  ...postImagesCallback];
                    setPostImages(allImages);
                    setUploading(false);
                    return;
                }
            }
        } catch (error) {
            console.log(error);
        }
    };
    const pickImages = async () => {
        try {
            const result = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsEditing: true,
                aspect: [4, 3],
                quality: 1,
                allowsMultipleSelection: true,
                base64: true,
            });
            if (!result.cancelled) {
                if(result.selected !== undefined && result.selected.length > 0){
                    return await compressImage(result.selected);
                }
            } else {
                return [];
            }
        } catch (error) {
            throw new Error(error);
        }
    };
  
    const removePic = (index:number) => {
        postimages.splice(index, 1);
        setPostImages(postimages);
        setTrigger(!trigger);
    };

    const doneBtnOnClick = async () => {
        text !== '' && text !== originText && await updatePostContent(props.postId, text);
        await updatePostImages(props.postId, postimages);
        props.setTrigger(!props.trigger);
        props.setShowEditPopUp(false);
    };
    
    
    return (
        <View style={styles.popUpContainer}>
            <View style={styles.popUpInnerContainer}>
                <View style={styles.topButtons}>
                    <Pressable onPress={()=>props.setShowEditPopUp(false)}><Text style={[FontProperty.p1,FontProperty.TextGrey,FontProperty.TextSemiBold,{textAlign:'center'}]}>Cancel</Text></Pressable>
                    <Pressable onPress={()=>doneBtnOnClick()}><Text style={[FontProperty.p1,FontProperty.TextTeal,FontProperty.TextSemiBold,{textAlign:'center'}, post.projectId && styles.textNavy]}>Done</Text></Pressable>
                </View>
                <View style={{paddingVertical:16, paddingHorizontal:24}}>
                    <View style={[Layout.row, Layout.rowBetween, Layout.mbottom25]}>
                        <ProfileHeadshotById userId={props.userId} />
                    </View>
                    <View >
                        <TextInput
                            autoCapitalize='none'
                            style={[styles.input]}
                            placeholder='Write something...'
                            placeholderTextColor={Colors.grey3}
                            onChangeText={setText}
                            value={text}
                            multiline={true}
                            numberOfLines={4}
                        />
                    </View>
                </View>
                {
                    postimages && !post.propertyId && !post.projectId &&
                        <View style={[Layout.row, Layout.globalPadding24]}>
                            {postimages.length > 0 && postimages.map((uploadImage, index) => {
                                return <View key={index} style={{marginRight:10,marginBottom:10}}>
                                    <Image style={[styles.itemContainer]} resizeMode={'cover'} source={{ uri: uploadImage }}/>
                                    <Pressable onPress={()=>removePic(index)} style={[styles.removeIcon, {backgroundColor: Colors.red}]}>
                                        <AntDesign name='close' size={14} color={Colors.white}/>
                                    </Pressable>
                                </View>;
                            }
                            )}
                            {
                                uploading
                                    ? <Pressable onPress={()=>{onUpload();}}>
                                        <Text style={[FontProperty.TextGrey, FontProperty.smallP]}>Uploading...</Text>
                                    </Pressable>
                                    : <Pressable style={styles.postImage} onPress={()=>{onUpload();}}>
                                        <AntDesign name='plus' color={Colors.grey3}/>
                                    </Pressable>
                            }
                        </View>
                }
                {
                    post.propertyId && post.propertyFImage && post.propertyTitle && post.propertyDesc && 
                    <View style={{paddingHorizontal:24}}>
                        <View style={styles.boxShaw}>
                            <Image source={post.propertyFImage} resizeMode='cover' style={{width: Layout.window.width > 425 ? 312 : Layout.window.width - 96, height: 95}} />
                            <View style={{padding:10}}>
                                <Text style={[FontProperty.smallP,FontProperty.TextBold,{marginBottom:4}]}>{post.propertyTitle}</Text>
                                <Text style={[FontProperty.smallP,{marginBottom:16}]} numberOfLines={1} ellipsizeMode='tail' >{uppercaseFirstLetter(post.propertyDesc)}</Text>
                                <View style={styles.btn}><Text style={[FontProperty.TextTeal,FontProperty.TextSemiBold]}>View updated property</Text></View>
                            </View>
                        </View>
                    </View>
                }
                {
                    post.projectId && post.propertyFImage && post.propertyTitle && post.propertyDesc && 
                    <View style={{paddingHorizontal:24}}>
                        <View style={styles.boxShaw}>
                            <Image source={post.propertyFImage} resizeMode='cover' style={{width: Layout.window.width > 425 ? 312 : Layout.window.width - 96, height: 95}} />
                            <View style={{padding:10}}>
                                <Text style={[FontProperty.smallP,FontProperty.TextBold,{marginBottom:4}]}>{post.propertyTitle}</Text>
                                <Text style={[FontProperty.smallP,{marginBottom:16}]} numberOfLines={1} ellipsizeMode='tail' >{uppercaseFirstLetter(post.propertyDesc)}</Text>
                                <View style={[styles.btn,styles.btnNavy]}><Text style={[FontProperty.TextNavy,FontProperty.TextSemiBold]}>View updated project</Text></View>
                            </View>
                        </View>
                    </View>
                }
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    popUpContainer: {
        position:'absolute',
        zIndex: 3,
        width: '100%',
        height: Layout.window.height,
        backgroundColor: '#000000cc',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 24,
    },
    popUpInnerContainer: {
        width: Layout.window.width > 425 ? 360 : '100%',
        backgroundColor: Colors.white,
        borderRadius: 16,
        paddingBottom: 24
    },
    topButtons: {
        justifyContent:'space-between',
        alignItems:'center',
        flexDirection:'row',
        padding: 24,
        borderBottomWidth: 0.5,
        borderColor: '#4f4f4f4d'
    },
    input: {
        color: Colors.navy,
        fontSize: 14,
        width: '100%',
        flex : 1,
        outlineStyle: 'none' 
    },
    btn: {
        width: '100%',
        borderColor: Colors.teal,
        borderWidth: 1,
        textAlign: 'center',
        paddingVertical: 7.5,
        borderRadius: 4
    },
    btnNavy: {
        borderColor: Colors.navy,
    },
    boxShaw: {
        boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.08)'
    },
    postImage: {
        borderWidth: 1,
        borderColor: Colors.grey4,
        alignItems: 'center',
        justifyContent: 'center',
        width: 48,
        height: 48,
        borderRadius: 4,
        marginBottom:10,
        borderStyle: 'dashed',
    },
    itemContainer: {
        alignItems: 'center',
        width: 48,
        height: 48,
        borderRadius: 4
    },
    removeIcon: {
        position: 'absolute',
        right: -5,
        top: -5,
        borderRadius: 50,
        padding: 2,
        width: 18,
        height: 18,
        justifyContent: 'center',
        alignItems: 'center',
    },
    textNavy: {
        color: Colors.navy
    }
});

export default PopUpEditPost;
