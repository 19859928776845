


import React, { useState } from 'react';
import { StyleSheet, View, Text, Pressable, Linking} from 'react-native';
import Layout from '../../constants/Layout';
import Colors from '../../constants/colors';
import { Ionicons, FontAwesome, Feather, Fontisto } from '@expo/vector-icons';
import FontProperty from '../../constants/FontProperty';
import * as Clipboard from 'expo-clipboard';

export type TShareModal = {
    setShareModal : () => void
    url : string
    mailSubject : string
    title : string
}

const ShareModal = (props:TShareModal) => {
    const [textCopied, setTextCopied] = useState(false);

    
    const shareLinkTo = (target:string) => {
        Linking.openURL(target);
    };

    const copyText = () => {
        Clipboard.setString(props.url);
        setTextCopied(true);
    };

    const targetURL = props.url.replace('&','%26');
    

    return (
        <View style={styles.popUpContainer}>
            <View style={styles.popUpInnerContainer}>
                <View style={Layout.rowBetween}>
                    <Text style={[FontProperty.h1, {marginBottom:8}]}>Share this {props.title}</Text>
                    <Ionicons name="close" size={20} color="#4F4F4F" onPress={()=>props.setShareModal()}/>
                </View>
                <View>
                    {/* Share to fb */}
                    <Pressable style={styles.shareBtn} onPress={()=>shareLinkTo('https://www.facebook.com/sharer/sharer.php?u=' + targetURL)}>
                        <FontAwesome name='facebook-square' color='#1877F2' size={22} style={styles.icon} />
                        <Text style={[FontProperty.TextSemiBold, FontProperty.h2, styles.marginLeftShareable]}>Facebook</Text>
                    </Pressable>
                    {/* Share to twitter */}
                    <Pressable style={styles.shareBtn} onPress={()=>shareLinkTo('http://twitter.com/share?text=&url=' + targetURL)}>
                        <FontAwesome name='twitter' color='#1DA1F2' size={22} style={styles.icon} />
                        <Text style={[FontProperty.TextSemiBold, FontProperty.h2, styles.marginLeftShareable]}>Twitter</Text>
                    </Pressable>
                    {/* Share to email */}
                    
                    <Pressable style={styles.shareBtn} onPress={()=>shareLinkTo('mailto:?subject=' + props.mailSubject + '&body=' + targetURL)}>
                        <Feather name='mail' color={Colors.black} size={22} style={[styles.icon, styles.leftAlign]} />
                        <Text style={[FontProperty.TextSemiBold, FontProperty.h2, styles.marginLeftShareable]}>Email</Text>
                    </Pressable>
                    {/* Share to Copy link */}
                    <Pressable style={styles.shareBtn} onPress={()=>{copyText();}}>
                        { textCopied 
                            ? <Ionicons name='checkmark-circle' color={Colors.teal} size={24} style={styles.icon} />
                            : <Fontisto name='link' color={Colors.black} size={22} style={styles.icon} />
                        }
                        
                        <Text style={[FontProperty.TextSemiBold, FontProperty.h2, styles.marginLeftShareable]}>{ textCopied ? 'Link Copied!' : 'Copy link'} </Text>
                    </Pressable>
                </View>
            </View>
        </View>

    );
};

const styles = StyleSheet.create({
    marginLeftShareable: {
        marginLeft: 26
    },
    popUpContainer: {
        position:'absolute',
        zIndex: 3,
        width: '100%',
        height: Layout.window.height,
        backgroundColor: '#000000cc',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 24
    },
    popUpInnerContainer: {
        width: Layout.window.width > 425 ? 360 : '100%',
        backgroundColor: Colors.white,
        borderRadius: 16,
        padding: 32
    },
    shareBtn: {
        borderWidth: 1,
        borderColor: '#BFBFBF',
        borderRadius: 50,
        paddingHorizontal: 24,
        paddingVertical: 12,
        marginTop: 16,
        textAlign: 'left',
        justifyContent: 'center'
    },
    icon: {
        position: 'absolute'
    }
});

export default ShareModal;
