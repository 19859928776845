import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import { getPollData } from '../../lib/api/Content/posts/posts';
import PercentageBar from './PercentageBar';
import { numberWithCommas } from '../../lib/utils/numFormatter';
import SmallHeadShot from './SmallHeadShot';

export type TPollResult = {
    propertyId: string,
    lowPrice : number,
    highPrice : number,
}

const PollResult = (props:TPollResult) => {
    const [pollData, setPollData] = useState([]);
    const [q5Yes, setQ5Yes] = useState([]);

    useEffect(() => {
        getPollData(props.propertyId).then(res => {
            res !== undefined && setPollData(res);
        });
    
    }, [props.propertyId]);

    const lowestedPrice = props.highPrice < 2000000 ? (props.lowPrice - 100000) : (props.lowPrice - 200000);
    const highestedPrice = props.highPrice < 2000000 ? (props.highPrice + 100000) : (props.highPrice + 200000);

    const questionAnswers = [
        {
            uuid : 'q1',
            question : 'How much do you think this property is worth?',
            answers : [
                {
                    label : 'Below range',
                    uuid : 'q1_1',
                },{
                    label : '$' + (props.highPrice < 2000000 ? ((props.lowPrice - 100000) < 0 ? 0 : numberWithCommas(props.lowPrice - 100000)) : (props.lowPrice - 200000 < 0 ? 0 : numberWithCommas(props.lowPrice - 200000))) + ' - $' + numberWithCommas(props.lowPrice), 
                    uuid : 'q1_2',
                },{
                    label : '$' + numberWithCommas(props.lowPrice) + ' - $' + numberWithCommas(props.highPrice) + ' (Owners estimate price)', 
                    uuid : 'q1_3',
                },{
                    label : '$' + numberWithCommas(props.highPrice) + ' - $' + (props.highPrice < 2000000 ? numberWithCommas(props.highPrice + 100000) : numberWithCommas(props.highPrice + 200000)),
                    uuid : 'q1_4',
                },{
                    label : 'Above range',
                    uuid : 'q1_5',
                }
            ],
        },
        {
            uuid : 'q2',
            question : 'Would you be interested in purchasing this property?',
            answers : [
                {
                    label : 'Yes, within the next 12 months', 
                    uuid : 'q2_1',
                },{
                    label : 'Not really', 
                    uuid : 'q2_2',
                },{
                    label : 'Maybe in the future', 
                    uuid : 'q2_3',
                }
            ],
        },
        {
            uuid : 'q3',
            question : 'What property features do you most like?',
            answers : [
                {
                    label : 'Location', 
                    uuid : 'q3_1',
                },{
                    label : 'Living spaces', 
                    uuid : 'q3_2',
                },{
                    label : 'Entertaining areas',
                    uuid : 'q3_3',
                },{
                    label : 'Outdoor Landscaping', 
                    uuid : 'q3_4',
                },{
                    label : 'Kitchen area',
                    uuid : 'q3_5',
                },{
                    label : 'Bathroom/s', 
                    uuid : 'q3_6',
                },{
                    label : 'Bedroom/s', 
                    uuid : 'q3_7',
                }
            ],
        },
        {
            uuid : 'q4',
            question : 'How can the owner best add value to this property?',
            answers : [
                {
                    label : 'Additional living space', 
                    uuid : 'q4_1',
                },{
                    label : 'Update bathroom', 
                    uuid : 'q4_2',
                },{
                    label : 'Update kitchen',
                    uuid : 'q4_3',
                },{
                    label : 'Extra bedroom / home office', 
                    uuid : 'q4_4',
                },{
                    label : 'Extra bathroom',
                    uuid : 'q4_5',
                },{
                    label : 'Outdoor landscaping or pool', 
                    uuid : 'q4_6',
                },{
                    label : 'Improve entertaining areas', 
                    uuid : 'q4_7',
                }
            ],
        },
        {
            uuid : 'q5',
            question : 'Would you like to make an offer or get in touch with the owner?',
            answers : [
                {
                    label : 'Yes', 
                    uuid : 'q5_1'
                },{
                    label : 'Maybe Later', 
                    uuid : 'q5_2'
                },
            ],
        },
    ];

    const awnserCount = [
        [0,0,0,0,0,],[0,0,0],[0,0,0,0,0,0,0],[0,0,0,0,0,0,0],[0,0]
    ];
    const awnserCountAll = [0, 0, 0, 0, 0];
    const Q5YesUsers = [];
    pollData.length > 0 &&
    pollData.forEach(poll => {
        let Q5Yes = false;
        poll.answers.forEach((aws) => {
            if(aws.includes('q')){
                const awsArr = aws.split('|');
                awsArr.forEach((single:string) => {
                    if(single !== ''){
                        const singleArr = single.slice(1).split('_');
                        awnserCountAll[singleArr[0]-1] = awnserCountAll[singleArr[0]-1] + 1;
                        awnserCount[singleArr[0]-1][singleArr[1]-1] = awnserCount[singleArr[0]-1][singleArr[1]-1] + 1;
                    }
                });

                if(aws.includes('q5_1')){
                    Q5Yes = true;
                }
            }else{
                (parseInt(aws) < lowestedPrice) && (awnserCountAll[0] = awnserCountAll[0] + 1) && (awnserCount[0][0] = awnserCount[0][0] + 1);
                (parseInt(aws) > highestedPrice) && (awnserCountAll[0] = awnserCountAll[0] + 1) && (awnserCount[0][4] = awnserCount[0][4] + 1);
            }
        });

        Q5Yes && poll.uuid && poll.uuid !== '' && Q5YesUsers.push(poll.uuid.split('_')[1]);
    });

    return (
        <View style={[Layout.globalPadding24, {paddingTop:16,paddingBottom:80}]}>
            <Text style={[FontProperty.TextBlack,FontProperty.smallP,FontProperty.TextSemiBold,{paddingBottom:10,borderBottomWidth:1,borderColor:'#E0E0E0',marginBottom:24}]}>All Poll Results ({pollData.length} participant{pollData.length > 1 && 's'})</Text>
            {
                questionAnswers.map((question, qIindex)=>{
                    return (
                        <View key={question.uuid} style={{marginBottom:17}}>
                            <Text style={[FontProperty.TextBlack,FontProperty.smallP,FontProperty.TextSemiBold, {marginBottom:20}]}>{question.question}</Text>
                            {
                                question.answers.map((answer, aIndex)=>{
                                    return (
                                        <View key={answer.uuid}>
                                            <PercentageBar title={answer.label} number={awnserCount[qIindex][aIndex] === 0 ? 0 : parseInt(awnserCount[qIindex][aIndex]/awnserCountAll[qIindex] * 100)} />
                                            <View style={Layout.row}>
                                                {qIindex === 4 && aIndex === 0 &&
                                                Q5YesUsers && Q5YesUsers.length > 0 &&
                                                Q5YesUsers.map((item:string, index:number) => {
                                                    return (
                                                        <SmallHeadShot key={index} userId={item} />
                                                    );
                                                })
                                                }
                                            </View>
                                        </View>
                                    );
                                })
                            }
                        </View>
                    );
                })
            }
        </View>
    );
};

const styles = StyleSheet.create({
});

export default PollResult;

