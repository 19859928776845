import React from 'react';
import { Text, StyleSheet, View, Image, Pressable } from 'react-native';
import IMAGE from '../../assets/images';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import { useNavigation } from '@react-navigation/native';

const CommentBarUnau = (props) => {
    const nav = useNavigation();

    return (
        <View style={[styles.container, Layout.row]}>

            <View style={[styles.imageContainer]}>
                <Image source={IMAGE.userProfile} style={[Layout.circle(55)]} />
            </View>

            <View style={[Layout.row, styles.textInputContainer]}>
                <Pressable onPress={() => nav.navigate('Platform', { screen: 'Authentication', params: { target: 'LOGIN' } })}>
                    <Text style={[props.darkTheme !== true ? FontProperty.TextTeal : FontProperty.TextNavy]}>Sign in</Text>
                </Pressable>
                <Text style={[FontProperty.TextGrey]}> to comment</Text>
            </View>

        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        width: '100%',
        alignSelf: 'center',
        paddingBottom: 25
    },
    textInputContainer: {
        borderRadius: 4,
        paddingVertical: 19,
        paddingHorizontal: 16,
        flex: 1,
        borderColor: Colors.grey4,
        borderWidth: 1
    },
    imageContainer: {
        paddingRight: 10
    },
});

export default CommentBarUnau;
