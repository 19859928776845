import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Image, Pressable } from 'react-native';
import IMAGE from '../../assets/images';
import Layout from '../../constants/Layout';
import { getUserData } from '../../lib/api/user';
import { IuserData } from '../../lib/api/user/types';
import { useNavigation } from '@react-navigation/native';

export type TSmallHeadShot = {
    userId : string
}

export const SmallHeadShot : React.FC< TSmallHeadShot > = (props) => {
    const [userData, setUserData] = useState<IuserData>();
    const nav = useNavigation();
    useEffect(() => {
        getUserData(props.userId).then(ref=>{
            if(ref.data()) {
                setUserData(ref.data());
            }
        });
    }, [props.userId]);

    if(!userData){
        return null;
    }

    return (
        <Pressable style={[Layout.row]} onPress={() => nav.navigate({name: 'UserProfile', params: { userId: props.userId, postId: '' }})}>
            <View style={styles.headshotContainer}>
                <Image source={userData.headshot ? userData.headshot : IMAGE.userProfile} style={[styles.headshot]} />
            </View>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    headshotContainer : {
        width: 25,
        height: 25,
        borderRadius: 25,
        overflow: 'hidden',
        marginRight: 10,
        marginBottom: 24
    },
    headshot : {
        width: 25,
        height: 25,
        resizeMode: 'cover',
        borderRadius: 25
    }
});

export default SmallHeadShot;
