import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import { getThreeFavouritePosts } from '../../lib/api/Content/posts/posts';
import { Ipost } from '../../lib/api/Content/posts/types';
import NewsfeedBlock from '../atom/newsfeedBlock/NewsfeedBlock';

export type TMostLikesPost = {
    userId : string
    setShowLikes: Dispatch<SetStateAction<boolean>>
    setShowComments: Dispatch<SetStateAction<boolean>>
    setTargetPost: Dispatch<SetStateAction<string>>
    setShowShare: Dispatch<SetStateAction<boolean>>
    setShareLink: Dispatch<SetStateAction<string>>
    setShowReportPopUp : Dispatch<SetStateAction<boolean>>
    setShowDeletePopUp : Dispatch<SetStateAction<boolean>>
    setShowEditPopUp : Dispatch<SetStateAction<boolean>>
    trigger: boolean
}

const MostLikesPosts = (props:TMostLikesPost) => {
    const [newsfeed, setNewsfeed] = useState<Ipost[]>([]);
    
    useEffect(() => {
        getThreeFavouritePosts().then(ref=>{
            ref && ref.length > 0 && setNewsfeed(ref);
        });
    },[props.userId, props.trigger]);

    return (
        <View>
            <View style={{paddingHorizontal:24}}>
                <Text style={[FontProperty.TextBlack,FontProperty.smallP,FontProperty.TextSemiBold,{paddingTop:16,paddingBottom:10,borderBottomWidth:1,borderColor:'#E0E0E0',marginBottom:24}]}>Top Posts</Text>
            </View>
            {
                newsfeed.map(post => (
                    <View key={post.uuid}>
                        <NewsfeedBlock
                            data={post}
                            userId={props.userId}
                            setShowLikes={props.setShowLikes}
                            setShowComments={props.setShowComments}
                            setTargetPost={props.setTargetPost}
                            setShowShare={props.setShowShare}
                            setShareLink={props.setShareLink}
                            setShowReportPopUp={props.setShowReportPopUp}
                            setShowDeletePopUp={props.setShowDeletePopUp}
                            setShowEditPopUp={props.setShowEditPopUp}
                        />
                        <View style={[styles.bar]}></View>
                    </View>
                ))
            }
        </View>
    );
};

const styles = StyleSheet.create({
    bar: {
        backgroundColor: Colors.beige,
        height: 4,
        width: '100%'
    }
});

export default MostLikesPosts;
