import { FontAwesome } from '@expo/vector-icons';
import React, {useState} from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';

export type TFilterRow = {
  label : string
  show : boolean
  num : number
  zIndex ? : number
}

const FilterRow : React.FC< TFilterRow > = ({label, show, num, children, zIndex}) => {
    const [showChild, setShowChild] = useState< boolean >(show);
    return (
        <View style={zIndex ? {zIndex:zIndex} : {zIndex:0}}>
            <TouchableOpacity  style={[Layout.rowBetween, styles.container]} onPress={()=>{setShowChild(!showChild);}}>
                <View style={Layout.row}>
                    <Text style={[FontProperty.h2, FontProperty.TextGrey, Layout.row]}>
                        {label}
                    </Text>
                    {num !== 0 && <Text style={styles.label}>{num === 999 ? '✓' : num}</Text>}
                </View>
                <FontAwesome size={30} name={`angle-${!showChild ? 'down' : 'up'}`} color={Colors.navy} />
            </TouchableOpacity>
            {showChild &&
              <View style={styles.childContainer}>
                  {children}
              </View>
            }
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        paddingVertical: 22
    },
    childContainer : {
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    buttonStyles : {
        paddingVertical : 10,
        padding : 10,
        width : 'fit-content',
    },
    label: {
        borderColor: Colors.navy,
        borderWidth: 1,
        fontSize: 8,
        borderRadius: 10,
        width: 15,
        height: 15,
        color: Colors.navy,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 5,
        fontWeight: 500
    }
});

export default FilterRow;
