import React, {useState, useEffect} from 'react';
import { StyleSheet, View, Image, Text, Pressable } from 'react-native';
import Layout from '../../constants/Layout';
import FontProperty from '../../constants/FontProperty';
import { IuserData } from '../../lib/api/user/types';
import { getUserData } from '../../lib/api/user';
import IMAGE from '../../assets/images';
import { useNavigation } from '@react-navigation/native';

export type TUserHeadshotAndFunction = {
  userId : string
  btnLabelOne : string
  btnLabelTwo : string
  btnLabelOneFunction: ()=>void
  btnLabelTwoFunction: ()=>void
  closeWholeModel: ()=>void
}
const UserHeadshotAndFunction = (props:TUserHeadshotAndFunction) => {
    const nav = useNavigation();
    const [ownerData, setOwnerData] = useState<IuserData>();
    const [removeData, setRemoveData] = useState<boolean>(true);


    useEffect(() => {
        getUserData(props.userId).then(ownerData => {
            setOwnerData(ownerData.data());
        });
    },[props.userId]);

    if(ownerData === undefined) {
        return null;
    }

    const progressData = (removeData: boolean) => {
        if(removeData){
            setRemoveData(false);
            props.btnLabelOneFunction();
        } else {
            setRemoveData(true);
            props.btnLabelTwoFunction();
        }
    };

    return (
        <Pressable style={[Layout.rowBetween, {marginBottom:16}]} 
            onPress={() => {
                props.closeWholeModel();
                setTimeout(() => {nav.navigate({name: 'UserProfile', params: { userId: props.userId, postId: '' }}); }, 500);
            }}>
            <Image source={(ownerData.headshot && ownerData.headshot !== '') ? ownerData.headshot : IMAGE.userProfile} style={{resizeMode:'cover',width:54,height:54,borderRadius:54}}/>
            <View style={{flex:1,paddingLeft:21}}>
                <Text style={[FontProperty.body_1, {marginBottom:2}]}>{ownerData.username}</Text>
            </View>
            <Pressable style={Layout.row} onPress={()=>{progressData(removeData);}}>
                <Text style={[FontProperty.TextTeal, FontProperty.TextMedium, FontProperty.smallLabel, {paddingLeft:20,paddingVertical:20}]}>{removeData ? props.btnLabelOne : props.btnLabelTwo}</Text>
            </Pressable>
        </Pressable>
    );
};

const styles = StyleSheet.create({

});

export default UserHeadshotAndFunction;
