import { Dimensions, ImageStyle, StyleProp, ViewStyle } from 'react-native';
import Colors from './colors';

let width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

if (width > 991) {
    width = 768;
}

const Layout = {
    row: 'row',
    center: 'center'
};

export default {
    window: {
        width,
        height
    },
    mtop15: {
        marginTop: 15
    },
    halfColumnRadio: 2.2,
    isSmallDevice: width < 375,
    row: {
        flexDirection: Layout.row,
        alignItems: Layout.center
    }as StyleProp<ViewStyle>,
    flexWrap: {
        flexWrap: 'wrap'
    }as StyleProp<ViewStyle>,
    rowBetween: {
        flexDirection: Layout.row,
        alignItems: Layout.center,
        justifyContent: 'space-between'
    }as StyleProp<ViewStyle>,
    globalPadding24: {
        paddingHorizontal: 24
    }as StyleProp<ViewStyle>,
    mbottom5: {
        marginBottom: 5
    }as StyleProp<ViewStyle>,
    mbottom8: {
        marginBottom: 8
    }as StyleProp<ViewStyle>,
    mbottom15: {
        marginBottom: 15
    }as StyleProp<ViewStyle>,
    mbottom25: {
        marginBottom: 25
    }as StyleProp<ViewStyle>,
    mbottom32: {
        marginBottom: 32
    }as StyleProp<ViewStyle>,
    mbottom40: {
        marginBottom: 40
    }as StyleProp<ViewStyle>,
    mbottom52: {
        marginBottom: 52
    }as StyleProp<ViewStyle>,
    mbottom60: {
        marginBottom: 60
    }as StyleProp<ViewStyle>,
    mbottom97: {
        marginBottom: 97
    }as StyleProp<ViewStyle>,
    screenContainer: {
        alignSelf: 'center',
        paddingHorizontal: 24,
        flex: 1,
        width: '100%'
    } as StyleProp<ViewStyle>,
    screenCenter: {
        alignSelf: 'center',
        paddingHorizontal: 24,
        width: '100%'
    } as StyleProp<ViewStyle>,
    banner: {
        width,
        height: height * 0.2
    } as StyleProp<ImageStyle>,
    baseScreen: {
        flex: 1,
        backgroundColor: Colors.background
    }as StyleProp<ViewStyle>,
    circle: (d) => ({
        width: d,
        height: d,
        borderRadius: 100,
        alignItems: 'center',
        justifyContent: 'center'
    })as StyleProp<ViewStyle>,
    red: {
        backgroundColor: Colors.red
    }as StyleProp<ViewStyle>,
    center: {
        justifyContent: 'center',
        alignItems: 'center'
    }as StyleProp<ViewStyle>,
    scrollViewPadding: {
        paddingBottom: 50
    }as StyleProp<ViewStyle>,
    noOutline: {
        outlineStyle: 'none'

    }as StyleProp<ViewStyle>,
    flexBottom: {
        flex: 1,
        justifyContent: 'flex-end',
        paddingBottom: 20

    }as StyleProp<ViewStyle>,
    flex: {
        flex: 1
    }as StyleProp<ViewStyle>,
    test: {
        backgroundColor: 'red'
    }as StyleProp<ViewStyle>,
    alignCenter: {
        alignItems: 'center'
    }as StyleProp<ViewStyle>,
    bgTeal: {
        backgroundColor: Colors.teal
    } as StyleProp<ViewStyle>

};
