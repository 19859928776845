import React, {useEffect, useRef, useState} from 'react';
import { Text, StyleSheet, ImageBackground, ScrollView  } from 'react-native';
import { getHomeContent } from '../../lib/api/Content/content';
import { IHomeContent } from '../../lib/api/Content/types';
import Colors from '../../constants/colors';

import ScreenBase from '../../components/ScreenBase';
import ScreenHeader from '../../components/atom/ScreenHeader';

import Layout from '../../constants/Layout';
import Footer from '../../components/molecule/Footer';
import FontProperty from '../../constants/FontProperty';
import ProfilePP from '../../components/profileScreen/ProfilePP';
import { useFocusEffect } from '@react-navigation/native';

const PrivacyPolicyScreen = () => {
    const ref = useRef();

    useFocusEffect(()=>{
        ref.current?.scrollTo({
            y: 0,
            animated: true,
        });
    });

    return (
        <ScreenBase>
            <ScreenHeader/>
            <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={Layout.scrollViewPadding} ref={ref}>
                <Text style={[styles.title, FontProperty.TextSemiBold, {paddingHorizontal: 24, paddingVertical: 40}]}>Privacy Policy</Text>
                <ProfilePP />
                <Footer />
            </ScrollView>
        </ScreenBase>
    );
};

export default PrivacyPolicyScreen;

const styles = StyleSheet.create({
    image: {
        paddingVertical: 50,
        paddingHorizontal: 24,
        zIndex: 1,
        marginBottom: 40
    },
    title: {
        fontSize: 30,
        lineHeight: 36,
        fontWeight: '600',
    },
    imageBanner: {
        paddingVertical: 40,
        paddingHorizontal: 24,
        zIndex: 1,
        textAlign: 'center',
    },
    titleBanner: {
        fontSize: 24,
        lineHeight: 30,
        fontWeight: '600',
        color: Colors.navy
    },
});