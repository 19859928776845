import React, {useEffect, useState} from 'react';
import { StyleSheet, View, Text, Pressable } from 'react-native';
import Layout from '../../constants/Layout';
import { IMessage } from '../../types';
import ProfileHeadshotById from '../atom/HomeOwnerHeadshotByID';
import { timeSince } from '../../lib/utils/dateAdition';
import FontProperty from '../../constants/FontProperty';
import Colors from '../../constants/colors';
import { AntDesign, MaterialCommunityIcons } from '@expo/vector-icons';
import { deleteMessage, updateReadStatus } from '../../lib/api/messages/messages';

export type TMessageItem = {
    data : IMessage
    setTrigger: (arg0:boolean) => void
    trigger: boolean
    setTargetConversation: (arg0:string) => void 
}

const MessageItem = (props:TMessageItem) => {
    const message = props.data;
    const [showEditDeletePopup, setShowEditDeletePopup] = useState(false);
    const [readUnread, setReadUnread] = useState(props.data.lastMessage.read);

    const readMessage = (messageId: string) => {
        props.setTargetConversation(messageId);
        props.setTrigger(!props.trigger);
        setReadUnread(true);
    };

    useEffect(() => {
        setReadUnread(props.data.lastMessage.read);
    }, [props.data.lastMessage]);

    return (
        <View style={[!readUnread && styles.blueBg, styles.container, Layout.row, showEditDeletePopup && styles.topZindex]}>
            <View style={{flex: 1,flexWrap:'wrap'}}>
                <ProfileHeadshotById userId={message.id.split('_')[1]} subtitle={message.lastMessage.message} onClickFunction={()=>readMessage(message.id)}/>
            </View>
            <View style={{alignItems:'flex-end',paddingLeft:10}}>
                <Text style={[FontProperty.miniP, {color:Colors.grey1}]}>{timeSince(message.lastMessage.createdByTimestamp)}</Text>
                <Pressable onPress={()=>setShowEditDeletePopup(!showEditDeletePopup)}><MaterialCommunityIcons name='dots-horizontal' size={20} color={Colors.grey1} style={{marginTop:6}} /></Pressable>
            </View>
            {
                showEditDeletePopup &&
              <Pressable style={styles.editDeleteReportPopupContainer} onPress={()=>setShowEditDeletePopup(false)}>
                  <View style={styles.editDeleteReportPopup}>
                      <Pressable style={[Layout.row, {marginBottom: 10}]} onPress={()=>{updateReadStatus(props.data.id, !readUnread);setShowEditDeletePopup(false);setReadUnread(!readUnread);}}>
                          <AntDesign name='eye' style={{marginRight:8}} />
                          <Text style={FontProperty.TextSemiBold}>Mark as {readUnread ? 'unread' : 'read'}</Text>
                      </Pressable>
                      <Pressable style={Layout.row} onPress={()=>{deleteMessage(props.data.id.split('_')[0], props.data.id.split('_')[1]);setShowEditDeletePopup(!showEditDeletePopup);}}>
                          <AntDesign name='delete' style={{marginRight:8}} />
                          <Text style={FontProperty.TextSemiBold}>Delete message</Text>
                      </Pressable>
                  </View>
              </Pressable>
            }
        </View>
    );
};


const styles = StyleSheet.create({
    blueBg: {
        backgroundColor: '#EAF3FE'
    },
    container: {
        paddingHorizontal: 16,
        paddingVertical: 12
    },
    editDeleteReportPopupContainer: {
        position: 'absolute',
        top: 4,
        right: 40,
        zIndex: 2,
        width: '90%',
        alignItems: 'flex-end'
    },
    editDeleteReportPopup: {
        backgroundColor: Colors.white,
        boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.08)',
        paddingHorizontal: 16,
        paddingVertical: 8,
        width: 164
    },
    topZindex: {
        zIndex: 4
    }
});

export default MessageItem;
