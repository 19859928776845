import { Ionicons } from '@expo/vector-icons';
import React from 'react';
import { StyleSheet, Image, Text, View, TouchableOpacity } from 'react-native';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import { TealButton } from '../Button';

export type TInfoPopup = {
    title: string,
    body: string,
    onClose: () => void
}

const InfoPopup: React.FC<TInfoPopup> = (props) => {
    return (
        <View style={styles.MoreInfoPopUp}>
            <View style={styles.Group693}>
                <TouchableOpacity onPress={props.onClose}>
                    <Ionicons name='close' size={20} style={styles.MenuClose_big} color={Colors.black}/>
                </TouchableOpacity>
                <Text style={[FontProperty.h1, styles.title, {color:Colors.black}]}>{props.title}</Text>
                <Text style={[FontProperty.body_1, {color:Colors.black}]}>
                    {props.body}
                </Text>
                <TealButton onPress={props.onClose} btnStyle={[styles.btnStyle]}>
                    <Text style={[FontProperty.button_1]}>Ok</Text>
                </TealButton>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    MoreInfoPopUp: {
        flex : 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        paddingTop: 21,
        paddingBottom: 33,
        paddingLeft: 23,
        paddingRight: 22,
        width: '100%',
        // backgroundColor : "red"
    },
    Group693: {
        display: 'flex',
        flexDirection: 'column',

        width: '100%'

    },
    MenuClose_big: {
        width: 21,
        height: 21,
        marginBottom: 16,
        alignSelf: 'flex-end'
    },
    title: {
        paddingBottom: 20,
        marginBottom: 23,
        borderBottomColor: Colors.grey5,
        borderBottomWidth: 1

    },

    btnStyle: {
        width: '100%',
        marginTop: 23
    }

});

export default InfoPopup;
