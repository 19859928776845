import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Image, Text, Pressable } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import FontProperty from '../../constants/FontProperty';
import Colors from '../../constants/colors';
import { setFollowProperty } from '../../lib/api/Content/posts/posts';
import { IpropertyData } from '../../lib/api/Content/types';
import { sendNotificationHouspo } from '../../lib/api/messages/notifications';
import { userAllowReceiveNotification } from '../../lib/api/messages/messages';

export type THomeOwnerHeadshot = {
    userId : string
    propertyData: IpropertyData
}

export const PropertyTileByIdVertial : React.FC< THomeOwnerHeadshot > = (props) => {
    const nav = useNavigation();
    const [followStatus, setFollowStatus] = useState(true);
    const [receiveNotification, setUserReceiveNotification] = useState<boolean>();

    useEffect(() => {
        props.propertyData.userId && userAllowReceiveNotification(props.propertyData.userId, setUserReceiveNotification);
    },[props.propertyData]);


    const followThisProperty = (propertyId : string, userId : string) => {
        setFollowProperty(propertyId, userId, followStatus);
        (followStatus && receiveNotification) && sendNotificationHouspo(userId, props.propertyData.userId, props.propertyData.uuid, '', true, false, false, false);
        setFollowStatus(!followStatus);
    };
  
    return (
        <View style={[styles.itemOutContent]}>
            <View style={styles.itemContent}>
                <Pressable onPress={()=>nav.navigate({name: 'PropertyProfile', params: { propertyId: props.propertyData.uuid }})} style={{alignItems:'center'}}>
                    <Image source={props.propertyData.images[0].viewUrl} style={[styles.headshot]} />
                    <Text style={[FontProperty.smallP, FontProperty.TextSemiBold, FontProperty.TextBlack,{textAlign:'center',marginBottom:19}]}>{props.propertyData.propertyName}</Text>
                </Pressable>
                {
                    followStatus
                        ? <Pressable 
                            onPress={()=>followThisProperty(props.propertyData.uuid, props.userId)}
                            style={[styles.btn, {backgroundColor:Colors.teal}]}
                        ><Text style={[FontProperty.TextWhite, FontProperty.smallLabel,FontProperty.TextSemiBold, {textAlign:'center'}]}>Follow</Text></Pressable>
                        : <Pressable 
                            onPress={()=>followThisProperty(props.propertyData.uuid, props.userId)}
                            style={[styles.btn, {backgroundColor:Colors.grey6}]}
                        ><Text style={[FontProperty.TextBlack, FontProperty.smallLabel,FontProperty.TextSemiBold, {textAlign:'center'}]}>Unfollow</Text></Pressable>
                }
                
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    itemOutContent: {
        width: 120,
        marginRight: 16,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: '#F2F2F2',
    },
    itemContent: {
        paddingTop: 16,
        paddingHorizontal: 8,
        paddingBottom: 32,
        alignItems: 'center',
        height: '100%'
    },
    headshot: {
        width: 45,
        height: 45,
        resizeMode: 'cover',
        borderRadius: 45,
        marginBottom: 8
    },
    btn: {
        borderRadius:4,
        paddingVertical:4.5,
        position: 'absolute',
        bottom: 8,
        left: 8,
        width: 104,


    }
});

export default PropertyTileByIdVertial;
