import React from 'react';
import { TouchableOpacity, Text, TextStyle, StyleProp, ViewStyle, GestureResponderEvent } from 'react-native';
import { debounceAction } from '../../../lib/utils/debounceAction';
import { a11yRoles } from '../../a11y';
import { testId } from './constants';
import styles from './styles';
import { IbuttonProps } from './types';

const ButtonComponent: React.FC<IbuttonProps> = (props) => {
    const customTextStyle = {
        color: props.textColor
    } as TextStyle;

    const customButtonStyle = {
        backgroundColor: props.backgroundColor
    } as StyleProp<ViewStyle>;

    if (props?.borderColor) {
        customButtonStyle.borderColor = props.borderColor;
        customButtonStyle.borderWidth = 2;
    }
    const debouncedOnPress = (cta) => {
        return debounceAction(() => {
            return cta();
        });
    };

    return (
        <TouchableOpacity
            testID={testId.button}
            {...props}
            // onPress={() => { props.onPress && debouncedOnPress(props.onPress); }}
            onPress={!props.isDisabled ? debouncedOnPress(props.onPress) : () => { }}
            style={[styles.buttonStyle, customButtonStyle, props.isDisabled && styles.disabledButtonStyle]}
            accessibilityLabel={a11yRoles.button}
        >
            <Text testID={testId.buttonText} style={[styles.buttonTextStyle, customTextStyle]}>{props.text || props.children}</Text>
        </TouchableOpacity>
    );
};

const Button = React.memo(ButtonComponent);

export default Button;
