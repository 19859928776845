import React from 'react';
import { StyleSheet, Pressable, Text, Image } from 'react-native';
import Colors from '../../constants/colors';
import Layout from '../../constants/Layout';
import IMAGE from '../../assets/images';

export type TDropdownBox = {
//   label : string,
  btnOnPress : React.Dispatch<string>,
  num ? : number
}

const FilterBox = (props: TDropdownBox) => {
    return (
        <Pressable onPress={props.btnOnPress} style={[styles.box, Layout.row]}>
            {/* <Text style={styles.text}>{props.label}</Text> */}
            <Image source={IMAGE.filterIcon} style={{resizeMode:'contain',width:16,height:16}} />
            {
                props.num !== 0 && <Text style={styles.label}>{props.num}</Text>
            }
        </Pressable>
    );
};

const styles = StyleSheet.create({
    box: {
        // paddingHorizontal: 16,
        // paddingVertical: 7,
        // borderColor: Colors.grey5,
        // borderWidth: 1,
        // borderRadius: 4
        marginTop: 16
    },
    text: {
        fontSize: 15,
        marginRight: 5,
        fontWeight: 600,
    },
    label: {
        backgroundColor: Colors.navy,
        fontSize: 8,
        borderRadius: 10,
        width: 15,
        height: 15,
        color: Colors.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 5,
        fontWeight: 500,
        position: 'absolute',
        top: -8,
        right: -10
    }
});

export default FilterBox;
