import React from 'react';
import { StyleSheet, Text, ScrollView, TouchableOpacity, Animated, Pressable } from 'react-native';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import { Ionicons } from '@expo/vector-icons';
import Layout from '../../constants/Layout';
import { Icomment } from '../../lib/api/Content/posts/types';
import CommentMessage from './CommentMessage';
import { useNavigation } from '@react-navigation/native';

export type TAllComments = {
    open : boolean,
    comments : Icomment | undefined,
    setViewAllComment : (arg0: boolean) => void
}

const AllComments : React.FC< TAllComments > = (props) => {
    const nav = useNavigation();
    const [bounceValue, setBounceValue] = React.useState(new Animated.Value(Layout.window.width + 200));
    const [isHidden, setIsHidden ] = React.useState(props.open);

    const closeWholeModel = () => {
        toggleSlide(false);
        setTimeout(() => {
            setIsHidden(false);
            props.setViewAllComment(false);
        }, 500);
    };

    const toggleSlide = (status : boolean) =>  {
        let slideToValue = Layout.window.width + 200;
        if(status) {
            slideToValue = 0;
        }
        Animated.spring(
            bounceValue,
            {
                toValue: slideToValue,
                velocity: 10,
                tension: 2,
                friction: 8,
                useNativeDriver: false
            }
        ).start();
    };

    toggleSlide(isHidden);
    
    return (
        <Animated.View style={[styles.outContainer, {transform: [{translateX: bounceValue}]}, !isHidden && {display:'none'}]}>
            <TouchableOpacity onPress={closeWholeModel} style={[Layout.row, {padding:24}]}>
                <Ionicons name="arrow-back" size={23} color={Colors.grey1} style={{marginRight:15}}/>
                <Text style={[FontProperty.h1, FontProperty.TextBlack]}>All Comments</Text>
            </TouchableOpacity>
            <ScrollView style={{paddingHorizontal:24, paddingBottom:40}} showsVerticalScrollIndicator={false}>
                {
                    props.comments?.map((comment:Icomment, index:number) => {
                        return (
                            <Pressable key={index} onPress={()=>{nav.navigate({name: 'UserProfile', params: { userId: comment.user.uid, postId: '' }});}}>
                                <CommentMessage comment={comment.comment} userID={comment.user.uid}/>
                            </Pressable>
                        
                        );
                    })
                }
            </ScrollView>
        </Animated.View>
    );
};

const styles = StyleSheet.create({
    outContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: Colors.white,
        justifyContent: 'space-between',
        zIndex: 2
    },
    message: {
        marginBottom: 10,
        borderWidth: 1,
        borderRadius: 8,
        paddingVertical: 17.5,
        alignItems: 'center',
        justifyContent: 'center',
        height: 55,
        borderColor: Colors.grey4,
    },
    activeMessage: {
        borderColor: Colors.teal,
    },
    send: {
        borderRadius: 8,
        paddingVertical: 17.5,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.teal,
        height: 56,
        marginTop: 40,
        width: '100%',
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.25)',
        position: 'absolute',
        top: 0,
        left: 0,
        paddingHorizontal: 24,
        justifyContent: 'center',
        width: '100%',
        height: '100%',
    },
    succeedModel: {
        backgroundColor: Colors.white,
        paddingHorizontal: 24,
        paddingTop: 62,
        paddingBottom: 37,
        alignItems: 'center',
    },
    image: {
        width: 142,
        height: 150,
        marginBottom: 31,
    },
    imageJump: {
        width: 108,
        height: 79,
        position: 'absolute'
    },
    close: {
        position: 'absolute',
        right: 24,
        top: 22,
        zIndex: 2
    },
    opacity5: {
        opacity: 0.5
    },
});

export default AllComments;

