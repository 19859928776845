import { Pressable, StyleSheet, Text, View, Image, FlatList, ActivityIndicator } from 'react-native';
import React, {useEffect, useState, useRef} from 'react';
import { useScrollToTop } from '@react-navigation/native';
import { useAuthentication } from '../../hooks/useAuthentication';
import ScreenHeader from '../../components/atom/ScreenHeader';
import ScreenBase from '../../components/ScreenBase';
import Colors from '../../constants/colors';
import { ScrollView } from 'react-native-gesture-handler';
import { useNavigation, useRoute } from '@react-navigation/native';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import { IpropertyData, IpropertyStatus, IpropertyType } from '../../lib/api/Content/types';
import { setFollowProperty, getFollowState, getPollState } from '../../lib/api/Content/posts/posts';
import { getPropertyContent, getPropertyTypeName, getSimiliarProperty, getPropertyStatus, deleteProperty, updatePropertyVisits } from '../../lib/api/Content/property';
import { AntDesign, Ionicons, MaterialIcons } from '@expo/vector-icons';
import { NavyOutlineButton, TealButton } from '../../components/Button';
import { getUserData } from '../../lib/api/user';
import { IuserData } from '../../lib/api/user/types';
import IMAGE from '../../assets/images';
import { Feather } from '@expo/vector-icons';
import { numFormatter } from '../../lib/utils/numFormatter';
import CommentBar from '../../components/atom/CommentBar';
import userStore from '../../store/userStore';
import { Icomment } from '../../lib/api/Content/posts/types';
import { createComment, getComments } from '../../lib/api/Content/posts/posts';
import CommentBarUnau from '../../components/atom/CommentBarUnau';
import { getCommentsProperty } from '../../lib/api/Content/posts/posts';
import CommentMessage from '../../components/atom/CommentMessage';
import PropertyGallery from '../../components/atom/PropertyGallery';
import StatusPill from '../../components/atom/StatusPill';
import ShareBtn from '../../components/atom/ShareBtn';
import ShareModal from '../../components/atom/ShareModal';
import PopUpGalleryModal from '../../components/atom/PopUpGalleryModal';
import WarningBar from '../../components/atom/Warningbar';
import AllComments from '../../components/atom/AllComments';
import WarningPopUp from '../../components/atom/WarningPopup';
import ContactHomeownerModel from '../../modals/ContactHomeownerModel';
import QuestionModel from '../../modals/QuestionModel';
import { sendNotificationHouspo } from '../../lib/api/messages/notifications';
import ContentText from '../../constants/Content';
import { staticUrls } from '../../constants/Content';
import { userAllowReceiveNotification } from '../../lib/api/messages/messages';
import NoResult from '../../components/atom/NoResult';
import SimilarProperty from '../../components/atom/SimilarProperty';
import ProjectCarousel from '../../components/atom/ProjectCarousel';
import { deleteProject } from '../../lib/api/Content/project';
import { uppercaseFirstLetter } from '../../lib/utils/uppercaseFirstLetter';
import Hyperlink from 'react-native-hyperlink';
import { getHomeTypeName } from '../../lib/utils/homeType';


const ViewPropertyScreen: React.FC<{ propertyId: string, from : string }> = (props) => {
    const nav = useNavigation();
    const { params } = useRoute();
    const { user } = useAuthentication();
    const userData = userStore;
    const [loading, setLoading] = useState(true);
    const [propertyContent, setPropertyContent] = useState<IpropertyData>();
    const [ownerData, setOwnerData] = useState<IuserData>();
    const [visitorData, setVisitorData] = useState<string>('');
    const [propertyType, setPropertyType] = useState<IpropertyType>();
    const [similarProperty, setSimilarProperty] = useState<IpropertyData>();
    const [commentsData, setCommentsData] = useState<Icomment>();
    const [commentsDataAll, setCommentsDataAll] = useState<Icomment>();
    const [comments, setComments] = useState<Icomment[]>([]);
    const [viewAllComments, setViewAllComment] = useState(false);
    const [postCommentSucceed, setPostCommentSucceed] = useState(false);
    const [propertyStatus, setPropertyStatus] = useState<IpropertyStatus>();
    const [shareModal, setShareModal] = useState(false);
    const [popUpGalleryIndex, setPopUpGalleryIndex] = useState(0);
    const [popUpGallery, setPopUpGallery] = useState(false);
    const [deletePropertyModal, setDeletePropertyModal] = useState(false);
    const [visualFollow, setVisualFollow] = useState<boolean>(false);
    const [projectList, setProjectList] = useState<string[]>();
    const [showContactHomeownerModal, setContactHomeownerModal] = useState(false);
    const [hasFinishedPoll, setHasFinishedPoll] = useState(false);
    const [trigger, setTrigger] = useState(false);
    const [openQuestionModal, setOpenQuestionModal] = useState(false);
    const [hasCompletedPoll, setHasCompletedPoll] = useState(true);

    const ref = useRef();
    const [postPostSucceed, SetPostPostSucceed] = useState(false);
    const [receiveNotification, setUserReceiveNotification] = useState<boolean>();

    useEffect(() => {
        params?.propertyId !== undefined &&
        getPropertyContent(params?.propertyId).then(res => {
            setPropertyContent(res);
            setDeletePropertyModal(false);
            res !== undefined && setLoading(false);
        });
    }, [params?.propertyId, params?.updated, trigger]);
    
    useScrollToTop(ref);

    useEffect(() => {
        propertyContent?.user &&
        userAllowReceiveNotification(propertyContent.userId, setUserReceiveNotification);

        propertyContent?.userId &&
        getUserData(propertyContent.userId).then(ownerData => {
            setOwnerData(ownerData.data());
            
        });

        propertyContent?.type &&
        getPropertyTypeName(propertyContent.property_type).then(res => {
            setPropertyType(getHomeTypeName(propertyContent.property_type));
        });

        propertyContent?.type && propertyContent?.uu_id &&
        getSimiliarProperty(propertyContent.type, propertyContent.uu_id).then(res => {
            setSimilarProperty(res);
        });

        propertyContent?.userId &&
        getComments(propertyContent.uu_id).then(res => {
            setComments(res);
        });

        user !== false && user?.uid &&
        getUserData(user.uid).then(visitorData => {
            setVisitorData(visitorData.data()?.headshot);
        });

        propertyContent?.uu_id &&
        getCommentsProperty(propertyContent.uu_id).then(commentDataRaw => {
            setCommentsDataAll(commentDataRaw);
            commentDataRaw.length > 3 && setCommentsData(commentDataRaw.slice(0, 3));
            commentDataRaw.length < 4 && setCommentsData(commentDataRaw);
        });

        propertyContent?.status &&
        getPropertyStatus(propertyContent.status).then(status => {
            setPropertyStatus(status);
        });

        user !== false && user?.uid && propertyContent?.uu_id &&
        getFollowState(propertyContent?.uu_id, user?.uid).then(ref=>{
            setVisualFollow(ref);
        });

        user !== false && user?.uid && propertyContent?.uu_id &&
        getPollState(propertyContent?.uu_id, user?.uid).then(ref=>{
            setHasFinishedPoll(ref);
            setHasCompletedPoll(ref);
            !ref && setOpenQuestionModal(false);
        });

    }, [propertyContent?.userId, propertyContent?.uu_id, trigger, receiveNotification, params?.updated]);

    useEffect(() => {
        ref.current?.scrollTo({
            y: 0,
            animated: true,
        });

        (user !== false && user?.uid !== undefined && propertyContent !== undefined && propertyContent.userId !== undefined &&(propertyContent.userId !== user?.uid)) && 
        updatePropertyVisits(user?.uid, propertyContent.uu_id);

    },[propertyContent?.userId, propertyContent?.uu_id]);

    

    if(loading) {
        return (
            <ScreenBase>
                <ScreenHeader noShadow={false}/>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <View style={{marginTop:150}}>
                        <ActivityIndicator size={Layout.window.width > 425 ? 107 : 80} color={Colors.teal} />
                    </View>
                </ScrollView>
            </ScreenBase>
        );
    }

    if(!loading && propertyContent === undefined) {
        return (
            <ScreenBase>
                <ScreenHeader noShadow={false}/>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <View style={[Layout.globalPadding24, {alignItems:'center'}]}>
                        <NoResult title="Property doesn't exist or been deleted by the owner" removeGapbelow={true} />
                        <View style={{marginTop:24}}></View>
                        <View style={{width: Layout.window.width > 425 ? 180 : '100%'}}>
                            <TealButton onPress={()=>nav.navigate('Explore')}>
                                <Text style={[FontProperty.button_1, FontProperty.TextWhite]}>Back to Explore</Text>
                            </TealButton>
                        </View>
                    </View>
                    
                </ScrollView>
            </ScreenBase>
        );
    }


    const ownersPriceEstimate = () => {
        if (propertyContent.owners_estimate_low_price && propertyContent.owners_estimate_high_price){
            return `${numFormatter(propertyContent.owners_estimate_low_price)} - ${numFormatter(propertyContent.owners_estimate_high_price)}`
         } else if (propertyContent.owners_estimate_low_price) {
            return `${numFormatter(propertyContent.owners_estimate_low_price)}`
         } else if (propertyContent.owners_estimate_high_price) {
            return `${numFormatter(propertyContent.owners_estimate_high_price)}`
         }
    }

    const followThisProperty = (pid: string) => {
        setVisualFollow(true);
        receiveNotification && sendNotificationHouspo(user?.uid, propertyContent.userId, propertyContent?.uu_id, '', true, false, false, false);
        setFollowProperty(pid, user?.uid, true);
    };

    const unfollowThisProperty = (pid: string) => {
        setVisualFollow(false);
        setFollowProperty(pid, user?.uid, false);
    };

    const contactThisUser = (uid: string) => {
        setContactHomeownerModal(true);
    };

    const deleteThisProperty = (pid: string) => {
        /*if(propertyContent?.projects !== undefined && propertyContent?.projects.length > 0){
            propertyContent?.projects.forEach(element => {
                deleteProject(element);
            });
        }*/
        deleteProperty(pid);
        setDeletePropertyModal(false);
        nav.navigate('Newsfeed');
    };

    const editThisProperty = (pid: string) => {
        nav.navigate('ListProperty', {
            screen: 'type',
            params: { propertyId: pid },
        });
    };

    const onPostComment = async (comment : string) => {
        const commentData = {
            comment,
            user: {
                uid: user.uid,
                username: userData?.name || ''
            },
            propertyId: propertyContent.uu_id
        };
        await createComment(commentData);
        receiveNotification && sendNotificationHouspo(user?.uid, propertyContent.userId, propertyContent.uu_id, '', false, false, true, false);
        setComments([commentData, ...comments]);
        setPostCommentSucceed(true);
        setTimeout(() => {setPostCommentSucceed(false);}, 3000);
        setTrigger(!trigger);
    };

    // const checkDistence = ({layoutMeasurement, contentOffset, contentSize}) => {
    //     ((layoutMeasurement.height + contentOffset.y) > contentSize.height - 10) ? setOpenQuestionModal(true) : setOpenQuestionModal(false);
    // };
    
    // (user !== false && user?.uid !== undefined && propertyContent.userId !== undefined && propertyContent.userId !== user?.uid && !hasFinishedPoll && !openQuestionModal) 
    // && setTimeout(() => {
    //     setOpenQuestionModal(true);
    // }, 60000); 

    return (
        <ScreenBase>
            <ScreenHeader noShadow={false}/>
            <ScrollView 
                showsVerticalScrollIndicator={false} 
                contentContainerStyle={[Layout.scrollViewPadding]} 
                ref={ref}
                scrollEventThrottle={0}
            >
                {
                    propertyStatus !== undefined &&
                    <StatusPill data={propertyStatus}/>
                }
                <ShareBtn url={window.location.href} setShareModal={()=>setShareModal(true)}/>
                {/* Gallery */}
                {
                    propertyContent && propertyContent.media !== undefined && 
                    <PropertyGallery images={propertyContent.media} setPopUpGalleryIndex={setPopUpGalleryIndex} setPopUpGallery={()=>setPopUpGallery(true)} popUpGallery={popUpGallery} imageStyle='cover' />
                }
                <Pressable style={[Layout.row, Layout.globalPadding24, {paddingTop:20}]} onPress={()=>nav.navigate('Explore')}>
                    <AntDesign name='arrowleft' size={12} color={Colors.grey3} style={{marginRight:5}}/><Text style={FontProperty.TextGrey}>Back to Explore</Text>
                </Pressable>
                { /* @todo - fix up to use logged in user auth */
                    user !== false && propertyContent && (propertyContent.user && propertyContent.user.id !== user?.uid) && !hasCompletedPoll &&
                    <Pressable onPress={()=>{setOpenQuestionModal(true);}}><Text style={[styles.projectBtn, styles.feedbackBtn, FontProperty.TextNavy, FontProperty.TextSemiBold]}>Provide feedback</Text></Pressable>
                }
                
                {/* Property Name */}
                <Text style={[FontProperty.h0, Layout.globalPadding24, {marginTop:16}]}>{propertyContent.title}</Text>
                {/* Address */}
                <Text style={[FontProperty.body_1, Layout.globalPadding24, FontProperty.TextGrey, {marginBottom:20}]}>{propertyContent.city.replace(/[^a-zA-Z ]/g, '').trim()}</Text>
                
                {/* Bathroom, Bedroom, Carpark, LandSize, PropertyType */}
                <View style={[Layout.row, Layout.globalPadding24, {alignItems:'center'}]}>
                    {
                        propertyContent && propertyContent.home_meta.bedrooms !== undefined && propertyContent.home_meta.bedrooms > 0 &&
                        <View style={[Layout.row, {marginRight:11,alignItems:'center'}]}>
                            <Ionicons name='bed-sharp' size={15} color='#939393' style={{marginBottom:-2}} />
                            <Text style={[FontProperty.smallLabel, FontProperty.TextSemiBold, {marginLeft:5,color:'#939393'}]}>{propertyContent.home_meta.bedrooms}</Text>
                        </View>
                    }
                    {
                        propertyContent && propertyContent.home_meta.bathrooms !== undefined && propertyContent.home_meta.bathrooms !== 0 &&
                        <View style={[Layout.row, {marginRight:11,alignItems:'center'}]}>
                            <MaterialIcons name='bathtub' size={15} color='#939393' />
                            <Text style={[FontProperty.smallLabel, FontProperty.TextSemiBold, {marginLeft:5,color:'#939393'}]}>{propertyContent.home_meta.bathrooms}</Text>
                        </View>
                    }
                    {
                        propertyContent && propertyContent.home_meta.carparks !== undefined && propertyContent.home_meta.carparks !== 0 &&
                        <View style={[Layout.row, {marginRight:11,alignItems:'center'}]}>
                            <Ionicons name='car' size={16} color='#939393' />
                            <Text style={[FontProperty.smallLabel, FontProperty.TextSemiBold, {marginLeft:5,color:'#939393'}]}>{propertyContent.home_meta.carparks}</Text>
                        </View>
                    }
                    {
                        propertyContent && propertyContent.home_meta.home_size !== undefined &&
                        <View style={[Layout.row, {marginRight:11,alignItems:'center'}]}>
                            <Image source={IMAGE.landSize} style={{resizeMode:'contain',width:14,height:14}}/>
                            <Text style={[FontProperty.smallLabel, FontProperty.TextSemiBold, {marginLeft:5,color:'#939393'}]}>{propertyContent.home_meta.home_size}㎡</Text>
                        </View>
                    }
                    {
                        propertyType !== undefined &&
                        <Text style={[FontProperty.smallLabel, FontProperty.TextSemiBold, {color:'#939393'}]}>{propertyType}</Text>
                    }
                </View>
                {/* Price Estimation */}
                {propertyContent && propertyContent.open_to_offers == true &&
                    <>
                        <View style={styles.sectionShadow}></View>
                        <View style={[Layout.globalPadding24, {paddingTop:24,alignItems:'center'}]}>
                            <Text style={[FontProperty.smallLabel, FontProperty.TextGrey, {marginBottom:3}]}>Owner’s Price Estimate</Text>
                            <Text style={[FontProperty.TextTeal, {fontSize:17}, FontProperty.TextSemiBold]}>
                                {ownersPriceEstimate()}
                            </Text>
                        </View>
                    </>
                }
                {/* Details */}
                {propertyContent && propertyContent.description !== undefined && propertyContent.description !== '' &&
                    <>
                        <View style={styles.sectionShadow}></View>
                        <View style={Layout.globalPadding24}>
                            <Text style={[FontProperty.h1, styles.headerContainer]}>The details</Text>
                            <Hyperlink linkDefault={ true } linkStyle={{color: '#00b7a1'}}>
                                <Text style={[FontProperty.body_1, FontProperty.TextGrey]}>
                                    {uppercaseFirstLetter(propertyContent.description)}
                                </Text>
                            </Hyperlink>
                        </View>
                    </>
                }
                {/* Neighbourhood */}
                {/*propertyContent && propertyContent.neighbourhoodDescription !== undefined && propertyContent.neighbourhoodDescription !== '' &&
                  <View style={Layout.globalPadding24}>
                      <Text style={[FontProperty.h1, styles.headerContainer]}>The neighbourhood</Text>
                      <Hyperlink linkDefault={ true } linkStyle={{color: '#00b7a1'}}>
                          <Text style={[FontProperty.body_1, FontProperty.TextGrey]}>
                              {uppercaseFirstLetter(propertyContent.neighbourhoodDescription)}
                          </Text>
                      </Hyperlink>
                  </View>
                */}
                {/* Project */}
                {/*propertyContent && 
                  <View style={[{marginBottom: 16}]}>
                      <Text style={[FontProperty.h1, styles.headerContainer, Layout.globalPadding24]}>Projects</Text>
                      {
                          (user !== false && propertyContent.userId === user?.uid) &&
                        <Pressable onPress={()=>nav.navigate('StartProject', {propertyId: propertyContent.uu_id })} style={styles.hasProjectBtn}><Text style={[styles.projectBtn, FontProperty.TextNavy, FontProperty.TextSemiBold]}>List a project</Text></Pressable>
                      }
                      {
                          propertyContent.projects !== undefined && propertyContent.projects.length> 0 && projectList !== undefined 
                              ? <ProjectCarousel data={projectList} />
                              : <Text style={Layout.globalPadding24}>No project listed</Text>
                      }
                      
                  </View>
                    */}
                {/* Owner */}
                {propertyContent && propertyContent.userId !== undefined && propertyContent.userId !== '' && ownerData !== undefined &&
                  <Pressable style={[Layout.globalPadding24,{marginBottom:26}]} onPress={()=>{nav.navigate({name: 'UserProfile', params: { userId: propertyContent.userId, postId: '' }});}}>
                      <Text style={[FontProperty.h1, styles.headerContainer]}>Owner details</Text>
                      <View style={[Layout.rowBetween, {marginTop:8}]}>
                          <Image source={(ownerData.headshot && ownerData.headshot !== '') ? ownerData.headshot : IMAGE.userProfile} style={{resizeMode:'cover',width:54,height:54,borderRadius:54}}/>
                          <View style={{flex:1,paddingLeft:21}}>
                              <Text style={[FontProperty.body_1, {marginBottom:2}]}>{ownerData.username}</Text>
                          </View>
                          <View style={Layout.row}>
                              <Feather name='user' size={20} color={Colors.teal} />
                              <Text style={[FontProperty.TextTeal, FontProperty.TextMedium, FontProperty.smallLabel, {marginLeft:4}]}>Profile</Text>
                          </View>
                      </View>
                  </Pressable>
                }
                {/* Comments */}
                {
                    <View style={[Layout.globalPadding24,{paddingBottom:24}]}>
                        <Text style={[FontProperty.h1, styles.headerContainer]}>Comments</Text>
                        {user !== false && propertyContent.userId !== user?.uid && 
                            <View style={{marginTop:22}}>
                                <CommentBar onPost={onPostComment} placeholder='Write a comment...' userImage={visitorData} />
                            </View>
                        }
                        {user == false && <View style={{marginTop:22}}><CommentBarUnau/></View> }
                        {
                            commentsData !== undefined && commentsData?.length > 0 ?
                                commentsData?.map((comment:Icomment, index:number) => {
                                    return (
                                        <Pressable key={index} onPress={()=>{nav.navigate({name: 'UserProfile', params: { userId: comment.user.uid, postId: '' }});}} style={{marginBottom:16}}>
                                            <CommentMessage comment={comment.comment} userID={comment.user.uid}/>
                                        </Pressable>
                                    
                                    );
                                })
                                : <Text>Comments coming soon! Check out the <a href={staticUrls.downloadMobileApp}>mobile app</a> for comments in the meantime.</Text>
                        }
                        {
                            commentsDataAll !== undefined && commentsDataAll.length > 3 &&
                            <Pressable onPress={()=>{setViewAllComment(true);}}><Text style={[FontProperty.TextTeal, Layout.mtop15]}>View all comments</Text></Pressable>
                        }
                    </View>
                }
                {/* Styles */}
                {propertyContent && propertyContent.style !== undefined && propertyContent.style.length > 0 &&
                    <>
                        <View style={styles.sectionShadow}></View>
                        <View style={Layout.globalPadding24}>
                            <Text style={[FontProperty.h1, styles.headerContainer]}>Styles</Text>
                            <View style={[Layout.row, styles.tagContainer]}>
                                {
                                    propertyContent.style?.map((style) => {
                                        return (
                                            <View key={style.uu_id} style={[{
                                                borderColor: Colors.lightBeige,
                                                borderWidth: 1,
                                                borderRadius: 5,
                                                paddingVertical: 6,
                                                paddingHorizontal: 15,
                                                marginRight: 10,
                                                marginBottom: 10
                                            }]}>
                                                <Text style={[FontProperty.h2, FontProperty.TextBeige]}>{style.name}</Text>
                                            </View>
                                        );
                                    })
                                }
                            </View>
                        </View>
                    </>
                }
                {/* Tags */}
                {
                    propertyContent && propertyContent.tags !== undefined && propertyContent.tags.length > 0 && 
                    <View style={Layout.globalPadding24}>
                        {propertyContent.tags[0] !== '' && <Text style={[FontProperty.h1, styles.headerContainer]}>Property feature tags</Text>}
                        <View style={[Layout.row, styles.tagContainer]}>
                            {
                                propertyContent.tags?.map((tag, index:number) => {
                                    return (
                                        tag !== '' &&
                                        <View key={index} style={[{
                                            backgroundColor: Colors.lightBeige,
                                            borderRadius: 5,
                                            paddingVertical: 6,
                                            paddingHorizontal: 15,
                                            marginRight: 10,
                                            marginBottom: 10
                                        }]}>
                                            <Text style={[FontProperty.h2, FontProperty.TextBeige]}>{tag}</Text>
                                        </View>
                                    );
                                })
                            }
                        </View>
                    </View>
                }
                {/* Suggestions */}
                {
                    similarProperty !== undefined && similarProperty.length > 0 && propertyContent &&
                    <SimilarProperty data={similarProperty}/>
                }
                
            </ScrollView>
            
            {
                user !== false &&
                <View style={[styles.screenFooter]}>
                    {
                        (propertyContent && propertyContent.userId !== user?.uid) &&
                        <View style={[Layout.rowBetween, {width:'100%'}]}>
                            {
                                !visualFollow 
                                    ? <NavyOutlineButton btnStyle={[styles.btnStyle]} onPress={()=>followThisProperty(propertyContent.uu_id)}>
                                        <Text style={[FontProperty.button_1, FontProperty.TextNavy]}>Follow</Text>
                                    </NavyOutlineButton>
                                    : <NavyOutlineButton btnStyle={[styles.btnStyle]} onPress={()=>unfollowThisProperty(propertyContent.uu_id)}>
                                        <Text style={[FontProperty.button_1, FontProperty.TextNavy]}>Unfollow</Text>
                                    </NavyOutlineButton>
                            }
                            <TealButton btnStyle={[styles.btnStyle]} onPress={()=>contactThisUser(propertyContent.userId)}>
                                <Text style={[FontProperty.button_1, FontProperty.TextWhite]}>Message</Text>
                            </TealButton>
                        </View>
                    }
                    {
                        (propertyContent && propertyContent.user && propertyContent.user.id == user?.username) &&
                        <View style={[Layout.rowBetween, {width:'100%'}]}>
                            <NavyOutlineButton btnStyle={[styles.btnStyle]} onPress={()=>setDeletePropertyModal(true)}>
                                <Text style={[FontProperty.button_1, FontProperty.TextNavy]}>Delete</Text>
                            </NavyOutlineButton>
                            <TealButton btnStyle={[styles.btnStyle]} onPress={()=>editThisProperty(propertyContent.uu_id)}>
                                <Text style={[FontProperty.button_1, FontProperty.TextWhite]}>Edit</Text>
                            </TealButton>
                        </View>
                    }
                </View>
            }          
            {
                shareModal &&
                <ShareModal setShareModal={()=>setShareModal(false)} url={window.location.href} mailSubject='Check out this property on abodely.app' title='property'/>
            }
            {
                popUpGallery && propertyContent && 
                <PopUpGalleryModal 
                    userId={user !== false && user?.uid} 
                    images={propertyContent.media} 
                    index={popUpGalleryIndex} 
                    setPopUpGallery={()=>setPopUpGallery(false)} 
                    allowFavourate={user !== false && propertyContent.userId !== user?.uid}
                    autoPlay={false}
                />
            }

            {
                postCommentSucceed &&
                <WarningBar text='Your comment is published!' icon='check-circle' backgroundcolor={Colors.teal} bottom={105}/>
            }
            {
                postPostSucceed &&
                <WarningBar text='Your post is published!' icon='check-circle' backgroundcolor={Colors.teal} bottom={105}/>
            }
            {
                viewAllComments &&
                <AllComments 
                    open={viewAllComments}
                    setViewAllComment={()=>setViewAllComment(false)}
                    comments={commentsDataAll}
                />
            }
            {
                deletePropertyModal && propertyContent && 
                <WarningPopUp 
                    title='Delete this listing?'
                    content={ContentText.deleteThisProperty}
                    yesBtnLabel='Delete'
                    noBtnLable='Cancel'
                    noBtnOnClick={()=>setDeletePropertyModal(false)}
                    yesBtnOnClick={()=>deleteThisProperty(propertyContent.uu_id)}
                />
            }
            {
                showContactHomeownerModal && propertyContent && ownerData &&
                <ContactHomeownerModel 
                    open={showContactHomeownerModal} // Set open={true} if want to show the modal once page loaded
                    setContactHomeownerModal={setContactHomeownerModal}
                    succeedBtnLabel="Return to property profile"
                    succeedText="Message sent!"
                    hideHeader={false}
                    placeholder={'Say something to ' + ownerData.username + ' about the property'}
                    senderId={user?.uid}
                    receiverId={propertyContent.userId}
                    targetUserData={ownerData}
                />
            }
            { user !== false && propertyContent && (propertyContent.userId !== user?.uid) && openQuestionModal &&
                <QuestionModel 
                    open={openQuestionModal}
                    title="Hey! Quick question..."
                    succeedTitle="You're all set!"
                    succeedText="Thank you, we appreciate your feedback!"
                    userId = {user.uid}
                    propertyId = {propertyContent.uu_id}
                    lowPrice = {propertyContent.estimateLowPrice}
                    highPrice = {propertyContent.estimateHighPrice}
                    setContactHomeownerModal = {setContactHomeownerModal}
                    setHasFinishedPoll = {setHasFinishedPoll}
                    setOpenQuestionModal = {setOpenQuestionModal}
                    setHasCompletedPoll = {setHasCompletedPoll}
                />
            }
        </ScreenBase>
    );
};

export default ViewPropertyScreen;
const styles = StyleSheet.create({
    headerContainer: {
        paddingTop: 24,
        marginBottom: 12
    },
    tagContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginVertical: 8
    },
    screenFooter: {
        paddingBottom: 6,
        boxShadow: '0px -6px 10px rgba(0, 0, 0, 0.1)',
        paddingHorizontal: 14,
        alignItems: 'center',
    },
    btnStyle: {
        paddingHorizontal: 30,
        marginTop : 16,
        width: Layout.window.width > 425 ? 360 : '100%',
        flex: 1,
        marginHorizontal: 10,
    },
    sectionShadow: {
        height: 1,
        width: '100%',
        backgroundColor: '#4f4f4f4d',
        marginTop: 24
    },
    projectBtn: {
        borderColor: Colors.navy,
        borderWidth: 1,
        borderRadius: 4,
        textAlign: 'center',
        paddingVertical: 4.5,
        paddingHorizontal: 15.5,
        width: 'fit-content'
    },
    hasProjectBtn: {
        position: 'absolute',
        right: 24,
        top: 20,
    },
    feedbackBtn: {
        marginLeft: 24,
        marginTop: -22,
        position: 'absolute',
        right: 24,
    }
});
