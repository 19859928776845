import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { AuthenticationStackParamList } from '../types';
import { AuthenticationScreen } from '../screens/Global/AuthenticationScreen';
import { RequestPasswordChangeScreen } from '../screens/ForgotPassword/RequestPasswordChangeScreen';
import { SetNewPasswordScreen } from '../screens/ForgotPassword/SetNewPasswordScreen';
import { UpdatePasswordChangeScreen } from '../screens/ForgotPassword/UpdatePasswordChangeScreen';
import { UpdateSetNewPasswordScreen } from '../screens/ForgotPassword/UpdateSetNewPasswordScreen';

const AuthenticationStack = createNativeStackNavigator<AuthenticationStackParamList>();
export function AuthenticationNavigation () {
    return (
        <AuthenticationStack.Navigator initialRouteName='SignIn' screenOptions={{ headerShown: false }}>
            <AuthenticationStack.Screen name="SignIn" component={AuthenticationScreen} />
            <AuthenticationStack.Screen name="RequestPasswordChange" component={RequestPasswordChangeScreen} />
            <AuthenticationStack.Screen name="SetNewPassword" component={SetNewPasswordScreen} />
            <AuthenticationStack.Screen name="UpdatePassword" component={UpdatePasswordChangeScreen} />
            <AuthenticationStack.Screen name="UpdateSetPassword" component={UpdateSetNewPasswordScreen} />
        </AuthenticationStack.Navigator>
    );
}