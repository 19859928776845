export const constants = {
    posts: 'posts',
    comments: 'comments',
    likes: 'likes',
    follows: 'follows',
    poll : 'poll',
    followProperties: 'followProperties',
    followUsers: 'followUsers',
    contactUs: 'contactUs',
    visitors: 'visitors',
};
