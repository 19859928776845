import React, {useState} from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Animated, Pressable } from 'react-native';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import { Ionicons } from '@expo/vector-icons';
import Layout from '../../constants/Layout';
import ProfileItem from './ProfileItem';
import ProfileFaqs from './ProfileFaqs';
import ProfileDetails from './ProfileDetails';
import { auth } from '../../config/firebase';
import ProfileGeneral from './ProfileGeneral';
import ProfileContact from './ProfileContact';
import { useAuthentication } from '../../hooks/useAuthentication';
import ProfilePP from './ProfilePP';
import ProfileTC from './ProfileTC';

export type TProfileSettings = {
    open : boolean
    setShowProfileSettings : (arg0 : boolean) => void
    userId : string
    trigger : boolean
    setTrigger : (arg0:boolean) => void
}

const ProfileSettings : React.FC< TProfileSettings > = (props) => {
    const { user } = useAuthentication();
    const [bounceValue, setBounceValue] = useState(new Animated.Value(Layout.window.width + 200));
    const [bounceValueContent, setBounceValueContent] = useState(new Animated.Value(Layout.window.width + 200));
    const [isHidden, setIsHidden ] = useState(props.open);
    const [showContent, setShowContent] = useState(false);
    const [isHiddenContent, setIsHiddenContent ] = useState(true);
    const [content, setContent] = useState<'details' | 'setting' | 'faqs' | 'contact' | 'feedback' | 'policy' | 'terms'>('details');
    const [contentTitle, setContentTitle] = useState('');

    const closeWholeModel = () => {
        toggleSlide(false);
        setTimeout(() => {
            setIsHidden(false);
            props.setShowProfileSettings(false);
        }, 500);
    };

    const closeContentModel = () => {
        toggleSlideContent(false);
        setTimeout(() => {
            setIsHiddenContent(false);
            setShowContent(false);
        }, 500);
        props.setTrigger(!props.trigger);
    };

    const showContentModal = (contentTarget: 'details' | 'setting' | 'faqs' | 'contact' | 'policy' | 'terms' ) => {
        setShowContent(true);
        toggleSlideContent(true);
        setContent(contentTarget);
        setIsHiddenContent(true);
    };

    const toggleSlide = (status : boolean) =>  {
        let slideToValue = Layout.window.width + 200;
        if(status) {
            slideToValue = 0;
        }
        Animated.spring(
            bounceValue,
            {
                toValue: slideToValue,
                velocity: 10,
                tension: 2,
                friction: 8,
                useNativeDriver: false
            }
        ).start();
    };

    const toggleSlideContent = (status : boolean) =>  {
        let contentSlideToValue = Layout.window.width + 200;
        if(status) {
            contentSlideToValue = 0;
        }
        Animated.spring(
            bounceValueContent,
            {
                toValue: contentSlideToValue,
                velocity: 10,
                tension: 2,
                friction: 8,
                useNativeDriver: false
            }
        ).start();
    };

    toggleSlide(isHidden);
    
    return (
        <Animated.View style={[styles.outContainer, {transform: [{translateX: bounceValue}]}, !isHidden && {display:'none'}]}>
            <View style={{paddingHorizontal:24,paddingBottom:24}}>
                <TouchableOpacity onPress={closeWholeModel} style={Layout.row}>
                    <Ionicons name="arrow-back" size={23} color={Colors.grey1} style={{marginRight:15}}/>
                </TouchableOpacity>
            </View>
            <View>
                <Pressable onPress={()=>{showContentModal('details');setContentTitle('Profile Details');}}>
                    <ProfileItem 
                        title='Profile Details'
                        subtitle='Email, Name, Password, etc.'
                        icon='user'
                    />
                </Pressable>
                <Pressable onPress={()=>{showContentModal('setting');setContentTitle('General Settings');}}>
                    <ProfileItem 
                        title='General Settings'
                        subtitle='Notifications, Permissions'
                        icon='settings'
                    />
                </Pressable>
                <Pressable onPress={()=>{showContentModal('contact');setContentTitle('Contact Us');}}>
                    <ProfileItem 
                        title='Contact Us'
                        icon='message-circle'
                    />
                </Pressable>
                <Pressable onPress={()=>{showContentModal('faqs');setContentTitle('FAQs');}}>
                    <ProfileItem 
                        title='FAQs'
                        icon='help-circle'
                    />
                </Pressable>
                <Pressable onPress={()=>{showContentModal('policy');setContentTitle('Privacy Policy');}}>
                    <ProfileItem 
                        title='Privacy Policy'
                        icon='shield'
                    />
                </Pressable>
                <Pressable onPress={()=>{showContentModal('terms');setContentTitle('Terms Of Use');}}>
                    <ProfileItem 
                        title='Terms Of Use'
                        icon='file-text'
                    />
                </Pressable>
                <Pressable onPress={()=>auth.signOut()}>
                    <ProfileItem 
                        title='Logout'
                        icon='log-out'
                    />
                </Pressable>
            </View>
            {
                showContent &&
                <Animated.View style={[styles.outContainer, {transform: [{translateX: bounceValueContent}]}, !isHiddenContent && {display:'none'}]}>
                    <View style={{paddingHorizontal:24,paddingBottom:24}}>
                        <TouchableOpacity onPress={closeContentModel} style={Layout.row}>
                            <Ionicons name="arrow-back" size={23} color={Colors.grey1} style={{marginRight:15}}/>
                            <Text style={[FontProperty.h1, FontProperty.TextBlack]}>{contentTitle}</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{flex:1}}>
                        { content === 'details' && <ProfileDetails userId={props.userId} user={user} /> }
                        { content === 'setting' && <ProfileGeneral userId={props.userId} /> }
                        { content === 'faqs' && <ProfileFaqs /> }
                        { content === 'contact' && <ProfileContact userId={props.userId} setShowProfileSettings={props.setShowProfileSettings} /> }
                        { content === 'policy' && <ProfilePP /> }
                        { content === 'terms' && <ProfileTC /> }
                    </View>
                    
                </Animated.View>
            }
        </Animated.View>
    );
};

const styles = StyleSheet.create({
    outContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: Colors.white,
        paddingTop: 26,
        zIndex: 2
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.8)',
        position: 'absolute',
        top: 0,
        left: 0,
        paddingHorizontal: 24,
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        alignItems: 'center'
    },
    close: {
        position: 'absolute',
        right: 24,
        top: 22,
        zIndex: 2
    },
    contentModel: {
        backgroundColor: Colors.white,
        paddingHorizontal: 24,
        paddingTop: 62,
        paddingBottom: 37,
        alignItems: 'center',
        width: Layout.window.width > 425 ? 360 : '100%',
    },
});

export default ProfileSettings;

