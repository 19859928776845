import { Pressable, StyleSheet, Text, View, Image, ActivityIndicator } from 'react-native';
import React, {useEffect, useState, useRef } from 'react';
import ScreenHeader from '../../components/atom/ScreenHeader';
import ScreenBase from '../../components/ScreenBase';
import Colors from '../../constants/colors';
import { ScrollView } from 'react-native-gesture-handler';
import { useNavigation, useRoute, useScrollToTop } from '@react-navigation/native';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import { TealButton } from '../../components/Button';
import IconText from '../../components/atom/IconText';
import { getPropertyContent } from '../../lib/api/Content/property';
import { IpropertyData } from '../../lib/api/Content/types';
import HouspoBarChart from '../../components/atom/HouspoBarChart';
import PollResult from '../../components/atom/PollResult';
import MostLikesPosts from '../../components/molecule/MostLikesPosts';
import { useAuthentication } from '../../hooks/useAuthentication';
import ShareModal from '../../components/atom/ShareModal';
import AllPostsLikeComments from '../../components/atom/AllPostsLikeComments';
import PopUpModal from '../../components/atom/PopUpModal';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import PopUpReportModal from '../../components/atom/PopUpReportModal';
import IMAGE from '../../assets/images';
import PopUpEditPost from '../../components/atom/PopUpEditPost';
import PopupNotifyUser from '../../components/atom/PopUpNotifyUser';
import { deletePost } from '../../lib/api/Content/posts/posts';
import AllInsightsModel from '../../components/atom/AllInsightsModel';
import ContentText from '../../constants/Content';
import NoResult from '../../components/atom/NoResult';


const ViewPropertyInsightsScreen: React.FC<{ propertyId: string}> = (props) => {
    const nav = useNavigation();
    const { params } = useRoute();
    const { user } = useAuthentication();
    const [loading, setLoading] = useState<boolean>(true);
    const [propertyId, setPropertyId] = useState();
    const [propertyContent, setPropertyContent] = useState<IpropertyData>();
    const [showLikes, setShowLikes] = useState<boolean>(false);
    const [showComments, setShowComments] = useState<boolean>(false);
    const [targetPost, setTargetPost] = useState<string>('');
    const [showShare, setShowShare] = useState<boolean>(false);
    const [shareLink, setShareLink] = useState<string>('');
    const [showDeletePopUp, setShowDeletePopUp] = useState<boolean>(false);
    const [showEditPopUp, setShowEditPopUp] = useState<boolean>(false);
    const [showReportPopUp, setShowReportPopUp] = useState<boolean>(false);
    const [showOtherProperties, setShowOtherProperties] = useState<boolean>(false);
    const [trigger, setTrigger] = useState<boolean>(false);
    const ref = useRef();

    useScrollToTop(ref);

    useEffect(() => {
        ref.current?.scrollTo({
            y: 0,
            animated: true,
        });
    },[propertyId]);

    useEffect(() => {
        if(params?.propertyId !== undefined){
            setPropertyId(params?.propertyId);
            getPropertyContent(params?.propertyId).then(res => {
                setPropertyContent(res[0]);
                setLoading(false);
            }
            );
        }
        // else{
        //     setPropertyId('GXSqcOV8JrrNW4KPBiuI');
        //     getPropertyContent('GXSqcOV8JrrNW4KPBiuI').then(res => {
        //         setPropertyContent(res[0]);
        //         setLoading(false);
        //     }
        //     );
        // }
    }, [params?.propertyId, trigger, user]);

    if(loading) {
        return (
            <ScreenBase>
                <ScreenHeader noShadow={false}/>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <View style={{marginTop:150}}>
                        <ActivityIndicator size={Layout.window.width > 425 ? 107 : 80} color={Colors.teal} />
                    </View>
                </ScrollView>
            </ScreenBase>
        );
    }

    if((!loading && propertyContent === undefined) || propertyContent?.userId !== user.uid) {
        return (
            <ScreenBase>
                <ScreenHeader noShadow={false}/>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <View style={[Layout.globalPadding24, {alignItems:'center'}]}>
                        <NoResult title="Property insight not available" />
                        <View style={{marginTop:24}}></View>
                        <View style={{width: Layout.window.width > 425 ? 180 : '100%'}}>
                            <TealButton onPress={()=>nav.navigate('Explore')}>
                                <Text style={[FontProperty.button_1, FontProperty.TextWhite]}>Back to Explore</Text>
                            </TealButton>
                        </View>
                    </View>
                    
                </ScrollView>
            </ScreenBase>
        );
    }

    const deleteMyPost = async (postId:string)=> {
        await deletePost(postId);
        setShowDeletePopUp(false);
        setTrigger(!trigger);
    };

    return (
        <ScreenBase>
            <ScreenHeader noShadow={false}/>
            {
                user !== false && user?.uid === propertyContent.userId &&
                <ScrollView 
                    showsVerticalScrollIndicator={false} 
                    contentContainerStyle={[Layout.scrollViewPadding]} 
                    scrollEventThrottle={0}
                    ref={ref}
                >
                    <View style={{padding:24,backgroundColor:Colors.lightBeige}}>
                        <Text style={[FontProperty.h4]}>Status of <Text  style={[FontProperty.TextSemiBold]}>{propertyContent.propertyName}</Text></Text>
                    </View>
                    <HouspoBarChart propertyId={propertyId} />
                    <IconText 
                        icon='bulb1'
                        title={ContentText.improvePropertyTitle}
                        subtitle={ContentText.improvePropertSubtTitle}
                    />
                    <PollResult 
                        propertyId={propertyId}
                        lowPrice = {propertyContent.estimateLowPrice}
                        highPrice = {propertyContent.estimateHighPrice}
                    />
                    <View style={[styles.bar]}></View>
                    <MostLikesPosts 
                        userId={user !== false && user?.uid}
                        setShowLikes={setShowLikes}
                        setShowComments={setShowComments}
                        setTargetPost={setTargetPost}
                        setShowShare={setShowShare}
                        setShareLink={setShareLink}
                        setShowReportPopUp={setShowReportPopUp}
                        setShowDeletePopUp={setShowDeletePopUp}
                        setShowEditPopUp={setShowEditPopUp}
                        trigger={trigger}
                    />
                    <View style={{marginBottom:-50}}></View>
                </ScrollView>
            }
            {
                user !== false && user?.uid === propertyContent.userId &&
            <View style={[styles.bottomContainer]}>
                <View style={[{ backgroundColor: '#fff',paddingTop:16,flex:1,width:'100%', alignItems:'center'}]}>
                    <View style={[styles.buttonContainer]}>
                        <TealButton onPress={()=>setShowOtherProperties(true)}>
                            <Text style={[FontProperty.button_1]}>View another property</Text>
                        </TealButton>
                    </View>
                </View>
            </View>
            }
            {
                showShare &&
                <ShareModal setShareModal={()=>setShowShare(false)} url={shareLink} mailSubject='Check out this post on abodely.app' title='post'/>
            }
            {
                (showLikes || showComments) &&
                <AllPostsLikeComments 
                    open={showLikes || showComments}
                    showLikes={showLikes} 
                    showComments={showComments} 
                    targetPost={targetPost}  
                    setShowLikes={setShowLikes}
                    setShowComments={setShowComments}
                    setTargetPost={setTargetPost}
                />
            }
            {user == false &&
                <PopUpModal show={true}>
                    <Pressable onPress={() => nav.navigate('Platform', { screen: 'Home' })} style={{marginBottom: 20}}>
                        <MaterialCommunityIcons name='arrow-left' size={20} />
                    </Pressable>
                    <View style={{alignItems: 'center'}}>
                        <Text style={[FontProperty.h1, FontProperty.TextBlack, Layout.mbottom8]}>Property Insight is locked <MaterialCommunityIcons name='lock' size={20} /></Text>
                        <Text style={[FontProperty.smallP, Layout.mbottom25, {textAlign: 'center'}]}>{ContentText.encourageSignUpFollow}</Text>
                        <Image source={IMAGE.signUpPlaceholder} style={{width:250,height:230,resizeMode:'contain',marginBottom:40}} />
                        <TealButton onPress={() => nav.navigate('Platform', { screen: 'Authentication' })} >
                            <Text style={[FontProperty.button_1, FontProperty.TextWhite]}>Sign In / Register</Text>
                        </TealButton>
                        <Text style={[FontProperty.smallLabel, {textAlign: 'center'}]}>Don't want to sign in? 
                            <Pressable onPress={() => nav.navigate('Platform', { screen: 'Explore' })}>
                                <Text style={FontProperty.TextTeal}> Explore properties</Text>
                            </Pressable>
                        </Text>
                    </View>
                </PopUpModal>
            }
            {
                showReportPopUp && 
                <PopUpReportModal 
                    open={showReportPopUp}
                    setShowReportPopUp={setShowReportPopUp} 
                    postId={targetPost} 
                    setTargetPost={setTargetPost}
                />
            }
            { showEditPopUp &&
                <PopUpEditPost 
                    userId={user?.uid}
                    postId={targetPost}
                    setShowEditPopUp={setShowEditPopUp}
                    trigger={trigger}
                    setTrigger={setTrigger}
                />
            }
            {
                showDeletePopUp &&
                <PopupNotifyUser 
                    title='Delete this post?'
                    subtitle={ContentText.deleteThisPost}
                    yesBtnLabel='Yes'
                    noBtnLable='No'
                    yesBtnOnClick={()=>deleteMyPost(targetPost)}
                    noBtnOnClick={()=>setShowDeletePopUp(false)}
                />
            }
            { showOtherProperties &&
                <AllInsightsModel 
                    open={showOtherProperties}
                    userId={user?.uid}
                    propertyId={propertyId}
                    setShowOtherProperties={setShowOtherProperties}
                    setTrigger={setTrigger}
                    trigger={trigger}
                    
                />
            }
        </ScreenBase>
    );
};

export default ViewPropertyInsightsScreen;
const styles = StyleSheet.create({
    buttonContainer: {
        width: Layout.window.width > 425 ? 360 : '100%',
        backgroundColor: '#fff',
    },
    bottomContainer: {
        justifyContent: 'flex-end',
        paddingBottom: 8,
        paddingHorizontal: 24,
        backgroundColor: '#fff',
        boxShadow: '0px -6px 10px rgba(0, 0, 0, 0.1)',
        alignItems: 'center',
    },
    bar: {
        backgroundColor: Colors.beige,
        height: 8,
    }
});
