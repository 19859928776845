import React, {useEffect, useState, Dispatch, SetStateAction} from 'react';
import { StyleSheet, View, TouchableOpacity, ActivityIndicator } from 'react-native';
import Layout from '../../constants/Layout';
import Colors from '../../constants/colors';
import { Ionicons } from '@expo/vector-icons';
import NewsfeedBlock from './newsfeedBlock/NewsfeedBlock';
import { getPostContent, getPostContentWithId } from '../../lib/api/Content/posts/posts';
import { IPropertyPost } from '../../lib/api/Content/posts/types';
import NoResult from './NoResult';

export type TPopUpViewOnePostModal = {
    open: boolean
    postId: string
    userId: string
    setShowPostPopUp : (arg0: boolean) => void
    setPopUpPost: (arg0: string) => void
    setShowLikes: Dispatch<SetStateAction<boolean>>
    setShowComments: Dispatch<SetStateAction<boolean>>
    setTargetPost: Dispatch<SetStateAction<string>>
    setShowShare: Dispatch<SetStateAction<boolean>>
    setShareLink: Dispatch<SetStateAction<string>>
    setShowReportPopUp : Dispatch<SetStateAction<boolean>>
    setShowDeletePopUp : Dispatch<SetStateAction<boolean>>
    setShowEditPopUp : Dispatch<SetStateAction<boolean>>
}
const PopUpViewOnePostModal = (props:TPopUpViewOnePostModal) => {
    const [post, setPost] = useState<IPropertyPost>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        props.postId &&
        getPostContentWithId(props.postId).then(postData => {
            if(postData){
                setPost(postData);
            }
            setLoading(false);
        });
    },[props.postId]);

    return (
        <View style={styles.popUpContainer}>
            <View style={styles.popUpInnerContainer}>
                <TouchableOpacity onPress={()=>{props.setShowPostPopUp(false);props.setPopUpPost('');}}>
                    <Ionicons name='close' size={20} style={styles.MenuClose_big} color={Colors.black}/>
                </TouchableOpacity>
                <View>
                    {
                        loading
                            ?
                            <ActivityIndicator size={80} color={Colors.teal} />
                            :
                            (
                                (post === undefined || post.userId === undefined)
                                    ? <NoResult title="Post doesn't esist"/>
                                    : <NewsfeedBlock 
                                        data={post} 
                                        userId={props.userId}
                                        setShowLikes={props.setShowLikes}
                                        setShowComments={props.setShowComments}
                                        setTargetPost={props.setTargetPost}
                                        setShowShare={props.setShowShare}
                                        setShareLink={props.setShareLink}
                                        setShowDeletePopUp={props.setShowDeletePopUp}
                                        setShowEditPopUp={props.setShowEditPopUp}
                                        setShowReportPopUp={props.setShowReportPopUp}
                                    /> 
                            )
                    }
                    
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    popUpContainer: {
        position:'absolute',
        zIndex: 1,
        width: '100%',
        height: Layout.window.height,
        backgroundColor: '#000000cc',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 24,
    },
    popUpInnerContainer: {
        width: '100%',
        backgroundColor: Colors.white,
        borderRadius: 16,
        paddingVertical: 24
    },
    MenuClose_big: {
        width: 21,
        height: 21,
        marginBottom: 16,
        alignSelf: 'flex-end',
        marginRight: 24,
    },
});

export default PopUpViewOnePostModal;
