import axios from 'axios';
import { fetchAccessToken } from './user/authentication';
import apiConfig from '../../config/api';

/**
 * apiCall function
 * @param endpoint - The endpoint in the API (i.e. home/view/7)
 * @param POSTparameters - Any parameters to be sent
 * @param type: string - 'get' or 'post'
 * @returns Promise / JSON Object
 */
export async function apiCall(endpoint: string, parameters: object, type='get'){
    const token = await fetchAccessToken(); // The JWT token you received when the user logged in

    let config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'content-type': 'application/json'
      },
      ResponseType: 'application/json'
    };

    endpoint = Object.keys(parameters).length ? endpoint + '?' + new URLSearchParams(parameters).toString() : endpoint;
    
    if (type=='get')
      return getCall()
    if (type=='post')
      return postCall()
    
      /** Simple method to construct API URL */
    function constructUrl(endpoint: string) {
        return apiConfig.url + '/' + endpoint
    }

    /**
     * Get calls
     * @returns Object
     */
    async function getCall()
    {
      try {
        let response = await axios.get(
            constructUrl(endpoint), 
            config
          )
        if (response && response.data.data) {
          return response.data.data;
        } else {
          return response;
        }
      } 
      catch(error) {
        return error;
      }
    }

    /**
     * Post calls
     * @returns Object
     */
    async function postCall(){
      try {
        let response = await axios.post(
            constructUrl(endpoint), 
            parameters,
            config
          )
        if (response && response.data.data) {
          return response.data.data;
        } else {
          return response;
        }
      } 
      catch(error) {
        return error;
      }
    }
  };