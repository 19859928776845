import React, {useEffect, useState} from 'react';
import { StyleSheet, Text, View, FlatList, Pressable, ActivityIndicator } from 'react-native';
import { ScrollView, TouchableOpacity } from 'react-native-gesture-handler';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import Layout from '../../constants/Layout';
import FontProperty from '../../constants/FontProperty';
import Colors from '../../constants/colors';
import { getPropertyStates, getPropertyStyles, getPropertyTypes, filterAllProperties } from '../../lib/api/Content/property';
import { IpropertyStyle, IpropertyType, IpropertyStatus, IpropertyData } from '../../lib/api/Content/types';
import ScreenHeader from '../../components/atom/ScreenHeader';
import ScreenBase from '../../components/ScreenBase';
import ScreenWrapper from '../../components/atom/ScreenWrapper';
import FilterRow from '../../components/atom/FilterRow';
import FilterBox from '../../components/atom/FilterBox';
import HousepoTextInput from '../../components/atom/HouspoTextInput';
import FilterTab from '../../components/atom/FilterTabs';
import HouseTile from '../../components/atom/HouseTile';
import { TealButton, NavyOutlineButton } from '../../components/Button';
import NoResult from '../../components/atom/NoResult';
import { numberWithCommas } from '../../lib/utils/numFormatter';
import HouspoSuburbsMultiSearch from '../../components/atom/HouspoSuburbsMultiSearch';
import { useFocusEffect } from '@react-navigation/native';
import Footer from '../../components/molecule/Footer';


const InitialSearchScreen = ({route}:any) => {
    const nav = useNavigation();
    // 0 Keywords, 1 Type, 2 Style, 3 Status, 4 Price Min, 5 Price Max, 6 Land Size, 7 Address
    const [filterObj, setFilterObj] = useState(['', '', '', '', '', '', '', '']);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [numOfFilter, setNumOfFilter] = useState(0);
    const [targetFilter, setTargetFilter] = useState('');
    const [allProperties, setAllProperties] = useState([]);

    const [propertyTypes, setPropertyTypes] = useState([]);
    const [propertyStyles, setPropertyStyles] = useState([]);
    const [propertyStatus, setPropertyStatus] = useState([]);
    const [filterPriceMin, setFilterMinPrice] = useState('');
    const [filterPriceMax, setFilterMaxPrice] = useState('');
    const [filterLandSize, setFilterLandSize] = useState('');
    const [filterkeyword, setFilterKeyword] = useState('');
    const [openSuburb, setSuburbOpen] = useState(false);
    const [locationSuburbValue, setLocationSuburbValue] = useState('');
    const [loading, setLoading] = useState(true);
    const [finalSuburbArr, setFinalSuburbArr] = useState([]);

    let paramAddress = '';
    let paraStyle = '';
    useEffect(() => {
        if(route.params){
            if(route.params?.address){
                paramAddress = route.params.address;
                setFinalSuburbArr([paramAddress]);
                setLocationSuburbValue(paramAddress);
                setFilterObj(['', '', '', '', '', '', '', paramAddress]);
                setNumOfFilter(0);
            }else if(route.params?.style){
                paraStyle = route.params.style;
                setFilterKeyword('');
                setFilterObj(['', '', paraStyle + '||', '', '', '', '', '']);
                setNumOfFilter(1);
            }else{
                setFilterObj(['', '', '', '', '', '', '', '']);
                setNumOfFilter(0);
            }
        }
    }, [route.params?.keyword, route.params?.style, route.params?.date]);

    const showFilter = (target: string) => {
        setShowFilterModal(true);
        setTargetFilter(target);
    };

    const updateFilter = (index: number, value: string, name: string) => {
        if(filterObj[index].includes(value)){
            filterObj[index] = filterObj[index].replace(value + '||', '');
        }else{
            filterObj[index] += value + '||';
        }
        setFilterObj(filterObj);
    };

    const applyFilter = () => {
        filterObj[0] = filterkeyword;
        filterObj[4] = filterPriceMin;
        filterObj[5] = filterPriceMax;
        filterObj[6] = filterLandSize;
        filterObj[7] = locationSuburbValue;

        let countFilterItems = 0;
        filterObj[0] !== '' && (countFilterItems = countFilterItems + 1);
        filterObj[1] !== '' && (countFilterItems = countFilterItems + 1);
        filterObj[2] !== '' && (countFilterItems = countFilterItems + 1);
        filterObj[3] !== '' && (countFilterItems = countFilterItems + 1);
        filterObj[4] !== '' && (countFilterItems = countFilterItems + 1);
        filterObj[5] !== '' && (countFilterItems = countFilterItems + 1);
        filterObj[6] !== '' && (countFilterItems = countFilterItems + 1);
        
        setNumOfFilter(countFilterItems);
        setShowFilterModal(false);
        setFilterObj(filterObj);
        doFilter(filterObj);
    };

    const clearFilter = () => {
        setFilterKeyword('');
        setLocationSuburbValue('');
        setFilterMinPrice('');
        setFilterMaxPrice('');
        setFilterLandSize('');
        setFilterObj(['', '', '', '', '', '', '', '']);
        setFinalSuburbArr([]);
        setNumOfFilter(0);
        setShowFilterModal(false);
    };

    const reorderArr = (array:any) => {
        if (array.length) {
            array.sort(function (a:any, b:any) {
                return a.weight - b.weight;
            });
        }
    };

    useEffect(() => {
        getPropertyStyles().then(res => {
            reorderArr(res);
            setPropertyStyles(res);
        }
        );
    }, []);

    useEffect(() => {
        getPropertyTypes().then(res => {
            reorderArr(res);
            setPropertyTypes(res);
        }
        );
    }, []);

    useEffect(() => {
        getPropertyStates().then(res => {
            reorderArr(res);
            setPropertyStatus(res);
        }
        );
    }, []);

    // useEffect(() => {
    //     doFilter(filterObj);
    // }, [filterObj]);

    useFocusEffect(
        React.useCallback(() => {
            doFilter(filterObj);
        }, [filterObj])
    );


    const doFilter = (filterObj: object) => {
        setLoading(true);
        filterAllProperties(filterObj).then(res => {
            reorderArr(res);
            setAllProperties(res);
            setLoading(false);
        });
    };

    return (
        <ScreenBase>
            <ScreenHeader noShadow={true}/>
            <View style={[Layout.row, styles.filterGroup]}>
                <View style={{flex:1}}>
                    <HouspoSuburbsMultiSearch 
                        open={openSuburb}
                        value={locationSuburbValue}
                        setOpen={setSuburbOpen}
                        setValue={setLocationSuburbValue}
                        targetState=''
                        noResultMessage="Postcode or suburb doesn't exist"
                        multiple={false}
                        finalArr={finalSuburbArr}
                        setFinalArr={setFinalSuburbArr}
                        noborder={true}
                    />
                </View>
                <Pressable onPress={()=>applyFilter()} style={styles.searchBtn}><Text style={[FontProperty.miniP, FontProperty.TextWhite, FontProperty.TextSemiBold]}>Search</Text></Pressable>
                <FilterBox label=' ' btnOnPress={()=>showFilter('all')} num={numOfFilter} />
            </View>
            <ScrollView showsVerticalScrollIndicator={false}>
                <ScreenWrapper>
                    <Text style={[FontProperty.h1, FontProperty.TextMedium, FontProperty.TextNavy, {marginTop:30,paddingHorizontal:10}]}>Explore homes</Text>
                    <View style={[Layout.rowBetween, {alignItems:'flex-start', marginTop:25, opacity: loading ? 0.5 : 1, marginBottom:40}]}>
                        {
                            allProperties.length > 0 ?
                                <FlatList
                                    data={allProperties}
                                    keyExtractor={(item:IpropertyData) => item.uu_id}
                                    renderItem={({item}) => {
                                        return (
                                            <HouseTile key={item.uu_id} uu_id={item.uu_id} cover={item.cover?item.cover.media_url:''} title={item.title} city={item?.city} property_status_string={item?.property_status_string} property_status_colour={item?.property_status_colour}
                                                onPress={() => {
                                                    nav.navigate('PropertyProfile', {
                                                        propertyId: item.id
                                                    });
                                                }} />
                                        );
                                    }}
                                    numColumns={2}
                                    columnWrapperStyle={{justifyContent:'space-between'}}
                                    onEndReachedThreshold={0.01}
                                />
                                :
                                loading
                                    ? <View style={{marginTop:150,flex:1}}>
                                        <ActivityIndicator size={Layout.window.width > 425 ? 107 : 80} color={Colors.teal} />
                                    </View>
                                    : <NoResult title='Oops! no result found' subtitle='Please try a different search term.' />
                        }
                    </View>
                </ScreenWrapper>
                <Footer />
            </ScrollView>
            {
                showFilterModal &&
                <View style={styles.overlayModal}>
                    <View style={styles.filterModalContent}>
                        <View style={[Layout.rowBetween, styles.filterModalHeader]}>
                            <Text></Text>
                            <Text style={[FontProperty.h1]}>All Filters</Text>
                            <Ionicons name="close" color={Colors.grey3} size={24} onPress={()=>setShowFilterModal(false)}/>
                        </View>
                        <ScrollView contentContainerStyle={[Layout.scrollViewPadding]} showsVerticalScrollIndicator={false}>
                            <FilterRow show={targetFilter === 'keyword'} label='Keyword'num={filterObj[0]!=='' ? 999 : 0}>
                                <View style={[Layout.rowBetween, {flex: 1}]}>
                                    <View style={{flex: 1}}>
                                        <HousepoTextInput 
                                            border={true} 
                                            placeholder='Type in keyword'
                                            icon='key'
                                            iconPosition='before'
                                            setText={setFilterKeyword}
                                            value={filterkeyword}
                                        />
                                    </View>
                                </View>
                            </FilterRow>
                            <FilterRow show={targetFilter === 'type'} label='Property Type' num={filterObj[1]!=='' ? filterObj[1].split('||').length - 1 : 0}>
                                {
                                    propertyTypes.length > 0 &&
                                    propertyTypes.map((item:IpropertyType) => (
                                        <FilterTab key={item.uuid} onPress={()=>updateFilter(1, item.uuid, item.type)} label={item.type} active={filterObj[1].includes(item.uuid)}></FilterTab>
                                    ))
                                }
                            </FilterRow>
                            <FilterRow show={targetFilter === 'style'} label='Style' num={filterObj[2]!=='' ? filterObj[2].split('||').length - 1 : 0}>
                                {
                                    propertyStyles.length > 0 &&
                                    propertyStyles.map((item:IpropertyStyle) => (
                                        <FilterTab key={item.uuid} onPress={()=>updateFilter(2, item.uuid, item.style)} label={item.style} active={filterObj[2].includes(item.uuid)}></FilterTab>    
                                    ))
                                }
                            </FilterRow>
                            <FilterRow show={targetFilter === 'status'} label='Property Status' num={filterObj[3]!=='' ? filterObj[3].split('||').length - 1 : 0}>
                                {
                                    propertyStatus.length > 0 &&
                                    propertyStatus.map((item:IpropertyStatus) => (
                                        <FilterTab key={item.uuid} onPress={()=>updateFilter(3, item.uuid, item.name)} label={item.name} active={filterObj[3].includes(item.uuid)}></FilterTab>  
                                    ))
                                }
                            </FilterRow>
                            <FilterRow show={targetFilter === 'price'} label='Price' num={filterObj[4]!=='' || filterObj[5]!=='' ? 999 : 0}>
                                <View style={{flex: 1}}>
                                    <HousepoTextInput 
                                        border={true} 
                                        placeholder='Min'
                                        icon='dollar-sign'
                                        iconPosition='before'
                                        setText={setFilterMinPrice}
                                        value={filterPriceMin}
                                    />
                                    {filterPriceMin !== undefined && filterPriceMin !== '' && !isNaN(parseInt(filterPriceMin)) && <Text style={[FontProperty.TextGrey,FontProperty.miniP]}>From AUD${numberWithCommas(parseInt(filterPriceMin))}</Text>}
                                </View>
                                <View style={{flex: 1}}>
                                    <HousepoTextInput 
                                        border={true} 
                                        placeholder='Max'
                                        icon='dollar-sign'
                                        iconPosition='before'
                                        setText={setFilterMaxPrice}
                                        value={filterPriceMax}
                                    />
                                    {filterPriceMax !== undefined && filterPriceMax !== '' && !isNaN(parseInt(filterPriceMax)) && <Text style={[FontProperty.TextGrey,FontProperty.miniP]}>To AUD${numberWithCommas(parseInt(filterPriceMax))}</Text>}
                                </View>
                            </FilterRow>
                            <FilterRow show={targetFilter === 'landSize'} label='Land Size'num={filterObj[6]!=='' ? 999 : 0}>
                                <View style={[Layout.rowBetween, {flex: 1}]}>
                                    <View style={{flex: 1}}>
                                        <HousepoTextInput 
                                            border={true} 
                                            placeholder='Min land size'
                                            icon='compress'
                                            iconPosition='before'
                                            setText={setFilterLandSize}
                                            value={filterLandSize}
                                        />
                                    </View>
                                    <Text style={{marginLeft: 20}}>㎡</Text>
                                </View>
                            </FilterRow>
                        </ScrollView>
                        <View style={[Layout.rowBetween, styles.filterModalFooter]}>
                            <TouchableOpacity onPress={() => {clearFilter();}}>
                                <NavyOutlineButton btnStyle={[styles.btnStyle]} >
                                    <Text style={[FontProperty.button_1, FontProperty.TextNavy]}>Reset</Text>
                                </NavyOutlineButton>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => {applyFilter();}}>
                                <TealButton btnStyle={[styles.btnStyle]} >
                                    <Text style={[FontProperty.button_1, FontProperty.TextWhite]}>Apply</Text>
                                </TealButton>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            }
        </ScreenBase>
    );
};

export default InitialSearchScreen;

const styles = StyleSheet.create({
    filterGroup: {
        paddingHorizontal: 24,
        backgroundColor: 'white',
        justifyContent:'center',
        boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.05)',
        zIndex: 1,
        alignItems: 'flex-start',
    },
    closeSearch: {
        position: 'absolute',
        top: 12,
        right: 15
    },
    filterModalHeader: {
        paddingVertical: 24,
        borderBottomWidth: 1,
        borderBottomColor: Colors.grey5
    },
    filterModalFooter: {
        borderTopWidth: 1,
        borderTopColor: Colors.grey5,
        paddingTop: 2,
        paddingBottom: 18
    },
    overlayModal: {
        backgroundColor: '#000000cc',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1
    },
    filterModalContent: {
        backgroundColor: 'white',
        maxHeight: (Layout.window.height)/1.5,
        maxWidth: Layout.window.width < 599 ? (Layout.window.width - 48) : 375,
        paddingHorizontal: 24,
        width: '100%',
        height: '100%', 
        borderRadius: 16
    },
    btnStyle: {
        paddingHorizontal: 30,
        marginTop : 16,
        width: Layout.window.width < 599 ? (Layout.window.width - 116)/2 : 153.5
    },
    searchBtn: {
        backgroundColor: Colors.teal,
        marginRight: 12,
        borderRadius: 4,
        paddingHorizontal: 8,
        paddingVertical: 5,
        marginTop: 11,
    }
});
