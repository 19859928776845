import React, { useEffect, useState } from 'react';
import { StyleSheet, Pressable, View, Image, Text, Linking } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { FontAwesome } from '@expo/vector-icons';
import Layout from '../../constants/Layout';
import IMAGE from '../../assets/images';
import FontProperty from '../../constants/FontProperty';
import { IHomeContent } from '../../lib/api/Content/types';
import { getHomeContent } from '../../lib/api/Content/content';

const Footer = (props: TFooter) => {
    const nav = useNavigation();
    const [homeContent, setHomeContent] = useState<IHomeContent[]>();

    useEffect(() => {
        getHomeContent().then(res => {
            setHomeContent(res);
        });
    }, []);

    if(homeContent === undefined){
        return null;
    }
    
    return (
        <View style={[Layout.globalPadding24]}>
            <View style={styles.innerContainer}>
                <Pressable style={styles.iconContainer} onPress={() => { nav.navigate('Platform', { screen: 'Home' }); }}>
                    <Image style={styles.icon} source={IMAGE.abodely.icon.teal} />
                </Pressable>
                <Text style={[FontProperty.p1, FontProperty.TextGrey, Layout.mbottom40]}>{homeContent[0].footerContent}</Text>
                <View style={[Layout.window.width > 426 && styles.footerLinkGroup]}>
                    {/* { homeContent[0].footerSiteCompanyLinks && }
                    <View style={{marginBottom:25}}>
                        <Text style={[FontProperty.h1, Layout.mbottom15]}>Company</Text>
                        <Pressable onPress={()=>nav.navigate('About')}><Text style={[FontProperty.p1, FontProperty.TextGrey, Layout.mbottom15]}>How it works</Text></Pressable>
                        <Pressable onPress={()=>nav.navigate('ContactUs')}><Text style={[FontProperty.p1, FontProperty.TextGrey, Layout.mbottom15]}>Contact Us</Text></Pressable>
                        <Pressable onPress={()=>nav.navigate('FAQs')}><Text style={[FontProperty.p1, FontProperty.TextGrey, Layout.mbottom15]}>FAQs</Text></Pressable>
                    </View>
                    {/* } */}
                    {/* { homeContent[0].footerSiteLinks && 
                    <View style={{marginBottom:25}}>
                        <Text style={[FontProperty.h1, Layout.mbottom15]}>Site Links</Text>
                        {/* { Object.entries(homeContent[0].footerSiteLinks).map(([label, url])=>{
                                return <Pressable key={url} onPress={()=>nav.navigate(url)}><Text style={[FontProperty.p1, FontProperty.TextGrey, Layout.mbottom15]}>{label}</Text></Pressable>;
                            })} 
                        <Pressable onPress={()=>nav.navigate('Newsfeed')}><Text style={[FontProperty.p1, FontProperty.TextGrey, Layout.mbottom15]}>Newsfeed</Text></Pressable>
                        <Pressable onPress={()=>nav.navigate('Explore')}><Text style={[FontProperty.p1, FontProperty.TextGrey, Layout.mbottom15]}>Explore</Text></Pressable>
                        <Pressable onPress={()=>nav.navigate('Start')}><Text style={[FontProperty.p1, FontProperty.TextGrey, Layout.mbottom15]}>List a Property</Text></Pressable>
                    </View>
                    {/* } */}
                    
                    {/* { homeContent[0].footerSiteSocialLinks && */}
                    <View style={{marginBottom:25}}>
                        <Text style={[FontProperty.h1, Layout.mbottom15]}>Social Links</Text>
                        <View style={Layout.row}>
                            { Object.entries(homeContent[0].footerSiteSocialLinks).map(([label, url])=>{
                                return <Pressable key={label} onPress={()=>Linking.openURL(url)} style={{marginRight: 40}}><FontAwesome name={label} size={20} color='#4F4F4F'/></Pressable>;
                            })}
                        </View>
                    </View>
                    {/* } */}
                </View>
                {/* 
                <View style={[Layout.rowBetween]}>
                    {Layout.window.width > 767 && <View></View> }
                    <View style={[Layout.row]}>
                        <Pressable onPress={()=>nav.navigate('PrivacyPolicy')}><Text style={[FontProperty.miniP, FontProperty.TextGrey, Layout.mbottom15, {marginRight: 20}]}>Privacy Policy</Text></Pressable>
                        <Pressable onPress={()=>nav.navigate('TermsOfUse')}><Text style={[FontProperty.miniP, FontProperty.TextGrey, Layout.mbottom15]}>Terms Of Use</Text></Pressable>
                    </View>
                </View>
                        */}
            </View>
        </View>

    );
};

const styles = StyleSheet.create({
    innerContainer: {
        borderTopWidth: 1,
        borderColor: 'rgba(0, 8, 55, 0.13)',
        paddingTop: 63,
    },
    iconContainer: {
        height: 40,
        width: 100,
        marginBottom: 15
    },
    icon : {
        resizeMode : 'contain',
        width: '100%',
        height: '100%'
    },
    footerLinkGroup: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    }
});

export default Footer;
