import React, {Dispatch, SetStateAction} from 'react';
import { StyleSheet, Text, View, Animated } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';

export type TWarningbar = {
  text : string | null | undefined
  icon : string 
  backgroundcolor: string
  bottom: number
  setErr ? : Dispatch<SetStateAction<string>>
}

const WarningBar = (props : TWarningbar) => {
    const [jumpValue, setJumpValue] = React.useState(new Animated.Value(-100));

    const toValue = props.bottom;

    const toggleSlide = () =>  {
        Animated.spring(
            jumpValue,
            {
                toValue: toValue,
                velocity: 0,
                tension: 0,
                friction: 7,
                useNativeDriver: true
            }
        ).start();
    };

    const toggleSlideOff = () => {
        Animated.spring(
            jumpValue,
            {
                toValue: -100,
                velocity: 0,
                tension: 0,
                friction: 7,
                useNativeDriver: true
            }
        ).start();
    };

    toggleSlide();
    setTimeout(() => {toggleSlideOff();}, 2000);
    props.setErr !== undefined && setTimeout(() => {props.setErr('');}, 2500);

    return (
        <Animated.View style={[styles.barOutContainer, {bottom:jumpValue}]}>
            <View style={[styles.barInnerContainer, {backgroundColor:props.backgroundcolor}]}>
                <FontAwesome5 name={props.icon} color={Colors.white} size={16} />
                <Text style={[FontProperty.TextWhite, {marginLeft:10}]}>{props.text}</Text>
            </View>
        </Animated.View>
    );
};

const styles = StyleSheet.create({
    barOutContainer: {
        position: 'absolute',
        width: '100%',
        justifyContent: 'center',
        paddingHorizontal: 24,
        alignItems: 'center'
    },
    barInnerContainer: {
        padding: 16,
        borderRadius: 12,
        maxWidth: 380,
        flexDirection: 'row',
        alignItems: 'center'
    }
});

export default WarningBar;
