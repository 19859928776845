import React, {Dispatch, SetStateAction, useState} from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Animated, Pressable, ScrollView } from 'react-native';
import Colors from '../../constants/colors';
import { Ionicons } from '@expo/vector-icons';
import Layout from '../../constants/Layout';
import { useNavigation } from '@react-navigation/native';
import UserHeadshotAndFunction from './UserHeadshotAndFunction';
import { setFollowUser } from '../../lib/api/Content/posts/posts';
import FavouriteImageList from './FavouriteImageList';
import NoResult from './NoResult';

export type TAllComments = {
    uid : string
    open : boolean,
    tab: 'Followers' | 'Followings' | 'Inspirations'
    setShowffi : Dispatch<SetStateAction<boolean>>
    followers: string[]
    followings: string[]
    inspirations: string[]
    trigger : boolean
    setTrigger : (arg0:boolean) => void
}

const AllFFI : React.FC< TAllComments > = (props) => {
    const nav = useNavigation();
    const [bounceValue, setBounceValue] = React.useState(new Animated.Value(Layout.window.width + 200));
    const [isHidden, setIsHidden ] = React.useState(props.open);
    const [ffiTabModal, setffiTabModal] = useState<'Followers' | 'Followings' | 'Inspirations'>(props.tab);

    const closeWholeModel = () => {
        toggleSlide(false);
        setTimeout(() => {
            setIsHidden(false);
            props.setShowffi(false);
            props.setTrigger(!props.trigger);
        }, 500);
    };

    const toggleSlide = (status : boolean) =>  {
        let slideToValue = Layout.window.width + 200;
        if(status) {
            slideToValue = 0;
        }
        Animated.spring(
            bounceValue,
            {
                toValue: slideToValue,
                velocity: 10,
                tension: 2,
                friction: 8,
                useNativeDriver: false
            }
        ).start();
    };

    toggleSlide(isHidden);

    const RemoveFollowRelation = (uuid:string) => {
        setFollowUser(uuid.split('_')[0], uuid.split('_')[1], false);
    };

    const UndoFollowRelation = (uuid:string) => {
        setFollowUser(uuid.split('_')[0], uuid.split('_')[1], true);
    };
    
    return (
        <Animated.View style={[styles.outContainer, {transform: [{translateX: bounceValue}]}, !isHidden && {display:'none'}]}>
            <TouchableOpacity onPress={closeWholeModel} style={[Layout.row, {padding:24}]}>
                <Ionicons name="arrow-back" size={23} color={Colors.grey1} style={{marginRight:15}}/>
            </TouchableOpacity>
            <ScrollView style={{paddingBottom:40}} showsVerticalScrollIndicator={false}>
                <View style={[Layout.rowBetween, {width:'100%'}]}>
                    <Pressable onPress={()=>{setffiTabModal('Followers');props.setTrigger(!props.trigger);}} style={[styles.tabs, ffiTabModal==='Followers' && styles.activeTab]}><Text>{props.followers.length} Followers</Text></Pressable>
                    <Pressable onPress={()=>{setffiTabModal('Followings');props.setTrigger(!props.trigger);}} style={[styles.tabs, ffiTabModal==='Followings' && styles.activeTab]}><Text>{props.followings.length} Following</Text></Pressable>
                    <Pressable onPress={()=>{setffiTabModal('Inspirations');props.setTrigger(!props.trigger);}} style={[styles.tabs, ffiTabModal==='Inspirations' && styles.activeTab]}><Text >{props.inspirations.length} Inspirations</Text></Pressable>
                </View>
                <View style={{padding:24}}>
                    {
                        ffiTabModal ==='Followers' &&
                        (props.followers.length > 0 
                            ? props.followers.map((item)=>{
                                return (
                                    <UserHeadshotAndFunction 
                                        key={item.uuid}
                                        userId={item.uuid.split('_')[1]} 
                                        btnLabelOne='Remove' 
                                        btnLabelTwo='Undo' 
                                        btnLabelOneFunction={()=>RemoveFollowRelation(item.uuid)} 
                                        btnLabelTwoFunction={()=>UndoFollowRelation(item.uuid)}
                                        closeWholeModel={()=>closeWholeModel()}
                                    />
                                );
                            })
                            : <NoResult title='No followers' />
                        )
                    }
                    {
                        ffiTabModal ==='Followings' &&
                        (props.followings.length > 0 
                            ? props.followings.map((item)=>{
                                return (
                                    <UserHeadshotAndFunction 
                                        key={item.uuid}
                                        userId={item.uuid.split('_')[0]} 
                                        btnLabelOne='Unfollow' 
                                        btnLabelTwo='Undo' 
                                        btnLabelOneFunction={()=>RemoveFollowRelation(item.uuid)} 
                                        btnLabelTwoFunction={()=>UndoFollowRelation(item.uuid)}
                                        closeWholeModel={()=>closeWholeModel()}
                                    />
                                );
                            })
                            : <NoResult title='No followings' />
                        )
                    }
                    <View style={[Layout.rowBetween, Layout.flexWrap]}>
                        {
                            ffiTabModal ==='Inspirations' &&
                            (props.inspirations.length > 0 
                                ? props.inspirations.map((item:string, index:number)=>{
                                    return (
                                        <FavouriteImageList key={index} imageUrl={item} uid={props.uid} />
                                    );
                                })
                                : <NoResult title='No inspirations' />
                            )
                        }
                    </View>
                </View>
            </ScrollView>
        </Animated.View>
    );
};

const styles = StyleSheet.create({
    outContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: Colors.white,
        justifyContent: 'space-between',
        zIndex: 2
    },
    
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.25)',
        position: 'absolute',
        top: 0,
        left: 0,
        paddingHorizontal: 24,
        justifyContent: 'center',
        width: '100%',
        height: '100%',
    },
    
    close: {
        position: 'absolute',
        right: 24,
        top: 22,
        zIndex: 2
    },
    tabs: {
        flex: 1,
        paddingBottom: 18,
        textAlign: 'center',
        borderBottomWidth: 5,
        borderBottomColor: '#E2E2E2',
    },
    activeTab: {
        borderBottomColor: '#000000',
    }
});

export default AllFFI;

