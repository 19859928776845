import React, {useEffect, useState, useRef} from 'react';
import { Pressable, StyleSheet, Text, View, Image, } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { AddProjectTypeBasicProps } from '../../types';
import { useAuthentication } from '../../hooks/useAuthentication';
import Colors from '../../constants/colors';
import { ScrollView } from 'react-native-gesture-handler';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import { IprojectData, IprojectType, IpropertyData} from '../../lib/api/Content/types';
import { getUserData } from '../../lib/api/user';
import { IuserData } from '../../lib/api/user/types';
import IMAGE from '../../assets/images';
import { Feather } from '@expo/vector-icons';
import PropertyGallery from '../../components/atom/PropertyGallery';
import PopUpGalleryModal from '../../components/atom/PopUpGalleryModal';
import { ListingBaseScreen } from './ListingBaseScreen';
import PopUpPropertyModal from '../../components/atom/PopUpPropertyModal';
import PopupNotifyUser from '../../components/atom/PopUpNotifyUser';
import PropertyToPost from '../../components/atom/PropertyToPost';
import { addProjectIntoProperty, getProjectContent, getProjectTypeName, updateProjectPublish } from '../../lib/api/Content/project';
import { getPropertyContent } from '../../lib/api/Content/property';
import { uppercaseFirstLetter } from '../../lib/utils/uppercaseFirstLetter';
import Hyperlink from 'react-native-hyperlink';

export const ReviewListingScreen: React.FC = (props) => {
    const nav = useNavigation();
    const { user } = useAuthentication();
    const { projectId } = useRoute<AddProjectTypeBasicProps>().params;
    const [loading, setLoading] = useState(true);
    const [projectContent, setProjectContent] = useState<IprojectData>();
    const [ownerData, setOwnerData] = useState<IuserData>();
    const [propertyContent, setPropertyContent] = useState<IpropertyData>();
    const [popUpGalleryIndex, setPopUpGalleryIndex] = useState(0);
    const [popUpGallery, setPopUpGallery] = useState(false);
    const [notifyPop, setNotifyPop] = useState(false);
    const [publishPop, setPublishPop] = useState(false);
    const [published, setPublisted] = useState(false);
    const [postProject, setPostProject] = useState(false);
    const [propertyType, setPropertyType] = useState<IprojectType>();

    useEffect(() => {
        getProjectContent(projectId).then(res => {
            setProjectContent(res[0]);
            setLoading(false);
            res[0] !== undefined && res[0].published !== undefined && res[0].published === true && setPublisted(true);
        }
        );
    }, [projectId]);

    useEffect(() => {
        projectContent?.userId &&
        getUserData(projectContent.userId).then(ownerData => {
            setOwnerData(ownerData.data());
        });

        projectContent?.propertyId &&
        getPropertyContent(projectContent?.propertyId).then(res => {
            setPropertyContent(res[0]);
        });

        projectContent?.type &&
        getProjectTypeName(projectContent?.type).then(res => {
            setPropertyType(res[0].type);
        });

    }, [projectContent?.userId, projectContent?.uuid]);

    if(loading || projectContent === undefined || ownerData === undefined || propertyContent === undefined) {
        return null;
    }

    const onNextStep = async () => {
        await updateProjectPublish(projectId, true);
        addProjectIntoProperty(projectContent?.propertyId, projectId);
        setNotifyPop(true);
    };


    return (
        <>
            <ListingBaseScreen
                step={4}
                nextScreenHandler={onNextStep}
                hideHeader={true}
                isEnd={true}
                isPublished={published}
            >
                <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={[Layout.scrollViewPadding]}>
                    <View style={styles.pill}><Text style={[FontProperty.miniP, FontProperty.TextWhite, FontProperty.TextSemiBold]}>Project</Text></View>
                    {/* Gallery */}
                    {
                        projectContent.images !== undefined && 
                  <PropertyGallery images={projectContent.images} setPopUpGalleryIndex={setPopUpGalleryIndex} setPopUpGallery={()=>setPopUpGallery(true)} popUpGallery={popUpGallery} imageStyle='cover'/>
                    }
                    {/* Project Name */}
                    <Text style={[FontProperty.h0, Layout.globalPadding24, {marginTop:16}]}>{projectContent.projectName}</Text>
                    {/* Address */}
                    <Text style={[FontProperty.body_1, Layout.globalPadding24, FontProperty.TextGrey]}>{propertyContent.address[0].replace(/[0-9]/g, '').trim() + ', ' + propertyContent.address[1] + ', ' + propertyContent.address[2] + ' ' + propertyContent.address[3]}</Text>
                
                    {/* Types */}
                    {/* {projectContent && projectContent.type !== undefined && projectContent.type.length > 0 &&
                    <>
                        <View style={Layout.globalPadding24}>
                            <View style={[Layout.row, styles.tagContainer]}>
                                {
                                    projectContent.type?.map((type) => {
                                        return (
                                            <View key={type.uuid} style={[{
                                                borderColor: Colors.lightBeige,
                                                borderWidth: 1,
                                                borderRadius: 5,
                                                paddingVertical: 6,
                                                paddingHorizontal: 15,
                                                marginRight: 10,
                                                marginBottom: 10
                                            }]}>
                                                <Text style={[FontProperty.miniP, FontProperty.TextBeige]}>{type.name}</Text>
                                            </View>
                                        );
                                    })
                                }
                            </View>
                        </View>
                    </>
                    }
                     */}
                    {
                        propertyType !== undefined &&
                        <View style={Layout.globalPadding24}>
                            <View style={[Layout.row, styles.tagContainer]}>
                                <View style={[{
                                    borderColor: Colors.lightBeige,
                                    borderWidth: 1,
                                    borderRadius: 5,
                                    paddingVertical: 6,
                                    paddingHorizontal: 15,
                                    marginRight: 10,
                                    marginBottom: 10
                                }]}>
                                    <Text style={[FontProperty.miniP, FontProperty.TextBeige]}>{propertyType}</Text>
                                </View>
                            </View>
                        </View>
                    }
                    {/* Details */}
                    {projectContent.description !== undefined && projectContent.description !== '' &&
                  <>
                      <View style={styles.sectionShadow}></View>
                      <View style={Layout.globalPadding24}>
                          <Text style={[FontProperty.h1, styles.headerContainer]}>Project details</Text>
                          <Hyperlink linkDefault={ true } linkStyle={{color: '#00b7a1'}}><Text style={[FontProperty.body_1, FontProperty.TextGrey]}>
                              {projectContent.description}
                          </Text></Hyperlink>
                      </View>
                  </>
                    }
                    {projectContent && projectContent.propertyId !== undefined && propertyContent !== undefined &&
                   <View style={Layout.globalPadding24}>
                       <Text style={[FontProperty.h1, styles.headerContainer]}>Property details</Text>
                       <Pressable>
                           <View style={[{borderRadius:4,overflow:'hidden'}, styles.boxShaw]}>
                               <Image source={propertyContent.images[0].viewUrl} resizeMode='cover' style={{width: Layout.window.width - 48, height: Layout.window.width > 425 ? 200 : 95}} />
                               <View style={{padding:10}}>
                                   <Text style={[FontProperty.smallP,FontProperty.TextBold,{marginBottom:4}]}>{propertyContent.propertyName}</Text>
                                   <Text style={[FontProperty.smallP,{marginBottom:16}]} numberOfLines={1} ellipsizeMode='tail' >{uppercaseFirstLetter(propertyContent.description)}</Text>
                                   <View style={styles.propertyTileBtn}><Text style={[FontProperty.TextNavy,FontProperty.TextSemiBold]}>View property</Text></View>
                               </View>
                           </View>
                       </Pressable>
                   </View>
                    }
    
                    {/* Owner */}
                    {projectContent.userId !== undefined && projectContent.userId !== '' &&
                <Pressable style={[Layout.globalPadding24,{marginBottom:26}]}>
                    <Text style={[FontProperty.h1, styles.headerContainer]}>Owner details</Text>
                    <View style={[Layout.rowBetween, {marginTop:8}]}>
                        <Image source={(ownerData.headshot && ownerData.headshot !== '') ? ownerData.headshot : IMAGE.userProfile} style={{resizeMode:'cover',width:54,height:54,borderRadius:54}}/>
                        <View style={{flex:1,paddingLeft:21}}>
                            <Text style={[FontProperty.body_1, {marginBottom:2}]}>{ownerData.username}</Text>
                        </View>
                        <View style={Layout.row}>
                            <Feather name='user' size={20} color={Colors.navy} />
                            <Text style={[FontProperty.TextDarkNavy, FontProperty.TextMedium, FontProperty.smallLabel, {marginLeft:4}]}>Profile</Text>
                        </View>
                    </View>
                </Pressable>
                    }
                    {/* Tags */}
                    {
                        projectContent.tags !== undefined && projectContent.tags.length > 0 &&
                  <View style={Layout.globalPadding24}>
                      <Text style={[FontProperty.h1, styles.headerContainer]}>Project feature tags</Text>
                      <View style={[Layout.row, styles.tagContainer]}>
                          {
                              projectContent.tags?.map((tag, index:number) => {
                                  return (
                                      <View key={index} style={[{
                                          backgroundColor: Colors.lightBeige,
                                          borderRadius: 5,
                                          paddingVertical: 6,
                                          paddingHorizontal: 15,
                                          marginRight: 10,
                                          marginBottom: 10
                                      }]}>
                                          <Text style={[FontProperty.h2, FontProperty.TextBeige]}>{tag}</Text>
                                      </View>
                                  );
                              })
                          }
                      </View>
                  </View>
                    }
                </ScrollView>
          
                
            </ListingBaseScreen>
            {
                notifyPop &&
                <PopupNotifyUser 
                    title='Notify your followers?'
                    subtitle={!published ? 'Notify your followers about your new project by auto generating a post on your wall.' : 'Notify your followers about the changes to your project by auto generating a post on your wall.'}
                    yesBtnLabel='Sure' 
                    noBtnLable='Skip' 
                    yesBtnOnClick={()=>{setPostProject(true);}}
                    noBtnOnClick={()=>{setNotifyPop(false);setPublishPop(true);}}
                    darktheme={true}
                />
            }
            {
                !published && publishPop &&
                    <PopUpPropertyModal 
                        title='Project listed!'
                        yesBtnLabel='View Project Profile' 
                        yesBtnOnClick={()=>nav.navigate('ProjectProfile', { projectId: projectContent?.uuid, updated: Date.now()})}
                        darktheme={true}
                    />
            }
            {
                published && publishPop &&
                    <PopUpPropertyModal 
                        title='Project Updated!'
                        yesBtnLabel='View Project Profile' 
                        yesBtnOnClick={()=>nav.navigate('ProjectProfile', { projectId: projectContent?.uuid, updated: Date.now()})}
                        darktheme={true}
                    />
            }
            
            {
                popUpGallery &&
                <PopUpGalleryModal 
                    userId={user !== false && user?.uid} 
                    images={projectContent.images} 
                    index={popUpGalleryIndex} 
                    setPopUpGallery={()=>setPopUpGallery(false)} 
                    allowFavourate={false}
                    autoPlay={false}
                />
            }
            {
                postProject &&
                <PropertyToPost 
                    userId={projectContent.userId}
                    projectId={projectId}
                    propertyName={projectContent.projectName}
                    propertyDesc={projectContent.description}
                    propertyImage={projectContent.images[0].viewUrl}
                    cancelBtnOnClick={()=>setPostProject(false)}
                />
            }
        </>
    );
};

const styles = StyleSheet.create({
    headerContainer: {
        paddingTop: 24,
        marginBottom: 12
    },
    tagContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginVertical: 8
    },
    screenFooter: {
        paddingBottom: 20,
        boxShadow: '0px -6px 10px rgba(0, 0, 0, 0.1)',
        paddingHorizontal: 14,
        alignItems: 'center',
    },
    btnStyle: {
        paddingHorizontal: 30,
        marginTop : 16,
        width: Layout.window.width > 425 ? 360 : '100%',
        flex: 1,
        marginHorizontal: 10,
    },
    sectionShadow: {
        height: 1,
        width: '100%',
        backgroundColor: '#4f4f4f4d',
        marginTop: 24
    },
    pill: {
        paddingVertical: 6,
        paddingHorizontal: 16,
        backgroundColor: Colors.navy,
        borderRadius: 16,
        width: 75,
        position: 'absolute',
        zIndex: 1,
        left: 15,
        top: 15
    },
    boxShaw: {
        boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.08)'
    },
    propertyTileBtn: {
        width: '100%',
        borderColor: Colors.navy,
        borderWidth: 1,
        textAlign: 'center',
        paddingVertical: 7.5,
        borderRadius: 4
    },
});
