import React, {useState, useEffect} from 'react';
import { StyleSheet, Text, Pressable, Image } from 'react-native';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import { IprojectData } from '../../lib/api/Content/types';
import { useNavigation } from '@react-navigation/native';
import { getProjectContent } from '../../lib/api/Content/project';

export type TProjectTile = {
    projectId : string
}

const ProjectTileID: React.FC<TProjectTile> = (props) => {
    const nav = useNavigation();
    const [projectData, setProjectData] = useState<IprojectData>();

    useEffect(() => {
        getProjectContent(props.projectId).then(res => {
            setProjectData(res[0]);
        });
    },[]);

    if(!projectData){
        return null;
    }

    return (
        <Pressable key={props.projectId} style={[styles.outContainer, Layout.mbottom25]}
            onPress={() => {
                nav.navigate('ProjectProfile', { projectId: props.projectId });
            }}
        >
            <Image source={projectData.images[0].viewUrl} style={styles.houseImage}/>
            <Text style={[FontProperty.TextGrey, FontProperty.p1, FontProperty.TextMedium, {marginBottom:4}]}>{projectData.projectName}</Text>
            <Text style={[FontProperty.TextGrey, FontProperty.smallP]}>{projectData.address[1] + ' ' + projectData.address[2]}</Text>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    outContainer: {
        width: (Layout.window.width - 68) / 2,
    },
    houseImage: {
        width: (Layout.window.width - 68) / 2,
        height: Layout.window.width > 767 ? 244 : 144,
        resizeMode: 'cover',
        marginBottom: 8,
        borderRadius: 4,
    }
});

export default ProjectTileID;

