import { ActivityIndicator, ScrollView, StyleSheet, Text, View } from 'react-native';
import React, {useEffect, useState} from 'react';
import Colors from '../../constants/colors';
import ScreenHeader from '../../components/atom/ScreenHeader';
import ScreenBase from '../../components/ScreenBase';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import { useAuthentication } from '../../hooks/useAuthentication';
import { getConversationsHouspo, getUserConversationsHouspo, userAllowReceiveMessage } from '../../lib/api/messages/messages';
import NoResult from '../../components/atom/NoResult';
import AllMessages from '../../components/molecule/AllMessages';
import MessageViewScreen from './MessageViewScreen';


const InboxScreen = () => {
    const { user } = useAuthentication();
    const [conversationIds, setConversationIds] = useState<any[]>([]);
    const [conversations, setConversations] = useState<any[]>([]);
    const [trigger, setTrigger] = useState(true);
    const [targetCov, setTargetConversation] = useState('');
    const [receiveMessage, setUserReceiveMessage] = useState<boolean>();

    useEffect(() => {
        if (user?.uid) {
            userAllowReceiveMessage(user?.uid, setUserReceiveMessage);
        }
    }, [user?.uid, trigger]);

    useEffect(() => {
        const getMessages = async() => {
            if (user?.uid) {
                await getUserConversationsHouspo(user?.uid, setConversationIds);
            }
        };
        getMessages();
    }, [user?.uid, trigger]);

    useEffect(() => {
        if (conversationIds) {
            const getMessagesItems = async() => {
                if (conversationIds.length > 0) {
                    await getConversationsHouspo(conversationIds, user?.uid, setConversations);
                }
            };
            getMessagesItems();
        }
    }, [conversationIds, trigger]);

    if(receiveMessage === undefined){
        return (
            <ScreenBase>
                <ScreenHeader noShadow={false}/>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <View style={{marginTop:150}}>
                        <ActivityIndicator size={Layout.window.width > 425 ? 107 : 80} color={Colors.teal} />
                    </View>
                </ScrollView>
            </ScreenBase>
        );
    }

    // const goReceiveMessage = () => {
    //     userBlockReceiveMessage(user?.uid, false);
    //     updateUserReceiveMessage(user?.uid, true);
    // };

    // const goBlockMessage = () => {
    //     userBlockReceiveMessage(user?.uid, true);
    //     updateUserReceiveMessage(user?.uid, false);
    // };

    return (
        <ScreenBase>
            <ScreenHeader />
            <ScrollView showsVerticalScrollIndicator={false}>
                <View style={[styles.header, Layout.rowBetween, Layout.globalPadding24]}>
                    <Text style={[FontProperty.h1, {marginBottom: 24}]}>Messages</Text>
                </View>
                {/* {
                    !receiveMessage ? 
                        <View style={Layout.row}>
                            <Text style={[FontProperty.TextGrey, FontProperty.miniP, Layout.globalPadding24, {marginBottom: 20}]}>
                                <AntDesign name='exclamationcircleo' size={12} color={Colors.grey3} />  {ContentText.cantReceiveMessage} <Pressable onPress={()=>goReceiveMessage()}><Text style={FontProperty.TextTeal}>Turn on receive message</Text></Pressable></Text>
                        </View> 
                        : <View style={Layout.row}>
                            <Text style={[FontProperty.TextGrey, FontProperty.miniP, Layout.globalPadding24, {marginBottom: 20}]}>
                                <AntDesign name='checkcircleo' size={12} color={Colors.grey3} />  {ContentText.allowReceiveMessage} <Pressable onPress={()=>goBlockMessage()}><Text style={FontProperty.TextTeal}>Turn off receive message</Text></Pressable></Text>
                        </View>
                } */}
                {
                    conversations && conversations.length > 0
                        ? <AllMessages data={conversations} setTrigger={setTrigger} trigger={trigger} setTargetConversation={setTargetConversation} />
                        : <NoResult title='No message'/>
                }
            </ScrollView>
            {
                targetCov !== '' &&
                <MessageViewScreen targetCov={targetCov} setTargetConversation={setTargetConversation} setTrigger={setTrigger} trigger={trigger} receiveMessage={receiveMessage}  />
            }
        </ScreenBase>
    );
};

export default InboxScreen;

const styles = StyleSheet.create({
    header: {
        paddingTop: 20
    },
    postBtn: {
        backgroundColor: Colors.teal,
        borderRadius: 4,
        paddingHorizontal: 16,
        paddingVertical: 11.5,
        width: 160,
        marginTop:24
    },
});
