import { View, Text, StyleSheet, Pressable } from 'react-native';
import React, { useState, Dispatch, SetStateAction } from 'react';
import HousepoTextInput from '../HouspoTextInput';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import { useNavigation } from '@react-navigation/native';

export type TUpdatePF = {
    uid : string,
    username : string | undefined,
    email : string,
    editable : boolean
    setUsername : Dispatch<SetStateAction<string | undefined>>
    setUserEmail : Dispatch<SetStateAction<string | undefined>>
}

const UpdateProfileForm = (props:TUpdatePF) => {
    const nav = useNavigation();

    return (
        <View style={{flex:1}}>
            <Text style={[FontProperty.smallP, FontProperty.TextSemiBold, Layout.mbottom8]}>Username</Text>
            <View>
                {!props.editable && <View style={styles.overlay}></View>}
                <HousepoTextInput
                    onChangeText={props.setUsername}
                    value={props.username} 
                    editable={props.editable}
                />
            </View>
            
            <Text style={[FontProperty.smallP, FontProperty.TextSemiBold, Layout.mbottom8]}>Email</Text>
            <View>
                {!props.editable && <View style={styles.overlay}></View>}
                <HousepoTextInput
                    onChangeText={props.setUserEmail}
                    value={props.email}
                    // editable={props.editable}
                    editable={false}
                />
            </View>

            <Text style={[FontProperty.smallP, FontProperty.TextSemiBold, Layout.mbottom8]}>Password</Text>
            <View>
                <View style={styles.overlay}></View>
                <HousepoTextInput
                    value={'\u2B24 \u2B24 \u2B24 \u2B24 \u2B24 \u2B24'}
                    editable={false}
                />
                <Pressable onPress={()=>nav.navigate('Platform', { screen: 'UpdatePassword' })} style={{marginTop:-14}}><Text style={[FontProperty.smallP,FontProperty.TextTeal]}>Change password</Text></Pressable>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    overlay:{
        position:'absolute',
        height: 55,
        top: 0,
        width: '100%',
        zIndex: 1
    }
});


export default UpdateProfileForm;
