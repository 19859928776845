import React, { useState, useEffect, useRef } from 'react';
import { View, Text, StyleSheet, ImageBackground, Pressable, ScrollView  } from 'react-native';
import ScreenBase from '../../components/ScreenBase';
import ScreenHeader from '../../components/atom/ScreenHeader';
import { getAboutContent } from '../../lib/api/Content/content';
import { IAboutContent } from '../../lib/api/Content/types';
import Colors from '../../constants/colors';
import ImageContent from '../../components/atom/ImageContent';
import FontProperty from '../../constants/FontProperty';
import Footer from '../../components/molecule/Footer';
import SwitchTab from '../../components/atom/SwitchTab';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useAuthentication } from '../../hooks/useAuthentication';
import { TouchableOpacity } from 'react-native-gesture-handler';

export const AboutUsScreen = ({route}:any) => {
    const [aboutContent, setAboutContent] = useState<IAboutContent[]>();
    const ref = useRef();
    const { user } = useAuthentication();
    const nav = useNavigation();

    useEffect(() => {
        getAboutContent().then(res => {
            setAboutContent(res);
        });
    }, []);

    useFocusEffect(()=>{
        ref.current?.scrollTo({
            y: 0,
            animated: true,
        });
    });
    
    if(aboutContent === undefined){
        return null;
    }

    return (
        <ScreenBase>
            <ScreenHeader/>
            <ScrollView showsVerticalScrollIndicator={false} ref={ref}>
                <ImageBackground source={aboutContent[0].bannerBgImage} resizeMode="cover" style={styles.image}>
                    <Text style={[styles.title, FontProperty.TextSemiBold]}>{aboutContent[0].pageTitle}</Text>
                    {user === false &&
                        <TouchableOpacity
                            style={[styles.btn]}
                            onPress={()=>{nav.navigate('Authentication');}}
                        >
                            <Text style={[FontProperty.smallP, FontProperty.TextWhite, FontProperty.TextMedium]}>Sign In</Text>
                        </TouchableOpacity>
                    }
                </ImageBackground>
                <ImageContent image={aboutContent[0].sectionOneImage} title={aboutContent[0].sectionOneTitle} content={aboutContent[0].sectionOneContent} layout="Image Right" />
                <View style={{ backgroundColor: '#fff'}}>
                    <ImageBackground source={aboutContent[0].sectionTwoImage} resizeMode="cover" style={styles.imageBanner} imageStyle= {{opacity:0.5}}>
                        <Text style={[styles.titleBanner, FontProperty.TextSemiBold]}>{aboutContent[0].sectionTwoTitle}</Text>
                    </ImageBackground>
                </View>
                <ImageContent image={aboutContent[0].sectionThreeImage} title={aboutContent[0].sectionThreeTitle} content={aboutContent[0].sectionThreeContent} layout="Image Left" />
                <SwitchTab 
                    title={aboutContent[0].sectionFourTitle} 
                    content={aboutContent[0].sectionFourContent} 
                    firstTabTitle={aboutContent[0].sectionFourOwnerTitle}
                    secondTabTitle={aboutContent[0].sectionFourBuyerTitle}
                    firstTabContent={aboutContent[0].sectionFourOwnerContent}
                    secondTabContent={aboutContent[0].sectionFourBuyerContent}
                    userLoggedIn={user}
                />
                <Footer />
            </ScrollView>
        </ScreenBase>
    );
};

const styles = StyleSheet.create({
    image: {
        paddingVertical: 50,
        paddingHorizontal: 24,
        zIndex: 1
    },
    title: {
        fontSize: 30,
        lineHeight: 36,
        fontWeight: '600',
    },
    imageBanner: {
        paddingVertical: 40,
        paddingHorizontal: 24,
        zIndex: 1,
        textAlign: 'center',
    },
    titleBanner: {
        fontSize: 24,
        lineHeight: 30,
        fontWeight: '600',
        color: Colors.navy
    },
    btn: {
        borderRadius: 8,
        paddingVertical: 11.5,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.navy,
        width: 140,
        marginTop: 20,
    }
});
