import React, { useState, Dispatch, SetStateAction, useRef } from 'react';
import { StyleSheet, View, Image, Pressable} from 'react-native';
import IMAGE from '../../assets/images';
import { IpropertyImage } from '../../lib/api/Content/types';
import Carousel, { ICarouselInstance } from 'react-native-reanimated-carousel';
import Layout from '../../constants/Layout';
import Colors from '../../constants/colors';


export type TpropertyData = {
  images : IpropertyImage[]
  setPopUpGalleryIndex : Dispatch<SetStateAction<number>>
  setPopUpGallery : () => void
  popUpGallery : boolean
  noViewURL ? : boolean
  imageStyle : 'cover' | 'contain'
}

const PropertyGallery : React.FC< TpropertyData  > = (props) => {
    const images = props.images;
    const width = props.noViewURL === true ? Layout.window.width-48 : Layout.window.width;
    const numOfImages = images.length;
    const dots = Array(numOfImages).fill('');
    const [active, setActive] = useState(0);
    const ref = useRef<ICarouselInstance>(null);

    return (
        <View style={{ flex: 1 }}>
            {
                (numOfImages > 0 && numOfImages < 2) ?
                    <Image source={props.noViewURL === true ? images[0] : images[0].media_url} resizeMode={props.imageStyle} style={{width:'100%',height:Layout.window.width > 425 ? 400 : 300}}></Image>
                    : 
                    <Carousel
                        loop
                        width={width}
                        height={Layout.window.width > 425 ? 320 : 220}
                        autoPlay={props.noViewURL === true ? false : !props.popUpGallery}
                        pagingEnabled={true}
                        data={images}
                        ref={ref}
                        scrollAnimationDuration={2000}
                        onSnapToItem={(index) => {setActive(index);}}
                        renderItem={({item, index }) => (
                            <Pressable style={{width:width,height:Layout.window.width > 425 ? 320 : 220}} onPress={()=>{props.setPopUpGalleryIndex(index);props.setPopUpGallery();}}>
                                <Image source={props.noViewURL === true ? item : item.media_url} resizeMode={props.imageStyle} style={{width:'100%',height:'100%'}}></Image>
                            </Pressable>
                        )}
                    />
            }
            
            {
                numOfImages > 1 &&
                <View style={[Layout.row, {position:'absolute', bottom:10,width:'100%',justifyContent:'center'}]}>
                    { dots.map((dot, dotIndex:number )=>{
                        return (
                            <Pressable key={dotIndex} style={[styles.dot, active===dotIndex && styles.activeDot]} onPress={()=>{ref.current?.scrollTo({ count: dotIndex - active, animated: true });}}></Pressable>
                        );
                    }) }
                </View>
            }
        </View>
    );
};

const styles = StyleSheet.create({
    dot: {
        width: 8,
        height: 8,
        borderRadius: 30,
        backgroundColor: 'white',
        marginHorizontal: 2.5
    },
    activeDot: {
        backgroundColor: Colors.teal
    }
});

export default PropertyGallery;
