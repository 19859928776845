import React from 'react';
import { StyleSheet, Text, View, Pressable} from 'react-native';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import { Feather } from '@expo/vector-icons';


export type TProfileItem = {
    title : string
    subtitle ? : string
    icon : string
}

const ProfileItem = (props:TProfileItem) => {

    return (
        <View style={[styles.outContainer, Layout.row]}>
            <Feather name={props.icon} size={24} color='#282828' />
            <View style={{paddingLeft:14}}>
                <Text style={[FontProperty.TextSemiBold, {fontSize:14}]}>{props.title}</Text>
                {props.subtitle &&
                <Text style={[FontProperty.smallLabel,FontProperty.TextGrey]}>{props.subtitle}</Text>
                }
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    outContainer: {
        paddingVertical:15,
        paddingHorizontal:19,
        borderBottomWidth:0.5,
        borderBottomColor:'#b3b3b399',
    }
});

export default ProfileItem;
