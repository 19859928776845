import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ListingBaseScreen } from './ListingBaseScreen';
import Colors from '../../constants/colors';
import Uploadbox from '../../components/atom/UploadBox';
import Layout from '../../constants/Layout';
import { AddListingTypeBasicProps } from '../../types';
import * as ImagePicker from 'expo-image-picker';
import { uploadMultipleImages } from '../../lib/api/Content/images';
import { useAuthentication } from '../../hooks/useAuthentication';
import { ScrollView } from 'react-native-gesture-handler';
import { getPropertyContent } from '../../lib/api/Content/property';
import { useProperty } from '../../hooks/useProperty';
import { compressImage } from '../../lib/utils/compressImage';

export const UploadScreen : React.FC = () => {
    const nav = useNavigation<AddListingTypeBasicProps>();
    const { propertyId } = useRoute<AddListingTypeBasicProps>().params;
    const [err, setErr] = useState<string>('');
    const [uploadedImages, setUploadedImages] = React.useState<string[]>([]);
    const [numOfPics, setNUmofPics] = useState<number>(0);
    const { property, updateProperty } = useProperty(propertyId);
    const { user } = useAuthentication();
    const [published, setPublisted] = useState(false);

    useEffect(() => {
        getPropertyContent(propertyId).then(res=> {
            if(res[0].images !== undefined) {
                setNUmofPics(res[0].images.length);
            }
            res[0] !== undefined && res[0].published !== undefined && res[0].published === true && setPublisted(true);
        });
    }, [property]);

    const onUpload = async () => {
        try {
            const images = await pickImages();

            if (images.length > 0) {
                await uploadMultipleImages(images, user.uid, propertyId);
                const imageLocalLocation = images.map(img => img.uri);
                setUploadedImages((prevState : string[]) => [...prevState, ...imageLocalLocation]);
            }
        } catch (error) {
            setErr('Something went wrong');
            console.log(error);
        }
    };

    const pickImages = async () => {
        // User Selects images from device
        try {
            const result = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsEditing: true,
                aspect: [4, 3],
                quality: 1,
                allowsMultipleSelection: true,
                base64: true,
            });
            if (!result.cancelled) {
                if(result.selected !== undefined && result.selected.length > 0){
                    return await compressImage(result.selected);
                }
            } else {
                return [];
            }
        } catch (error) {
            throw new Error(error);
        }
    };

    const onNextStep = async () => {
        if(uploadedImages.length > 0 || numOfPics > 0){
            nav.navigate('arrange', { propertyId });
        } else {
            setErr('Please upload at least one image');
        }
    };

    return (
        <ListingBaseScreen
            step={5}
            header="Next, let’s add some photos!"
            nextScreenHandler={onNextStep}
            error={err}
            setErr={setErr}
            isPublished={published}
        >
            <View>
                <Uploadbox
                    onUpload={onUpload}
                />
            </View>
            <ScrollView contentContainerStyle={[Layout.row, Layout.flexWrap, styles.contentContainer]} showsVerticalScrollIndicator={false}>
                {uploadedImages.length > 0 && uploadedImages.map((uploadImage, index) => {
                    return <View key={index} style={{padding: 10}}><Image style={[styles.itemContainer]} resizeMode={'cover'} source={{ uri: uploadImage }}/></View>;
                }
                )}
            </ScrollView>
        </ListingBaseScreen>
    );
};

const styles = StyleSheet.create({

    contentContainer: {
        marginTop: 10
    },
    item: {
        fontSize: 16,
        color: Colors.lightNavy,
        fontFamily: 'inter-medium'
    },

    itemContainer: {
        alignItems: 'center',
        width: 100,
        height: 100,
        borderRadius: 4,
        marginBottom: 20
    },
    info: {
        marginLeft: 10
    },
    isActive: {
        borderColor: Colors.teal
    },
    isActiveText: {
        color: Colors.teal
    },
    skip: {
        textAlign: 'center',
        fontFamily: 'inter-semibold',
        color: Colors.grey4,
        marginTop: 6
    }
});
