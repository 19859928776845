import { ActivityIndicator, Pressable, ScrollView, StyleSheet, Text, View } from 'react-native';
import React, {useEffect, useState} from 'react';
import ScreenHeader from '../../components/atom/ScreenHeader';
import ScreenBase from '../../components/ScreenBase';
import { useAuthentication } from '../../hooks/useAuthentication';
import { getUserNotificationHouspo } from '../../lib/api/messages/notifications';
import NoResult from '../../components/atom/NoResult';
import AllNotifications from '../../components/molecule/AllNotifcations';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import Colors from '../../constants/colors';
import ContentText from '../../constants/Content';
import { AntDesign } from '@expo/vector-icons';
import { userAllowReceiveNotification, userBlockNotification } from '../../lib/api/messages/messages';
import { updateUserNotification } from '../../lib/api/user/contants';

const NotificationScreen: React.FC = () => {
    const { user } = useAuthentication();
    const [notifications, setNotifications] = useState<any[]>([]);
    const [receiveNotification, setUserReceiveNotification] = useState<boolean>();

    useEffect(() => {
        if (user?.uid) {
            userAllowReceiveNotification(user?.uid, setUserReceiveNotification);
        }
    }, [user?.uid]);

    useEffect(() => {
        const getNotifications = async() => {
            if (user?.uid) {
                await getUserNotificationHouspo(user?.uid, setNotifications);
            }
        };
        getNotifications();
    }, [user?.uid]);

    if(receiveNotification === undefined){
        return (
            <ScreenBase>
                <ScreenHeader noShadow={false}/>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <View style={{marginTop:150}}>
                        <ActivityIndicator size={Layout.window.width > 425 ? 107 : 80} color={Colors.teal} />
                    </View>
                </ScrollView>
            </ScreenBase>
        );
    }

    const goReceiveNotification = () => {
        userBlockNotification(user?.uid, false);
        updateUserNotification(user?.uid, true);
    };

    const goBlockNotification = () => {
        userBlockNotification(user?.uid, true);
        updateUserNotification(user?.uid, false);
    };

    return (
        <ScreenBase>
            <ScreenHeader notificationActive={true} />
            <ScrollView style={{paddingBottom: 50}} showsVerticalScrollIndicator={false}>
                <View style={[styles.header, Layout.rowBetween, Layout.globalPadding24]}>
                    <Text style={[FontProperty.h1, {marginBottom: 8}]}>Notifications</Text>
                </View>
                {
                    !receiveNotification ? 
                        <View style={Layout.row}>
                            <Text style={[FontProperty.TextGrey, FontProperty.miniP, Layout.globalPadding24, {marginBottom: 20}]}>
                                <AntDesign name='exclamationcircleo' size={12} color={Colors.grey3} />  {ContentText.cantReceiveNotification} <Pressable onPress={()=>goReceiveNotification()}><Text style={FontProperty.TextTeal}>Turn on notification</Text></Pressable></Text>
                        </View> 
                        : <View style={Layout.row}>
                            <Text style={[FontProperty.TextGrey, FontProperty.miniP, Layout.globalPadding24, {marginBottom: 20}]}>
                                <AntDesign name='checkcircleo' size={12} color={Colors.grey3} />  {ContentText.allowReceiveNotification} <Pressable onPress={()=>goBlockNotification()}><Text style={FontProperty.TextTeal}>Turn off notification</Text></Pressable></Text>
                        </View>
                }
                {
                    notifications && notifications.length > 0
                        ? <AllNotifications data={notifications} userId={user?.uid} />
                        : <NoResult title='No Notifications'/>
                }
            </ScrollView>
        </ScreenBase>
    );
};

export default NotificationScreen;

const styles = StyleSheet.create({
    header: {
        paddingTop: 20
    },
    postBtn: {
        backgroundColor: Colors.teal,
        borderRadius: 4,
        paddingHorizontal: 16,
        paddingVertical: 11.5,
        width: 180,
        marginTop:24
    },
});
