import { action, observable, makeObservable } from 'mobx'
import { persist } from 'mobx-persist'
type TaddListingField = 'propertyType' |'name' | 'address' | 'bedrooms' | 'bathrooms' | 'estimate_min' | 'estimate_max' | 'description' | 'features' | 'images'
class AddListing {
  constructor () {
    makeObservable(this)
  }

  @persist @observable propertyType = '' as string
  @persist @observable address = '' as string
  @persist @observable bedrooms = 0 as number
  @persist @observable bathrooms = 0 as number
  @persist @observable carparks = 0 as number
  @persist @observable landSize = '' as string
  @persist @observable name = '' as string
  @persist @observable description = '' as string
   @observable tags = []
   @observable features = []
  @persist @observable neighbourhood_description = '' as string
   @observable neighbourhood_tags = [] as string[]
  @persist @observable estimate_min = 0 as number
  @persist @observable estimate_max = 0 as number
  @persist @observable status = '' as string

  @action
  setField (field : TaddListingField, value : any) {
    console.log('setField', field, value)
    this[field] = value
  }
}

const addListingStore = new AddListing()

export default addListingStore
