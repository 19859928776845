import { TextStyle, ViewStyle, StyleProp } from 'react-native';

const buttonTextStyle = {
    fontWeight: '600' as const,
    fontSize: 16,
    lineHeight: 12
} as TextStyle;

const buttonStyle = {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    width: '100%',
    paddingVertical: 17.5
} as StyleProp<ViewStyle>;

const disabledButtonStyle = {
    opacity: 0.5
} as StyleProp<ViewStyle>;
export default {
    buttonTextStyle,
    buttonStyle,
    disabledButtonStyle
};
