import React from 'react';
import { StyleSheet, Pressable } from 'react-native';
import { Feather } from '@expo/vector-icons';

export type TShareBrn = {
  url : string
  setShareModal : () => void
}

const ShareBtn = (props : TShareBrn) => {
    return (
        <>
            <Pressable style={styles.pill} onPress={()=>{props.setShareModal();}}>
                <Feather name='share-2' size={20} />
            </Pressable>            
        </>
    );
};

const styles = StyleSheet.create({
    pill: {
        padding: 8,
        backgroundColor:'white',
        borderRadius:30,
        position: 'absolute',
        zIndex: 2,
        right: 15,
        top: 15
    },
});

export default ShareBtn;
