import React, { useEffect, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, Image, Pressable } from 'react-native';
import { FontAwesome5, Ionicons, Feather } from '@expo/vector-icons';
import Layout from '../../constants/Layout';
import Colors from '../../constants/colors';
import { useNavigation } from '@react-navigation/native';
import IMAGE from '../../assets/images';
import { useAuthentication } from '../../hooks/useAuthentication';
import { getUserData } from '../../lib/api/user';
import userStore from '../../store/userStore';
import { haveUnreadNotification } from '../../lib/api/messages/notifications';
import { haveUnreadMessages } from '../../lib/api/messages/messages';

export type TScreenHeader = {
    noShadow ? : boolean
    notificationActive ? : boolean
}

export const ScreenHeader = (props: TScreenHeader) => {
    const nav = useNavigation();
    const { user } = useAuthentication();
    const [haveUnread, setHaveUnread] = useState(false);
    const [haveUnreadMessage, setHaveUnreadMessage] = useState(false);
    
    const onMenuHandler = () => {
        nav.toggleDrawer(); 
    };

    useEffect(() => {
        if (user) {
            getUserData(user.uid).then(userData => {
                userStore.setField('name', userData.data()?.username);
            });
            haveUnreadNotification(user.uid, setHaveUnread);
            haveUnreadMessages(user.uid, setHaveUnreadMessage);
        }
    }, [user]);

    return (
        <View style={[props.noShadow ? styles.containerNoShadow : styles.container]}>
            <View style={[Layout.rowBetween, Layout.screenContainer]}>
                <TouchableOpacity style={{flex : 1}} onPress={onMenuHandler}>
                    {haveUnreadMessage && <View style={styles.redDotLeft}></View>}
                    <FontAwesome5 name='bars' size={18} color={Colors.navy} />
                </TouchableOpacity>

                <Pressable style={styles.iconContainer} onPress={() => { nav.navigate('Platform', { screen: 'Home' }); }}>
                    <Image style={styles.icon} source={IMAGE.abodely.icon.teal} />
                </Pressable>

                <View style={[Layout.row, {flex : 1, justifyContent : 'flex-end'}]}>
                    <View style={[Layout.row]}>
                        {
                            false == true && user !== false &&
                            <TouchableOpacity style={[styles.notificationContainer]} onPress={() => { nav.navigate('Platform', { screen: 'Notification' }); }}>
                                {haveUnread && <View style={styles.redDot}></View>}
                                <FontAwesome5 name='bell' size={20} color={props.notificationActive ? Colors.teal : Colors.navy} />
                            </TouchableOpacity>

                        }
                        <TouchableOpacity>
                            {
                                user !== false 
                                    ? <Ionicons name='search' onPress={()=>{nav.navigate('Explore');}} size={20} color={Colors.navy} />
                                    : <Feather name='user' onPress={()=>{nav.navigate('Authentication');}} size={20} color={Colors.navy} />
                            }
                            
                        </TouchableOpacity>
                    </View>

                </View>
            </View>
        </View>
    );
};

export default ScreenHeader;

const styles = StyleSheet.create({
    containerNoShadow: {
        paddingVertical: 15,
        zIndex: 1,
        boxShadow:'0px 1px 2px rgba(0, 0, 0, 0.05)',
        alignItems: "baseline"
    },
    container: {
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.2,
        shadowRadius: 10,
        elevation: 5,
        paddingVertical: 15,
        zIndex: 1,
        alignItems: "baseline"
    },
    notificationContainer: {
        paddingRight: 30
    },
    iconContainer : {
        flex : 1,
        height : 40,
    },
    icon : {
        resizeMode : 'contain',
        width: '100%',
        height: '100%'
    },
    redDot: {
        width: 8,
        height: 8,
        borderRadius: 8,
        backgroundColor: '#FF0000',
        borderWidth:2,
        borderColor: Colors.white,
        position: 'absolute',
        left: 10,
        top: -2
    },
    redDotLeft: {
        width: 8,
        height: 8,
        borderRadius: 8,
        backgroundColor: '#FF0000',
        borderWidth:2,
        borderColor: Colors.white,
        position: 'absolute',
        left: 14,
        top: -2
    }
});
