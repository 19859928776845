import React from 'react';
import { StyleSheet, View } from 'react-native';

export type TPopUpModal = {
  show : boolean
}
const PopUpModal : React.FC< TPopUpModal > = ({show, children}) => {
    return (
        <View style={styles.filterModal}>
            <View style={styles.filterModalContent}>
                {children}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    filterModal: {
        backgroundColor: '#000000cc',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1
    },
    filterModalContent: {
        backgroundColor: 'white',
        maxWidth: 375,
        paddingTop: 25,
        paddingBottom: 40,
        paddingHorizontal: 25,
        width: '100%',
        borderRadius: 16
    }
});

export default PopUpModal;
