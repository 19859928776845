import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Animated, ScrollView } from 'react-native';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import { Ionicons } from '@expo/vector-icons';
import Layout from '../../constants/Layout';
import { getPostComments, getPostLikes } from '../../lib/api/Content/posts/posts';
import ProfileHeadshotById from './HomeOwnerHeadshotByID';
import { useNavigation } from '@react-navigation/native';
import { firebaseCreatedAtToDate } from '../../lib/utils/dateAdition';

export type TAllPostsLikeComments = {
    open : boolean,
    showLikes : boolean,
    showComments : boolean,
    targetPost : string,
    setShowLikes: Dispatch<SetStateAction<boolean>>
    setShowComments: Dispatch<SetStateAction<boolean>>
    setTargetPost: Dispatch<SetStateAction<string>>
}
const AllPostsLikeComments = (props: TAllPostsLikeComments) => {
    const [bounceValue, setBounceValue] = useState(new Animated.Value(Layout.window.width + 200));
    const [isHidden, setIsHidden ] = useState(props.open);
    const [postLikes, setPostLikes ] = useState();
    const [postComments, setPostComments ] = useState();
    const nav = useNavigation();

    const closeWholeModel = () => {
        toggleSlide(false);
        setTimeout(() => {
            setIsHidden(false);
            props.setShowLikes(false);
            props.setShowComments(false);
            props.setTargetPost('');
        }, 500);
    };

    const toggleSlide = (status : boolean) =>  {
        let slideToValue = Layout.window.width + 200;
        if(status) {
            slideToValue = 0;
        }
        Animated.spring(
            bounceValue,
            {
                toValue: slideToValue,
                velocity: 10,
                tension: 2,
                friction: 8,
                useNativeDriver: false
            }
        ).start();
    };

    toggleSlide(isHidden);

    useEffect(() => {
        getPostLikes(props.targetPost).then(postData => {
            postData !== undefined && setPostLikes(postData.reverse());
        });
        getPostComments(props.targetPost).then(postData => {
            postData !== undefined && setPostComments(postData);
        });
    }, [props.targetPost]);
    
    return (
        <Animated.View style={[styles.outContainer, {transform: [{translateX: bounceValue}]}, !isHidden && {display:'none'}]}>
            <TouchableOpacity onPress={closeWholeModel} style={[Layout.row, {padding:24}]}>
                <Ionicons name="arrow-back" size={23} color={Colors.grey1} style={{marginRight:15}}/>
                <Text style={[FontProperty.h1, FontProperty.TextBlack]}>{props.showLikes && 'Likes'}{props.showComments && 'Comments'} ({props.showLikes && postLikes !== undefined && postLikes.length}{props.showComments && postComments !== undefined && postComments.length})</Text>
            </TouchableOpacity>
            <ScrollView style={{paddingHorizontal:24, paddingBottom:40}} showsVerticalScrollIndicator={false}>
                {
                    props.showLikes && props.targetPost !== undefined && postLikes !== undefined && postLikes.length > 0 && 
                    postLikes.map((item:string, index:number)=>{
                        return (
                            <View key={index} style={{marginBottom:24}}>
                                <ProfileHeadshotById userId={item} onClickFunction={()=>nav.navigate({name: 'UserProfile', params: { userId: item, postId: '' }})}/>
                            </View>
                        );
                    })
                }
                {
                    props.showComments && props.targetPost !== undefined && postComments !== undefined && postComments.length > 0 && 
                    postComments.map((item:string, index:number)=>{
                        return (
                            <View key={index} style={{marginBottom:24}}>
                                <ProfileHeadshotById 
                                    userId={item.userId} 
                                    subtitle={item.comment} 
                                    rightText={firebaseCreatedAtToDate(new Date(item.createdAt.seconds * 1000))} 
                                    onClickFunction={()=>nav.navigate({name: 'UserProfile', params: { userId: item.userId, postId: '' }})}
                                />
                            </View>
                        );
                    })
                }
            </ScrollView>
        </Animated.View>
    );
};

const styles = StyleSheet.create({
    outContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: Colors.white,
        justifyContent: 'space-between',
        zIndex: 2
    },
});

export default AllPostsLikeComments;

