import React, {useEffect, useState} from 'react';
import { ActivityIndicator, FlatList, Image, Pressable, StyleSheet, Text, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useAuthentication } from '../../hooks/useAuthentication';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import IMAGE from '../../assets/images';
import { getUsersProperties } from '../../lib/api/Content/property';
import { IpropertyData } from '../../lib/api/Content/types';

import ScreenHeader from '../../components/atom/ScreenHeader';
import ScreenBase from '../../components/ScreenBase';
import PopUpModal from '../../components/atom/PopUpModal';
import { TealButton } from '../../components/Button';
import HouseTile from '../../components/atom/HouseTile';
import NoResult from '../../components/atom/NoResult';
import ContentText from '../../constants/Content';
import Colors from '../../constants/colors';

const InsightsScreen = () => {
    const { user } = useAuthentication();
    const nav = useNavigation();
    const [myListing, setMyListing] = useState<IpropertyData>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        user !== false && user && user.uid && 
        getUsersProperties(user.uid).then(res => {
            setMyListing(res);
            res !== undefined && setLoading(false);
        });
    },[user]);

    if(loading && user !== false) {
        return (
            <ScreenBase>
                <ScreenHeader noShadow={false}/>
                <View style={{marginTop:150}}>
                    <ActivityIndicator size={Layout.window.width > 425 ? 107 : 80} color={Colors.teal} />
                </View>
            </ScreenBase>
        );
    }

    if(!loading && myListing && myListing.length === 0) {
        return (
            <ScreenBase>
                <ScreenHeader noShadow={false}/>
                <View style={[Layout.globalPadding24, {alignItems:'center'}]}>
                    <NoResult title="No insights available" removeGapbelow={true} />
                    <View style={{marginTop:24}}></View>
                    <View style={{width: Layout.window.width > 425 ? 180 : '100%'}}>
                        <TealButton onPress={()=>nav.navigate('Start')}>
                            <Text style={[FontProperty.button_1, FontProperty.TextWhite]}>List a property</Text>
                        </TealButton>
                    </View>
                </View>
            </ScreenBase>
        );
    }

    return (
        <ScreenBase>
            <ScreenHeader />
            <View style={Layout.globalPadding24}>
                <Text style={[FontProperty.h3,FontProperty.TextBlack,{paddingVertical:24}]}>Choose a property to view insights</Text>
                <View style={[Layout.rowBetween, Layout.flexWrap]}>
                    {
                        (myListing && myListing.length > 0 &&
                            <FlatList
                                data={myListing}
                                keyExtractor={(item:IpropertyData) => item.uuid}
                                renderItem={({item}) => (
                                    <HouseTile key={item.uuid} uuid={item.uuid} image={item.images[0].viewUrl} name={item.propertyName} suburb={item?.address}
                                        onPress={() => {
                                            nav.navigate('PropertyInsight', {
                                                propertyId: item.uuid
                                            });
                                        }} />
                                )}
                                numColumns={2}
                                columnWrapperStyle={{justifyContent:'space-between'}}
                                onEndReachedThreshold={0.01}
                            />
                        )
                    }
                </View>
            </View>
            
            {user == false &&
                <PopUpModal show={true}>
                    <Pressable onPress={() => nav.navigate('Platform', { screen: 'Home' })} style={{marginBottom: 20}}>
                        <MaterialCommunityIcons name='arrow-left' size={20} />
                    </Pressable>
                    <View style={{alignItems: 'center'}}>
                        <Text style={[FontProperty.h1, FontProperty.TextBlack, Layout.mbottom8]}>Insights is locked <MaterialCommunityIcons name='lock' size={20} /></Text>
                        <Text style={[FontProperty.smallP, Layout.mbottom25, {textAlign: 'center'}]}>{ContentText.encourageSignUpInsight}</Text>
                        <Image source={IMAGE.signUpPlaceholder} style={{width:250,height:230,resizeMode:'contain',marginBottom:40}} />
                        <TealButton onPress={() => nav.navigate('Platform', { screen: 'Authentication' })} >
                            <Text style={[FontProperty.button_1, FontProperty.TextWhite]}>Sign In / Register</Text>
                        </TealButton>
                        <Text style={[FontProperty.smallLabel, {textAlign: 'center'}]}>Don't want to sign in? 
                            <Pressable onPress={() => nav.navigate('Platform', { screen: 'Explore' })}>
                                <Text style={FontProperty.TextTeal}>  Explore properties</Text>
                            </Pressable>
                        </Text>
                    </View>
                </PopUpModal>
            }
            <View>
            </View>
        </ScreenBase>
    );
};

export default InsightsScreen;

const styles = StyleSheet.create({

});
