import React, {useEffect, useState} from 'react';
import { StyleSheet, View, Text, ScrollView } from 'react-native';
import Layout from '../../constants/Layout';
import { getPP } from '../../lib/api/Content/content';
import RenderHtml, { MixedStyleDeclaration } from 'react-native-render-html';

const ProfilePP = () => {
    const [ppContent, setPPContent] = useState<string>();

    useEffect(() => {
        getPP().then(res => {
            setPPContent(res);
        });
    },[]);

    if(!ppContent){
        return null;
    }
    return (
        <ScrollView style={[Layout.globalPadding24, {paddingBottom:100}]} showsVerticalScrollIndicator={false}>
            <RenderHtml
                contentWidth={Layout.window.width-48}
                source={{ html: ppContent }}
                tagsStyles={tagsStyles}
                defaultTextProps={{selectable:true}}
            />
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    
});

const tagsStyles: Record<string, MixedStyleDeclaration> = {
    body: {
        fontFamily: 'inter-regular',
        fontSize: 14,
        lineHeight: 20,
        margin: 0
    },
    p: {
        margin: 0,
        marginBottom: 16
    },
    ul: {
        margin: 0,
        marginBottom: 16,
        paddingHorizontal: 20
    },
    li: {
        margin: 0,
        marginBottom: 16,
        paddingHorizontal: 20
    },
    h2: {
        fontFamily: 'inter-semibold',
        marginBottom: 16,
        marginTop: 16,
        fontSize: 14,
        lineHeight: 20,
    }
};


export default ProfilePP;

