
import React, { useEffect, useState } from 'react';
import { Image, Pressable, Text, StyleSheet, View } from 'react-native';
import Colors from '../constants/colors';
import { AuthenticatedDrawerParamList } from '../types';
import { useNavigation } from '@react-navigation/native';
import {
    createDrawerNavigator, DrawerContentScrollView, DrawerItem, DrawerItemList
} from '@react-navigation/drawer';
import { observer } from 'mobx-react';
import { useAuthentication } from '../hooks/useAuthentication';
import { getUserData } from '../lib/api/user';
import userStore from '../store/userStore';

import FontProperty from '../constants/FontProperty';
import IMAGE from '../assets/images';
import Newsfeed from '../screens/Global/Newsfeed';
import InitialSearch from '../screens/Global/InitialSearchScreen';
import HomeScreen from '../screens/Global/HomeScreen';
import { AddListingNavigator } from './ListPropertyNavigation';
import InsightsScreen from '../screens/Global/InsightsScreen';
import { logout } from '../lib/api/user/authentication';

import { OnboardingScreen } from '../screens/OnboardScreens/OnboardingScreen';
import { CodeVerificationScreen } from '../screens/Global/CodeVerificationScreen';
import ViewPropertyScreen from '../screens/Global/ViewPropertyScreen';
import { IuserData } from '../lib/api/user/types';
import UserProfileScreen from '../screens/Global/UserProfileScreen';
import ViewPropertyInsightsScreen from '../screens/Global/ViewPropertyInsightsScreen';
import NotificationScreen from '../screens/Global/NotificationScreen';
import InboxScreen from '../screens/Inbox/InboxScreen';
import { Ionicons } from '@expo/vector-icons';
import { haveUnreadMessages } from '../lib/api/messages/messages';
import { StartScreen } from '../screens/Global/StartScreen';
import { AddProjectNavigator } from './ListProjectNavigation';
/* import { StartProjectScreen } from '../screens/Global/StartProjectScreen';
import ViewProjectScreen from '../screens/Global/ViewProjectScreen';
import ExploreProject from '../screens/Global/ExploreProjectScreen'; */
import { AboutUsScreen } from '../screens/Global/AboutUsScreen';
import { AuthenticationNavigation } from './AuthenticationNavigation';
import FaqsScreen from '../screens/Global/FaqsScreen';
import PrivacyPolicyScreen from '../screens/Global/PrivacyPolicyScreen';
import TermsOfUseScreen from '../screens/Global/TermsOfUseScreen';
import { ContactUsScreen } from '../screens/Global/ContactUsScreen';

export type TAuthenticatedDrawerNavigation = {
    initialRoute: string
}

const AuthenticatedDrawer = createDrawerNavigator<AuthenticatedDrawerParamList>();
export const AuthenticatedDrawerNavigation = observer((props:TAuthenticatedDrawerNavigation) => {
    const { user } = useAuthentication();
    const nav = useNavigation();
    const [userData, setUserData] = useState(user);
    const [haveUnread, setHaveUnread] = useState(false);

    const getTargetParam = (str:string, from:string, to:string) => {
        return (str !== undefined && str !== '') ? str.split(from).pop().split(to)[0] : '';
    };

    useEffect(() => {
        if (user) {
            getUserData(user.uid).then(userData => {
                userStore.setField('name', userData.data()?.username);
            });
            haveUnreadMessages(user.uid, setHaveUnread); 
        }
    }, [user]);

    const initialRouteWithoutParam = props.initialRoute.split('?')[0];
    const initialRouteParams = props.initialRoute.split('?')[1];
    const addressParam = '';
    const styleParam = '';
    const styleNameParam = '';
    let userIdParam = '';
    let postIdParam = '';
    let propertyIdParam = '';
    let projectIdParam = '';
    if(initialRouteParams !== '' && initialRouteParams !== undefined){
        userIdParam = initialRouteParams.includes('userId') ? getTargetParam(initialRouteParams, 'userId=', '&') : ''; 
        postIdParam = initialRouteParams.includes('postId') ? getTargetParam(initialRouteParams, 'postId=', '&') : ''; 
        propertyIdParam = initialRouteParams.includes('propertyId') ? getTargetParam(initialRouteParams, 'propertyId=', '&') : ''; 
        projectIdParam = initialRouteParams.includes('projectId') ? getTargetParam(initialRouteParams, 'projectId=', '&') : ''; 
    }

    
    return (
        <AuthenticatedDrawer.Navigator
            screenOptions={{
                headerShown: false,
                drawerStyle: {
                    backgroundColor: Colors.darkNavy
                },
                drawerLabelStyle: FontProperty.h2,
                drawerInactiveTintColor: Colors.white,
                drawerActiveTintColor: Colors.teal,
                drawerActiveBackgroundColor: Colors.darkNavy,
            }}
            initialRouteName={(initialRouteWithoutParam !== undefined && initialRouteWithoutParam !== '') ? initialRouteWithoutParam : 'Home'}
            drawerContent={(props) => <CustomDrawerContent {...props} />}
            backBehavior = 'history'
        >
            <AuthenticatedDrawer.Screen
                name="Home"
                component={InitialSearch}
                options={{
                    drawerItemStyle: {
                        display: 'none',
                        height: 0
                    }
                }}
                initialParams={{address: addressParam, style: styleParam, styleName: styleNameParam}}
            />
            <AuthenticatedDrawer.Screen
                name="About"
                component={AboutUsScreen}
                options={{
                    drawerLabel: 'How it works',
                    drawerItemStyle: {
                        display: 'none',
                        height: 0
                    }
                }}
            />
            {user !== false ?
                <>
                    <AuthenticatedDrawer.Screen
                        name="Newsfeed"
                        component={Newsfeed}
                        options={{
                            drawerLabel: 'Newsfeed',
                            drawerItemStyle: {
                                display: 'none',
                                height: 0
                            }
                        }}
                    />
                </>
                :
                <>
                    <AuthenticatedDrawer.Screen
                        name="Newsfeed"
                        component={Newsfeed}
                        options={{
                            drawerItemStyle: {
                                display: 'none',
                                height: 0
                            }
                        }}
                    />
                </>
            }
            
            <AuthenticatedDrawer.Screen
                name="Explore"
                component={InitialSearch}
                options={{
                    drawerLabel: 'Explore Homes'
                }}
                initialParams={{address: addressParam, style: styleParam, styleName: styleNameParam}}
            />
           { /*  <AuthenticatedDrawer.Screen
                name="ExploreProject"
                component={ExploreProject}
                options={{
                    drawerLabel: 'Explore Projects'
                }}
            /> */ }
            {user !== false ?
                <>
                    <AuthenticatedDrawer.Screen
                        name="Insights"
                        component={InsightsScreen}
                        options={{
                            drawerLabel: 'Insights Dashboard',
                            drawerItemStyle: {
                                display: 'none',
                                height: 0
                            }
                        }}
                    />
                    <AuthenticatedDrawer.Screen
                        name="Start"
                        component={StartScreen}
                        options={{
                            drawerLabel: 'Add New Home',
                            drawerItemStyle: {
                                display: 'none',
                                height: 0
                            }
                        }}
                    />
                    <AuthenticatedDrawer.Screen
                        name="Inbox"
                        component={InboxScreen}
                        options={{
                            drawerLabel: 'Messages',
                            drawerIcon: () => (
                                <Ionicons
                                    name="ellipse"
                                    size={6}
                                    color={haveUnread ? '#FF0000' : 'transparent'}
                                    style={{position:'absolute',left:115,top:14,zIndex:2}}
                                />
                            ),  
                            drawerItemStyle: {
                                marginLeft:-22,
                                display: 'none',
                                height: 0
                            }
                        }}
                    />
                    <AuthenticatedDrawer.Screen
                        name="Onboarding"
                        component={OnboardingScreen}
                        options={{
                            // drawerLabel: 'Onboarding',
                            drawerItemStyle: {
                                display: 'none',
                                height: 0
                            }
                        }}
                    />
                </>
                :
                <>
                    <AuthenticatedDrawer.Screen
                        name="Insights"
                        component={InsightsScreen}
                        options={{
                            drawerItemStyle: {
                                display: 'none',
                                height: 0
                            }
                        }}
                    />
                    <AuthenticatedDrawer.Screen
                        name="Start"
                        component={StartScreen}
                        options={{
                            drawerItemStyle: {
                                display: 'none',
                                height: 0
                            }
                        }}
                    />
                </>
            }
            <AuthenticatedDrawer.Screen
                name="ContactUs"
                component={ContactUsScreen}
                options={{
                    drawerLabel: 'Contact Us',
                    drawerItemStyle: {
                        display: 'none',
                        height: 0
                    }
                }}
            />
            
            {/* Hidden Screens */}
            <AuthenticatedDrawer.Screen
                name="Notification"
                component={NotificationScreen}
                options={{
                    drawerItemStyle: {
                        display: 'none',
                        height: 0
                    }
                }}
            />
            { /* Removed whilst projects are not in the app 
            <AuthenticatedDrawer.Screen
                name="StartProject"
                component={StartProjectScreen}
                options={{
                    drawerItemStyle: {
                        display: 'none',
                        height: 0
                    }
                }}
            /> */ }
            <AuthenticatedDrawer.Screen
                name="Authentication"
                component={AuthenticationNavigation}
                options={{
                    drawerItemStyle: {
                        display: 'none',
                        height: 0
                    }
                }}
            />
            <AuthenticatedDrawer.Screen
                name="ListProperty"
                component={AddListingNavigator}
                options={{
                    drawerItemStyle: {
                        display: 'none',
                        height: 0
                    }
                }}
            />
            <AuthenticatedDrawer.Screen
                name="ListProject"
                component={AddProjectNavigator}
                options={{
                    drawerItemStyle: {
                        display: 'none',
                        height: 0
                    }
                }}
            />
            
            <AuthenticatedDrawer.Screen
                name="UserProfile"
                component={UserProfileScreen}
                initialParams={{ userId: userIdParam, postId: postIdParam}}
                options={{
                    drawerItemStyle: {
                        display: 'none',
                        height: 0
                    }
                }}
            />
            <AuthenticatedDrawer.Screen
                name="PropertyProfile"
                component={ViewPropertyScreen}
                initialParams={{ propertyId: propertyIdParam}}
                options={{
                    drawerItemStyle: {
                        display: 'none',
                        height: 0
                    }
                }}
            />
            { /* 
            <AuthenticatedDrawer.Screen
                name="ProjectProfile"
                component={ViewProjectScreen}
                initialParams={{ projectId: projectIdParam}}
                options={{
                    drawerItemStyle: {
                        display: 'none',
                        height: 0
                    }
                }}
            /> */ }
            <AuthenticatedDrawer.Screen
                name="PropertyInsight"
                component={ViewPropertyInsightsScreen}
                initialParams={{ propertyId: propertyIdParam}}
                options={{
                    drawerItemStyle: {
                        display: 'none',
                        height: 0
                    }
                }}
            />
            <AuthenticatedDrawer.Screen 
                name="Verify" 
                options={{
                    drawerItemStyle: {
                        display: 'none',
                        height: 0
                    }
                }}
            >
                {props => <CodeVerificationScreen setUser={setUserData} />}
            </AuthenticatedDrawer.Screen>
            <AuthenticatedDrawer.Screen
                name="FAQs"
                component={FaqsScreen}
                options={{
                    drawerItemStyle: {
                        display: 'none',
                        height: 0
                    }
                }}
            />
            <AuthenticatedDrawer.Screen
                name="PrivacyPolicy"
                component={PrivacyPolicyScreen}
                options={{
                    drawerItemStyle: {
                        display: 'none',
                        height: 0
                    }
                }}
            /> 
            <AuthenticatedDrawer.Screen
                name="TermsOfUse"
                component={TermsOfUseScreen}
                options={{
                    drawerItemStyle: {
                        display: 'none',
                        height: 0
                    }
                }}
            />
        </AuthenticatedDrawer.Navigator>
    );
});

const CustomDrawerContent = observer((props) => {
    const { user } = useAuthentication();
    const [userData, setUserData] = useState<IuserData>();
    const nav = useNavigation();

    useEffect(() => {
        if (user) {
            getUserData(user.uid).then(userData => {
                setUserData(userData.data());
            });
        }
    }, [user]);

    return (
        <>
            <DrawerContentScrollView
                {...props}>
                <Pressable style={{
                    alignItems: 'flex-start',
                    //  width : "100%",
                    marginBottom: 24,
                    padding: 8,
                    marginTop: 60,
                    marginLeft: 24

                }}
                onPress={() => { nav.navigate('Home'); }}
                >
                    <Image style={{
                        width: 100,
                        height: 60,
                        left: -10,
                        resizeMode: 'contain',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start'

                    }} source={IMAGE.abodely.icon.white} />
                </Pressable>
                <DrawerItemList {...props} />
                {user !== false
                    ? <DrawerItem
                        label="Logout"
                        onPress={() => {logout();nav.navigate('Home');}}
                        labelStyle={[FontProperty.TextTeal, FontProperty.h2]}
                        style={{
                            paddingTop: 20,
                            borderTopColor: Colors.white,
                            borderTopWidth: 1,
                            borderRadius: 0,
                            width: '90%'
                        }}
                    />
                    : 
                    <>
                        <DrawerItem
                            label="Sign In"
                            onPress={() => nav.navigate('Platform', { screen: 'Authentication', params: { target: 'LOGIN' } })}
                            labelStyle={[FontProperty.TextWhite, FontProperty.h2]}
                            style={{
                                paddingTop: 20,
                                borderTopColor: Colors.teal,
                                borderTopWidth: 1,
                                borderRadius: 0,
                                width: '90%',
                                marginTop: 20
                            }}
                        />
                        <DrawerItem
                            label="Register"
                            onPress={() => nav.navigate('Platform', { screen: 'Authentication', params: { target: 'REGISTER' } })}
                            labelStyle={[FontProperty.TextWhite, FontProperty.h2]}
                            style={{
                                borderRadius: 0,
                                width: '90%'
                            }}
                        />
                    </>
                }
            </DrawerContentScrollView>
            {user !== false && userData !== undefined && user &&
                <Pressable style={styles.userProfile} onPress={() => nav.navigate({name: 'UserProfile', params: { userId: user.uid, postId: '' }})}>
                    <Image source={userData.headshot ? userData.headshot : IMAGE.userProfile} resizeMode={'cover'} style={styles.imgCard}></Image>
                    <Text style={FontProperty.h2}>{userStore.name}</Text>
                </Pressable>
            }
        </>
    );
});

const styles = StyleSheet.create({
    userProfile: {
        padding: 24,
        backgroundColor: Colors.white,
        flexDirection: 'row',
        alignItems: 'center',
    },
    imgCard: {
        width : 48,
        height : 48,
        marginRight: 17,
        borderRadius: 50
    }
});
