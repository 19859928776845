import { useNavigation } from '@react-navigation/native';
import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { StyleSheet, Text, View, Image, Pressable, TextInput } from 'react-native';
import Colors from '../../../constants/colors';
import FontProperty from '../../../constants/FontProperty';
import Layout from '../../../constants/Layout';
import ProfileHeadshotById from '../HomeOwnerHeadshotByID';
import ScreenWrapper from '../ScreenWrapper';
import { AntDesign, FontAwesome, MaterialCommunityIcons } from '@expo/vector-icons';
import { IPropertyPost } from '../../../lib/api/Content/posts/types';
import PropertyGallery from '../PropertyGallery';
import { addPostComment, addPostLike, getPostComments, removePostLike } from '../../../lib/api/Content/posts/posts';
import { firebaseCreatedAtToDate } from '../../../lib/utils/dateAdition';
import { uppercaseFirstLetter } from '../../../lib/utils/uppercaseFirstLetter';
import Hyperlink from 'react-native-hyperlink';

export type TNewsFeedBlockPost = {
    data : IPropertyPost
    userId : string
    setShowLikes: Dispatch<SetStateAction<boolean>>
    setShowComments: Dispatch<SetStateAction<boolean>>
    setTargetPost: Dispatch<SetStateAction<string>>
    setShowShare: Dispatch<SetStateAction<boolean>>
    setShareLink: Dispatch<SetStateAction<string>>
    setShowReportPopUp : Dispatch<SetStateAction<boolean>>
    setShowDeletePopUp : Dispatch<SetStateAction<boolean>>
    setShowEditPopUp : Dispatch<SetStateAction<boolean>>
}

const NewsfeedBlock = (props:TNewsFeedBlockPost) => {
    const nav = useNavigation();
    const [popUpGalleryIndex, setPopUpGalleryIndex] = useState(0);
    const [popUpGallery, setPopUpGallery] = useState(false);
    const [numOfLikes, setNumOfLikes] = useState(0);
    const [numOfComments, setNumOfComments] = useState(0);
    const [likes, setLikes] = useState<string[]>([]);
    const [comment, setComments] = useState<string>('');
    const [showCommentInput, setShowCommentInput] = useState(false);
    const [succeed, setSucceed] = useState(false);
    const [showEditDeleteReportPopup, setShowEditDeleteReportPopup] = useState(false);

    useEffect(() => {
        if (props.data && props.data.likes) {
            setLikes(props.data.likes);
            setNumOfLikes(props.data.likes.length);
        }
        if (props.data && props.data.uuid) {
            const fetchComments = async () => {
                const comments = await getPostComments(props.data.uuid);
                setNumOfComments(comments.length);
            };

            fetchComments().catch(console.error);
        }
    },[props.data, props.userId]);
  
    if(props.data === undefined){
        return null;
    }

    const likeUnlikePost = async (userId:string)=> {
        if(likes.includes(userId) && likes.length > 0){
            likes.splice(likes.indexOf(userId), 1);
            setNumOfLikes(numOfLikes-1);
            await removePostLike(props.data.uuid, userId);
        }else{
            likes.push(userId) && setNumOfLikes(numOfLikes+1);
            await addPostLike(props.data.uuid, userId);
        }
    };

    const postComment  = async (userId:string, comment:string)=> {
        if(comment !== ''){
            await addPostComment(props.data.uuid, userId, uppercaseFirstLetter(comment));
            setNumOfComments(numOfComments+1);
            setSucceed(true);
            setComments('');
            setTimeout(() => {
                setSucceed(false);
            }, 2000);
        }
    };

    const postUrl = 'http://' + window.location.host + '/UserProfile?userId=' + props.data.userId + '&postId=' + props.data.uuid;

    return (
        <ScreenWrapper>
            <View style={[styles.container]}>
                <View style={[Layout.rowBetween, {marginBottom:24}]}>
                    <ProfileHeadshotById userId={props.data.userId} onClickFunction={()=>nav.navigate({name: 'UserProfile', params: { userId: props.data.userId, postId: '' }})} />
                    <View style={{alignItems:'flex-end'}}>
                        {
                            props.userId !== undefined 
                                ? <Pressable onPress={()=>setShowEditDeleteReportPopup(!showEditDeleteReportPopup)}><MaterialCommunityIcons name='dots-horizontal' size={20} style={{marginBottom:10}} /></Pressable>
                                : <Pressable onPress={()=>{props.setShowShare(true);props.setShareLink(postUrl);}}><AntDesign name='sharealt' size={18} color={Colors.black} style={{marginBottom:10}}/></Pressable>
                        }
                        <Text style={[FontProperty.smallP, FontProperty.TextGrey]}>{firebaseCreatedAtToDate(new Date(props.data.createdAt.seconds * 1000))}</Text>
                    </View>
                </View>
                { props.data.description !== '' && <Hyperlink linkDefault={ true } linkStyle={{color: '#00b7a1'}}><Text style={[FontProperty.smallP, {marginBottom:16}]}>{props.data.description}</Text></Hyperlink> }
                {
                    props.data.type === 'post' && props.data.images !== undefined && props.data.images.length > 0 &&
                    <View style={{borderRadius:4,overflow:'hidden',marginBottom:24}}>
                        <PropertyGallery 
                            images={props.data.images} 
                            setPopUpGalleryIndex={setPopUpGalleryIndex} 
                            setPopUpGallery={()=>setPopUpGallery(true)} 
                            popUpGallery={popUpGallery} 
                            noViewURL={true}
                            imageStyle='contain'
                        />
                    </View>
                }
                {
                    props.data.type === 'propertyPost' && 
                    <View style={{marginBottom:24}} >
                        <Pressable style={[styles.boxShaw, {borderRadius:4,overflow:'hidden'}]} onPress={()=> nav.navigate('PropertyProfile', { propertyId: props.data.propertyId })}>
                            <Image source={props.data.propertyFImage} resizeMode='cover' style={{width: Layout.window.width > 425 ? '100%' : Layout.window.width - 48, height: Layout.window.width > 425 ? 200 : 95}} />
                            <View style={{padding:10}}>
                                <Text style={[FontProperty.smallP,FontProperty.TextBold,{marginBottom:4}]}>{props.data.propertyTitle}</Text>
                                <Text style={[FontProperty.smallP,{marginBottom:16}]} numberOfLines={2} ellipsizeMode='tail' >{uppercaseFirstLetter(props.data.propertyDesc)}</Text>
                                <View style={styles.btn}><Text style={[FontProperty.TextTeal,FontProperty.TextSemiBold]}>View updated property</Text></View>
                            </View>
                        </Pressable>
                    </View>
                }
                {
                    props.data.type === 'projectPost' && 
                    <View style={{marginBottom:24}} >
                        <Pressable style={[styles.boxShaw, {borderRadius:4,overflow:'hidden'}]} onPress={()=> nav.navigate('ProjectProfile', { projectId: props.data.projectId })}>
                            <Image source={props.data.propertyFImage} resizeMode='cover' style={{width: Layout.window.width > 425 ? '100%' : Layout.window.width - 48, height: Layout.window.width > 425 ? 200 : 95}} />
                            <View style={{padding:10}}>
                                <View style={styles.pill}><Text style={[FontProperty.miniP, FontProperty.TextWhite, FontProperty.TextSemiBold]}>Project</Text></View>
                                <Text style={[FontProperty.smallP,FontProperty.TextBold,{marginBottom:4}]}>{props.data.propertyTitle}</Text>
                                <Text style={[FontProperty.smallP,{marginBottom:16}]} numberOfLines={2} ellipsizeMode='tail' >{uppercaseFirstLetter(props.data.propertyDesc)}</Text>
                                <View style={[styles.btn, styles.btnNavy]}><Text style={[FontProperty.TextNavy,FontProperty.TextSemiBold]}>View updated project</Text></View>
                            </View>
                        </Pressable>
                    </View>
                }
                <View style={[Layout.rowBetween, {paddingBottom:16,borderBottomWidth:1,borderBottomColor:'#E0E0E0'}]}>
                    <Pressable onPress={()=>{numOfLikes > 0 && props.setShowLikes(true);numOfLikes > 0 && props.setTargetPost(props.data.uuid);}}><Text style={[FontProperty.smallP, FontProperty.TextGrey]}>{numOfLikes} {numOfLikes > 1 ? 'likes' : 'like' } </Text></Pressable>
                    <Pressable onPress={()=>{numOfComments > 0 && props.setShowComments(true);numOfComments > 0 && props.setTargetPost(props.data.uuid);}}><Text style={[FontProperty.smallP, FontProperty.TextGrey]}>{numOfComments} {numOfComments > 1 ? 'Comments' : 'Comment' }</Text></Pressable>
                </View>
                {
                    props.userId !== undefined && 
                    <View style={[{paddingTop:16},Layout.rowBetween]}>
                    
                        <View style={[Layout.row]}>
                            <Pressable onPress={()=>likeUnlikePost(props.userId)}>
                                {
                                    likes !== undefined && likes.length > 0 && likes.includes(props.userId) 
                                        ? <FontAwesome name='heart' size={18} color={Colors.teal} />
                                        : <FontAwesome name='heart-o' size={18} color={Colors.black} />
                                }
                            </Pressable>
                            <Pressable onPress={()=>{setShowCommentInput(!showCommentInput);}}><FontAwesome name='comment-o' size={18} color={Colors.black} style={{marginLeft:16}} /></Pressable>
                        </View>
                        <Pressable onPress={()=>{props.setShowShare(true);props.setShareLink(postUrl);}}><AntDesign name='sharealt' size={18} color={Colors.black} /></Pressable>
                    </View>
                }
                {
                    showCommentInput &&
                    <View style={{marginTop:16,flexDirection:'row',alignItems:'center'}}>
                        <TextInput
                            autoCapitalize='none'
                            style={[styles.input]}
                            placeholder='Write a comment...'
                            placeholderTextColor={Colors.grey3}
                            onChangeText={setComments}
                            value={comment}
                            multiline={true}
                        />
                        <Pressable style={styles.postBtn} onPress={()=>postComment(props.userId,comment)}><Text style={[FontProperty.TextWhite, FontProperty.TextSemiBold]}>
                            {
                                succeed
                                    ? <Text>Posted!</Text>
                                    : <Text>Post Comment</Text>
                            }
                        </Text></Pressable>
                    </View>
                }
            </View>
            {
                showEditDeleteReportPopup &&
                <View style={styles.editDeleteReportPopup}>
                    {
                        (props.userId && props.userId === props.data.userId) 
                            ?
                            <>
                                <Pressable style={[Layout.row, {marginBottom: 20}]} onPress={()=>{props.setShowEditPopUp(true);props.setTargetPost(props.data.uuid);setShowEditDeleteReportPopup(false);}}>
                                    <AntDesign name='edit' style={{marginRight:8}} />
                                    <Text style={FontProperty.TextSemiBold}>Edit</Text>
                                </Pressable>
                                <Pressable style={Layout.row} onPress={()=>{props.setShowDeletePopUp(true);props.setTargetPost(props.data.uuid);setShowEditDeleteReportPopup(false);}}>
                                    <AntDesign name='delete' style={{marginRight:8}} />
                                    <Text style={FontProperty.TextSemiBold}>Remove</Text>
                                </Pressable>
                            </>
                            : <Pressable style={Layout.row} onPress={()=>{props.setShowReportPopUp(true);props.setTargetPost(props.data.uuid);setShowEditDeleteReportPopup(false);}}>
                                <AntDesign name='exclamationcircleo' style={{marginRight:8}}  />
                                <Text style={FontProperty.TextSemiBold}>Report</Text>
                            </Pressable>
                    }
                </View>
            }
        </ScreenWrapper>
    );
};

const styles = StyleSheet.create({

    container: {
        paddingVertical: 16
    },
    imgContainer: {
        height: 230,
        width: '100%',
        marginVertical: 10,
        borderRadius: 5
    },
    socialStatsContainer: {
        paddingBottom: 14,
        borderBottomColor: Colors.grey5,
        borderBottomWidth: 1
    },
    socialButtonsContainer: {
        paddingVertical: 10
    },
    commentIconContainer: {
        paddingLeft: 16
    },
    boxShaw: {
        boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.08)'
    },
    btn: {
        width: Layout.window.width > 425 ? 'fit-content' : '100%',
        borderColor: Colors.teal,
        borderWidth: 1,
        textAlign: 'center',
        paddingVertical: 7.5,
        paddingHorizontal: 16,
        borderRadius: 4
    },
    btnNavy: {
        borderColor: Colors.navy,
    },
    input: {
        color: Colors.navy,
        fontSize: 14,
        width: '100%',
        flex : 1,
        outlineStyle: 'none' 
    },
    postBtn: {
        backgroundColor: Colors.teal,
        borderRadius: 4,
        paddingHorizontal: 15,
        paddingVertical: 4.5,
        marginLeft: 16
    },
    editDeleteReportPopup: {
        position: 'absolute',
        top: 40,
        right: 24,
        backgroundColor: Colors.white,
        zIndex: 2,
        boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.08)',
        padding: 16
    },
    pill: {
        paddingVertical: 6,
        paddingHorizontal: 16,
        backgroundColor: Colors.navy,
        borderRadius: 16,
        width: 75,
        marginBottom: 8
    },
});

export default NewsfeedBlock;