import React, {useState} from 'react';
import { StyleSheet, Text, View, Animated, Image } from 'react-native';
import Colors from '../../constants/colors';
import { Ionicons } from '@expo/vector-icons';
import Layout from '../../constants/Layout';
import { TealButton, TealOutlineButton } from '../common/button';
import FontProperty from '../../constants/FontProperty';
import * as ImagePicker from 'expo-image-picker';
import { uploadHeadshot } from '../../lib/api/Content/images';
import { compressImageSingle } from '../../lib/utils/compressImage';

export type TChangeAvatar = {
    image : string
    uploadImageModal: boolean
    setUploadImageModal: (arg0:boolean)=>void
    uid : string
    trigger : boolean
    setTrigger : (arg0:boolean) => void
}

const ChangeAvatar = (props:TChangeAvatar) => {
    const [opacityValue, setOpacityVlaue] = useState(new Animated.Value(0));
    const [isHiddenSucceed, setIsHiddenSucceed ] = useState(true);
    const [image, setImage] = useState();
    const [imageUrl, setImageUrl] = useState(props.image);
    const [imageUploaded, setImageUploaded] = useState(false);
    const [uploading, setUploading] = useState(false);

    const closeUploadModal = () => {
        toggleSlideSucceed(false);
        setTimeout(() => {
            setIsHiddenSucceed(false);
            props.setUploadImageModal(false);
        }, 500);
    }; 

    const toggleSlideSucceed = (status : boolean) =>  {
        let opacityToValue = 0;
        if(status) {
            opacityToValue = 1;
        }
        Animated.spring(
            opacityValue,
            {
                toValue: opacityToValue,
                velocity: 10,
                tension: 2,
                friction: 8,
                useNativeDriver: false
            }
        ).start();
    };

    toggleSlideSucceed(isHiddenSucceed);

    const pickImage = async () => {
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            aspect: [3, 3],
            quality: 1,
            base64: true,
        });

        if (!result.cancelled) {
            if(result !== undefined){
                const compressedImage = await compressImageSingle(result);
                setImage(compressedImage);
                setImageUrl(compressedImage.uri);
                setImageUploaded(true);
            }
        }
    };

    const UpdateImage = async()=> {
        setUploading(true);
        if(image !== undefined){
            await uploadHeadshot(image, props.uid);
            props.setTrigger(!props.trigger);
            closeUploadModal();
        }else{
            closeUploadModal();
        }
        setUploading(false);
    };
  
    return (
        <Animated.View style={[styles.overlay, {opacity:opacityValue}, !isHiddenSucceed && {display:'none'}]}>
            <View style={styles.modal}>
                <Ionicons name="close" size={20} color="#4F4F4F" style={styles.close} onPress={closeUploadModal}/>
                <Image source={imageUrl} style={{width:150, height:150,resizeMode:'cover',marginBottom:30,borderRadius:150}} />
                {
                    imageUploaded &&
                    <View style={{marginBottom: 20,width:'100%'}}>
                        <TealButton onPress={()=>UpdateImage()} >
                            <Text style={[FontProperty.TextSemiBold,FontProperty.p2,FontProperty.TextWhite]}>{uploading ? 'Saving...' : 'Save'}</Text>
                        </TealButton>
                    </View>
                }
                <TealOutlineButton onPress={()=>pickImage()}>
                    <Text style={[FontProperty.TextSemiBold,FontProperty.p2,FontProperty.TextTeal]}>Change Avatar</Text>
                </TealOutlineButton>
                
            </View>
        </Animated.View>
    );
};

const styles = StyleSheet.create({
  
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.8)',
        position: 'absolute',
        top: 0,
        left: 0,
        paddingHorizontal: 24,
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        alignItems: 'center'
    },
    modal: {
        backgroundColor: Colors.white,
        paddingHorizontal: 24,
        paddingTop: 62,
        paddingBottom: 37,
        alignItems: 'center',
        width: Layout.window.width > 425 ? 360 : '100%',
    },
    close: {
        position: 'absolute',
        right: 24,
        top: 22,
        zIndex: 2
    }
});

export default ChangeAvatar;

