import { TextInputProps, Text, View, StyleSheet } from 'react-native';
import React, { useEffect, useState } from 'react';
import { Formik, FormikErrors } from 'formik';
import * as Yup from 'yup';

import HousepoTextInput from '../HouspoTextInput';
import { SetNewPasswordFormProps } from './types';
import { TealButton } from '../Button';
import ButtonLoader from '../atom/ButtonLoader';
import FontProperty from '../../constants/FontProperty';
import { getFunctions, httpsCallable } from '@firebase/functions';
import { useNavigation } from '@react-navigation/core';
import { useAuthentication } from '../../hooks/useAuthentication';

const RequestNewPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Email is invalid').required('Email Required lala'),
    code: Yup.string().required('Verification Code Required'),
    password: Yup.string().required('Password Required').min(6, 'Password must be at least 6 characters')

});

const functions = getFunctions();
const setNewPassword = httpsCallable(functions, 'setNewPassword');

const SetNewPasswordChangeForm: React.FC = (props) => {
    const nav = useNavigation();
    const [error, setError] = React.useState('');
    const [successMessage, setSuccessMessage] = React.useState('');
    const [userEmail, setUserEmail] = useState<string>('');
    const { user } = useAuthentication();
    const onRequestNewPasswordHandler = async (
        { email, code, password }: SetNewPasswordFormProps,
        setErrors: (errors: FormikErrors<{
            email: string;
        }>) => void
    ) => {
        try {
            await setNewPassword({ email, code, password });
            setError('');
            setSuccessMessage('Password Changed Successfully');

        } catch (error: any) {
            setError('Failed to set new password, please check the email address and try again');
        }
    };

    useEffect(() => {
        user !== undefined && user !== false && user.email !== undefined && setUserEmail(user.email);
    }, [user]);

    if(userEmail === ''){
        return null;
    }

    return (
        <Formik
            initialValues={{ email: userEmail }}
            validationSchema={RequestNewPasswordSchema}
            onSubmit={(values, { setErrors }) => onRequestNewPasswordHandler({ ...values }, setErrors)}
        >
            {({ handleChange, handleBlur, handleSubmit, values, errors, isSubmitting }) => (
                <>
                    {/* Inputs */}
                    <View>
                        <View style={styles.overlay}></View>
                        <HousepoTextInput
                            onChangeText={handleChange('email')}
                            value={values.email}
                            onBlur={handleBlur('email')}
                            error={errors.email || ''}
                            {...emailProps}
                            editable={false}  />
                    </View>

                    <HousepoTextInput
                        onChangeText={handleChange('code')}
                        value={values.code}
                        onBlur={handleBlur('code')}
                        error={errors.code || ''}
                        {...codeProps} />

                    <HousepoTextInput
                        onChangeText={handleChange('password')}
                        value={values.password}
                        onBlur={handleBlur('password')}
                        error={errors.password || ''}
                        {...newPasswordProps} />

                    {/* Button */}
                    {successMessage
                        ? <TealButton
                            isDisabled={isSubmitting}
                            onPress={()=>{nav.navigate('Authentication');}} >
                            <Text style={[FontProperty.TextWhite, FontProperty.TextSemiBold, FontProperty.p2]}>
                                Continue
                            </Text>
                        </TealButton>
                        : <TealButton
                            isDisabled={isSubmitting}
                            onPress={handleSubmit} >
                            <Text style={[FontProperty.TextWhite, FontProperty.TextSemiBold, FontProperty.p2]}>
                                {isSubmitting ? <ButtonLoader /> : 'Set New Password'}
                            </Text>
                        </TealButton>
                    }
                    <Text style={[FontProperty.TextError]}>{error}</Text>
                    <Text style={[FontProperty.TextTeal]}>{successMessage}</Text>
                </>
            )}

        </Formik>);
};

export default SetNewPasswordChangeForm;

const emailProps = {
    testID: 'email-input',
    placeholder: 'Email',
    autoCapitalize: 'none',
    autoCorrect: false
} as Readonly<TextInputProps>;

const codeProps = {
    testID: 'code-input',
    placeholder: 'Verification Code (Check your email)',
    autoCapitalize: 'none',
    autoCorrect: false
} as Readonly<TextInputProps>;

const newPasswordProps = {
    testID: 'password-input',
    placeholder: 'New Password',
    autoCapitalize: 'none',
    autoCorrect: false
} as Readonly<TextInputProps>;


const styles = StyleSheet.create({
    overlay:{
        position:'absolute',
        height: 55,
        top: 0,
        width: '100%',
        zIndex: 1
    }
});