import React, {Dispatch, SetStateAction} from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import Colors from '../../constants/colors';
import Layout from '../../constants/Layout';
import { useNavigation } from '@react-navigation/native';
import { NavyButton, NavyOutlineButton, TealButton } from '../../components/Button';
import FontProperty from '../../constants/FontProperty';
import ScreenBase from '../../components/ScreenBase';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { StepBarCounter } from '../../components/atom/StepBarCounter';
import WarningBar from '../../components/atom/Warningbar';

type TlistingBaseScreen = {
  step: number
  header?: string
  nextScreenHandler: () => void
  isCreated?: boolean
  showExit?:boolean
  showSkip?:boolean
  isEnd?:boolean
  hideHeader?:boolean
  onEdit?:()=>void
  isPublished?:boolean
  isSecondLastStep?:boolean
  error?: string
  setErr?: Dispatch<SetStateAction<string>>
}

export const ListingBaseScreen: React.FC<TlistingBaseScreen> = (props) => {
    const nav = useNavigation();

    return (
        <ScreenBase>
            {
                !props.hideHeader &&
                    <View style={[{width: '100%',alignSelf: 'center',paddingHorizontal: 24}]}>
                        {
                            !props.hideHeader
                                ? !props.isCreated
                                    ? <View style={[Layout.rowBetween]}>
                                        <TouchableOpacity onPress={() => nav.navigate('Home')}>
                                            <Text style={[FontProperty.button_1, styles.cancelTextContainer]}>Cancel</Text>
                                        </TouchableOpacity>
                                    </View>
                                    : <View style={[Layout.rowBetween]}>

                                        {props.showExit && (<TouchableOpacity onPress={() => { nav.navigate('Platform'); }}>
                                            <Text style={[FontProperty.button_1, styles.cancelTextContainer, FontProperty.TextTeal]}>Save & Exit</Text>
                                        </TouchableOpacity>
                                        )}

                                        {props.showSkip && (<TouchableOpacity onPress={() => { nav.navigate('Platform'); }}>
                                            <Text style={[FontProperty.button_1, styles.cancelTextContainer]}>Skip</Text>
                                        </TouchableOpacity>
                                        )}

                                    </View>
                                : <View style={{ paddingTop: 30 }}></View>
                        }

                        {props.header &&
                            <Text style={[FontProperty.h1, styles.headerContainer]}>
                                {props.header}
                            </Text>
                        }
                    </View>
            }
            <ScrollView style={[{flex:1}, !props.isEnd && {paddingHorizontal: 24}]}>
                {props.children}
            </ScrollView>
            <View style={[styles.bottomContainer]}>
                {!props.isEnd && 
                    <View style={styles.stepCounterContainer}>
                        <StepBarCounter step={props.step} totalSteps={8} />
                    </View>
                }
                <View style={[Layout.rowBetween, { backgroundColor: '#fff',paddingTop:16}]}>
                    <View style={[styles.buttonContainer]} >
                        <NavyOutlineButton onPress={() => { props.onEdit ? props.onEdit() : nav.goBack(); }}>
                            <Text style={[FontProperty.button_1, FontProperty.TextNavy]}>Back</Text>
                        </NavyOutlineButton>
                    </View>
                    <View style={[styles.buttonContainer]} >
                        {
                            !props.loading &&
                            props.isEnd
                                ? props.isPublished
                                    ? <TealButton onPress={props.nextScreenHandler}>
                                        <Text style={[FontProperty.button_1]}>Complete</Text>
                                    </TealButton>
                                    : <TealButton onPress={props.nextScreenHandler}>
                                        <Text style={[FontProperty.button_1]}>Publish</Text>
                                    </TealButton>
                                : <NavyButton onPress={props.nextScreenHandler}>
                                    <Text style={[FontProperty.button_1]}>{props.isSecondLastStep ? 'Preview' : (props.isPublished ? 'Update' : 'Next')}</Text>
                                </NavyButton>
                        }

                    </View>
                </View>
            </View>
            { props.error !== '' &&  props.error !== undefined &&  props.error !== null && 
                <WarningBar text={props.error} icon='exclamation-triangle' backgroundcolor={Colors.red} bottom={160} setErr={props.setErr} />
            }
        </ScreenBase>
    );
};

const styles = StyleSheet.create({
    cancelTextContainer: {
        paddingVertical: 43,
        color: Colors.grey4
    },
    headerContainer: {
        paddingBottom: 20
    },
    buttonContainer: {
        width: (Layout.window.width - 64)/2,
        backgroundColor: '#fff'
    },
    bottomContainer: {
        justifyContent: 'flex-end',
        paddingBottom: 6,
        paddingHorizontal: 24,
        backgroundColor: '#fff',
        boxShadow: Layout.window.width > 425 ? 'none' : '0px -6px 10px rgba(0, 0, 0, 0.1)'
    },
    stepCounterContainer: {
        paddingBottom: 18,
        backgroundColor: '#fff',
        paddingTop: 10

    }

});
