import { collection, doc, DocumentSnapshot, getDoc, getDocs, query, QuerySnapshot, orderBy, where } from 'firebase/firestore';
import { db } from '../../../config/firebase';
import { IdeleteReason, IuserData } from './types';
import { AuthUser, getCurrentUser } from "aws-amplify/auth";

export const getUserData = async (userId : string) : Promise<AuthUser> => {
    try {
        let user = await getCurrentUser();
        return user;
/*
        return <IuserData>{
            username: 'Joel',
            isOnboarded: true,
            headshot: 'https://upload.wikimedia.org/wikipedia/commons/f/fe/Michelle_Borromeo_Actor_Headshots_30.jpg',
            favouriteImages: [],
            onboardQuestions: [],
            receiveMessage: false,
            receiveNotification : false,
        };
        */
        
        // const userDataRef = doc(collection(db, 'userData'), userId);
        // const userData = await getDoc(userDataRef);
        // return userData as DocumentSnapshot<IuserData>;
    } catch (error) {
        throw new Error(error);
    }
};

export const getDeleteReasons = async () : Promise<QuerySnapshot<IdeleteReason>> => {
    try {
        const deleteReasonsRef = collection(db, 'deleteReasons');

        const deleteReasonsQuery = query(deleteReasonsRef, orderBy('weight', 'desc'));
        const deleteReasonsSnapshot = await getDocs(deleteReasonsQuery);

        return deleteReasonsSnapshot as QuerySnapshot<IdeleteReason>;
    } catch (err) {
        throw new Error(err);
    }
};

const getMultipleRandom = (arr:[], num:number) => {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
};

const findCommonElementsByUUID = (arr1:any[], arr2:any[]) =>{
    return arr1.some(item => arr2.includes(item.uuid));    
};

export const getSuggestedProperties = async (userId : string, propertyTypes : string[], propertyStyles : string[]) => {
    try {
        const followRef = query(collection(db, 'followProperties'), where('terms', 'array-contains',  '_' + userId), orderBy('createdAt', 'desc'));
        const followData = await getDocs(followRef);
        const myFollowings = [];
        followData.docs.map(followdt => (
            myFollowings.push(followdt.data().terms[0].replace('_', ''))
        ));

        const propertyDataRef = query(collection(db, 'properties'), where('published', '==', true));
        const querySnapshot = await getDocs(propertyDataRef);
        const suggestedProperties = [];
        querySnapshot?.docs.map(
            doc => {
                if(doc.data().userId !== userId){
                    let showThisProperty = false;
                    showThisProperty === false &&  propertyTypes.includes(doc.data().type) && (showThisProperty = true);
                    doc.data().styles !== undefined && doc.data().styles.length > 0 &&
                    findCommonElementsByUUID(doc.data().styles, propertyStyles) && (showThisProperty = true);
                    !myFollowings.includes(doc.id) && showThisProperty && suggestedProperties.push(doc);
                }
            });
        return getMultipleRandom(suggestedProperties, 10) as any[];

        // const followRef = query(collection(db, 'followUsers'), where('terms', 'array-contains',  '_' + userId), orderBy('createdAt', 'desc'));
        // const followData = await getDocs(followRef);
        // const myFollowings = [];
        // followData.docs.map(followdt => (
        //     myFollowings.push(followdt.data().terms[0].replace('_', ''))
        // ));

        // const userDataRef = query(collection(db, 'userData'));
        // const querySnapshot = await getDocs(userDataRef);
        // const suggestedUser = [];
        // querySnapshot?.docs.map(
        //     doc => {
        //         if(doc.data().isOnboarded && doc.id !== userId){
        //             const ownerType = doc.data().onboardQuestions[3];
        //             const ownerStyle = doc.data().onboardQuestions[4];
        //             let showThisUser = false;

        //             userTypes.forEach(userType => {
        //                 ownerType.includes(userType) && showThisUser === false && (showThisUser = true);
        //             });

        //             userStyles.forEach(userStyle => {
        //                 ownerStyle.includes(userStyle) && showThisUser === false && (showThisUser = true);
        //             });

        //             !myFollowings.includes(doc.id) && showThisUser && suggestedUser.push(doc);
        //         }
        //     });
        // return getMultipleRandom(suggestedUser, 10) as any[];
    } catch (err) {
        throw new Error(err);
    }
};