import React from 'react';
import { StyleSheet, Text, View, Image } from 'react-native';
import IMAGE from '../../assets/images';
import Colors from '../../constants/colors';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import userStore from '../../store/userStore';

export type THomeOwnerHeadshot = {
    name : string,
    imgUrl : string

}

export const ProfileHeadshot : React.FC< THomeOwnerHeadshot > = (props) => {
    // Get people headshot and name from targetPeopleID
    return (
        <View style={Layout.row}>
            <View style={styles.headshotContainer}>
                <Image source={props.imgUrl} style={[styles.headshot]} />
            </View>
            <Text style={[FontProperty.h2, FontProperty.TextBlack]}>{props.name}</Text>
        </View>
    );
};

export const HomeOwnerHeadshot : React.FC = (props) => {
    const name = userStore.name;
    const imgUrl = userStore.userImgUrl;

    return (
        <ProfileHeadshot name={name} imgUrl={imgUrl}/>
    );
};

const styles = StyleSheet.create({
    headshotContainer : {
        width: 45,
        height: 45,
        borderRadius: 45,
        overflow: 'hidden',
        marginRight: 13,
    },
    headshot : {
        width: 45,
        height: 45,
        resizeMode: 'cover',
        borderRadius: 45
    }
});

export default HomeOwnerHeadshot;
