import React from 'react';
import { StyleSheet, View, Text, Pressable, TouchableOpacity } from 'react-native';
import Layout from '../../constants/Layout';
import FontProperty from '../../constants/FontProperty';
import { TealButton, NavyButton } from '../Button';
import Colors from '../../constants/colors';
import { Ionicons } from '@expo/vector-icons';

export type TPopUpPropertyModal = {
    title : string
    subtitle ? : string
    yesBtnLabel ? : string
    noBtnLable ? : string
    yesBtnOnClick : () => void
    noBtnOnClick : () => void
    darktheme ? : boolean
}
const PopupNotifyUser = (props:TPopUpPropertyModal) => {
    return (
        <View style={styles.popUpContainer}>
            <View style={styles.popUpInnerContainer}>
                <TouchableOpacity onPress={()=>props.noBtnOnClick()}>
                    <Ionicons name='close' size={20} style={styles.MenuClose_big} color={Colors.black}/>
                </TouchableOpacity>
                <View>
                    <Text style={[FontProperty.h1,{marginBottom:24}]}>{props.title}</Text>
                    {props.subtitle && <Text style={[FontProperty.p1,{marginBottom:40}]}>{props.subtitle}</Text> }
                </View>
                <View style={{justifyContent:'flex-end',alignItems:'center',flexDirection:'row'}}>
                    {props.noBtnLable && <Pressable onPress={()=>props.noBtnOnClick()}><Text style={[FontProperty.p2, {textAlign:'center',marginBottom:10,marginRight:30}]}>{props.noBtnLable}</Text></Pressable>}
                    {props.yesBtnLabel && props.darktheme === undefined && <TealButton onPress={()=>props.yesBtnOnClick()} isDisabled ={false} ><Text style={[FontProperty.TextWhite, FontProperty.TextSemiBold, FontProperty.p2]}>{props.yesBtnLabel}</Text></TealButton>}
                    {props.yesBtnLabel && props.darktheme && <NavyButton onPress={()=>props.yesBtnOnClick()} isDisabled ={false} ><Text style={[FontProperty.TextWhite, FontProperty.TextSemiBold, FontProperty.p2]}>{props.yesBtnLabel}</Text></NavyButton>}
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    popUpContainer: {
        position:'absolute',
        zIndex: 3,
        width: '100%',
        height: Layout.window.height,
        backgroundColor: '#000000cc',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 24,
    },
    popUpInnerContainer: {
        width: Layout.window.width > 425 ? 360 : '100%',
        backgroundColor: Colors.white,
        borderRadius: 16,
        padding: 32
    },
    MenuClose_big: {
        width: 21,
        height: 21,
        marginBottom: 16,
        alignSelf: 'flex-end'
    },
});

export default PopupNotifyUser;
