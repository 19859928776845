import React, { Dispatch, SetStateAction, useState } from 'react';
import {StyleSheet } from 'react-native';
import Colors from '../../constants/colors';
import DropDownPicker from 'react-native-dropdown-picker';


export type THouspoDropdown = {
  open : boolean
  value : string
  items : { label: string; value: string; }[]
  setOpen : Dispatch<SetStateAction<boolean>>
  setValue : Dispatch<SetStateAction<string>>
  setItems : Dispatch<SetStateAction<{ label: string; value: string; }[]>>
  placeholder : string
  narrowPadding ? : boolean
}

const HouspoDropdown = (props: THouspoDropdown) => {
    return (
        <DropDownPicker
            open={props.open}
            value={props.value}
            items={props.items}
            setOpen={props.setOpen}
            setValue={props.setValue}
            setItems={props.setItems}
            placeholder={props.placeholder}
            containerStyle={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
                borderWidth: 1,
                borderColor: Colors.grey5,
                borderRadius: 4,
                paddingHorizontal: 16,
                paddingVertical: (props.narrowPadding !== undefined && props.narrowPadding) ? 8 : 19,
                marginBottom: 16,
                
            }}
            listItemContainerStyle={{
                backgroundColor: Colors.white,
                paddingHorizontal: 16,
                paddingVertical: 8.5,
                flexDirection: 'row',
            }}
            selectedItemLabelStyle={{
                color: Colors.teal
            }}
            dropDownContainerStyle={{
                borderWidth: 1,
                borderColor: Colors.grey5,
                top: (props.narrowPadding !== undefined && props.narrowPadding) ? 33 : 54,
                justifyContent: 'space-between',
                left: 0
            }}
            textStyle={{
                color: Colors.grey1,
                fontSize: 14,
            }}
            tickIconStyle={{
                borderColor: '#4F4F4F',
                width: 12,
                height: 12,
            }}
            arrowIconStyle={{
                width: 12,
                height: 12,
                marginLeft: 8
            }}
            theme="LIGHT"
            style={styles.dropdown}
        />
    );
};

const styles = StyleSheet.create({
    dropdown : {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1
    },
});

export default HouspoDropdown;
