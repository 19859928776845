import React, {useEffect, useState, useRef} from 'react';
import { Pressable, StyleSheet, Text, View, Image, } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { AddListingTypeBasicProps } from '../../types';
import { useAuthentication } from '../../hooks/useAuthentication';
import Colors from '../../constants/colors';
import { ScrollView } from 'react-native-gesture-handler';
import FontProperty from '../../constants/FontProperty';
import Layout from '../../constants/Layout';
import { IpropertyData, IpropertyStatus, IpropertyType } from '../../lib/api/Content/types';
import { getPropertyContent, getPropertyTypeName, getPropertyStatus, updatePropertyPublish } from '../../lib/api/Content/property';
import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import { getUserData } from '../../lib/api/user';
import { IuserData } from '../../lib/api/user/types';
import IMAGE from '../../assets/images';
import { Feather } from '@expo/vector-icons';
import { numFormatter } from '../../lib/utils/numFormatter';
import PropertyGallery from '../../components/atom/PropertyGallery';
import StatusPill from '../../components/atom/StatusPill';
import PopUpGalleryModal from '../../components/atom/PopUpGalleryModal';
import { ListingBaseScreen } from './ListingBaseScreen';
import PopUpPropertyModal from '../../components/atom/PopUpPropertyModal';
import PopupNotifyUser from '../../components/atom/PopUpNotifyUser';
import PropertyToPost from '../../components/atom/PropertyToPost';
import { uppercaseFirstLetter } from '../../lib/utils/uppercaseFirstLetter';
import Hyperlink from 'react-native-hyperlink';

export const ReviewListingScreen: React.FC = (props) => {
    const nav = useNavigation();
    const { user } = useAuthentication();
    const { propertyId } = useRoute<AddListingTypeBasicProps>().params;
    const [loading, setLoading] = useState(true);
    const [propertyContent, setPropertyContent] = useState<IpropertyData>();
    const [ownerData, setOwnerData] = useState<IuserData>();
    const [propertyType, setPropertyType] = useState<IpropertyType>();
    const [propertyStatus, setPropertyStatus] = useState<IpropertyStatus>();
    const [popUpGalleryIndex, setPopUpGalleryIndex] = useState(0);
    const [popUpGallery, setPopUpGallery] = useState(false);
    const [notifyPop, setNotifyPop] = useState(false);
    const [publishPop, setPublishPop] = useState(false);
    const [published, setPublisted] = useState(false);
    const [postProperty, setPostProperty] = useState(false);

    useEffect(() => {
        getPropertyContent(propertyId).then(res => {
            setPropertyContent(res[0]);
            setLoading(false);
            res[0] !== undefined && res[0].published !== undefined && res[0].published === true && setPublisted(true);
        }
        );
    }, [propertyId]);

    useEffect(() => {
        propertyContent?.userId &&
      getUserData(propertyContent.userId).then(ownerData => {
          setOwnerData(ownerData.data());
      });

        propertyContent?.type &&
      getPropertyTypeName(propertyContent.type).then(res => {
          setPropertyType(res[0].type);
      });

        propertyContent?.status &&
      getPropertyStatus(propertyContent.status).then(status => {
          setPropertyStatus(status);
      });

    }, [propertyContent?.userId, propertyContent?.uuid]);

    if(loading || propertyContent === undefined || ownerData === undefined || propertyStatus === undefined) {
        return null;
    }

    const onNextStep = async () => {
        await updatePropertyPublish(propertyId, true);
        setNotifyPop(true);
    };


    return (
        <>
            <ListingBaseScreen
                step={8}
                nextScreenHandler={onNextStep}
                hideHeader={true}
                isEnd={true}
                isPublished={published}
            >
                <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={[Layout.scrollViewPadding]}>
                    {
                        propertyStatus !== undefined &&
                  <StatusPill data={propertyStatus}/>
                    }
                    {/* Gallery */}
                    {
                        propertyContent.images !== undefined && 
                  <PropertyGallery images={propertyContent.images} setPopUpGalleryIndex={setPopUpGalleryIndex} setPopUpGallery={()=>setPopUpGallery(true)} popUpGallery={popUpGallery} imageStyle='cover'/>
                    }
                    {/* Property Name */}
                    <Text style={[FontProperty.h0, Layout.globalPadding24, {marginTop:16}]}>{propertyContent.propertyName}</Text>
                    {/* Address */}
                    <Text style={[FontProperty.body_1, Layout.globalPadding24, FontProperty.TextGrey, {marginBottom:20}]}>{propertyContent.address[0].replace(/[0-9]/g, '').trim() + ', ' + propertyContent.address[1] + ', ' + propertyContent.address[2] + ' ' + propertyContent.address[3]}</Text>
                
                    {/* Bathroom, Bedroom, Carpark, LandSize, PropertyType */}
                    <View style={[Layout.row, Layout.globalPadding24, {alignItems:'center'}]}>
                        {
                            propertyContent.bedroom !== undefined && propertyContent.bedroom > 0 &&
                      <View style={[Layout.row, {marginRight:11,alignItems:'center'}]}>
                          <Ionicons name='bed-sharp' size={15} color='#939393' />
                          <Text style={[FontProperty.smallLabel, FontProperty.TextSemiBold, {marginLeft:5,color:'#939393'}]}>{propertyContent.bedroom}</Text>
                      </View>
                        }
                        {
                            propertyContent.bathroom !== undefined && propertyContent.bathroom !== 0 &&
                      <View style={[Layout.row, {marginRight:11,alignItems:'center'}]}>
                          <MaterialIcons name='bathtub' size={15} color='#939393' />
                          <Text style={[FontProperty.smallLabel, FontProperty.TextSemiBold, {marginLeft:5,color:'#939393'}]}>{propertyContent.bathroom}</Text>
                      </View>
                        }
                        {
                            propertyContent.carpark !== undefined && propertyContent.carpark !== 0 &&
                      <View style={[Layout.row, {marginRight:11,alignItems:'center'}]}>
                          <Ionicons name='car' size={16} color='#939393' />
                          <Text style={[FontProperty.smallLabel, FontProperty.TextSemiBold, {marginLeft:5,color:'#939393'}]}>{propertyContent.carpark}</Text>
                      </View>
                        }
                        {
                            propertyContent.landSize !== undefined &&
                      <View style={[Layout.row, {marginRight:11,alignItems:'center'}]}>
                          <Image source={IMAGE.landSize} style={{resizeMode:'contain',width:14,height:14}}/>
                          <Text style={[FontProperty.smallLabel, FontProperty.TextSemiBold, {marginLeft:5,color:'#939393'}]}>{propertyContent.landSize}㎡</Text>
                      </View>
                        }
                        {
                            propertyType !== undefined &&
                      <Text style={[FontProperty.smallLabel, FontProperty.TextSemiBold, {color:'#939393'}]}>{propertyType}</Text>
                        }
                    </View>
                    {/* Price Estimation */}
                    {propertyContent.showEstimatePrice == true &&
                  <>
                      <View style={styles.sectionShadow}></View>
                      <View style={[Layout.globalPadding24, {paddingTop:24,alignItems:'center'}]}>
                          <Text style={[FontProperty.smallLabel, FontProperty.TextGrey, {marginBottom:3}]}>Owner’s Price Estimate</Text>
                          <Text style={[FontProperty.TextTeal, {fontSize:17}, FontProperty.TextSemiBold]}>
                              ${numFormatter(propertyContent.estimateLowPrice)} - ${numFormatter(propertyContent.estimateHighPrice)}
                          </Text>
                      </View>
                  </>
                    }
                    {/* Details */}
                    {propertyContent.description !== undefined && propertyContent.description !== '' &&
                  <>
                      <View style={styles.sectionShadow}></View>
                      <View style={Layout.globalPadding24}>
                          <Text style={[FontProperty.h1, styles.headerContainer]}>The details</Text>
                          <Hyperlink linkDefault={ true } linkStyle={{color: '#00b7a1'}}><Text style={[FontProperty.body_1, FontProperty.TextGrey]}>
                              {uppercaseFirstLetter(propertyContent.description)}
                          </Text></Hyperlink>
                      </View>
                  </>
                    }
                    {/* Neighbourhood */}
                    {propertyContent.neighbourhoodDescription !== undefined && propertyContent.neighbourhoodDescription !== '' &&
                <View style={Layout.globalPadding24}>
                    <Text style={[FontProperty.h1, styles.headerContainer]}>The neighbourhood</Text>
                    <Hyperlink linkDefault={ true } linkStyle={{color: '#00b7a1'}}><Text style={[FontProperty.body_1, FontProperty.TextGrey]}>
                        {propertyContent.neighbourhoodDescription}
                    </Text></Hyperlink>
                </View>
                    }
                    {/* Owner */}
                    {propertyContent.userId !== undefined && propertyContent.userId !== '' &&
                <Pressable style={[Layout.globalPadding24,{marginBottom:26}]}>
                    <Text style={[FontProperty.h1, styles.headerContainer]}>Owner details</Text>
                    <View style={[Layout.rowBetween, {marginTop:8}]}>
                        <Image source={(ownerData.headshot && ownerData.headshot !== '') ? ownerData.headshot : IMAGE.userProfile} style={{resizeMode:'cover',width:54,height:54,borderRadius:54}}/>
                        <View style={{flex:1,paddingLeft:21}}>
                            <Text style={[FontProperty.body_1, {marginBottom:2}]}>{ownerData.username}</Text>
                        </View>
                        <View style={Layout.row}>
                            <Feather name='user' size={20} color={Colors.teal} />
                            <Text style={[FontProperty.TextTeal, FontProperty.TextMedium, FontProperty.smallLabel, {marginLeft:4}]}>Profile</Text>
                        </View>
                    </View>
                </Pressable>
                    }
                    {/* Styles */}
                    {propertyContent.style !== undefined && propertyContent.style.length > 0 &&
                  <>
                      <View style={styles.sectionShadow}></View>
                      <View style={Layout.globalPadding24}>
                          <Text style={[FontProperty.h1, styles.headerContainer]}>Styles</Text>
                          <View style={[Layout.row, styles.tagContainer]}>
                              {
                                  propertyContent.style?.map((style) => {
                                      return (
                                          <View key={style.uuid} style={[{
                                              borderColor: Colors.lightBeige,
                                              borderWidth: 1,
                                              borderRadius: 5,
                                              paddingVertical: 6,
                                              paddingHorizontal: 15,
                                              marginRight: 10,
                                              marginBottom: 10
                                          }]}>
                                              <Text style={[FontProperty.h2, FontProperty.TextBeige]}>{style.name}</Text>
                                          </View>
                                      );
                                  })
                              }
                          </View>
                      </View>
                  </>
                    }
                    {/* Tags */}
                    {
                        propertyContent.tags !== undefined && propertyContent.tags.length > 0 &&
                  <View style={Layout.globalPadding24}>
                      <Text style={[FontProperty.h1, styles.headerContainer]}>Property feature tags</Text>
                      <View style={[Layout.row, styles.tagContainer]}>
                          {
                              propertyContent.tags?.map((tag, index:number) => {
                                  return (
                                      <View key={index} style={[{
                                          backgroundColor: Colors.lightBeige,
                                          borderRadius: 5,
                                          paddingVertical: 6,
                                          paddingHorizontal: 15,
                                          marginRight: 10,
                                          marginBottom: 10
                                      }]}>
                                          <Text style={[FontProperty.h2, FontProperty.TextBeige]}>{tag}</Text>
                                      </View>
                                  );
                              })
                          }
                      </View>
                  </View>
                    }
                </ScrollView>
          
                
            </ListingBaseScreen>
            {
                notifyPop &&
                <PopupNotifyUser 
                    title='Notify your followers?'
                    subtitle={!published ? 'Notify your followers about your new property by auto generating a post on your wall.' : 'Notify your followers about the changes to your property by auto generating a post on your wall.'}
                    yesBtnLabel='Sure' 
                    noBtnLable='Skip' 
                    yesBtnOnClick={()=>{setPostProperty(true);}}
                    noBtnOnClick={()=>{setNotifyPop(false);setPublishPop(true);}}
                />
            }
            {
                !published && publishPop &&
                    <PopUpPropertyModal 
                        title='Property listed!'
                        yesBtnLabel='View Property Profile' 
                        yesBtnOnClick={()=>nav.navigate('Platform', { screen: 'PropertyProfile', params: {propertyId: propertyContent?.uuid, updated: Date.now()} })}
                    />
            }
            {
                published && publishPop &&
                    <PopUpPropertyModal 
                        title='Property Updated!'
                        yesBtnLabel='View Property Profile' 
                        yesBtnOnClick={()=>nav.navigate('Platform', { screen: 'PropertyProfile', params: {propertyId: propertyContent?.uuid, updated: Date.now()} })}
                    />
            }
            
            {
                popUpGallery &&
                <PopUpGalleryModal 
                    userId={user !== false && user?.uid} 
                    images={propertyContent.images} 
                    index={popUpGalleryIndex} 
                    setPopUpGallery={()=>setPopUpGallery(false)} 
                    allowFavourate={false}
                    autoPlay={false}
                />
            }
            {
                postProperty &&
                <PropertyToPost 
                    userId={propertyContent.userId}
                    propertyId={propertyId}
                    propertyName={propertyContent.propertyName}
                    propertyDesc={uppercaseFirstLetter(propertyContent.description)}
                    propertyImage={propertyContent.images[0].viewUrl}
                    cancelBtnOnClick={()=>setPostProperty(false)}
                />
            }
        </>
    );
};

const styles = StyleSheet.create({
    headerContainer: {
        paddingTop: 24,
        marginBottom: 12
    },
    tagContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginVertical: 8
    },
    screenFooter: {
        paddingBottom: 20,
        boxShadow: '0px -6px 10px rgba(0, 0, 0, 0.1)',
        paddingHorizontal: 14,
        alignItems: 'center',
    },
    btnStyle: {
        paddingHorizontal: 30,
        marginTop : 16,
        width: Layout.window.width > 425 ? 360 : '100%',
        flex: 1,
        marginHorizontal: 10,
    },
    sectionShadow: {
        height: 1,
        width: '100%',
        backgroundColor: '#4f4f4f4d',
        marginTop: 24
    },
});
